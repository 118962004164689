import type {
  PostBillingAddressRequest,
  PostDeliveryAddressRequest,
} from '@/data/api/frontApi/address/addressTypes'
import {
  frontApiPostBillingAddress,
  frontApiPostDeliveryAddress,
} from '@/services/api/front/address'
import {
  frontApiPutCheckoutBillingAddress,
  frontApiPutCheckoutDeliveryAddress,
} from '@/services/api/front/checkout'
import { toastError, toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'

// TODO: checkout v2 SPA page in progress src/pages/PageCheckout2/AddressSection.vue but not yet use
// expect one of three props must exist when we create address for new destination(warehouse)
// if we are here that means shoppingCartChange=true || productsAvailabilityChange=true
// and we always reload page to HP after change or create address
async function checkoutConfirmLogic(
  changeAddressId: string | null,
  addressDraft: string | null,
  billingDraft: string | null,
  closeSwitch: () => void,
  onConfirm: () => void,
) {
  try {
    if (changeAddressId) {
      await frontApiPutCheckoutDeliveryAddress(Number(changeAddressId))
    }

    if (addressDraft) {
      const address = JSON.parse(addressDraft) as PostDeliveryAddressRequest
      const {
        data: { id },
      } = await frontApiPostDeliveryAddress(address)

      await frontApiPutCheckoutDeliveryAddress(id)
    }

    if (billingDraft) {
      const billingAddress = JSON.parse(billingDraft) as PostBillingAddressRequest

      const {
        data: { id },
      } = await frontApiPostBillingAddress(billingAddress)

      await frontApiPutCheckoutBillingAddress(id)
    }

    onConfirm()
    toastSuccess(t('destinationSwitchMessage'))
  } catch (error) {
    closeSwitch()
    toastError(t('checkout.subDestinationSwitch.error'))
  }
}

export default checkoutConfirmLogic
