import type { RouteLocationNormalizedLoaded } from 'vue-router'

import { LocalStorageKeys } from '@/constants/storageKeys'
import { toastInfo } from '@/services/toast'
import { t } from '@/services/translations'

import { storageRetrieveLocal, storagePersistLocal, storageRemoveLocal } from './storage'

type Voucher = {
  code: string
  type?: string
}

const KEEP_VOUCHER_DAYS = 1

function _saveVoucher(code: string, type: string) {
  const voucher: Voucher = { code, type }
  storagePersistLocal(LocalStorageKeys.voucher, voucher, KEEP_VOUCHER_DAYS)
}

function _removeVoucher() {
  storageRemoveLocal(LocalStorageKeys.voucher)
}

function _showToastWithVoucher() {
  const { code } = storageRetrieveLocal<Voucher>(LocalStorageKeys.voucher) || {}

  if (code) {
    toastInfo(t('voucherReminder', { voucherCode: code }), { hasLongerTimeout: true })
  } else {
    _removeVoucher()
  }
}

function initVoucherMessage(route: RouteLocationNormalizedLoaded) {
  const { voucherCode, voucherType } = route.query

  if (route.name === 'PageThankYou') {
    _removeVoucher()
  } else if (route.name === 'TransportPayment') {
    _showToastWithVoucher()
  } else if (typeof voucherCode === 'string' && typeof voucherType === 'string') {
    _saveVoucher(voucherCode, voucherType)
    _showToastWithVoucher()
  }
}

export { initVoucherMessage }
