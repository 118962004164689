import { required, max, is } from '@vee-validate/rules'
import dayjs from 'dayjs'
import { defineRule } from 'vee-validate'

import { t, tc } from '@/services/translations'
import { isFuture } from '@/utils/date'

import { specialChars, validStringRegex } from './specialChars'

const defineRequired = () => {
  defineRule('required', required)
}

const defineMax = () => {
  defineRule('max', max)
}

const defineIs = () => {
  defineRule('is', is)
}

/**
 * Validates passed in date for correct format and allowed date span.
 * @param dateString - Date that should be validated
 * @param params - Parameter's object
 * @param params.allowFutureDate - Whether future date should be allowed. Default is true
 * @param params.maxYears - Maximum number of years to past from @dateString
 */
const _validateDate = (
  dateString: string,
  params: { allowFutureDate: boolean; maxYears: number | undefined } = {
    allowFutureDate: true,
    maxYears: undefined,
  },
) => {
  const validCharsRegex = /^[0-9.]+$/
  if (!validCharsRegex.test(dateString)) {
    return t('validations.validateDate.invalidCharacters')
  }

  const dateFormatRegex = /^(\d{1,2})\.(\d{1,2})\.(\d{4})$/
  if (!dateFormatRegex.test(dateString)) {
    return t('validations.validateDate.wrongFormat')
  }

  const dateInstance = dayjs(`${dateString} 23:59:59`, 'D.M.YYYY HH:mm:ss')
  if (!dateInstance.isValid()) {
    return t('validations.validateDate.wrongDate')
  }

  if (!params.allowFutureDate && isFuture(dateInstance)) {
    return t('validations.validateDate.wrongDate')
  }

  if (params.maxYears) {
    const limitDate = dayjs().subtract(params.maxYears, 'years')

    if (dateInstance.isBefore(limitDate)) {
      return tc('validations.validateDate.expiredDate', params.maxYears, { limit: params.maxYears })
    }
  }

  return true
}

const _noSpecialChars = (textString: string) => {
  if (!validStringRegex.test(textString)) {
    return t('validations.noSpecialChars') + ': ' + specialChars
  }

  return true
}

const _validatePersonName = (textString: string) => {
  const pattern = `^[a-zA-ZàáâäãåąčćďęèéêëřėěįìíîïłľńňòóôöõøùúûüůųūÿýťżźñçšžÀÁÂÄÃÅĄĆČĎĖĘÈÉÊËĚÌÍÎÏĮŁĽĹŃŇÒÓÔÖÕØÙÚÛÜŲŪŔŤŸÝŻŹÑßÇŒÆŠŘŽ∂ð ,.'-]+$`

  if (!new RegExp(pattern).test(textString)) {
    return t('validations.validatePersonName')
  }

  return true
}

const _validateEmail = (email: string) => {
  const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  if (!pattern.test(email)) {
    return t('validations.email')
  }

  return true
}

const defineEmail = () => {
  defineRule('email', _validateEmail)
}

const defineValidateDate = () => {
  defineRule('validateDate', _validateDate)
}

const defineNoSpecialChars = () => {
  defineRule('noSpecialChars', _noSpecialChars)
}

const defineValidatePersonName = () => {
  defineRule('validatePersonName', _validatePersonName)
}

export {
  defineRequired,
  defineMax,
  defineIs,
  defineValidateDate,
  defineNoSpecialChars,
  defineValidatePersonName,
  defineEmail,
  _validateEmail,
}
