<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

import { storeToRefs } from 'pinia'

import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type Category = {
  id: number
  name: string
  url: string
}

const props = defineProps<{
  categories: Category[]
  linkMore: string
}>()

const emit = defineEmits<{
  categoryClick: []
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)

const maxVisible = 4

const isShowMoreLink = computed(
  () => !breakpointDownMd.value && props.categories.length > maxVisible,
)

const categoriesFilter = computed(() =>
  breakpointDownMd.value ? props.categories : props.categories.slice(0, maxVisible),
)
</script>

<template>
  <div>
    <RouterLink
      v-for="category in categoriesFilter"
      :key="category.id"
      :to="category.url"
      class="nav-l3__link"
      @click.prevent="emit('categoryClick')">
      {{ category.name }}
    </RouterLink>

    <RouterLink
      v-if="isShowMoreLink"
      :to="linkMore"
      class="nav-l3__link nav-l3__link--other">
      + {{ t('navBar.rest') }}
    </RouterLink>
  </div>
</template>

<style lang="scss">
@use '@/legacy' as *;

.nav-l3 {
  &__link {
    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      display: flex;
      align-items: center;
      height: 45px;
      padding-left: 40px;
      border-top: 1px solid color('gray-light');

      & + & {
        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          content: '';
          background: linear-gradient(
            to right,
            color('white') 40px,
            color('gray-light') 40px,
            color('white') 75%
          );
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: block;
      padding: 4px 0 5px;

      &:hover {
        color: color('off-primary-mid');
      }
    }

    &--other {
      color: color('gray');
      text-transform: capitalize;
    }
  }
}
</style>
