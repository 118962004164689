import { LocalStorageKeys } from '@/constants/storageKeys'
import type {
  EmailProvider,
  EmailProviderClaims,
  SocialProvider,
  SocialProviderClaims,
} from '@/services/auth/aadTypes'
import getMsalInstance from '@/services/auth/getMsalInstance'
import { storagePersistLocal, storageRetrieveLocal } from '@/services/storage'

/**
 * To ensure backward compatibility, we must check if user has old claims without "idp" filed.
 * In that case, we use deprecated "idpForGtm" field to determine identity provider.
 * We can remove fallback after 1.1.2025, possibly this function as a whole can be removed,
 * because all users will have new claims by then.
 */
async function checkIdentityProvider(isUserSignedIn: boolean) {
  const identityProvider = storageRetrieveLocal<EmailProvider | SocialProvider>(
    LocalStorageKeys.identityProvider,
  )

  // Only for logged in users without proper provider key in storage.
  if (!isUserSignedIn || identityProvider) return

  const msalInstance = await getMsalInstance()
  const activeAccount = msalInstance.getActiveAccount()

  if (!activeAccount) {
    // If we're unable to get active account, we assume that the user is using email provider
    // even if it's not the case.
    storagePersistLocal(LocalStorageKeys.identityProvider, 'email')
    return
  }

  const claims = activeAccount.idTokenClaims as
    | EmailProviderClaims
    | SocialProviderClaims
    | undefined

  let provider: string | undefined = claims?.idp
  provider ??= claims?.idpForGtm === 'email_pass_oauth' ? 'email' : 'fallback'

  if (provider) storagePersistLocal(LocalStorageKeys.identityProvider, provider)
}

export default checkIdentityProvider
