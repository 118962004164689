<script setup lang="ts">
import { DeliverySlot } from '@/componentsPure'
import type { TimeslotTime } from '@/store/pinia/useTimeslotsStore'

import type { DeliverySlotsGroupName } from '../..'

type DeliveryTimeslotsListProps = {
  timeslots: TimeslotTime[]
  groupName: DeliverySlotsGroupName
  readonly?: boolean
}
const props = defineProps<DeliveryTimeslotsListProps>()
</script>

<template>
  <div class="delivery-timeslots-list">
    <DeliverySlot
      v-for="timeslot in timeslots"
      :key="timeslot.id"
      :group-name="props.groupName"
      :readonly="props.readonly"
      :timeslot="timeslot"
      class="timeslot" />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.delivery-timeslots-list {
  max-width: 541px;
  margin: auto;
}

.timeslot {
  margin-bottom: 2px;

  &:last-child {
    margin: 0;
  }
}
</style>
