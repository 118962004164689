import type { Product } from '@/composables/useProductItem'
import useUser from '@/composables/useUser'
import { LocalStorageKeys } from '@/constants/storageKeys'
import appConfig from '@/services/appConfig'
import { storageRetrieveLocal, storagePersistLocal } from '@/services/storage'
import useCartStore from '@/store/pinia/useCartStore'
import useProductsStore from '@/store/pinia/useProductsStore'

function getCheckboxStorageKey() {
  const { getUserId } = useUser()

  return `${LocalStorageKeys.charityCheckbox}-${getUserId}`
}

function setPersistValue() {
  const { getProductBySlug } = useProductsStore()

  if (!appConfig.charityProduct) {
    return
  }
  const productQuantity = getProductBySlug(appConfig.charityProduct)?.computed.quantity
  storagePersistLocal(getCheckboxStorageKey(), productQuantity)
}

function loadPersistValue(product: Product) {
  const { cartActions } = useCartStore()

  if (product.computed.quantity < 1) {
    const checkboxPersistValue = Number(storageRetrieveLocal<number>(getCheckboxStorageKey()))

    if (checkboxPersistValue > 0) {
      cartActions.setCartProductQuantity({
        product: {
          id: product.id,
          quantity: checkboxPersistValue,
        },
        increasing: true,
      })
    }
  }
}

export {
  setPersistValue as charityServiceSetPersistValue,
  loadPersistValue as charityServiceLoadPersistValue,
}
