import { localize } from '@vee-validate/i18n'
import { configure } from 'vee-validate'

import appConfig from '@/services/appConfig'
import { t, tc } from '@/services/translations'

export default {
  install() {
    configure({
      // First param of localize() fn had to be set, but it doesn't do anything, as the translations are from @/services/translations generated per domain
      generateMessage: localize(appConfig.langCode, {
        messages: {
          required: t('validations.required'),
          max: ({ rule }) => tc('validations.max', rule?.params?.[0], { max: rule?.params?.[0] }),
          is: t('validations.is'),
        },
      }),
      validateOnChange: true,
    })
  },
}
