<script setup lang="ts">
import { computed } from 'vue'

import { type VendorID, Vendors } from '@/constants/vendors'
import { t } from '@/services/translations'

type NoResultsProps = {
  vendorId?: VendorID
}

const props = defineProps<NoResultsProps>()

const message = computed(() => {
  return props.vendorId === Vendors.PHARMACY
    ? t('productSearch.sorryNoResultsFoundPharmacy')
    : t('productSearch.sorryNoResultsFound')
})
</script>
<template>
  <div
    class="text-gray"
    data-tid="product-search__no-results">
    {{ message }}
  </div>
</template>
