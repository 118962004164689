<script setup lang="ts">
import type { Dayjs } from 'dayjs'

import { t } from '@/services/translations'

type DeliveryTimeslotsGridTimelineProps = {
  timelineIndex: number
  timelineItem: Dayjs
}

const props = defineProps<DeliveryTimeslotsGridTimelineProps>()
</script>

<template>
  <div
    class="timeline"
    :style="{
      gridArea: `${props.timelineIndex + 1} / 1 / ${props.timelineIndex + 2} / 2`,
    }"
    v-html="
      t('delivery.timeline', {
        timeline: props.timelineItem.tz().hour(),
      })
    " />
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.timeline {
  @include make-font-scale(2);

  justify-self: end;
  padding-top: 8px;

  @include media-breakpoint-up(md) {
    padding-top: 11px;
  }
}
</style>
