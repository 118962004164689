<script setup lang="ts">
import { ref } from 'vue'
import { VueToggles } from 'vue-toggles'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpSm } = storeToRefs(deviceDetectorStore)

type AccordionType = {
  accordionHeading: string
  accordionText: string
  index: number
}

const props = defineProps<AccordionType>()

const active = defineModel<boolean>()

const showInfo = ref(false)
function toggleInfo() {
  showInfo.value = !showInfo.value
}
</script>
<template>
  <div class="cookie-toggle-container items-gap p-3 d-flex flex-column">
    <div class="d-flex align-items-sm-center justify-content-between">
      <div
        :class="[
          'pointer d-flex align-items-sm-center items-gap flex-column-reverse flex-sm-row',
          { 'mb-1': !breakpointUpSm && showInfo },
        ]"
        @click="toggleInfo">
        <Icon
          v-if="breakpointUpSm"
          icon="arrow-reversed"
          :class="{ 'cookie-arrow': showInfo }" />
        <div v-else-if="!breakpointUpSm && !showInfo">
          <Transition
            name="fade"
            appear>
            <div class="text-red">
              {{ t('privacySettings.displayInfo') }}
            </div>
          </Transition>
        </div>
        <span class="text-5 font-weight-bold">
          {{ props.accordionHeading }}
        </span>
      </div>
      <span
        v-if="props.index < 1"
        class="checked-text">
        {{ t('privacySettings.alwaysActive') }}
      </span>
      <VueToggles
        v-else
        v-model="active"
        checked-bg="#258334"
        unchecked-bg="#828D98"
        width="56"
        height="28" />
    </div>
    <Transition
      name="fade"
      appear>
      <div v-if="showInfo">
        <p class="text-4 mb-0">{{ props.accordionText }}</p>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.checked-text {
  color: color('focus');
}

.items-gap {
  gap: 8px;

  @include media-breakpoint-up(sm) {
    gap: 16px;
  }
}

.cookie-toggle-container {
  background-color: color('white');
  border-radius: var(--borderRadiusMedium);
}

.cookie-arrow {
  transform: rotate(180deg);
}
</style>
