<script setup lang="ts">
import { ref, computed, watch, useSlots } from 'vue'

import {
  analyticsLoggerPageScroll,
  analyticsLoggerWidgetShown,
} from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import type { Product } from '@/composables/useProductItem'
import PaginationInfinite from '@/components/global/PaginationInfinite.vue'
import ProductItemBox from '@/components/ProductItemBox'

import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useInteractionSource from '@/composables/useInteractionSource'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'
import { storeToRefs } from 'pinia'

const columnSizeClassMap = {
  2: 'col-6',
  3: 'col-4',
  4: 'col-3',
  5: 'w-20',
  6: 'col-2',
} as const

const { isCartPreviewOpenDesktop } = storeToRefs(useUserInterfaceStore())

const defaultColumnsSizes = computed(() => {
  return {
    xs: 2,
    sm: 3,
    md: 4,
    lg: isCartPreviewOpenDesktop.value ? 3 : 6,
    xl: isCartPreviewOpenDesktop.value ? 5 : 6,
  } as const
})

type ProductBoxListingProps = {
  products: Product[]
  isLoadingNew?: boolean
  isLoadingMore?: boolean
  hasMoreProducts?: boolean
  allProductsUrl?: string
  allProductsLabel?: string
  ghostCount?: number
  columnSizes?: ColumnSizes
  showHorizontalSeparators?: boolean
  onlyOneLine?: boolean
}

const props = withDefaults(defineProps<ProductBoxListingProps>(), {
  allProductsUrl: undefined,
  allProductsLabel: undefined,
  ghostCount: undefined,
  columnSizes: () => ({}),
  showHorizontalSeparators: true,
  onlyOneLine: false,
})

const emit = defineEmits<{
  loadMore: []
  productsRender: [renderedProducts: Product[], originalProducts: Product[]]
}>()

const deviceDetectorStore = useDeviceDetectorStore()

const loadMoreCount = ref(0)

const getColumns = computed(() => {
  return {
    ...defaultColumnsSizes.value,
    ...props.columnSizes,
  }
})

// @TODO TS: generics needed here beacuse getCurrentBootstrapBreakpointName returns just string and thus we can't use it safely as key in getColumns
const getActiveColumnsCount = computed<PossibleColumnCounts>(() => {
  return (
    getColumns.value[deviceDetectorStore.getCurrentBootstrapBreakpointName] || getColumns.value.xl
  )
})

const oneLineProductCounts = {
  xs: 4, //2x2
  sm: 3, //1x3
  md: 4, //2x2
  lg: 6, //2x3
  xl: 5,
} as const

const slots = useSlots()
const hasBeforeSlot = computed(() => !!slots.before)

/**
 * Number of finally displayed products depends on existence of
 * before slot and props.showOnlyOneLine
 */
const effectiveProducts = computed(() => {
  if (!props.onlyOneLine || props.products.length === 0) {
    return props.products
  }

  const minusSlot = hasBeforeSlot.value ? 1 : 0

  const count =
    oneLineProductCounts[deviceDetectorStore.getCurrentBootstrapBreakpointName] - minusSlot

  return props.products.slice(0, count)
})

// Let parent know what products end up being rendered
watch(
  () => effectiveProducts.value,
  () => {
    if (props.isLoadingNew || !effectiveProducts.value.length) return
    emit('productsRender', effectiveProducts.value, props.products)
  },
  { immediate: true },
)

const getProductBoxSizeClass = computed(() => columnSizeClassMap[getActiveColumnsCount.value])

const getFirstIndexLastRow = computed(
  () =>
    props.products.length -
    (props.products.length % getActiveColumnsCount.value || getActiveColumnsCount.value),
)

const getGhostProductsData = computed(() =>
  new Array(props.ghostCount ?? getActiveColumnsCount.value).fill(null),
)

function resetLoadMoreCount() {
  loadMoreCount.value = 0
}

function loadMore() {
  analyticsLoggerPageScroll(++loadMoreCount.value)
  emit('loadMore')
}

function hasSeparator(productIndex: number) {
  return ++productIndex % getActiveColumnsCount.value !== 1
}

watch(
  () => props.isLoadingNew,
  (newValue) => {
    if (newValue) {
      resetLoadMoreCount()
    }
  },
)

/* Analytics handling */

const interactionSource = useInteractionSource('ProductBoxListing')

// If products are loaded, analyticsEvent is triggered
watch(
  () => props.products,
  (newValue) => {
    if (newValue.length > 0) {
      analyticsLoggerWidgetShown(
        interactionSource({
          products: newValue,
        }),
      )
    }
  },
  { immediate: true },
)
</script>

<script lang="ts">
type PossibleColumnCounts = 2 | 3 | 4 | 5 | 6
export type ColumnSizes = {
  xs?: PossibleColumnCounts
  sm?: PossibleColumnCounts
  md?: PossibleColumnCounts
  lg?: PossibleColumnCounts
  xl?: PossibleColumnCounts
}
</script>

<template>
  <div
    class="text-center"
    data-tid="product-box-listing">
    <div
      v-show="!props.isLoadingNew"
      class="product-box-listing row no-gutters">
      <ProductItemBox
        v-for="(product, index) in effectiveProducts"
        :key="`product-${index}`"
        :order="index + 1"
        :product="product"
        :class="[
          getProductBoxSizeClass,
          {
            'horizontally-separated':
              (index < getFirstIndexLastRow || props.isLoadingMore) &&
              props.showHorizontalSeparators,
          },
        ]"
        :separated="hasSeparator(index)" />

      <slot
        name="after"
        :class-name="getProductBoxSizeClass" />
    </div>
    <div
      v-show="props.isLoadingMore || props.isLoadingNew"
      class="product-listing row no-gutters">
      <ProductItemBox
        v-for="(ghostProduct, index) in getGhostProductsData"
        :key="`ghost-product-${index}`"
        :product="ghostProduct"
        :class="getProductBoxSizeClass"
        :separated="hasSeparator(index)" />
    </div>
    <RouterLink
      v-if="props.allProductsUrl && !props.hasMoreProducts"
      class="product-box-listing__all-link"
      :to="{ path: allProductsUrl ?? '' }">
      {{ allProductsLabel || t('general.showAll') }}
    </RouterLink>
    <PaginationInfinite
      v-if="props.hasMoreProducts && !props.isLoadingNew && !props.onlyOneLine"
      v-show="!props.isLoadingMore"
      ghosts
      @load-next="loadMore" />
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

$item-border-width: 2px;

.product-box-listing {
  position: relative;
  z-index: $zix-base;

  &__all-link {
    display: inline-block;
    padding: 11px 24px;
    margin-top: 24px;
    background: color('white');
    border: 1px solid color('black');
    border-radius: 50rem;
    transition:
      background var(--baseTransitionTime),
      color var(--baseTransitionTime);

    @include media-breakpoint-up(md) {
      padding: 11px 88px;
    }

    &:hover,
    &:focus,
    &:active {
      color: color('white');
      text-decoration: none;
      background: color('black');
    }
  }
}

.horizontally-separated {
  margin-bottom: $item-border-width;

  &::after {
    position: absolute;
    bottom: -$item-border-width;
    left: 0;
    z-index: $zix-below;
    width: 100%;
    height: $item-border-width;
    content: '';
    background: color('gray-lighter');
  }
}
</style>
