import { ref } from 'vue'

import type { Product } from '@/composables/useProductItem'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { frontApiGetTopItems, type TopItemsCategory } from '@/services/api/front/topItems'
import useProductsStore from '@/store/pinia/useProductsStore'

let isFetched = false

const topProducts = ref<Product[]>([])
const topProduct = ref<Product | null>(null)
const topCategories = ref<TopItemsCategory[]>([])
const topQueries = ref<string[]>([])
const topBanner = ref<Banner>()

async function fetchTopItems() {
  const { setProducts, getProducts, getProduct } = useProductsStore()

  const rawResult = await frontApiGetTopItems()
  await setProducts(rawResult.data.products)
  const ids = rawResult.data.products.map((product) => product.id)
  topCategories.value = rawResult.data.categories
  topQueries.value = rawResult.data.queries
  topProduct.value = getProduct(ids[0])
  topProducts.value = getProducts(ids)
  topBanner.value = rawResult.data.banner
}

async function fetchTopItemsLazy() {
  if (!isFetched) {
    await fetchTopItems()
    isFetched = true
  }
}

export function useTopItems() {
  return {
    fetchTopItemsLazy,
    fetchTopItems,
    topProduct,
    topProducts,
    topCategories,
    topQueries,
    topBanner,
  }
}
