<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

type CartDeliveryInfoLabelProps = {
  headerMode?: boolean // used in CartPreviewHeader
}

const props = defineProps<CartDeliveryInfoLabelProps>()

const { isMinimalOrderPrice, getMinimalOrderPrice, getFreeDeliveryOrderPrice, hasFreeTimeslots } =
  useCartDeliveryData()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

function getRemainingLabel(key: string, remaining: number) {
  return t(key, { price: localizePrice(remaining) })
}

const minPriceLabel = computed(() => {
  if (getMinimalOrderPrice.value === undefined) return ''
  const remaining = getMinimalOrderPrice.value - cartGetters.value.getCartProductsRealPrice
  return getRemainingLabel('cart.progress.minimalOrderRemaining', remaining)
})

const freeDeliveryLabel = computed(() => {
  if (getFreeDeliveryOrderPrice.value === undefined) return ''
  const remaining = getFreeDeliveryOrderPrice.value - cartGetters.value.getCartProductsRealPrice
  return getRemainingLabel('cart.progress.freeDeliveryRemaining', remaining)
})

const deliveryLabel = computed(() => {
  if (!isMinimalOrderPrice.value) return minPriceLabel.value
  if (hasFreeTimeslots.value) return freeDeliveryLabel.value

  return t('addressSelector.timeslots.noFreeTimeslots')
})

const minimalPriceCompleted = computed(() => isMinimalOrderPrice.value && hasFreeTimeslots.value)
</script>

<template>
  <div
    :class="[
      'cart-delivery-info-label',
      'font-weight-bold',
      {
        'text-green': minimalPriceCompleted && !props.headerMode,
        'text-neutrals-70': minimalPriceCompleted && props.headerMode,
        'text-red': !isMinimalOrderPrice,
      },
    ]">
    {{ deliveryLabel }}
  </div>
</template>

<style scoped lang="scss">
.cart-delivery-info-label {
  padding: 2px 0;
  text-align: center;
}
</style>
