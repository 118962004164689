import { useQueryClient } from '@tanstack/vue-query'

import { notificationQueryKeys } from '@/constants/queryKeys'

// update vue-qeury state
function useNotificationsSetCount() {
  const queryClient = useQueryClient()

  function notificationsSetCount(count: number) {
    queryClient.setQueryData(notificationQueryKeys.count(), count)
  }

  return notificationsSetCount
}

export default useNotificationsSetCount
