<script setup lang="ts">
import { ref, computed } from 'vue'

import { uniqBy } from 'lodash-es'

import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { frontApiProductGetById } from '@/services/api/front/product'
import { toastApiErrorResponse } from '@/services/toast'
import useProductsStore from '@/store/pinia/useProductsStore'

import Widget from './components/Widget.vue'

type WidgetRecipeProps = {
  similarProductId: string
  title: string
  quantity: string
  excludeCategoryId: string
}

const props = defineProps<WidgetRecipeProps>()

const { fetchProductsSearch, setProduct, getProductOrThrow, fetchProductRelatedProducts } =
  useProductsStore()

const products = ref<Product[]>()
const temporaryProducts = ref<Product[]>()
const isLoaded = ref(false)
const similarProductId = Number(props.similarProductId)
const excludeCategoryId = props.excludeCategoryId.split(',')

const fetchedSearchProducts = ref<{
  products: Product[]
  totalCount: number
  cursor: string | null
}>()

const maxProducts = 8

function setIsLoadedTrue() {
  isLoaded.value = true
}

const isAnyProducts = computed(
  () => !!(temporaryProducts.value?.length ?? fetchedSearchProducts.value?.products.length),
)

function noExistingProducts() {
  if (!isAnyProducts.value) {
    products.value = []
    setIsLoadedTrue()
    return true
  }
  return false
}

function removeDuplicateProduct() {
  // products from search can be the same as some of the related products, so we need to remove duplicates, and then slice to 8 products
  if (fetchedSearchProducts.value?.products.length) {
    temporaryProducts.value = uniqBy(
      [...(temporaryProducts.value ?? []), ...fetchedSearchProducts.value.products],
      'id',
    ).slice(0, maxProducts)
  }
}

async function loadSearchData() {
  // we want min 8 products, so if we dont have enough products from related products, fetch more from search
  const remainingLimit = maxProducts - (temporaryProducts.value?.length ?? 0)

  if (remainingLimit > 0) {
    try {
      fetchedSearchProducts.value = await fetchProductsSearch({
        query: props.title,
        limit: maxProducts,
        excludeCategoryId: excludeCategoryId,
      })

      if (noExistingProducts()) return

      removeDuplicateProduct()
    } catch (e) {
      toastApiErrorResponse(e)
    }
  }
}

async function loadProducts() {
  if (isLoaded.value) return

  if (similarProductId !== 0) {
    try {
      const { data } = await frontApiProductGetById(similarProductId)
      setProduct({ breadcrumbs: data.breadcrumbs, ...data.product })
      const firstProduct = getProductOrThrow(similarProductId)

      temporaryProducts.value = [firstProduct]

      const fetchedRelatedProducts = await fetchProductRelatedProducts({
        productId: similarProductId,
        relatedProductsKey: 'similarProducts',
      })

      if (fetchedRelatedProducts.length) {
        temporaryProducts.value = [...temporaryProducts.value, ...fetchedRelatedProducts]
      }
    } catch (e) {
      toastApiErrorResponse(e)
    }
  }

  await loadSearchData()

  if (!isAnyProducts.value) return
  products.value = temporaryProducts.value

  setIsLoadedTrue()
}

const interactionSource = computed(() => ({
  code: props.title,
}))
useInteractionSource('WidgetRecipe', interactionSource)
</script>

<template>
  <Widget
    :products="products"
    :title="`${props.title} ${props.quantity}`"
    @intersecting="loadProducts" />
</template>
