import { fetchGet } from '@/services/api/fetch'

function sectionTitleEditPanel() {
  if (!document.querySelector('.js-section-editpanel-toggle')) {
    return
  }

  const wrapper_element_class = '.section-title__homepage'
  const button = 'js-section-editpanel-toggle'
  const dropdown_class = '.section-title__edit-panel'
  const active_class = '.section-title__edit-panel--is-active'
  const active = 'section-title__edit-panel--is-active'

  window.onclick = (e) => {
    let target_element_contains_button_class = e.target.classList.contains(button)
    if (!target_element_contains_button_class) {
      target_element_contains_button_class = e.target.parentElement?.classList.contains(button)
    }

    if (
      !target_element_contains_button_class &&
      document.querySelector(`${wrapper_element_class} ${dropdown_class + active_class}`)
    ) {
      document
        .querySelector(`${wrapper_element_class} ${dropdown_class + active_class}`)
        .classList.remove(active)
    }

    if (target_element_contains_button_class) {
      let current_active_element
      let wrapper = e.target.closest(wrapper_element_class)
      document.querySelectorAll(wrapper_element_class).forEach((wrapper_for_contents) => {
        if (wrapper_for_contents.querySelector(dropdown_class).classList.contains(active)) {
          current_active_element = wrapper_for_contents
          wrapper_for_contents.querySelector(dropdown_class).classList.remove(active)
        }
      })
      if (current_active_element !== wrapper) {
        wrapper.querySelector(dropdown_class).classList.add(active)
      }
    }
  }
}

function toggleInfoBlock() {
  const elementInfoBarList = document.querySelectorAll('.status-bar')

  elementInfoBarList?.forEach((elementInfoBar) => {
    const elementArrow = elementInfoBar.querySelector('.status-bar__arrow')
    const elementStatusBarText = elementInfoBar.querySelector('.status-bar__text')

    elementArrow?.addEventListener('click', () => {
      elementStatusBarText.classList.toggle('status-bar__text--open')
      elementArrow.classList.toggle('status-bar__arrow--rotate-down')
    })
  })
}

function snippetSupport() {
  const classAjax = 'ajax'

  document.addEventListener('click', async (event) => {
    const ajaxControl = event.target.classList.contains(classAjax)
      ? event.target
      : event.target.closest(`.${classAjax}`)
    const href = ajaxControl?.href

    if (href) {
      event.preventDefault()
      const {
        data: { snippets, redirect },
      } = await fetchGet(href)

      if (redirect) {
        window.location.href = redirect
        return
      }

      if (snippets) {
        Object.keys(snippets).forEach((key) => {
          document.getElementById(key).innerHTML = snippets[key]
        })
      }
    }
  })
}

export default (() => {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      sectionTitleEditPanel()
      toggleInfoBlock()
      snippetSupport()
    }, 500)
  })
})()
