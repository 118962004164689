import type { ApiEmptyResponse } from '@/services/api/types'
import appConfig from '@/services/appConfig'

import { fetchGet, fetchDelete } from '../fetch'
import type { ClientCreditCardsResponse } from './clientTypes'

const modulePath = 'client'
const clientPath = `${appConfig.frontApiBaseUrl}${modulePath}`
const clientCreditCardsPath = `${clientPath}/credit-card`

function getCreditCards() {
  return fetchGet<ClientCreditCardsResponse>(clientCreditCardsPath)
}

function deleteCreditCard(id: number) {
  return fetchDelete<ApiEmptyResponse>(clientCreditCardsPath + '/' + id)
}

export {
  getCreditCards as frontApiGetClientCreditCards,
  deleteCreditCard as frontApiDeleteClientCreditCard,
}
