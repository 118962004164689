<script setup lang="ts">
import { computed } from 'vue'

import appConfig from '@/services/appConfig'

import AddressSelectorPriceBanner from './AddressSelectorPriceBanner.vue'
import AddressSelectorSelect from './AddressSelectorSelect.vue'
import usePreviewTimeslots from './usePreviewTimeslots'

const { hasPreviewTimeslots } = usePreviewTimeslots()

const showPriceBanner = computed(
  () => appConfig.enableAddressSelectorPriceBanner && hasPreviewTimeslots.value,
)
</script>

<template>
  <div class="address-selector-header">
    <AddressSelectorSelect :has-divider="showPriceBanner" />
    <AddressSelectorPriceBanner v-if="showPriceBanner" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-selector-header {
  background: color('gray-lighter');
}
</style>
