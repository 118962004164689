<script setup lang="ts">
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { BaseButton, Tooltip } from '@/componentsPure'
import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import localizePrice from '@/utils/localizePrice'

import CartDeliveryInfoLabel from './CartDeliveryInfoLabel.vue'
import CartFreeDeliveryInfo from './CartFreeDeliveryInfo.vue'
import useCartPreviewTooltip from './useCartPreviewTooltip'

type CartPreviewHeaderProps = {
  isOpen: boolean
}

const props = defineProps<CartPreviewHeaderProps>()
const emit = defineEmits<{ click: [e: MouseEvent] }>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)
const { isTooltipDisabled } = useCartPreviewTooltip()
const { showProgressInfo } = useCartDeliveryData()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
</script>

<template>
  <div
    class="cart-preview-header"
    :class="{ 'cart-preview-header--open': props.isOpen }"
    data-tid="cart-preview-header"
    @click="(e) => emit('click', e)">
    <div class="cart-preview-header-first-row">
      <div
        v-if="!breakpointDownMd"
        class="cart-preview-header-arrow" />

      <div class="position-relative ml-1">
        <Icon
          icon="cart"
          class="cart-preview-header-cart" />

        <strong
          class="cart-preview-header-count"
          v-text="cartGetters.getCartProductsCount" />
      </div>

      <strong
        class="cart-preview-header-price"
        data-tid="cart-preview__cart-value">
        <span
          v-if="cartGetters.isCartLoaded"
          v-text="localizePrice(cartGetters.getCartTotalPrice)" />
        <template v-else> &hellip; </template>
      </strong>

      <Tooltip
        direction="bottom-left"
        text-color="red"
        bg-color="white"
        shadow
        nowrap
        :disabled="isTooltipDisabled">
        <!-- Stopping event propagation to prevent unintended CartPreview toggling: https://mallfresh.atlassian.net/browse/KNW-17641 -->
        <BaseButton
          :to="{ name: 'Cart' }"
          entity="router-link"
          class="font-weight-bold ml-3 mr-2"
          :disabled="!cartGetters.isAllowedCheckout"
          data-tid="cart-preview__to-first-step-top"
          variant="super-primary"
          size="sm"
          @click.stop
          v-text="t('cart.toCheckout')" />

        <template #message>
          {{ t('cart.empty') }}
        </template>
      </Tooltip>
    </div>
    <template
      v-if="!cartGetters.hasAdditionalOrders && cartGetters.getCartProductsCount && !props.isOpen">
      <CartDeliveryInfoLabel
        v-if="showProgressInfo"
        header-mode />
      <CartFreeDeliveryInfo
        v-else
        compact />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$baseHeaderHeight: 44px;
$extendedHeaderHeight: $baseHeaderHeight + 26;

.cart-preview-header {
  box-sizing: content-box;
  cursor: pointer;
  user-select: none;
  border-radius: 0 0 4px 4px;

  &-first-row {
    display: flex;
    align-items: center;
    min-height: $baseHeaderHeight;
    background: color('gray-lighter');
  }

  &--open {
    border: 0;
  }

  &-arrow {
    width: 0;
    height: 0;
    margin: 0 8px;
    border-top: 8px solid color('gray-darker');
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  &--open &-arrow {
    border-top: 0;
    border-bottom: 8px solid color('gray-darker');
  }

  &-cart {
    width: 24px;
    height: 24px;
    color: color('gray-darker');
  }

  &-count {
    position: absolute;
    top: -4px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    background-color: color('accent-yellow');
    border-radius: 10px;

    @include make-font-scale(2);
  }

  &-price {
    @include make-font-scale(4);

    flex-grow: 1;
    text-align: right;
  }
}
</style>
