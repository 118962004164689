<script setup lang="ts">
import { computed } from 'vue'

import uniqWith from 'lodash-es/uniqWith'

import { NavBreadcrumb } from '@/componentsPure'
import { t } from '@/services/translations'

type SearchResultsFoundProps = {
  products: any[]
  categories: any[]
  brands: any[]
  searchTerm: string
}

const props = defineProps<SearchResultsFoundProps>()

const MAX_CATEGORIES_COUNT = 10
const MAX_BRANDS_COUNT = 20

// transforms [[cat1, cat2], [cat2, cat3]] to [cat1, cat2, cat3], applies limit count
const transformedCategories = computed(() => {
  const flatted = props.categories?.flatMap((x) => x) || []
  const uniqueFlatted = uniqWith(flatted, (a, b) => a.id === b.id).slice(0, MAX_CATEGORIES_COUNT)

  return uniqueFlatted.length ? [uniqueFlatted] : []
})

const transformedBrands = computed(() => props.brands.slice(0, MAX_BRANDS_COUNT))

const noCategoriesAndBrands = computed(
  () => !transformedCategories.value.length && !transformedBrands.value.length,
)
</script>

<template>
  <div class="d-md-flex w-100">
    <!-- left menu -->
    <div
      class="product-search__left-menu"
      data-tid="product-search__left-menu">
      <!-- categories -->

      <div v-if="transformedCategories.length || noCategoriesAndBrands">
        <div class="product-search__block-header d-flex align-items-center">
          <div>{{ t('general.categories') }}</div>
          <div
            class="bg-gray-light flex-grow-1 mx-3"
            style="height: 1px" />
        </div>

        <template
          v-for="(category, index) in transformedCategories"
          :key="`category-${index}`">
          <NavBreadcrumb
            v-if="category.length"
            :items="category"
            class="my-2 pr-4"
            separator="" />
        </template>
      </div>

      <!--  brands -->
      <div
        v-if="transformedBrands.length || noCategoriesAndBrands"
        class="mt-2">
        <div class="product-search__block-header d-flex align-items-center">
          <div>{{ t('general.brands') }}</div>
          <div
            class="bg-gray-light flex-grow-1 mx-3"
            style="height: 1px" />
        </div>
        <NavBreadcrumb
          v-if="transformedBrands.length"
          :items="transformedBrands"
          class="my-2"
          separator="" />
      </div>
    </div>
    <div
      class="bg-gray-light mt-3 mb-2 mr-2"
      style="width: 1px" />

    <!-- default slot -->
    <div
      class="product-search__block-results"
      data-tid="search-results-block">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-search {
  &__container {
    .product-search__block-results {
      flex: 1;

      @include media-breakpoint-up(lg) {
        max-width: 708px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 928px;
      }
    }
  }

  .show-all-btn {
    padding: 8px 24px;
    text-decoration: underline;
  }
}
</style>
