<script setup lang="ts">
import { ref, computed, defineAsyncComponent, watch } from 'vue'

import { storeToRefs } from 'pinia'

import CartCheckoutControls from '@/components/CartCheckoutControls'
import { CheckoutCartList } from '@/components/Checkout'
import WidgetTabbed from '@/components/Widget/WidgetTabbed.vue'
import useConfig from '@/composables/useConfig'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import useUser from '@/composables/useUser'
import {
  analyticsLoggerViewCart,
  analyticsLoggerOutOfStock,
} from '@/services/analytics/analyticsLogger'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useCartAlertsStore from '@/store/pinia/useCartAlertsStore'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'

import ReplacementsRefusal from './ReplacementsRefusal.vue'

type CartCheckoutProps = {
  linkNext: string
  linkBack: string
}

const Charity = defineAsyncComponent(() => import('@/components/Charity/Charity.vue'))

const props = defineProps<CartCheckoutProps>()

const cartAlertsStore = useCartAlertsStore()
const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd, breakpointUpMd, breakpointUpLg } = storeToRefs(deviceDetectorStore)
const configData = useConfig()
const { fetchProductDetail } = useProductDetailStore()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
const { isUserSignedIn } = useUser()

const CROSS_SELL_WIDGET_CODES = ['checkoutUpsell', 'checkoutUpsellPromo', 'checkoutUpsellMultibuy']

const charityProduct = ref<Product | null>(null)
async function getCharityProduct() {
  if (!appConfig.charityProduct) return

  try {
    charityProduct.value = await fetchProductDetail({
      path: appConfig.charityProduct,
      isSale: false,
    })
  } catch (e) {
    // @TODO
  }
}
getCharityProduct()

const isRegularBuyer = ref<boolean>(
  configData.value.user.email !== null && !configData.value.user.isNewBuyer,
)

const isCartLoaded = computed(() => cartGetters.value.isCartLoaded)
watch(isCartLoaded, analyticsLoggerViewCart)

const isYottlyEnabled = computed(() => configData.value.analytics.yottlyTracking.enable)
const getPersonalisedWidgetCodes = computed(() => {
  const codes: string[] = []

  if (isYottlyEnabled.value) {
    codes.push('cartComplement')
  }

  if (!configData.value.user.isNewBuyer && isUserSignedIn) {
    codes.push('userFrequentlyBuyed')
  }

  return codes
})

useInteractionSource('CartCheckout')
</script>

<template>
  <div>
    <CartCheckoutControls
      class="mb-md-0"
      position="top"
      :link-back="props.linkBack"
      :link-next="props.linkNext"
      checkout-step="CartContent" />

    <WidgetTabbed
      v-if="isCartLoaded && isRegularBuyer && breakpointUpMd"
      :codes="CROSS_SELL_WIDGET_CODES"
      class="mt-3 mb-4" />

    <Transition name="fade">
      <div
        v-if="cartAlertsStore.cartAlert && !breakpointDownMd"
        key="alert"
        class="a-flash-message mb-4"
        v-text="t(cartAlertsStore.cartAlert.messageKey, cartAlertsStore.cartAlert.params)" />
    </Transition>

    <CheckoutCartList
      v-if="cartGetters.getCartProductsCount"
      :large="breakpointUpLg"
      has-summarized-groups
      has-cart-actions
      rounded
      data-tid="cart-checkout-list"
      @has-replaceable-products="
        analyticsLoggerOutOfStock(cartGetters.getCartReplaceableProducts)
      " />

    <div
      v-if="isCartLoaded && cartGetters.getCartProductsCount < 1"
      class="text-center py-5 my-5">
      <p
        class="font-weight-bold text-5"
        v-html="t('cart.empty')" />
      <p
        class="small"
        v-html="t('cart.addSomeGoods')" />
    </div>

    <Charity
      v-if="charityProduct && isCartLoaded"
      :product="charityProduct"
      class="my-4" />

    <ReplacementsRefusal v-if="cartGetters.isReplacementsRefusalVisible" />

    <WidgetTabbed
      v-if="isCartLoaded && (!isRegularBuyer || !breakpointUpMd)"
      :codes="CROSS_SELL_WIDGET_CODES"
      class="my-4" />

    <WidgetTabbed
      v-if="isCartLoaded && getPersonalisedWidgetCodes.length"
      :codes="getPersonalisedWidgetCodes" />

    <div class="row">
      <p
        class="col-12 text-gray text-center text-lg-right text-2 mt-2"
        v-html="t('cart.underAgeRestrictionMessage')" />
    </div>

    <CartCheckoutControls
      v-if="isCartLoaded"
      class="my-4"
      position="bottom"
      :link-back="props.linkBack"
      :link-next="props.linkNext"
      checkout-step="CartContent" />
  </div>
</template>

<style lang="scss" scoped>
.upsell {
  border-radius: 8px;
}
</style>
