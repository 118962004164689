<script setup>
import { DEFAULT_SHIPPER } from '@/constants/defaultShipper'
import { t } from '@/services/translations'

const props = defineProps({
  shipper: {
    type: String,
    default: DEFAULT_SHIPPER,
  },
})
</script>

<template>
  <div class="d-flex align-items-center text-2 text-gray-dark flex-row-reverse text-right">
    <img
      :src="`/vue/img/shipper/${props.shipper.toLowerCase()}.svg?v=1`"
      class="logo ml-2"
      alt="Delivery shipper" />
    <span v-html="t('delivery.shipperInfo')" />
  </div>
</template>

<style lang="scss" scoped>
.logo {
  height: 16px;
}
</style>
