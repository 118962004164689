import { computed, ref, watch, toRaw } from 'vue'
import { useRouter } from 'vue-router'

import debounce from 'lodash-es/debounce'

import { usePaymentGooglePayEcho } from '@/composables/payment'
import { checkoutQueryKeys } from '@/constants/queryKeys'
import { analyticsLoggerPayment } from '@/services/analytics/analyticsLogger'
import {
  frontApiPostCheckoutPayGoogle,
  frontApiPutCheckoutRollback,
} from '@/services/api/front/checkout'
import appConfig from '@/services/appConfig'
import { googlePayGetCheckoutFinishData } from '@/services/googlePay'
import queryClient from '@/services/queryClient'
import { toastError } from '@/services/toast'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

let gPayClient
let gPayEchoData

async function onGooglePaymentButtonClicked(router, parentOrderId) {
  analyticsLoggerPayment('google_pay')

  let checkoutFinishData

  // creates order or additional order
  try {
    checkoutFinishData = await googlePayGetCheckoutFinishData(parentOrderId)

    // Whole order is payed by credits, there is no need for GPay, also no payment data are available
    // TODO doriesit
    if (!checkoutFinishData.transactionInfo) {
      router.push({ name: 'PageThankYou', params: { id: checkoutFinishData.orderId } })
      return
    }
  } catch (err) {
    if (err.name === 'OrderError') {
      toastError(t('checkout.googlePay.error.unavailable'))
      // TODO doriesit
      // redirect to 3rd step of checkout (keeps products in cart)
      //router.push(defaultPaymentErrorRedirect)
    } else if (err.name === 'AdditionalOrderError') {
      toastError(t('checkout.googlePay.error.paymentFailed'))
      // TODO doriesit
      // redirect to 1st step of checkout (cart will be empty)
      //router.push({ name: 'Cart' })
    }
    return
  }

  // Opens GPay payment prompt
  try {
    const paymentDataObject = {
      ...gPayEchoData,
      transactionInfo: {
        ...gPayEchoData.transactionInfo,
        ...checkoutFinishData.transactionInfo,
      },
    }
    const paymentData = await gPayClient.loadPaymentData(paymentDataObject)

    googlePayProcessPayment(router, paymentData, checkoutFinishData)
  } catch (err) {
    if (err.statusCode === 'CANCELED') {
      // user canceled Google Pay payment by clicking the modal "back" button
      await frontApiPutCheckoutRollback(checkoutFinishData.orderId)
      queryClient.invalidateQueries({ queryKey: checkoutQueryKeys.detail() })
    } else {
      toastError(t('checkout.googlePay.error.unavailable'))
      // TODO zistit co sa ma stat v checkoute 2.0
      // router.push({ name: 'TransportPayment' })
    }
  }
}

const debouncedOnButtonClick = debounce(
  (router, parentOrderId) => onGooglePaymentButtonClicked(router, parentOrderId),
  5000,
  {
    leading: true,
    trailing: false,
  },
)

async function googlePayProcessPayment(router, paymentData, checkoutFinishData) {
  const { orderId } = checkoutFinishData

  try {
    const {
      data: { redirectUrl },
    } = await frontApiPostCheckoutPayGoogle(paymentData, orderId)
    window.location.href = redirectUrl
  } catch (err) {
    toastError(t('checkout.googlePay.error.paymentFailed'))
    // TODO zistit co sa ma stat v checkoute 2.0
    // Reloads a page. Also rollback of order is made internally by BE.
    router.go()
  }
}

const isGPayClientCreated = computed(() => window.google?.payments?.PaymentsClient)
const isGPayReadyToPay = ref(false)

function usePaymentGooglePay(parentOrderId) {
  const router = useRouter()
  const { data: GPayEchoResponse, isFetched: isGPayEchoFetched } = usePaymentGooglePayEcho()

  const isGPayScriptLoaded = ref(false)

  /*
    Watches for loaded GPay script and fetched GPay echo data.
    After both of the conditions are met, it creates GPay client
    and checks if GPay is available.
  */
  watch(
    () => [isGPayScriptLoaded.value, isGPayEchoFetched.value],
    async ([newIsGPayScriptLoaded, newIsGPayEchoFetched]) => {
      if (!newIsGPayScriptLoaded || !newIsGPayEchoFetched) return

      // Clones GPay echo data, and gets rid of Proxy object as it is not supported
      gPayEchoData = structuredClone(toRaw(GPayEchoResponse.value))

      // Creates GPay client - should be created only once
      if (isGPayClientCreated.value) return
      gPayClient = new window.google.payments.api.PaymentsClient({
        environment: gPayEchoData.environment,
      })

      // Checks if GPay is available
      try {
        const res = await gPayClient.isReadyToPay({ ...gPayEchoData })

        if (!res.result) {
          toastError(t('checkout.googlePay.error.unavailable'))
          // TODO doriesit
          //router.push({ name: 'TransportPayment' })
        }

        isGPayReadyToPay.value = res.result
      } catch (err) {
        toastError(t('checkout.googlePay.error.unavailable'))
        // TODO doriesit
        // router.push({ name: 'TransportPayment' })
      }
    },
  )

  const deviceDetectorStore = useDeviceDetectorStore()

  /**
   * Creates Google Pay payment button, with @click handler fn.
   */
  function addGooglePayButton(wrapper) {
    const googlePayButton = gPayClient.createButton({
      buttonType: 'pay',
      buttonLocale: appConfig.langCode,
      buttonSizeMode: deviceDetectorStore.breakpointUpMd ? 'static' : 'fill',
      onClick: () => debouncedOnButtonClick(router, parentOrderId),
    })

    wrapper.appendChild(googlePayButton)
  }

  return {
    isGPayScriptLoaded,
    isGPayReadyToPay,
    addGooglePayButton,
  }
}

export default usePaymentGooglePay
