<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { useClipboard } from '@vueuse/core'
import type { Dayjs } from 'dayjs'

import DialogConfirmOverlay from '@/components/Dialog/DialogConfirmOverlay.vue'
import { isButtonKey } from '@/components/Notification/notificationProviderKey'
import type { Notification } from '@/components/Notification/notificationTypes'
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import { BaseTag } from '@/componentsPure'
import { resizer } from '@/services/resizer'
import { toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import { formatDate } from '@/utils/date'

type NotificationMessageProps = {
  isNew?: boolean
  isRead?: boolean
  notificationId: Notification['id']
  title: Notification['title']
  description: Notification['description']
  imageUrl: Notification['parameters']['imageUrl']
  validityTo: Dayjs
  hideValidity: Notification['hideValidity']
  redirectUrl?: Notification['parameters']['redirectUrl']
  copyValue?: Notification['parameters']['copyBox']
}

const props = withDefaults(defineProps<NotificationMessageProps>(), {
  isNew: false,
  isRead: false,
  redirectUrl: '',
  copyValue: '',
})

const isButton = inject(isButtonKey)
const isRemoveOverlayVisible = ref(false)
const dateFormatted = formatDate(props.validityTo, { showYear: true })

const emit = defineEmits<{
  remove: [notificationId: Notification['id']]
  messageRead: [notificationId: Notification['id']]
}>()

const getRedirectUrl = computed(() => (props.redirectUrl ? props.redirectUrl : null))
const getComponent = computed(() => (props.redirectUrl ? 'a' : 'span'))

function removeNotification(notificationId: Notification['id']) {
  isRemoveOverlayVisible.value = false
  emit('remove', notificationId)
}

const { copy } = useClipboard({ legacy: true })

function copyToClipboard() {
  copy(props.copyValue)
  toastSuccess(t('notifications.copy'))
}

function emitMessageRead() {
  emit('messageRead', props.notificationId)
}

function handleMessageClick() {
  if (props.copyValue) {
    copyToClipboard()
  }

  if (props.isNew) {
    emitMessageRead()
  }
}

function handleCloseButtonClick() {
  isRemoveOverlayVisible.value = true

  if (props.isNew) {
    emitMessageRead()
  }
}
</script>

<template>
  <div
    :class="[
      'notification-message position-relative',
      {
        'notification-message__btn': isButton,
        'notification-message__btn--show-outline': isButton && !isRemoveOverlayVisible,
        'border-bottom border-gray-lighter pb-2': !isButton,
      },
    ]"
    data-tid="notification-message">
    <!-- TODO href: Change to RouterLink after BE starts sending a relative link -->
    <Component
      :is="getComponent"
      class="d-flex pointer text-decoration-none"
      :href="getRedirectUrl"
      @click="handleMessageClick">
      <figure class="position-relative flex-shrink-0 align-self-center m-0">
        <ImgResponsive
          :class="[
            'notification-message__image mr-2 flex-shrink-1',
            {
              'notification-message__image--new': isNew,
            },
          ]"
          :alt="title"
          :srcset="[
            {
              url: resizer(imageUrl, 78, 78, 'SHRINK_ONLY'),
            },
            {
              url: resizer(imageUrl, 150, 150, 'SHRINK_ONLY'),
              pixelRatio: 1.5,
            },
          ]"
          lazyload
          class-img="rounded-left" />
        <BaseTag
          v-if="isNew"
          modifier="strong primary"
          size="small"
          class="notification-message__tag"
          v-text="t('notifications.new')" />
      </figure>
      <div class="d-flex justify-content-between flex-row flex-grow-1">
        <div class="d-flex flex-column">
          <span
            :class="[
              'notification-message__title mr-1',
              {
                'text-primary font-weight-bold': isNew,
                'text-gray-darker': isRead,
                'notification-message__title--button': isButton,
              },
            ]"
            v-text="title" />
          <span
            :class="[
              'notification-message__description text-gray-darker',
              {
                'notification-message__description--button': isButton,
              },
            ]"
            v-text="description" />
          <span
            v-if="!hideValidity"
            class="text-2 text-gray mb-1"
            v-text="t('notifications.validTo', { validTo: dateFormatted })" />
        </div>
        <button
          type="button"
          class="a-control-close mr-1 mt-1 flex-shrink-0"
          data-tid="notification-cross-button"
          @click.stop.prevent="handleCloseButtonClick" />
      </div>
    </Component>
    <DialogConfirmOverlay
      v-show="isRemoveOverlayVisible"
      :title="t('notifications.deleteMessage.title')"
      :accept-label="t('notifications.deleteMessage.yes')"
      :decline-label="t('notifications.deleteMessage.no')"
      data-tid-accept="notification-remove-button"
      data-tid-decline="notification-back-button"
      @click.stop
      @accepted="removeNotification(notificationId)"
      @declined="isRemoveOverlayVisible = false" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.notification-message {
  $margin: 8px;
  $margin-small: 6px;

  min-height: 78px;
  line-height: 1.2;

  &__btn {
    border-radius: 4px;
    box-shadow: 0 0 10px color('black', 0.1);

    &--show-outline {
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 0 1px color('gray-light');
      }
    }
  }

  &__image {
    $size: 78px;

    width: $size;
    height: $size;

    &::after {
      position: absolute;
      width: $size;
      height: $size;
      content: '';
      border-top-left-radius: var(--borderRadiusSmall);
      border-bottom-left-radius: var(--borderRadiusSmall);
    }

    &--new {
      &::after {
        border-left: 3px color('primary') solid;
      }
    }
  }

  &__tag {
    position: absolute;
    top: 6px;
    min-height: 21px;
    padding: 0 4px;
  }

  &__title {
    @include make-font-scale(3);

    margin-top: $margin-small;
    margin-bottom: $margin-small;

    @include media-breakpoint-up(md) {
      @include make-font-scale(5);

      margin-top: $margin;
      margin-bottom: $margin;
    }

    &--button {
      @include make-font-scale(3);

      margin-top: $margin-small;
      margin-bottom: $margin-small;
    }
  }

  &__description {
    @include make-font-scale(2);

    margin-bottom: $margin-small;

    @include media-breakpoint-up(md) {
      @include make-font-scale(3);

      margin-bottom: $margin;
    }

    &--button {
      @include make-font-scale(2);

      margin-bottom: $margin-small;
    }
  }
}
</style>
