import { reactive } from 'vue'

import type { Product } from '@/composables/useProductItem'
import { useVendor } from '@/composables/useVendor'
import { type VendorType, type VendorID, Vendors } from '@/constants/vendors'
import { analyticsLoggerCreateSearchProductsSuggested } from '@/services/analytics/analyticsLogger'
import type { ProductListItem } from '@/services/api/front/productTypes'
import type { SuggestResponse } from '@/store/pinia/useUserInterfaceStore'

type SuggestionsProduct = Product | ProductListItem
type SuggestionsAnalyticsCallback = (
  renderedProducts: SuggestionsProduct[],
  originalProducts: SuggestionsProduct[],
) => void

type OriginalScope = {
  products: SuggestionsProduct[]
  searchTerm: string
  vendorId: VendorID
  dataSourceEndpoint: SuggestResponse['dataSourceEndpoint']
}

export function useSuggestionsAnalytics() {
  const { currentVendorId } = useVendor()
  const analyticsLoggerSearchProductsSuggested = analyticsLoggerCreateSearchProductsSuggested()

  const callbacks: Partial<Record<VendorType, SuggestionsAnalyticsCallback | null>> = reactive({})

  function registerRenderCallback(vendorType: VendorType, originalScope: OriginalScope) {
    if (!originalScope.products.length) return

    callbacks[vendorType] = (renderedProducts, originalProducts) => {
      if (originalProducts !== originalScope.products) return

      const isCurrentVendorBase = currentVendorId.value === Vendors.BASE
      const isOriginalVendorPharmacy = originalScope.vendorId === Vendors.PHARMACY
      const hasSuklId = isCurrentVendorBase && isOriginalVendorPharmacy ? 0 : undefined

      analyticsLoggerSearchProductsSuggested.byVendorType(vendorType)(
        renderedProducts,
        originalScope.searchTerm,
        originalScope.dataSourceEndpoint,
        {
          vendorId: originalScope.vendorId,
          hasSuklId,
        },
      )
      callbacks[vendorType] = null
    }
  }

  function createRenderCallbackTrigger(vendorType: VendorType): SuggestionsAnalyticsCallback {
    return (renderedProducts, originalProducts) => {
      callbacks[vendorType]?.(renderedProducts, originalProducts)
      callbacks[vendorType] = null
    }
  }

  const onProductsRender = createRenderCallbackTrigger('current')
  const onOtherProductsRender = createRenderCallbackTrigger('other')

  return {
    registerRenderCallback,
    onProductsRender,
    onOtherProductsRender,
  }
}
