import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'

type PaymentGooglePayEchoResponse = {
  apiVersion: number
  apiVersionMinor: number
  paymentMethodType: string
  allowedCardNetworks: string[]
  allowedCardAuthMethods: string[]
  assuranceDetailsRequired: boolean
  billingAddressRequired: boolean
  billingAdddressParametersFormat: string
  tokenizationSpecificationType: string
  gateway: string
  gatewayMerchantId: string
  googlepayMerchantId: string
  merchantName: string
  environment: string
  totalPriceStatus: string
  countryCode: string
}

/**
 * Fetches needed data for Google Pay initialization.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Payment/readGooglePayEcho
 * @returns Promise of API response data.
 */
function getGooglePayEcho() {
  return fetchGet<PaymentGooglePayEchoResponse>(
    `${appConfig.frontApiBaseUrl}payment/googlepay/echo`,
  )
}

export { getGooglePayEcho as frontApiGetPaymentGooglePayEcho }
