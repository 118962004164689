<script setup lang="ts">
import { ref } from 'vue'

import { Form as VeeForm } from 'vee-validate'

import BenjaminekAddChildInputs from '@/components/BenjaminekAddChildInputs/BenjaminekAddChildInputs.vue'
import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import { useBenjaminekPostChildren } from '@/composables/benjaminek'
import modalIndex from '@/constants/modalIndex'
import { type BenjaminekChild } from '@/data/api/frontApi/benjaminek/benjaminekTypes'
import { toastApiErrorResponse, toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'

const child = ref<BenjaminekChild>({ name: '', birthDate: '' })
const isAddChildProcessing = ref(false)

const addChildren = useBenjaminekPostChildren()
const { closeModal } = useModalStore()

async function submitForm() {
  try {
    const { translatedMessage } = await addChildren([child.value])

    closeModal(modalIndex.benjaminekAddChild)
    child.value = { name: '', birthDate: '' }

    toastSuccess(translatedMessage)
  } catch (error) {
    toastApiErrorResponse(error)
  }
}
</script>

<template>
  <Popup
    :name="modalIndex.benjaminekAddChild"
    size="large"
    content
    box-class-css="text-center">
    <img
      :src="'/vue/img/clubs/benjaminek-logo.svg?v=1'"
      :alt="t('kidsClub.title')"
      class="logo-image mb-4 mb-lg-5" />

    <h2 class="title font-weight-bold m-0 p-0">
      {{ t('kidsClub.children.addChild.title') }}
    </h2>

    <VeeForm
      class="form text-center mx-auto my-3 my-lg-5 p-0 p-md-4"
      autocomplete="off"
      @submit="submitForm">
      <div class="form__row text-left">
        <BenjaminekAddChildInputs :model-value="child" />
      </div>
      <BaseButton
        type="submit"
        :disabled="isAddChildProcessing"
        variant="primary"
        size="sm"
        pill
        class="my-4 px-5">
        {{ t('kidsClub.children.addChild.button') }}
      </BaseButton>
    </VeeForm>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.children-list {
  gap: 16px;

  @include media-breakpoint-up(md) {
    gap: 24px;
  }
}

.logo-image {
  width: 213px;
  height: 43px;

  @include media-breakpoint-up(lg) {
    width: 380px;
    height: 76px;
  }
}

.title {
  @include make-font-scale(8);
}

.form {
  max-width: 550px;
  text-align: left;

  @include media-breakpoint-up(lg) {
    box-shadow: 4px 4px 20px rgba(23, 27, 30, 20%);
  }

  &__row {
    display: grid;
    gap: 10px 0;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4px 24px;
    }
  }
}
</style>
