<script setup lang="ts">
import { toastInfo, toastSuccess, toastWarning, toastError } from '@/services/toast'

type LatteFlashMessageProps = {
  type?: string
  message: string
  flashMessageId?: string
}

const props = withDefaults(defineProps<LatteFlashMessageProps>(), {
  type: 'info',
  flashMessageId: undefined,
})

const TIMEOUT_KEY = 'longer'

const hasLongerTimeout = props.type.includes(TIMEOUT_KEY)
const type = hasLongerTimeout ? props.type.replace(TIMEOUT_KEY, '').trim() : props.type

const options = {
  hasLongerTimeout,
  ...(props.flashMessageId && { flashMessageId: props.flashMessageId }),
}

switch (type) {
  case 'success':
    toastSuccess(props.message, options)
    break
  case 'warning':
    toastWarning(props.message, options)
    break
  case 'error':
    toastError(props.message, options)
    break
  default:
    toastInfo(props.message, options)
}
</script>

<template>
  <slot />
</template>
