function edit() {
  const buttonEdit = document.querySelector('.js-edit-address')
  if (!buttonEdit) return

  buttonEdit.addEventListener('click', () => {
    if (buttonEdit.className.indexOf('cart__saved-billing-address__addnew--open') !== -1) {
      // Check that you want to use the newly being added address
      document.getElementById(
        'frm-personalDataForm-new_delivery_address-add_delivery_address',
      ).checked = true

      // Uncheck that you dont want to use the old address
      const addressboxChecked = document.querySelector('.cart-addresbox-radio[checked=checked]')

      if (addressboxChecked) {
        addressboxChecked.checked = false
      }

      buttonEdit.style.display = 'none'
      document.querySelector('.cart__saved-billing-address__addnew--close').style.display = 'table'

      document.querySelector('.checkout-personal-info').style.cssText = `
                  height: auto;
                `
      document.querySelector('.checkout-personal-info').removeAttribute('tabindex')
    }
  })
}

function addressboxClick() {
  const cartAddressboxes = document.querySelectorAll('.cart-addressbox--radio')

  if (!cartAddressboxes) {
    return
  }

  cartAddressboxes.forEach((cartAddressbox) => {
    cartAddressbox.addEventListener('click', () => {
      var new_address_form_input = document.getElementById(
        'frm-personalDataForm-new_delivery_address-add_delivery_address',
      )
      // If user clicks to check one of the addresses, then uncheck the 'New address' or 'Edit address' form
      if (new_address_form_input.checked) {
        new_address_form_input.checked = false
        document.querySelector('.cart__saved-billing-address__addnew--close').click()
      }
    })
  })
}

function close() {
  const addressClose = document.querySelector('.js-edit-address-close')

  if (!addressClose) {
    return
  }

  addressClose.addEventListener('click', () => {
    document.querySelector('.cart__saved-billing-address__addnew--close').style.display = 'none'
    document.querySelector('.cart__saved-billing-address__addnew--open').style.display = 'table'

    document.querySelector('.checkout-personal-info').style.cssText = `
              height: 0;
            `
    document.querySelector('.checkout-personal-info').setAttribute('tabindex', -1)

    // uncheck the 'New address' or 'Edit address' form
    document.getElementById(
      'frm-personalDataForm-new_delivery_address-add_delivery_address',
    ).checked = false
  })
}

document.addEventListener('DOMContentLoaded', () => {
  // Safari being the new IE, for some reason it does't add event listener for addressClose
  // Will be deleted in Checkout 2.0
  setTimeout(() => {
    edit()
    close()
    addressboxClick()
  }, 1000)
})

export default {}
