import { setTranslations } from '@kosik/ui/config'

import { t } from '@/services/translations'

export default {
  install() {
    setTranslations({
      FieldLabel: {
        required: () => t('kosikUi.fieldLabel.required'),
      },
      Select: {
        placeholder: () => t('kosikUi.select.placeholder'),
        searchPlaceholder: () => t('kosikUi.select.searchPlaceholder'),
        noResults: () => t('kosikUi.select.noResults'),
        noOptions: () => t('kosikUi.select.noOptions'),
      },
      DateField: {
        date: () => t('kosikUi.dateField.date'),
        month: () => t('kosikUi.dateField.month'),
        year: () => t('kosikUi.dateField.year'),
      },
    })
  },
}
