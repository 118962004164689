<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'

import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { analyticsLoggerAlternativesModalLoaded } from '@/services/analytics/analyticsLogger'
import useProductsStore from '@/store/pinia/useProductsStore'

import Widget from './components/Widget.vue'

type WidgetAlternativesProps = {
  replacingProduct: Product
  includeReplacingProduct?: boolean
}

const props = defineProps<WidgetAlternativesProps>()

const { fetchProductRelatedProducts } = useProductsStore()

const products = ref<Product[]>()

onMounted(() => {
  loadProducts()
})

const loadProducts = async () => {
  const fetchedRelatedProducts = await fetchProductRelatedProducts({
    productId: props.replacingProduct.id,
    relatedProductsKey: 'replacements',
  })

  if (props.includeReplacingProduct) {
    fetchedRelatedProducts.unshift(props.replacingProduct)
  }

  products.value = fetchedRelatedProducts

  analyticsLoggerAlternativesModalLoaded(products.value, props.replacingProduct.origin.url)
}

const COMPONENT_UID = 'WidgetAlternatives'

const interactionSource = computed(() => ({
  code: COMPONENT_UID,
  replacingProductId: props.replacingProduct.id,
}))

useInteractionSource(COMPONENT_UID, interactionSource)
</script>

<template>
  <Widget
    :products="products"
    border />
</template>
