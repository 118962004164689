<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import ProductLimited from '@/components/Product/Limited/ProductLimited.vue'
import { type Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useTimeslotsStore, { type TimeslotsLimitedProduct } from '@/store/pinia/useTimeslotsStore'

type LimitedProduct = Product & TimeslotsLimitedProduct

type LimitedProductsProps = {
  limitedProducts: LimitedProduct[]
  openDayIndex: number
}

const props = defineProps<LimitedProductsProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpMd } = storeToRefs(deviceDetectorStore)
const { isCartTimeslotAvailable } = useTimeslotsStore()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

// Affects textation severity
const isSomeProductPartiallyAvailable = computed(() => {
  return props.limitedProducts.some(
    // Cannot use "isAvailable" flag here, because the "limitedProducts" array contains also fully available products (for UX purposes). Because of that, the condition would be wrongly evaluated.
    (product) => product.days[props.openDayIndex].limitedTimeRanges?.length,
  )
})

const isAllProductsPartiallyAvailable = computed(() => {
  return props.limitedProducts.every(
    // Similarly, cannot use "limitedTimeRanges" here, because of the limitedProducts array containing fully available products. Thus we utilize the "isAvailable" flag which both fully and partially available products have.
    (product) => product.days[props.openDayIndex].isAvailable,
  )
})

const isSelectedTimeslotDaySameAsOpenDay = computed(() => {
  const selectedTimeslotStart = cartGetters.value.getSelectedTimeslot?.start
  return props.limitedProducts.some((product) =>
    product.days[props.openDayIndex].date.isSame(selectedTimeslotStart, 'day'),
  )
})
const isSelectedTimeslotAvailable = computed(() => {
  const selectedTimeslotId = cartGetters.value.getSelectedTimeslot?.id
  return !!(
    selectedTimeslotId &&
    isCartTimeslotAvailable(selectedTimeslotId) &&
    isSelectedTimeslotDaySameAsOpenDay.value
  )
})

// Affects background color severity
const getColor = computed(() => {
  if (isSelectedTimeslotAvailable.value)
    return { background: 'bg-neutrals-5', text: 'text-text-paragraph' } as const
  if (isAllProductsPartiallyAvailable.value)
    return { background: 'bg-tint-orange', text: 'text-text-paragraph' } as const
  return { background: 'bg-tint-red', text: 'text-contrast-red' } as const
})
</script>

<template>
  <!-- @todo: clear class="mx-auto" and style="max-width: 800px" in K3W-15060 - checkout 2.0-->
  <div
    :class="['mx-auto p-2 rounded', getColor.background]"
    style="max-width: 800px">
    <div class="py-1 px-sm-1 py-sm-2 px-xl-2">
      <div :class="['text-center', getColor.text]">
        <p class="mb-0">
          {{
            isSomeProductPartiallyAvailable
              ? t('delivery.limitedProducts.sentenceStart.partiallyLimited.sentenceStart')
              : t('delivery.limitedProducts.sentenceStart.fullyLimited.sentenceStart')
          }}
          <strong>{{
            isSomeProductPartiallyAvailable
              ? t('delivery.limitedProducts.sentenceStart.partiallyLimited.thisDay')
              : t('delivery.limitedProducts.sentenceStart.fullyLimited.thisDay')
          }}</strong>
          <span v-if="!isSomeProductPartiallyAvailable">
            {{ t('delivery.limitedProducts.sentenceStart.fullyLimited.sentenceEnd') }}
          </span>
        </p>
        <p class="pb-2 mb-1">
          {{ t('delivery.limitedProducts.pleaseChoose') }}
          <strong>{{ t('delivery.limitedProducts.sentenceEnd') }}</strong>
        </p>
      </div>
      <ProductLimited
        v-for="(product, index) in props.limitedProducts"
        :key="index"
        :product="product"
        :large="breakpointUpMd"
        :active-day-index="openDayIndex"
        :class="[
          'text-left', // @todo: clear this rule after removing .selection-box wrapper in latte (K3W-15060 - checkout 2.0)
          { 'mb-1': index + 1 !== limitedProducts.length },
        ]" />
    </div>
  </div>
</template>
