<script setup lang="ts">
import { useRouter } from 'vue-router'

import Icon from '@/components/Icon/Icon.vue'
import { BaseLoader } from '@/componentsPure'
import NavBreadcrumb, { type NavBreadcrumbItem } from '@/componentsPure/NavBreadcrumb'
import useProfileOrdersGet from '@/composables/order/useProfileOrdersGet'
import type { OrderListParams } from '@/services/api/front/profile'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import OrdersItem from '../Item/OrdersItem.vue'

const deviceDetectorStore = useDeviceDetectorStore()

const router = useRouter()

const profileOrderParams: OrderListParams = { limit: 50 }

const { preparedOrders, isLoading } = useProfileOrdersGet(profileOrderParams)

const getBreadcrumbs: NavBreadcrumbItem[] = [
  {
    name: t('general.brand'),
    url: router.resolve({ name: 'Home' }).href,
  },
  {
    name: t('userMenu.myAccount'),
    url: router.resolve({
      name: 'Profile',
      params: {
        presenter: 'order',
        action: 'default',
      },
    }).href,
  },
  {
    name: t('userMenu.labels.orders'),
    tag: 'span',
    class: 'text-gray',
  },
]
</script>

<template>
  <NavBreadcrumb
    v-if="deviceDetectorStore.breakpointUpMd"
    :items="getBreadcrumbs"
    separator="arrow" />
  <h2
    class="text-10 font-weight-normal mb-2"
    v-text="t('userMenu.labels.orders')" />
  <div
    v-if="isLoading"
    class="position-relative m-5 p-5">
    <BaseLoader />
  </div>
  <div
    v-else-if="!preparedOrders?.length"
    class="text-center text-gray-darker text-3 mt-4 mt-lg-5">
    <div class="d-flex justify-content-center mb-3">
      <Icon
        icon="alert"
        group="error"
        class="icon mr-2" />
      <p
        class="font-weight-bold m-0"
        v-text="t('profile.noOrders.title')" />
    </div>
    <p
      class="mb-3"
      v-text="t('profile.noOrders.description')" />
    <p
      class="mb-3"
      v-html="t('profile.noOrders.contactInfo')" />
  </div>
  <template v-else>
    <OrdersItem
      v-for="(order, index) in preparedOrders"
      :key="index"
      :order="order"
      :head-visible="index === 0"
      class="mb-1" />
  </template>
</template>

<style lang="scss" scoped>
.icon {
  $size: 18px;

  width: $size;
  height: $size;
}
</style>
