import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'

type DaktelaQueueResponse = {
  result: {
    accessToken: string
    /**
     * URL
     */
    server: string
    isOnline: boolean
    isOverload: boolean
  }
}

function queue() {
  return fetchGet<DaktelaQueueResponse>(appConfig.frontApiBaseUrl + 'daktela/queue')
}

export { queue as frontApiDaktelaQueue }
