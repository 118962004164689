import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import AppMainAuth from './AppMainAuth.vue'
import AppMainDefault from './AppMainDefault.vue'

/**
 * List of available wrapper components. You can add more components here.
 * Selected page wrapper is based on the `appMainWrapperComponent` meta field
 * of the page route (see "router meta fields").
 */
const wrapperComponents = {
  AppMainDefault,
  AppMainAuth,
}

type WrapperComponentNames = keyof typeof wrapperComponents

function isWrapperComponent(name: unknown): name is WrapperComponentNames {
  return Object.keys(wrapperComponents).includes(name as string)
}

/**
 * If valid wrapper component is provided in the route meta field, use it.
 * Otherwise, use the default wrapper component.
 * @param wrapperComponentName Wrapper component name from the route meta field.
 * @returns Wrapper component name as a string.
 */
function getWrapperComponentName(wrapperComponentName: unknown) {
  return isWrapperComponent(wrapperComponentName) ? wrapperComponentName : 'AppMainDefault'
}

/**
 *
 * @returns Reactive wrapper component name.
 */
function useWrapperComponent() {
  const route = useRoute()

  const wrapperComponentName = ref<WrapperComponentNames>(
    getWrapperComponentName(route.meta.appMainWrapperComponent),
  )

  watch(
    () => route.meta.appMainWrapperComponent,
    () => {
      wrapperComponentName.value = getWrapperComponentName(route.meta.appMainWrapperComponent)
    },
  )

  return {
    wrapperComponents,
    wrapperComponentName,
  }
}

export default useWrapperComponent
