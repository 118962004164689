<script setup lang="ts">
import { onMounted, watch } from 'vue'

import { storeToRefs } from 'pinia'

import { DeliveryMessage, DeliveryShipper, DeliveryTimeslots } from '@/components/Delivery'
import { BaseLoader } from '@/componentsPure'
import appConfig from '@/services/appConfig'
import { toastError } from '@/services/toast'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'
import useCartStore from '@/store/pinia/useCartStore'
import useTimeslotsStore from '@/store/pinia/useTimeslotsStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

import EarlyDeliveryCheckbox from './EarlyDeliveryCheckbox.vue'

const { isDefaultShipper } = storeToRefs(useAddressStore())
const timeslotsStore = useTimeslotsStore()
const { scrollToElement } = useUserInterfaceStore()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

timeslotsStore.fetchCartTimeslots()

function addBtnClickHandler() {
  const continueBtns = document.querySelectorAll('.js-cart-step-3-continue')
  if (!continueBtns.length) return

  continueBtns.forEach((continueBtn) => {
    continueBtn.addEventListener('click', (e) => {
      const selectedTimeslotId = cartGetters.value.getSelectedTimeslot?.id
      if (!selectedTimeslotId || !timeslotsStore.isCartTimeslotAvailable(selectedTimeslotId)) {
        e.preventDefault()
        toastError(t('delivery.slotNotChosen'))
      }
    })
  })
}

onMounted(addBtnClickHandler)

watch(
  () => cartGetters.value.getSelectedTimeslot,
  (selectedTimeslot) => {
    if (!selectedTimeslot) return
    scrollToElement({ targetElementId: 'payment-method-selection' })
  },
)
/*
 * End of abomination
 */
</script>

<template>
  <div>
    <DeliveryMessage
      v-if="timeslotsStore.hasCartTimeslots && timeslotsStore.hasCartClosingSoonTimeslot"
      :icon="{
        group: 'welcome',
        name: 'clock',
      }"
      :title="t('delivery.closingSoon.title')"
      :description="t('delivery.closingSoon.description')"
      color="blue"
      class="mb-2" />
    <DeliveryMessage
      :icon="{
        name: 'eco-badge',
      }"
      :title="t('delivery.ecoTransport.title')"
      :description="t('delivery.ecoTransport.description')" />
    <DeliveryTimeslots
      v-if="timeslotsStore.hasCartTimeslots"
      :timeslots="timeslotsStore.getCartTimeslots"
      group-name="checkout">
      <template #belowTimeslotsContent>
        <EarlyDeliveryCheckbox
          v-if="appConfig.earlyDeliveryCheckbox"
          class="early-delivery-checkbox" />
        <DeliveryShipper
          v-if="isDefaultShipper"
          class="mt-3" />
      </template>
    </DeliveryTimeslots>
    <div
      v-else
      class="position-relative py-5 my-5">
      <BaseLoader />
    </div>
  </div>
</template>

<style scoped lang="scss">
.early-delivery-checkbox {
  margin-top: 2rem;
}
</style>
