<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import UserLogin from '@/components/UserLogin'
import UserNav from '@/components/UserNav'
import { BaseButton, Tooltip } from '@/componentsPure'
import useAuth from '@/composables/useAuth'
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'

const { getUserName, isUserSignedIn } = useUser()
const { handleRegistrationClick } = useAuth()

const displayName = computed(() => {
  if (!isUserSignedIn) {
    return t('userMenu.signIn')
  }

  return getUserName ?? t('userMenu.user')
})

const linksToAction = computed(() => {
  return {
    name: 'Profile',
    params: {
      presenter: 'order',
      action: 'default',
    },
  }
})
</script>

<template>
  <Tooltip
    v-if="isUserSignedIn"
    direction="bottom-left"
    :disabled="!isUserSignedIn"
    shadow
    message-class="p-0"
    text-color="black"
    bg-color="white"
    wrapper-class="h-100">
    <RouterLink
      :to="linksToAction"
      data-tid="user-menu-trigger"
      class="control-link">
      <Icon
        class="mr-2 login-icon"
        :icon="'user'"
        :group="'mobile'" />
      <span
        class="name font-weight-bold"
        v-text="displayName" />
    </RouterLink>

    <template #message>
      <UserNav
        class="navigation"
        data-tid="header__user-menu" />
    </template>
  </Tooltip>

  <div
    v-else
    class="d-flex align-items-center">
    <UserLogin
      class="control-link mr-3 font-weight-bold"
      data-tid="user-menu-trigger"
      :gtm-click-intention="'login'">
      <Icon
        class="mr-2 login-icon"
        :icon="'user'"
        :group="'mobile'" />
      {{ t('general.login') }}
    </UserLogin>

    <BaseButton
      variant="secondary"
      @click.prevent="handleRegistrationClick"
      v-text="t('general.register')" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.login-icon {
  width: 22px;
  height: 22px;
}

.control-link {
  display: flex;
  align-items: center;
  min-width: 64px;
  max-width: 160px;
  height: 100%;
  color: color('primary');
  text-align: center;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
}

.name {
  text-overflow: ellipsis;
  word-break: break-word;

  @include make-line-clamp(2);
}

.navigation {
  @include media-breakpoint-up(md) {
    width: 212px;
  }
}
</style>
