<script setup lang="ts">
import { onUpdated, ref } from 'vue'

import { BaseButton, StatusMessage } from '@/componentsPure'
import useReleaseChecker from '@/composables/useReleaseChecker'
import { t } from '@/services/translations'

const emit = defineEmits<{
  releaseDetected: []
}>()

const newVersionReleased = ref(false)

useReleaseChecker(() => {
  newVersionReleased.value = true
})

function reload() {
  window.location.reload()
}

onUpdated(() => {
  emit('releaseDetected')
})
</script>

<template>
  <div
    v-if="newVersionReleased"
    class="new-release">
    <StatusMessage
      severity="warning"
      :message="t('newReleaseBar.text')"
      emphasized>
      <template #controls>
        <BaseButton
          variant="primary"
          @click="reload">
          {{ t('newReleaseBar.loadButton') }}
        </BaseButton>
      </template>
    </StatusMessage>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.new-release {
  position: relative;
  z-index: $zix-header__app-messages;
}
</style>
