import { inject } from 'vue'

import { growthbookKey } from '@/services/growthbook'

function useGrowthbookReady() {
  const isGrowthbookReady = inject(growthbookKey)

  if (!isGrowthbookReady) {
    throw new Error('isGrowthbookReady is undefined. Make sure the Growthbook plugin is installed.')
  }

  return isGrowthbookReady
}

export default useGrowthbookReady
