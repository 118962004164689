<script setup lang="ts">
type AddressSelectorTitleProps = {
  title: string
}

const props = defineProps<AddressSelectorTitleProps>()
</script>

<template>
  <h3 class="font-weight-bold text-7 text-black text-center mb-0">
    {{ props.title }}
  </h3>
</template>
