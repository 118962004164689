<script lang="ts" setup>
import { t } from '@/services/translations'

type SeparatorLineProps = {
  searchTerm: string
  showDisplayAll: boolean
  vendorId: number | undefined
}

const props = defineProps<SeparatorLineProps>()

const emit = defineEmits<{
  navigate: []
}>()
</script>

<template>
  <div class="d-flex align-items-center">
    <div class="separator-title">
      {{ t('productSearch.productsFound') }}
    </div>
    <div
      class="bg-gray-light flex-grow-1 ml-2"
      style="height: 1px" />
    <RouterLink
      v-if="props.showDisplayAll"
      :to="{
        name: 'ProductSearchResults',
        query: { search: props.searchTerm, vendor: props.vendorId },
      }"
      class="text-primary show-all-btn font-weight-normal text-4"
      @click="emit('navigate')">
      {{ t('general.showAll') }}
    </RouterLink>
    <div v-else>&nbsp;</div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.pharmacy-logo {
  width: 80px;
  min-width: 80px;
  height: 26px;
}

.kosik-logo {
  width: 110px;
  min-width: 110px;
  height: 26px;
  margin-right: 8px;
  margin-left: 8px;
}

.show-all-btn {
  padding: 8px 0 8px 24px;
  text-decoration: underline;
}

.separator-title {
  font-weight: var(--fontWeightBold);

  @include make-font-size(4);

  @include media-breakpoint-up(md) {
    @include make-font-size(6);
  }
}
</style>
