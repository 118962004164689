<script setup lang="ts">
import { computed } from 'vue'

import { usePaymentGooglePayOld, usePaymentApplePayOld } from '@/composables/payment'
import appConfig from '@/services/appConfig'

type CheckoutPaymentProps = {
  selectedPayment: string
  checkoutStepNumber: 3 | 4
  parentOrderId?: number
}

const props = defineProps<CheckoutPaymentProps>()

usePaymentApplePayOld()
const { onGooglePayLoaded } = usePaymentGooglePayOld(props.parentOrderId)

const shouldLoadGPayScript = computed(() => {
  return (
    appConfig.enableGooglePay &&
    props.selectedPayment === 'googlepay' &&
    props.checkoutStepNumber === 4
  )
})
</script>

<template>
  <Component
    :is="'script'"
    v-if="shouldLoadGPayScript"
    async
    type="text/javascript"
    src="https://pay.google.com/gp/p/js/pay.js"
    @load="onGooglePayLoaded" />
</template>

<style lang="scss">
@use '@/legacy' as *;

.apple-pay-button-payment-box {
  display: none;
}

.apple-pay-button {
  display: inline-block;
  width: 190px;
  height: 36px;
  cursor: pointer;
  border-radius: 18px;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-style: white-outline;
    -apple-pay-button-type: check-out;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    padding: 0;
    background-color: color('white');
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 60%;
    border: 0 solid color('black');
  }
}
</style>
