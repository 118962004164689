<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'

import AddressSelectorTitle from './AddressSelectorTitle.vue'

const { isDefaultShipper } = storeToRefs(useAddressStore())

const title = computed(() =>
  isDefaultShipper.value ? t('delivery.noSlotAvailable.title') : t('delivery.inTime.title'),
)
</script>

<template>
  <div class="d-flex align-items-center flex-column p-2 py-5">
    <AddressSelectorTitle
      :title="title"
      class="pb-2 mt-3" />
    <span
      v-if="isDefaultShipper"
      class="text-6 text-center mb-3">
      {{ t('delivery.noSlotAvailable.message') }}
    </span>
  </div>
</template>
