<script setup lang="ts">
import { computed } from 'vue'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'

type Props = {
  orderPrice: number
  deliveryPrice: number
  orderLabel: string
  deliveryLabel: string
}

const props = defineProps<Props>()

const { getFreeDeliveryOrderPrice } = useCartDeliveryData()

const isFreeDeliveryBox = computed(() => props.deliveryPrice === 0)
const isOurHintBox = computed(() => props.orderPrice === getFreeDeliveryOrderPrice.value)
const transportPriceHtml = computed(() => {
  if (isFreeDeliveryBox.value) {
    return t('general.gratis').toUpperCase()
  }
  return t('addressSelector.banner.from', {
    price: props.deliveryPrice,
    class: 'delivery-option__transport-price--value',
  })
})
</script>

<template>
  <div class="delivery-option">
    <span
      v-if="isOurHintBox"
      class="delivery-option__our-hint">
      {{ t('addressSelector.banner.ourHint') }}
    </span>
    <div class="delivery-option__order-value">
      <div
        class="delivery-option__order-value__container"
        data-tid="delivery-option__order-value__container">
        {{ props.orderLabel }}
      </div>
    </div>
    <div class="delivery-option__transport">
      <span>{{ props.deliveryLabel }}</span>
      <p
        :class="[
          'delivery-option__transport-price',
          {
            'delivery-option__transport-price--value delivery-option__transport-price--free':
              isFreeDeliveryBox,
          },
        ]"
        data-tid="delivery-price-banner-delivery-value"
        v-html="transportPriceHtml" />
    </div>
  </div>
</template>

<style lang="scss">
@use '@/legacy' as *;

.delivery-option {
  position: relative;
  padding: 24px 8px 16px;
  margin: 0 3px;
  overflow: hidden;
  font-size: em(14px);
  line-height: 1.2;
  color: color('black');
  text-align: center;
  background: color('white');
  border: 1px solid color('gray-light');
  border-radius: var(--borderRadiusSmall);
  box-shadow: 0 4px 4px color('gray-light');

  @include media-breakpoint-up(sm) {
    min-width: 146px;
    padding: 24px 19px 20px;
    margin: 0 8px;
  }

  @include media-breakpoint-up(md) {
    min-width: 162px;
    padding: 24px 24px 20px;
  }

  &__our-hint {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 4px;
    font-size: em(10px);
    font-weight: var(--fontWeightBold);
    background-color: color('brand-peach-light');
    border-radius: 4px 0;
  }

  &__order-value {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
    margin: 0;
    font-weight: var(--fontWeightBold);
    border-bottom: 1px solid color('gray-light');

    @include media-breakpoint-up(sm) {
      padding: 0 3px 8px;
    }

    &__container {
      max-width: 150px;

      @include media-breakpoint-up(sm) {
        max-width: unset;
      }
    }
  }

  &__transport {
    padding-top: 8px;

    @include media-breakpoint-up(sm) {
      padding-top: 16px;
    }
  }

  &__transport-price {
    padding-top: 4px;
    margin: 0;
    font-size: em(12px);

    &--value {
      font-size: em(17px);
      font-weight: var(--fontWeightBold);

      @include media-breakpoint-up(sm) {
        font-size: em(20px);
      }
    }

    &--free {
      color: color('green');
    }
  }
}
</style>
