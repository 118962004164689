<script setup lang="ts">
import { computed } from 'vue'

import { CarouselItem, CarouselWrapper } from '@/components/global/Carousel'
import RadioButton from '@/components/stories/atoms/radioButton/RadioButton.vue'

type NavTabItem = {
  name: string
  formValue: string
  label: string
}

type NavTabsProps = {
  items: NavTabItem[]
  modelValue: string
}

const props = defineProps<NavTabsProps>()

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const radioValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue: string) {
    emit('update:modelValue', newValue)
  },
})
</script>

<template>
  <CarouselWrapper
    faded-borders
    control-variant="dark"
    control-size="sm"
    class="nav-tab">
    <CarouselItem
      v-for="(item, index) in props.items"
      :key="index">
      <RadioButton
        v-model="radioValue"
        :name="item.name"
        :form-value="item.formValue"
        class="mr-2 my-2 text-uppercase">
        {{ item.label }}
      </RadioButton>
    </CarouselItem>
  </CarouselWrapper>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.nav-tab {
  height: $sliding-radio-menu--height;
}
</style>
