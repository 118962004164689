import useConfig from './useConfig'

/**
 * Returns true if user is logged in. False otherwise.
 * Returned value is not reactive, so after login status change,
 * the app must be reloaded.
 * @returns True if user is logged in. False otherwise.
 */
function useIsLogged() {
  const config = useConfig()

  // Not a computed() value, because there is always
  // reload after login status has changed.
  return config.value.user.email !== null
}

export default useIsLogged
