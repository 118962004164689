<script setup lang="ts">
import { provide, ref, onMounted, onUnmounted } from 'vue'

import { useWindowSize } from '@vueuse/core'

import Icon from '@/components/Icon/Icon.vue'
import NotificationList from '@/components/Notification/NotificationList.vue'
import { isButtonKey } from '@/components/Notification/notificationProviderKey'
import { BaseLoader } from '@/componentsPure'
import { useNotificationsDelete, useNotificationsValid } from '@/composables/notifications'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import { getCssVariable } from '@/utils/cssVariable'

import type { Notification } from './notificationTypes'

const { data: notifications, isLoading } = useNotificationsValid()

const removeNotification = useNotificationsDelete()

const deviceDetectorStore = useDeviceDetectorStore()
const { closeModal } = useModalStore()

function closeNotificationsModal() {
  closeModal(modalIndex.notifications)
}

const isButton = true // Notification message components in modal should look like buttons
provide(isButtonKey, isButton)

async function handleNotificationDeletion(notificationId: Notification['id']) {
  removeNotification(notificationId)
}

const windowHeight = ref<number>()
const { height } = useWindowSize()

function setWindowHeight() {
  const offsetMobile = 14 // 7px * 2 - vertical paddings from .tooltip__message
  const offset = deviceDetectorStore.breakpointUpSm ? 0 : offsetMobile // 14 for mobile, 0 for tablet and desktop

  const topbarHeightTablet = getCssVariable('--topbarHeightTablet')
  if (!topbarHeightTablet) return

  windowHeight.value = height.value - topbarHeightTablet - offset
}
onMounted(() => {
  setWindowHeight()
  window.addEventListener('resize', setWindowHeight)
})
onUnmounted(() => window.removeEventListener('resize', setWindowHeight))
</script>

<template>
  <div
    class="notification-modal"
    :style="{ maxHeight: `${windowHeight}px` }">
    <p
      class="text-3 text-gray-darker font-weight-bold my-2 mx-1"
      v-text="t('userMenu.labels.notifications')" />
    <div
      v-if="isLoading"
      class="position-relative p-5">
      <BaseLoader />
    </div>
    <div
      v-else-if="!notifications?.length"
      class="text-center">
      <Icon
        icon="no-notification"
        class="icon text-gray-darker mb-2" />
      <p
        class="text-primary text-3 font-weight-bold"
        v-text="t('notifications.noNotifications.title')" />
      <p
        class="text-gray-darker text-1 mb-1"
        v-text="t('notifications.noNotifications.description')" />
    </div>
    <template v-else>
      <NotificationList
        :notifications="notifications"
        @remove="handleNotificationDeletion" />
      <RouterLink
        :to="{
          name: 'Profile',
          params: {
            presenter: 'profile',
            action: 'notifications',
          },
        }"
        class="text-1 text-gray-darker a-link-underline d-flex justify-content-center mt-2 mb-1"
        @click="closeNotificationsModal"
        v-text="t('notifications.showAll')" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.icon {
  $icon-size: 70px;

  width: $icon-size;
  height: $icon-size;
}

.notification-modal {
  margin: 0;
  overflow-y: auto;

  @include media-breakpoint-up(sm) {
    width: 305px;
  }
}
</style>
