<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import CartPriceContentInfoSection from '@/components/CartPriceContentInfoSection'
import Icon from '@/components/Icon/Icon.vue'
import { OrdersMerge } from '@/components/Orders'
import { Tooltip } from '@/componentsPure'
import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { analyticsLoggerBeginCheckout } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const CheckoutSteps = {
  CartContent: 'CartContent',
  PersonalData: 'PersonalData',
  TransportPaymentFood: 'TransportPaymentFood',
  Summary: 'Summary',
  AdditionalOrderSummary: 'AdditionalOrderSummary',
} as const

type CheckoutStep = keyof typeof CheckoutSteps

type CartCheckoutControlsProps = {
  position: 'top' | 'bottom'
  linkBack: string
  linkNext: string
  checkoutStep: CheckoutStep
}

const props = defineProps<CartCheckoutControlsProps>()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownSm } = storeToRefs(deviceDetectorStore)
const { isMinimalOrderPrice, getMinimalOrderPrice } = useCartDeliveryData()

const isCartContentStep = computed(() => props.checkoutStep === CheckoutSteps.CartContent)
const isPersonalDataStep = computed(() => props.checkoutStep === CheckoutSteps.PersonalData)

const hasOrdersMerge = computed(
  () =>
    isCartContentStep.value &&
    cartGetters.value.hasAdditionalOrders &&
    !!cartGetters.value.getCartProductsCount,
)

const isAllowContinue = computed(
  () =>
    props.linkNext.length &&
    isMinimalOrderPrice.value &&
    !cartGetters.value.getCartReplaceableProductsCount &&
    cartGetters.value.hasBaseVendorProduct,
)

// Order of conditions is matching those in the "hasAdditionalInfo" computed property in the CartPriceContentInfoSection component
const tooltipText = computed(() => {
  if (!cartGetters.value.getCartProductsCount) {
    return t('checkout.tooltip.noProducts')
  }
  if (!cartGetters.value.hasBaseVendorProduct) {
    return t('checkout.tooltip.missingBaseVendorProduct')
  }
  if (cartGetters.value.getCartReplaceableProductsCount) {
    return t('checkout.tooltip.replaceableProducts')
  }
  if (!isMinimalOrderPrice.value) {
    return t('checkout.tooltip.minimalOrderPrice', {
      price: getMinimalOrderPrice.value,
    })
  }
  return undefined
})

// Tooltip is shown only on the first step of the checkout
const hasTooltip = computed(
  () => isCartContentStep.value && !isAllowContinue.value && !!tooltipText.value,
)

const linkBackText = computed<Record<CheckoutStep, string>>(() => ({
  CartContent: t('cart.backToShopping'),
  PersonalData: t('cart.backToCart'),
  TransportPaymentFood: t('cart.backToPersonalData'),
  Summary: breakpointDownSm.value ? t('general.back') : t('cart.backToDelivery'),
  AdditionalOrderSummary: breakpointDownSm.value ? t('general.back') : t('cart.backToCart'),
}))

const getWeightedProductDepositInclusionType = computed(() => {
  if (isCartContentStep.value) {
    return 'Excluded'
  }
  return 'Included'
})

const getPriceComponentsInclusionType = computed(() => {
  if (isCartContentStep.value) {
    return 'none'
  }
  if (isPersonalDataStep.value) {
    return 'Excluded'
  }
  return 'Included'
})
</script>

<template>
  <div
    :class="
      hasOrdersMerge && {
        'd-flex': true,
        'flex-column': props.position === 'top',
        'flex-column-reverse': props.position === 'bottom',
      }
    ">
    <div
      :class="{
        'd-flex flex-wrap justify-content-between align-items-md-center no-gutters': true,
        'pb-4': props.position === 'bottom',
      }">
      <div class="col-auto col-md-4 col-xl-3">
        <a
          :href="props.linkBack"
          class="btn btn__rounded btn--purple-inversed btn__left-icon">
          <Icon
            icon="arrow-button"
            class="btn__left-icon__main-icons btn__left-icon__main-icons--arrow-icon" />
          {{ linkBackText[props.checkoutStep] }}
        </a>
      </div>

      <CartPriceContentInfoSection
        :class="{
          'col-12 col-md-4 col-xl-6 mt-4': true,
          'mb-4': props.position === 'bottom',
          'order-first': props.position === 'top' && breakpointDownSm,
          'order-last': props.position === 'bottom' && breakpointDownSm,
        }"
        :weighted-product-deposit-inclusion="getWeightedProductDepositInclusionType"
        :price-components-inclusion="getPriceComponentsInclusionType"
        :price-components-display="props.position === 'bottom'"
        :transport-price-bar-hidden="props.checkoutStep === CheckoutSteps.AdditionalOrderSummary" />

      <div class="col-auto col-md-4 col-xl-3 text-right">
        <slot>
          <Tooltip
            :disabled="!hasTooltip"
            :direction="props.position === 'top' ? 'bottom-left' : 'top-left'"
            nowrap
            message-class="text-left">
            <RouterLink
              :to="isAllowContinue ? props.linkNext : ''"
              :disabled="!isAllowContinue"
              :class="{
                'btn__rounded btn--super-primary btn__right-icon': true,
                'btn--disabled': !isAllowContinue,
              }"
              data-tid="cart-step-1__continue"
              @click="analyticsLoggerBeginCheckout()">
              {{ t('cart.continue') }}
              <Icon
                icon="arrow-button"
                class="btn__right-icon__main-icons btn__right-icon__main-icons--arrow-icon" />
            </RouterLink>
            <template #message><span v-html="tooltipText" /></template>
          </Tooltip>
        </slot>
      </div>
    </div>

    <OrdersMerge
      v-if="hasOrdersMerge"
      class="mt-3 mb-4 mt-lg-4" />
  </div>
</template>
