<script setup lang="ts">
import { computed } from 'vue'

import { t } from '@/services/translations'

type Props = {
  street: string
  zip: string
}

const props = defineProps<Props>()

const address = computed(() => `${props.street}, ${props.zip}`)
</script>

<template>
  <div class="delivery-on-location-modal-header">
    <h1 class="title">{{ t('delivery.deliveryLocation.modal.title') }}</h1>
    <span class="subtitle">{{ t('delivery.deliveryLocation.modal.subtitle') }}</span>
    <span class="address">{{ address }}</span>
  </div>
</template>

<style scoped lang="scss">
.delivery-on-location-modal-header {
  display: flex;
  flex-direction: column;
  padding: 0;

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: get-font-size(4);
    line-height: 0.9;
    color: color('gray');
  }

  .address {
    font-size: get-font-size(5);
  }
}
</style>
