import { ref, watch, type Ref } from 'vue'

import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import type { UserAddress } from '@/store/pinia/useAddressStore'

type AddressForm = {
  gps: GPS | null
  positionGps: GPS | null
  street: string
  zip: string
  city: string
}

type AddressLatteForm = {
  street: HTMLInputElement | null
  city: HTMLInputElement | null
  zip: HTMLInputElement | null
  lat: HTMLInputElement | null
  lng: HTMLInputElement | null
  positionLat: HTMLInputElement | null
  positionLng: HTMLInputElement | null
}

// init form with initAddressForms, and use it as state
const addressForm = ref<UserAddress>({
  street: '',
  city: '',
  zip: '',
  gps: null,
  address: '',
  positionGps: null,
  note: null,
})
// this is used for quick update form elements, and is also initialized in initAddressForms
const addressLatteForm = ref<AddressLatteForm>({
  street: null,
  city: null,
  zip: null,
  lat: null,
  lng: null,
  positionLat: null,
  positionLng: null,
})

// use as store
function useLatteInputAddress() {
  // call this only only once on mounted on LatteInputAddress, AND NO MORE
  // it sets two global variables, addressForm (for vue data manipulation) and addressLatteForm for saving to latte form inputs
  function initAddressForms(formContainerLatteRef: Ref<HTMLDivElement | null>) {
    // load html elements with values. DO NOT TRY to make it writable computed, its impossible! Html element are not reactive
    Object.keys(addressLatteForm.value).forEach((inputName) => {
      addressLatteForm.value[inputName] = formContainerLatteRef.value?.querySelector(
        '.js-' + inputName,
      )
    })
    //setup addressForm with addressLatteForm values
    addressForm.value = getAddressForm()
  }

  function getAddressForm(): UserAddress {
    const street = addressLatteForm.value.street?.value ?? ''
    const zip = addressLatteForm.value.zip?.value ?? ''
    const city = addressLatteForm.value.city?.value ?? ''
    const address = street ? `${street}, ${zip} ${city}` : ''

    return {
      street,
      city,
      zip,
      address,
      gps: {
        lat: Number(addressLatteForm.value.lat?.value),
        lng: Number(addressLatteForm.value.lng?.value),
      },
      positionGps: {
        lat: Number(addressLatteForm.value.positionLat?.value),
        lng: Number(addressLatteForm.value.positionLng?.value),
      },
      note: null,
    }
  }

  // update addressLatteForm according to addressForm
  function setAddressLatteInputs(newAddressForm: UserAddress) {
    Object.keys(addressLatteForm.value).forEach((inputName) => {
      if (['lat', 'lng'].includes(inputName)) {
        //
        if (newAddressForm.gps === null) return

        addressLatteForm.value[inputName].value = newAddressForm.gps[inputName]
      } else if (['positionLat', 'positionLng'].includes(inputName)) {
        const attribute = inputName === 'positionLat' ? 'lat' : 'lng'

        addressLatteForm.value[inputName].value = newAddressForm.positionGps
          ? newAddressForm.positionGps[attribute]
          : null
      } else {
        addressLatteForm.value[inputName].value = newAddressForm[inputName]
      }
    })
  }
  watch(() => addressForm.value, setAddressLatteInputs)
  return { addressForm, initAddressForms }
}

export default useLatteInputAddress
export type { AddressForm }
