<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

import Icon from '../Icon/Icon.vue'

type CartFreeDeliveryInfoProps = {
  compact?: boolean
}

const props = defineProps<CartFreeDeliveryInfoProps>()

const { forAnonymousCustomer } = useCartDeliveryData()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const freeDeliveryOn30Days = computed(
  () => cartGetters.value.hasFreeDelivery || forAnonymousCustomer.value,
)

const freeDeliveryLabel = computed(() => {
  return freeDeliveryOn30Days.value
    ? t('addressSelector.freeDeliveryNewBuyer')
    : t('cart.progress.freeDelivery')
})
</script>

<template>
  <div
    class="cart-free-delivery"
    :class="{
      'cart-free-delivery--compact': props.compact,
      'cart-free-delivery--long-text': freeDeliveryOn30Days,
    }"
    data-tid="cart-free-delivery">
    <Icon
      v-if="!freeDeliveryOn30Days"
      class="icon--checkmark"
      icon="checkmark"
      group="checkout" />
    {{ freeDeliveryLabel }}
    <Icon icon="car" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.cart-free-delivery {
  @include make-font-size(4);

  display: flex;
  column-gap: 11px;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  font-weight: var(--fontWeightBold);
  color: color('contrast-green');
  background-color: color('tint-green');

  &--compact {
    @include make-font-size(3);

    padding: 0;
  }

  .icon {
    width: 24px;
    height: 24px;

    &--checkmark {
      width: 18px;
      color: color('accent-green');
    }
  }
}
</style>
