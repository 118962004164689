<script setup lang="ts">
import { computed } from 'vue'

type CartPriceTRansportPriceProps = {
  minLabel: string
  maxLabel: string
  color: 'primary-mid' | 'primary'
  progress: number
}
const props = defineProps<CartPriceTRansportPriceProps>()
const isPrimaryMid = computed(() => props.color === 'primary-mid')
const isPrimary = computed(() => props.color === 'primary')
const progressPercent = computed(() => `${props.progress * 100}%`)
</script>

<template>
  <section>
    <div :class="['cart-price-transport-bar overflow-hidden']">
      <div
        :class="[
          'cart-price-transport-bar__progress h-100',
          {
            'cart-price-transport-bar__progress--primary-mid': isPrimaryMid,
            'cart-price-transport-bar__progress--primary': isPrimary,
          },
        ]" />
    </div>
    <div class="cart-price-transport-bar--labels">
      <span v-html="props.minLabel" />
      <span v-html="props.maxLabel" />
    </div>
  </section>
</template>

<style lang="scss">
@use '@/legacy' as *;

.cart-price-transport-bar {
  height: 6px;
  background-color: color('background-disabled');
  border-radius: 3px;

  &--to-green {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0%),
      rgba(255, 255, 255, 0%) 50%,
      color('green-light')
    );
  }

  &__progress {
    width: v-bind('progressPercent');
    border-radius: 6px;
    transition: width var(--baseTransitionTime);

    &--primary {
      background: color('primary');
    }

    &--primary-mid {
      background: color('primary-mid');
    }
  }

  &--labels {
    @include make-font-size(3);

    display: flex;
    justify-content: space-between;
    font-weight: var(--fontWeightBold);
    line-height: 1.5;
    color: color('gray');
    letter-spacing: -0.9px;
  }
}
</style>
