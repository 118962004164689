<script setup lang="ts">
import { ref, computed, nextTick } from 'vue'

import { vOnClickOutside } from '@vueuse/components'
import debounce from 'lodash-es/debounce'

type SelectOption = {
  value: number
  selected: boolean
  country: string
}

type SelectBoxProps = {
  options: SelectOption[]
}
const props = defineProps<SelectBoxProps>()

const emit = defineEmits<{
  focus: []
  blur: []
  change: []
  changeFromDetail: []
}>()

const open = ref(false)
const focusOnOption = ref<number | null>(null)
const search = ref('')
const selectboxOptions = ref(props.options)

const getSelectedOption = computed(() => {
  return selectboxOptions.value.find((option) => option.selected)
})

const getSelectedIndex = computed(() => {
  if (!getSelectedOption.value) return -1
  return selectboxOptions.value.indexOf(getSelectedOption.value)
})

const getSearchOption = computed(() => {
  return search.value
    ? selectboxOptions.value.find((option) => option.value.toString().search(search.value) === 0)
    : null
})

const getSearchIndex = computed(() => {
  if (!getSearchOption.value) return -1
  return selectboxOptions.value.indexOf(getSearchOption.value)
})

function optionsToggle() {
  return open.value ? optionsClose() : optionsOpen()
}

const optionRef = ref<HTMLSpanElement | null>(null)

function optionsOpen() {
  open.value = true
  nextTick(() => {
    //WATCHOUT getSelectedIndex may return -1
    optionRef.value && optionRef.value[getSelectedIndex.value].scrollIntoViewIfNeeded(false)
  })
  emit('focus')
}

function optionsClose() {
  open.value = false
  focusOnOption.value = null
  emit('blur')
}

function setSelect(position: number) {
  if (position >= 0 && position < selectboxOptions.value.length) {
    const selectedOption = selectboxOptions.value.find((option) => option.selected)
    if (selectedOption) selectedOption.selected = false
    selectboxOptions.value[position].selected = true
    emit('change')
  }
}

function setSelectFromDetail(newSetPosition: number) {
  setSelect(newSetPosition)
  emit('changeFromDetail')
}

function setFocusOnOption(action: number | null, index: number) {
  const selectedIndex = focusOnOption.value !== null ? focusOnOption.value : getSelectedIndex.value
  const newPosition = index >= 0 ? index : selectedIndex + (action ?? 0) // Added change, delete comment after test

  if (newPosition >= 0 && newPosition < selectboxOptions.value.length) {
    focusOnOption.value = newPosition
    optionRef.value && optionRef.value[focusOnOption.value].scrollIntoViewIfNeeded(false)
  }
}

const searchRemove = debounce(() => {
  search.value = ''
}, 1000)

function viewSearch() {
  if (getSearchIndex.value >= 0) {
    setFocusOnOption(null, getSearchIndex.value)
  }
}

function searchAdd(key: string) {
  const REGEX_ALPHANUMERIC = /^([a-zA-Z0-9])$/
  if (REGEX_ALPHANUMERIC.test(key)) {
    search.value = search.value + key
    viewSearch()
    searchRemove() // removed 'this'! try first then delete comment
  }
}

function keyControlOnRoot(event: KeyboardEvent) {
  const eventKey = event.key
  const STOP_PROPAGATION_ON = ['Space', 'ArrowUp', 'ArrowDown', 'Enter']

  if (STOP_PROPAGATION_ON.includes(eventKey)) {
    event.stopPropagation()
    event.preventDefault()
  }

  if (open.value) {
    if (eventKey === 'Escape' || eventKey === 'Tab') {
      optionsClose()
    } else if (eventKey === 'Enter') {
      if (focusOnOption.value !== null) {
        setSelectFromDetail(focusOnOption.value)
      }
      optionsClose()
    } else if (eventKey === 'ArrowUp') {
      setFocusOnOption(-1, 0)
    } else if (eventKey === 'ArrowDown') {
      setFocusOnOption(+1, 0)
    } else if (eventKey) {
      searchAdd(eventKey)
    }
  } else if (eventKey === 'Enter' || eventKey === 'Space') {
    optionsOpen()
  } else if (eventKey === 'ArrowUp') {
    setSelect(getSelectedIndex.value - 1)
  } else if (eventKey === 'ArrowDown') {
    setSelect(getSelectedIndex.value + 1)
  }
}
</script>

<template>
  <div
    v-on-click-outside="() => optionsClose()"
    :class="{
      'is-open': open,
    }"
    class="a-input selectbox"
    tabindex="0"
    @click="optionsToggle"
    @keydown="keyControlOnRoot">
    <span class="selectbox__selected">
      <slot
        :option="getSelectedOption"
        name="selected">
        {{ getSelectedOption?.value }}
      </slot>
    </span>
    <div class="selectbox__options-wrap">
      <div class="selectbox__options a-scroll-nice">
        <span
          v-for="(option, index) in selectboxOptions"
          ref="optionRef"
          :key="index"
          :class="{
            'is-focus': index === focusOnOption,
            'is-selected': index === getSelectedIndex,
          }"
          class="selectbox__option"
          @click="setSelectFromDetail(index)">
          <slot
            :option="option"
            name="option">
            {{ option.value }}
          </slot>
        </span>
      </div>
    </div>
    <span class="selectbox__arrow" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.selectbox {
  position: relative;
  z-index: $zix-selectbox;
  padding-top: 12px;
  user-select: none;

  &.is-open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__selected {
    display: block;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__options {
    position: absolute;
    top: 100%;
    right: -1px;
    left: -1px;
    display: none;
    max-height: 158px;
    overflow: hidden;
    overflow-y: auto;
    background: color('white');
    border: 1px solid color('gray-darker');
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 4px color('black', 0.2);

    .is-open & {
      display: block;
    }
  }

  &__option {
    position: relative;
    display: block;
    padding: 10px;
    overflow: hidden;
    transition: background var(--baseTransitionDelay);

    &.is-focus,
    &:hover {
      background: color('gray-lighter');
    }

    &.is-selected {
      background: color('gray-light');
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-color: color('gray-darker') transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;

    .is-open & {
      transform: rotate(180deg);
    }
  }

  &__ico-check {
    @include make-font-size(11, lg);

    position: absolute;
    top: 50%;
    right: -8px;
    margin: -30px 0 0 0;
    color: color('green');
  }

  @include media-breakpoint-down(sm) {
    &.is-open &__options-wrap {
      position: fixed;
      inset: 0 0 0 0;
      z-index: $zix-selectbox__options-wrap;
      height: auto;
      background: color('black', 0.5);
    }

    &.is-open &__options {
      position: absolute;
      top: 50%;
      right: 10px;
      left: 10px;
      max-height: calc(100% - 20px);
      transform: translateY(-50%);
    }

    &.is-open &__arrow {
      display: none;
    }
  }
}
</style>
