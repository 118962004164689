function useOrder(orderId: number) {
  const linkToOrder = {
    name: 'Profile',
    params: {
      presenter: 'order',
      action: 'detail-order',
    },
    query: { orderId },
  }

  return { linkToOrder }
}

export default useOrder
