<script setup lang="ts">
import { computed } from 'vue'

import { t } from '@/services/translations'
import type { RefinedTimeslot } from '@/store/pinia/useTimeslotsStore'
import localizePrice from '@/utils/localizePrice'

type DeliveryDayButtonProps = {
  day: RefinedTimeslot
  index: number
  readonly: boolean
  openDayIndex: number
  futureTimeslotsLength: number
  hasTimeslotLimitedProduct: boolean[]
  hidePrice?: boolean
}

const props = defineProps<DeliveryDayButtonProps>()

const emit = defineEmits<{
  onClick: []
}>()

const customPrice = computed(() =>
  localizePrice(props.day.cheapestPrice ?? 0, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }),
)
</script>

<template>
  <button
    :data-tid="props.day.hasFreeTimeslots ? 'delivery-day' : 'delivery-day-unavailable'"
    type="button"
    :class="[
      `day-toggle`,
      {
        'day-toggle--first': props.index === 0,
        'day-toggle--last': props.index + 1 === props.futureTimeslotsLength,
        'day-toggle--active': props.index === props.openDayIndex,
        'day-toggle--unavailable':
          props.day.unavailable || (props.hasTimeslotLimitedProduct[index] && !props.readonly),
      },
    ]"
    :disabled="!props.day.hasFreeTimeslots || false"
    @click="emit('onClick')">
    <div
      class="d-flex align-items-center flex-column h-100"
      :class="props.hidePrice ? 'justify-content-center' : 'justify-content-between'">
      <strong
        class="day-toggle__day"
        :class="{ 'day-toggle__day--show-price': !props.hidePrice }">
        {{ props.day.day }}
      </strong>
      <span class="day-toggle__date">
        {{ props.day.dateString }}
      </span>
      <span
        v-if="!props.hidePrice"
        class="day-toggle__value">
        <template v-if="props.day.hasFreeTimeslots">
          <template v-if="props.day.hasFreeDeliveryPrice">
            {{ t('general.gratis') }}
          </template>
          <template v-else>
            {{ t('delivery.slotPriceFrom') }}
            <span data-tid="delivery-day-price">
              {{ customPrice }}
            </span>
          </template>
        </template>
        <template v-else>
          {{ t('delivery.slotClosed') }}
        </template>
      </span>
    </div>
  </button>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.day-toggle {
  $this: &;

  height: 70px;
  padding: 0;
  margin: 0 2px;
  overflow: hidden;
  font-weight: var(--fontWeightBold);
  line-height: 1.2;
  color: color('gray-dark');
  background: color('gray-lighter');
  border: none;
  border-radius: 4px;
  transition:
    box-shadow var(--fastTransitionTime),
    background var(--fastTransitionTime);

  @include media-breakpoint-up(md) {
    min-width: 105px;
    height: 90px;
  }

  @include media-breakpoint-up(lg) {
    flex-grow: 1;
    min-width: 122px;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px color('gray-dark');
  }

  &__day {
    @include make-font-scale(4);

    min-width: 80px;
    color: color('black');
    text-transform: uppercase;

    &--show-price {
      padding: 8px 18px 0;

      @include media-breakpoint-up(md) {
        padding: 16px 16px 0;
      }
    }
  }

  &__date {
    @include make-font-scale(3);

    padding-bottom: 1px;
    color: color('gray');
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      @include make-font-scale(5);
    }
  }

  &__value {
    @include make-font-scale(2);

    display: block;
    width: 100%;
    padding: 8px 15px;
    color: color('white');
    white-space: nowrap;
    background: color('gray-dark');
    transition: background var(--fastTransitionTime);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--active,
  &--active:hover {
    pointer-events: none;
    background: color('primary-light');
    box-shadow: inset 0 0 0 2px color('primary');

    #{$this}__date {
      color: color('gray');
    }

    #{$this}__value {
      background: color('primary');
    }
  }

  &--unavailable {
    position: relative;

    &::after {
      @include make-font-scale(1);

      $size: 24px;

      position: absolute;
      top: 0;
      right: 0;
      width: $size;
      height: $size;
      padding: 3px 5px;
      color: color('white');
      text-align: right;
      content: '!';

      // non-round value in the following rule prevents zigzag rendering in FF
      background: linear-gradient(45deg, transparent 50%, color('accent-red') 50.1%);

      @include media-breakpoint-up(md) {
        $size: 28px;

        width: $size;
        height: $size;
        padding: 4px 6px;
      }
    }
  }

  &[disabled] {
    background: color('gray-lighter');
    box-shadow: none;

    #{$this}__date,
    #{$this}__day {
      color: color('gray');
    }

    #{$this}__value {
      color: color('gray');
      background: color('gray-lighter');
    }
  }
}
</style>
