import { watch } from 'vue'

import { useQuery, useQueryClient } from '@tanstack/vue-query'

import QueryTime from '@/constants/QueryTime'
import { notificationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type { NotificationsCountParams } from '@/data/api/notificationsApi/notifications/notificationsTypes'
import { analyticsLoggerNotificationBell } from '@/services/analytics/analyticsLogger'

function useNotificationsCount(queryParams: NotificationsCountParams = {}) {
  const queryClient = useQueryClient()

  // Memoize previous value
  let lastValue = -1

  const query = useQuery({
    queryKey: notificationQueryKeys.count(),
    queryFn: () => api.notifications.notifications.notificationsCount({ queryParams }),
    refetchInterval: QueryTime.TEN_MINUTES,
  })

  watch(query.isSuccess, () => {
    // Only perform actions if count changes
    if (query.data.value !== lastValue && query.data.value) {
      analyticsLoggerNotificationBell(query.data.value)
      queryClient.invalidateQueries({ queryKey: notificationQueryKeys.data() })
      lastValue = query.data.value
    }
  })

  return query
}

export default useNotificationsCount
