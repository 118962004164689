<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useDeliveryData from '@/composables/transport/useDeliveryData'
import useTimeslotsStore, { type RefinedTimeslot } from '@/store/pinia/useTimeslotsStore'
import { isSameDay } from '@/utils/date'

import DeliveryDayButton from './DeliveryDayButton.vue'

const props = defineProps<{
  futureTimeslots: RefinedTimeslot[]
  readonly: boolean
  openDayIndex: number
}>()

const emit = defineEmits<{
  onDayClick: [index: number | null]
}>()

const { limitedProducts } = storeToRefs(useTimeslotsStore())
const { data: deliveryData } = useDeliveryData()

const hasTimeslotLimitedProduct = computed(() =>
  props.futureTimeslots
    .map((timeslot) =>
      limitedProducts.value.map((limitedProduct) =>
        limitedProduct.days.some((day) => isSameDay(day.date, timeslot.date)),
      ),
    )
    .map((timeslot) => timeslot.some((hasLimitation) => hasLimitation === true)),
)
</script>

<template>
  <div class="delivery-days-wrapper">
    <div class="delivery-days">
      <DeliveryDayButton
        v-for="(day, index) in props.futureTimeslots"
        :key="day.day"
        :day="day"
        :index="index"
        :open-day-index="openDayIndex"
        :has-timeslot-limited-product="hasTimeslotLimitedProduct"
        :future-timeslots-length="futureTimeslots.length"
        :readonly="props.readonly"
        :hide-price="deliveryData?.forAnonymousCustomer"
        @on-click="() => emit('onDayClick', day.hasFreeTimeslots ? index : null)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.delivery-days-wrapper {
  display: flex;
  padding: 16px 0;
  overflow-x: auto;

  @include media-breakpoint-up(md) {
    margin: 0 -16px;
  }

  &::before,
  &::after {
    flex: 1;
    min-width: 16px;
    content: '';
  }
}

.delivery-days {
  display: flex;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0 7px color('gray-dark', 25);

  @include media-breakpoint-up(md) {
    box-shadow: none;
  }

  // @todo: prepared for next adjustments (K3W-15060 - checkout 2.0)
  // @include media-breakpoint-up(lg) {
  //   flex-grow: 1;
  // }
}
</style>
