<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import CartFlashMessage from '@/components/CartFlashMessage'
import { CheckoutCartList } from '@/components/Checkout'
import Icon from '@/components/Icon/Icon.vue'
import useInteractionSource from '@/composables/useInteractionSource'
import { getCookie } from '@/services/cookies'
import { t } from '@/services/translations'
import useCartAlertsStore from '@/store/pinia/useCartAlertsStore'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'
import { formatUntilDate } from '@/utils/date'

import CartPreviewFooter from './CartPreviewFooter.vue'
import CartPreviewHeader from './CartPreviewHeader.vue'

const cartAlertsStore = useCartAlertsStore()
const deviceDetectorStore = useDeviceDetectorStore()
const userInterfaceStore = useUserInterfaceStore()
const { headerHeight, isCartPreviewOpenDesktop } = storeToRefs(userInterfaceStore)

const { breakpointDownMd, breakpointDownLg } = storeToRefs(deviceDetectorStore)

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const isDesktopCartClosed = computed(
  () => !breakpointDownMd.value && !isCartPreviewOpenDesktop.value,
)

const getCartProductsCount = computed(() => cartGetters.value.getCartProductsCount)

const hasAdditionalOrders = computed(() => cartGetters.value.hasAdditionalOrders)

const getCartAdditionalOrders = computed(() => cartGetters.value.getCartAdditionalOrders)
const additionalOrderCloseDayTimeInfo = computed(() => {
  if (!hasAdditionalOrders.value) {
    return null
  }

  return formatUntilDate(dayjs(getCartAdditionalOrders.value[0].closeDateTime))
})

// state for mouse hover
const isOpenTemporarily = ref(false)

const handleMouseHover = (e: MouseEvent) => {
  // keeps open when removing items
  if (isOpenTemporarily.value && e.type === 'mouseenter') return

  // keeps closed after click and mouseleave
  if (!isOpenTemporarily.value && e.type === 'mouseleave') return

  isOpenTemporarily.value = !isOpenTemporarily.value
}

// pernament state that is set after clicking on CartPreview
// due to UI changes in categories, it is set also in store
const isOpen = computed(() => breakpointDownMd.value || isCartPreviewOpenDesktop.value)

const setCartPreviewOpenDesktop = (isCartPreviewOpen: boolean) =>
  userInterfaceStore.setCartPreviewOpenDesktop(isCartPreviewOpen)
// @TODO remove KNW-12689 adjustments after Checkout2.0 is released
const router = useRouter()
const toggle = (e: MouseEvent) => {
  if (getCookie('checkout2')) {
    e.ctrlKey && router.push({ name: 'PageCart' })
    e.shiftKey && router.push({ name: 'Checkout2' })
    return
  }
  // @TODO end KNW-12689 adjustments remove after Checkout2.0 is released
  setCartPreviewOpenDesktop(!isOpen.value)

  // close on click
  if (!isOpen.value) {
    isOpenTemporarily.value = false
  }
}

useInteractionSource('cartPreview')
</script>

<template>
  <div
    :class="[
      'cart-preview',
      'ssr-hide-mobile',
      {
        'cart-preview--open': isOpen,
        'cart-preview--extended': hasAdditionalOrders,
        'cart-preview--bottom': breakpointDownLg && isOpen,
      },
    ]"
    :style="{
      height:
        (isOpen || isOpenTemporarily) && !breakpointDownMd
          ? `calc(100vh - ${headerHeight}px)`
          : 'auto',
    }"
    data-tid="cart-preview"
    @mouseenter="handleMouseHover"
    @mouseleave="handleMouseHover">
    <div
      v-if="hasAdditionalOrders"
      class="text-2 px-2 py-1 text-white bg-contrast-green clearfix">
      <strong
        data-tid="cart-preview__additional-order-info"
        v-text="
          t('cart.additionalOrders.youCan', {
            info: cartGetters.hasMoreAdditionalOrders
              ? t('cart.additionalOrders.fewClicks')
              : additionalOrderCloseDayTimeInfo,
          })
        " />
      <RouterLink
        class="float-right a-link-underline"
        :to="{ name: 'StaticPage', params: { id: t('cart.additionalOrders.link') } }"
        v-text="t('cart.additionalOrders.linkText')" />
    </div>

    <CartPreviewHeader
      :is-open="isOpen || isOpenTemporarily"
      @click="toggle" />

    <span
      v-if="isDesktopCartClosed && !isOpenTemporarily"
      class="cart-preview__flash-message">
      <CartFlashMessage />
    </span>

    <template v-if="isOpen || isOpenTemporarily">
      <div class="cart-preview__content">
        <Transition name="fade">
          <div
            v-if="cartAlertsStore.cartAlert"
            class="cart-preview__content-message a-flash-message a-flash-message--radius-sm"
            v-text="t(cartAlertsStore.cartAlert.messageKey, cartAlertsStore.cartAlert.params)" />
        </Transition>

        <CheckoutCartList v-if="getCartProductsCount" />

        <div
          v-else-if="cartGetters.isCartLoaded"
          class="h-100 d-flex align-items-center py-4">
          <div class="text-gray text-center flex-grow-1">
            <Icon
              icon="cart-large"
              class="cart-preview__empty-cart" />
            <strong
              class="d-block mt-4"
              data-tid="cart-preview__empty-cart"
              v-text="t('cart.empty')" />
          </div>
        </div>
      </div>

      <CartPreviewFooter
        class="cart-preview__footer"
        data-tid="cart-preview-footer" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$baseHeaderHeight: 44px;
$extendedHeaderHeight: $baseHeaderHeight + 26;

.cart-preview {
  position: fixed;
  top: var(--topbarHeightTablet);
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: color('gray-lighter');

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    width: var(--previewCartWidth);
    border-color: color('gray-light');
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 4px 4px;

    &--open {
      border-bottom: 0;
      border-radius: 0;
    }

    &--extended {
      min-height: $extendedHeaderHeight + 1;
    }
  }

  &--bottom {
    bottom: 50px;
  }

  &__flash-message {
    position: relative;
    top: 10px;
  }

  &__content {
    flex-grow: 1;
    padding-bottom: 4px;
    overflow: auto;
  }

  &__content-message {
    @include make-font-scale(2);

    margin: 0 4px;
  }

  &__empty-cart {
    width: 80px;
    height: 80px;
    opacity: 0.15;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
    line-height: 1.33;
    text-align: center;
    background: color('white');
    box-shadow: 0 -2px 4px color('black', 0.1);
  }
}
</style>
