<script setup lang="ts">
import { storeToRefs } from 'pinia'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

import PriceTransportBar from './PriceTransportBar.vue'
import PriceTransportLabel from './PriceTransportLabel.vue'

const { isFreeDelivery, isMinimalOrderPrice, getFreeDeliveryOrderPrice } = useCartDeliveryData()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
</script>

<template>
  <PriceTransportLabel :effective-price="cartGetters.getCartProductsRealPrice" />
  <PriceTransportBar
    class="progress-bar"
    :effective-price="cartGetters.getCartProductsRealPrice" />
  <div
    v-if="cartGetters.hasELicenceOrReturnablesProducts && !isMinimalOrderPrice"
    class="returnables-info">
    {{ t('cart.minimalOrderPriceInfo') }}
  </div>
  <span
    v-if="getFreeDeliveryOrderPrice !== undefined && (isFreeDelivery || !isMinimalOrderPrice)"
    class="text-gray text-2 mt-1 mb-1"
    v-text="
      t('cart.progress.minimalOrderForFreeDelivery', {
        price: localizePrice(getFreeDeliveryOrderPrice),
      })
    " />
  <span
    v-else
    class="text-gray text-2 mt-1 mb-1"
    v-text="t('cart.progress.checkoutClaim')" />
</template>

<style lang="scss">
.returnables-info {
  margin: 16px 0 8px 0;
  font-size: 12px;
  font-weight: 500;
}

.progress-bar {
  width: 266px;
  margin: 8px auto;
}
</style>
