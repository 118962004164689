<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

import CartDeliveryInfoLabel from './CartDeliveryInfoLabel.vue'
import CartFreeDeliveryInfo from './CartFreeDeliveryInfo.vue'
import CartPriceTransportBar from './CartPriceTransportBar.vue'

const {
  isMinimalOrderPrice,
  isFreeDelivery,
  getMinimalOrderPrice,
  getFreeDeliveryOrderPrice,
  showFreeDeliveryInfo,
  showProgressInfo,
  forAnonymousCustomer,
} = useCartDeliveryData()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const hasELicenceOrReturnablesProducts = computed(
  () => cartGetters.value.hasELicenceOrReturnablesProducts,
)

const color = computed<'primary-mid' | 'primary'>(() =>
  isMinimalOrderPrice.value ? 'primary' : 'primary-mid',
)

const minPrice = computed(() => (isMinimalOrderPrice.value ? getMinimalOrderPrice.value : 0))

const maxPrice = computed(() => {
  return !isMinimalOrderPrice.value ? getMinimalOrderPrice.value : getFreeDeliveryOrderPrice.value
})

const progress = computed(() => {
  if (minPrice.value === undefined || maxPrice.value === undefined) return 0

  const progressFloat =
    (cartGetters.value.getCartProductsRealPrice - minPrice.value) /
    (maxPrice.value - minPrice.value)
  return Math.min(+progressFloat.toFixed(6), 1)
})

const showCartPriceTransportBar = computed(() => {
  const freeDelivery = isFreeDelivery.value || cartGetters.value.hasFreeDelivery

  return !freeDelivery || !isMinimalOrderPrice.value
})
</script>

<template>
  <section>
    <div
      v-if="showProgressInfo"
      class="cart-delivery-info--container">
      <CartDeliveryInfoLabel />
      <div v-if="hasELicenceOrReturnablesProducts">
        {{ t('cart.minimalOrderPriceInfo') }}
      </div>
      <CartPriceTransportBar
        v-if="showCartPriceTransportBar && minPrice !== undefined && maxPrice !== undefined"
        :color="color"
        :min-label="localizePrice(minPrice)"
        :max-label="localizePrice(maxPrice)"
        :progress="progress"
        class="cart-delivery-info--container__progress" />
    </div>

    <CartFreeDeliveryInfo
      v-if="showFreeDeliveryInfo || forAnonymousCustomer"
      class="cart-delivery-info--free-delivery" />
  </section>
</template>

<style lang="scss" scoped>
.cart-delivery-info {
  &--container {
    margin: 16px 12px 0 12px;

    &__progress {
      width: 100%;
      margin-top: 4px;
    }
  }

  &--free-delivery {
    width: 100%;
    margin-bottom: 4px;
  }
}
</style>
