import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartStore from '@/store/pinia/useCartStore'

export function useCart() {
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)

  const cartTotalPrice = computed(() => cartGetters.value.getCartTotalPrice || 0)
  const cartTotalItems = computed(() => cartGetters.value.getCartProductsCount || 0)
  const hasAdditionalOrders = computed(() => cartGetters.value.hasAdditionalOrders)

  return {
    cartTotalPrice,
    cartTotalItems,
    hasAdditionalOrders,
  }
}
