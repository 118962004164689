import logger from '@/services/logger'

const log = logger('getBuildManifest')

/**
 * Get the manifest.json file contents. This file contains current application
 * file paths. (E.g. path to app.js, vendor.js, main.css, etc.)
 * Although file contains JSON, it is returned as a string.
 *
 * @returns {Promise<string | null>} The manifest.json file contents or null if manifest could not be fetched.
 */
async function getBuildManifest() {
  try {
    const response = await fetch('/vue/manifest.json')
    if (response.status !== 200) return null

    const version = await response.text()

    return version
  } catch (error) {
    log(new Error('Error fetching application version.'))
    return null
  }
}

export default getBuildManifest
