import { useRouter } from 'vue-router'

import debounce from 'lodash-es/debounce'

import { analyticsLoggerPayment } from '@/services/analytics/analyticsLogger'
import { frontApiPostCheckoutPayGoogle } from '@/services/api/front/checkout'
import appConfig from '@/services/appConfig'
import { googlePayGetEchoData, googlePayGetCheckoutFinishData } from '@/services/googlePay'
import logApmError from '@/services/logApmError'
import { toastError } from '@/services/toast'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

export class GooglePayError extends Error {
  constructor(message, originalError = null, isReadyToPayResult = undefined) {
    const errorMessage =
      originalError instanceof Error ? `${message}: ${originalError.message}` : message

    super(errorMessage)
    this.name = 'GooglePayError'
    this.detail = originalError?.response?.data?.detail
    this.title = originalError?.response?.data?.title
    this.translatedDetail = originalError?.response?.data?.context?.translatedDetail
    this.status = originalError?.response?.data?.status
    this.url = originalError?.url
    this.isReadyToPayResult = JSON.stringify(isReadyToPayResult)
  }
}

let googlePayClient
let googlePayEchoData

async function onGooglePaymentButtonClicked(router, parentOrderId, buttonWrapper) {
  analyticsLoggerPayment('google_pay')

  // Prevents user from clicking GPay button when payment prompt closes after successfull payment
  // as that would create another order and fail due to cart being empty
  buttonWrapper.style.pointerEvents = 'none'

  let checkoutFinishData

  try {
    checkoutFinishData = await googlePayGetCheckoutFinishData(parentOrderId)

    // Whole order is payed by credits, there is no need for GPay, also no payment data are available
    // another bullshit hotfix
    if (!checkoutFinishData.transactionInfo) {
      router.push({ name: 'PageThankYou', params: { id: checkoutFinishData.orderId } })
      return
    }
  } catch (err) {
    const errorResponseType = err?.response?.data?.type
    const errorTranslatedDetail = err?.response?.data?.context?.translatedDetail
    /**
     * If the error response type is 'credit/application-failed', it means that
     * there was an issue with the credits and the order was not created.
     *
     * This can happen, when a user tries to use reserved credits.
     *
     * In this case, we should reload the page, backend unsets the credits and the prices are recalculated.
     */
    if (errorResponseType === 'credit/application-failed') {
      toastError(errorTranslatedDetail || t('checkout.googlePay.error.paymentFailed'))
      logApmError(new GooglePayError('GPay checkout finish error - credit/application-failed', err))
      // reload the page, credits will be recalculated
      router.go()
    } else if (err.customType === 'OrderError') {
      toastError(errorTranslatedDetail || t('checkout.googlePay.error.unavailable'))
      logApmError(new GooglePayError('GPay checkout finish error', err))
      // redirect to 3rd step of checkout (keeps products in cart)
      router.push({ name: 'TransportPayment' })
    } else if (err.customType === 'AdditionalOrderError') {
      toastError(errorTranslatedDetail || t('checkout.googlePay.error.paymentFailed'))
      logApmError(new GooglePayError('GPay additional order finish error', err))
      // redirect to 1st step of checkout (cart will be empty)
      router.push({ name: 'Cart' })
    }
    return
  }

  // Opens GPay payment prompt
  try {
    const paymentDataObject = {
      ...googlePayEchoData,
      transactionInfo: {
        ...googlePayEchoData.transactionInfo,
        ...checkoutFinishData.transactionInfo,
      },
    }
    const paymentData = await googlePayClient.loadPaymentData(paymentDataObject)

    googlePayProcessPayment(router, paymentData, checkoutFinishData)
  } catch (err) {
    if (err.statusCode === 'CANCELED') {
      // user canceled Google Pay payment by clicking the modal "back" button
      // should be changed to api/front/checkout/rollback/:orderId in checkout 2.0
      router.go()
    } else {
      toastError(t('checkout.googlePay.error.unavailable'))
      logApmError(new GooglePayError('GPay loadPaymentData error', err))
      router.push({ name: 'TransportPayment' })
    }
  }
}

const debouncedOnButtonClick = debounce(
  (router, parentOrderId, buttonWrapper) =>
    onGooglePaymentButtonClicked(router, parentOrderId, buttonWrapper),
  5000,
  {
    leading: true,
    trailing: false,
  },
)

async function googlePayProcessPayment(router, paymentData, checkoutFinishData) {
  const { orderId } = checkoutFinishData

  try {
    const {
      data: { redirectUrl },
    } = await frontApiPostCheckoutPayGoogle(paymentData, orderId)
    window.location.href = redirectUrl
  } catch (err) {
    toastError(t('checkout.googlePay.error.paymentFailed'))
    logApmError(new GooglePayError('GPay process payment error', err))
    // Reloads a page. Also rollback of order is made internally by BE.
    router.go()
  }
}

/**
 * @deprecated Used in Checkout 1.0. Will be deleted after Checkout 2.0 is released.
 */
function usePaymentGooglePayOld(parentOrderId) {
  const router = useRouter()

  async function onGooglePayLoaded() {
    // Data for GPay initialization. CAN'T BE PROXY OBJECT
    try {
      googlePayEchoData = await googlePayGetEchoData()
    } catch (err) {
      toastError(t('checkout.googlePay.error.unavailable'))
      logApmError(new GooglePayError('GPay failed to get ECHO data', err))
      return
    }

    if (!googlePayEchoData) return

    // Creates GPay client
    try {
      googlePayClient = new window.google.payments.api.PaymentsClient({
        environment: googlePayEchoData.environment,
      })
    } catch (err) {
      toastError(t('checkout.googlePay.error.unavailable'))
      logApmError(new GooglePayError('GPay create client error', err))
      return
    }

    // Checks if GPay is available
    try {
      const res = await googlePayClient.isReadyToPay({ ...googlePayEchoData })

      if (!res.result) {
        toastError(t('checkout.googlePay.error.unavailable'))
        logApmError(new GooglePayError('GPay isReadyToPay result false', null, res))
        router.push({ name: 'TransportPayment' })
      }
    } catch (err) {
      toastError(t('checkout.googlePay.error.unavailable'))
      logApmError(new GooglePayError('GPay isReadyToPay error', err))
      router.push({ name: 'TransportPayment' })
    }

    // Creates GPay payment button
    addGooglePayButtons()
  }

  const deviceDetectorStore = useDeviceDetectorStore()

  /**
   * Creates Google Pay payment button, with @click handler fn.
   */
  function addGooglePayButtons() {
    const wrapper = document.querySelector('.google-pay-button__wrapper')
    wrapper.classList.remove('js-animation-spinner', 'text-red')

    try {
      const googlePayButton = googlePayClient.createButton({
        buttonType: 'pay',
        buttonLocale: appConfig.langCode,
        buttonSizeMode: deviceDetectorStore.breakpointUpMd ? 'static' : 'fill',
        onClick: () => debouncedOnButtonClick(router, parentOrderId, wrapper),
      })

      wrapper.appendChild(googlePayButton)
    } catch (err) {
      logApmError(new GooglePayError('GPay create button error', err))
    }
  }

  return {
    onGooglePayLoaded,
  }
}

export { usePaymentGooglePayOld }
