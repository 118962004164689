<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { DeliveryMessage, DeliveryTimeslots } from '@/components/Delivery'
import { BaseButton, BaseLoader } from '@/componentsPure'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'

import AddressSelectorEmptyTimeslots from './AddressSelectorEmptyTimeslots.vue'
import AddressSelectorHeader from './AddressSelectorHeader.vue'
import AddressSelectorTitle from './AddressSelectorTitle.vue'
import usePreviewTimeslots from './usePreviewTimeslots'

const emit = defineEmits<{
  onCloseClick: []
}>()

const {
  hasPreviewClosingSoonTimeslot,
  previewTimeslots,
  hasPreviewTimeslots,
  isPreviewTimeslostsFetching,
} = usePreviewTimeslots()

const { userAddressStale } = storeToRefs(useAddressStore())
</script>

<template>
  <div>
    <AddressSelectorHeader />
    <div
      v-if="isPreviewTimeslostsFetching || userAddressStale"
      class="position-relative py-5 my-5">
      <BaseLoader />
    </div>
    <template v-else-if="hasPreviewTimeslots">
      <AddressSelectorTitle
        :title="t('addressSelector.timeslots.title')"
        class="mt-3" />
      <DeliveryTimeslots
        :timeslots="previewTimeslots"
        group-name="preview"
        readonly>
        <template #aboveTimeslotsContent>
          <div
            class="text-4 font-weight-bold text-center pb-2"
            v-text="t('delivery.previewFootnote')" />
        </template>
        <template #belowTimeslotsContent>
          <DeliveryMessage
            v-if="hasPreviewClosingSoonTimeslot"
            :icon="{
              group: 'welcome',
              name: 'clock',
            }"
            :title="t('delivery.closingSoon.title')"
            :description="t('delivery.closingSoon.description')"
            color="blue"
            class="mb-2 mt-3" />
          <DeliveryMessage
            :icon="{
              name: 'eco-badge',
            }"
            :title="t('delivery.ecoTransport.title')"
            :description="t('delivery.ecoTransport.description')"
            class="mb-2 mt-3" />
        </template>
      </DeliveryTimeslots>
      <div class="text-center pt-2 pb-3">
        <BaseButton
          variant="secondary"
          :icon-left="{
            name: 'cross',
            class: 'mr-1',
          }"
          @click="() => emit('onCloseClick')">
          {{ t('general.close') }}
        </BaseButton>
      </div>
    </template>
    <AddressSelectorEmptyTimeslots v-else />
  </div>
</template>
