<script setup lang="ts">
import { useSlots } from 'vue'

import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'

const slots = useSlots()
const defaultSlotContent = slots.default?.()[0]?.children
const elements: any[] = defaultSlotContent?.[0]?.children || defaultSlotContent || []
</script>

<template>
  <CarouselWrapper
    :step="4"
    control-size="sm"
    :control-box-shadow="true">
    <CarouselItem
      v-for="(element, index) in elements"
      :key="index">
      <Component :is="element" />
    </CarouselItem>
  </CarouselWrapper>
</template>
