import dayjs from 'dayjs'
import 'dayjs/locale/bg'
// @TODO dynamic locales import according to domain
import 'dayjs/locale/cs'
import 'dayjs/locale/sk'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import minMax from 'dayjs/plugin/minMax'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

const domainToLocaleMap = {
  cz: 'cs',
  sk: 'sk',
  bg: 'bg',
} as const

dayjs.locale(domainToLocaleMap[import.meta.env.K3W_DOMAIN] ?? domainToLocaleMap.cz)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(minMax)
dayjs.tz.setDefault(import.meta.env.TZ ?? 'Europe/Prague')
