<script setup lang="ts">
// @todo: after killing this latte bridge component change all UserNav styles to scoped
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import Icon from '@/components/Icon/Icon.vue'
import UserNav from '@/components/UserNav'
import AToggle from '@/components/stories/atoms/toggle/Toggle.vue'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type UserNavToggleProps = {
  activeMenuItem: string
}

const props = defineProps<UserNavToggleProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const route = useRoute()
const isMenuOpen = ref(!!route.query?.showmenu)

watch(
  () => !!route.query?.showmenu,
  (value) => (isMenuOpen.value = value),
)

function closeMenu() {
  isMenuOpen.value = false
}
</script>

<template>
  <div>
    <UserNav
      v-if="deviceDetectorStore.breakpointUpMd"
      data-tid="profile__user-menu"
      item-class="my-2" />
    <AToggle
      v-else
      v-model="isMenuOpen">
      <template #control>
        <div :class="['d-flex flex-column', isMenuOpen ? 'pt-3' : 'py-3']">
          <div class="title px-3 mr-3">
            {{ t('userMenu.myAccount') }}
            <Icon
              icon="arrow-button"
              :class="{
                'icon--menu-open': isMenuOpen,
              }" />
          </div>
          <span
            class="user-nav__link user-nav__link--active text-gray-darker text-5 pl-3"
            v-html="props.activeMenuItem" />
        </div>
      </template>
      <UserNav
        class="pt-0"
        excluded-active-route
        item-class="my-2"
        @close="closeMenu" />
    </AToggle>
  </div>
</template>

<style lang="scss" scoped>
.title {
  position: relative;
  font-size: 18px;
  font-weight: var(--fontWeightBold);
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);

  &--menu-open {
    transform: translateY(-50%) rotate(-90deg);
  }
}
</style>
