<script setup lang="ts">
import { ref, computed } from 'vue'

import { BaseButton } from '@/componentsPure'
import { LocalStorageKeys } from '@/constants/storageKeys'
import appConfig from '@/services/appConfig'
import { storagePersistLocal, storageRetrieveLocal } from '@/services/storage'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import ButtonIcon from './components/ButtonIcon.vue'

const isVisible = ref(true)
const emit = defineEmits<{
  close: []
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const isIos = deviceDetectorStore.isIos
const isAndroid = deviceDetectorStore.isAndroid
const isSafari = deviceDetectorStore.isSafari

function hideSuggester() {
  isVisible.value = false
  storagePersistLocal(LocalStorageKeys.isMobileAppSuggested, true)
  emit('close')
}

const isMobileAppSuggested = computed(() =>
  storageRetrieveLocal<boolean>(LocalStorageKeys.isMobileAppSuggested),
)

function getAppLink() {
  if (isIos) return 'appStore'
  if (isAndroid) return 'googlePlay'
}

function openApp() {
  const link = getAppLink()
  link && window.open(appConfig.mobileAppLinks[link])
  hideSuggester()
}

if (
  !appConfig.mobileAppLinks ||
  !deviceDetectorStore.breakpointDownSm ||
  !(isIos || isAndroid) ||
  (isIos && isSafari)
) {
  hideSuggester()
}
</script>

<template>
  <div
    v-if="isVisible && !isMobileAppSuggested"
    class="py-2 d-flex align-items-center text-white bg-gray-dark">
    <ButtonIcon
      icon="cross"
      :aria-label="t('general.close')"
      class="btn-margin"
      @click="hideSuggester" />
    <div
      class="mr-3 d-flex align-items-center justify-content-center bg-white"
      style="border-radius: 14px">
      <img
        src="/img/logo-symbol.svg?v=1"
        alt=""
        width="44"
        height="44"
        class="m-2" />
    </div>
    <div class="flex-grow-1">
      <div
        class="text-7 font-weight-bold"
        style="line-height: 1.2"
        v-text="t('general.brand')" />
      {{ t('general.company') }}
    </div>
    <BaseButton
      variant="primary"
      size="sm"
      pill
      class="btn-margin text-3 text-uppercase"
      @click="openApp"
      v-text="t('general.open')" />
  </div>
</template>

<style lang="scss" scoped>
.btn-margin {
  margin: 12px;
}
</style>
