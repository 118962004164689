<script setup>
import Icon from '@/components/Icon/Icon.vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <button
    type="button"
    class="button-icon">
    <Icon
      :icon="props.icon"
      class="icon" />
  </button>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$button-size: 24px;
$icon-size: 14px;

.icon {
  width: $icon-size;
  height: $icon-size;
  color: color('gray-darker');
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $button-size;
  height: $button-size;
  padding: 0;
  background: color('white', 0.6);
  border: 0;
  border-radius: $button-size * 0.5;
}
</style>
