import { computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import useConfig from '@/composables/useConfig'
import { menuQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

function useMenuMain() {
  const configData = useConfig()

  const browserCacheToken = computed(() => configData.value.cart.browserCacheToken)

  return useQuery({
    queryKey: menuQueryKeys.main(),
    queryFn: () =>
      api.front.menu.main({
        queryParams: {
          v: browserCacheToken.value ?? '',
        },
      }),
  })
}

export default useMenuMain
