import { computed, toValue, type MaybeRef } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { orderQueryKeys } from '@/constants/queryKeys'
import { frontApiGetProfileOrders, type OrderListParams } from '@/services/api/front/profile'
import { prepareSimpleOrder } from '@/store/utils/prepareOrder'

function useProfileOrdersGet(orderListParams: MaybeRef<OrderListParams>) {
  const query = useQuery({
    queryKey: orderQueryKeys.list(orderListParams),
    queryFn: () => frontApiGetProfileOrders(toValue(orderListParams)),
  })

  const preparedOrders = computed(() => {
    const orders = query.data.value?.data.orders || []
    return orders.map((orderItem) => prepareSimpleOrder(orderItem))
  })

  return {
    ...query,
    preparedOrders,
  }
}

export default useProfileOrdersGet
