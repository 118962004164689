<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

import { AddressSelector } from '@/components/AddressSelector'
import CartAlternativesModal from '@/components/AppFooter/CartAlternativesModal.vue'
import LiveChat from '@/components/AppFooter/LiveChat.vue'
import PharmacyWelcomeModal from '@/components/AppFooter/PharmacyWelcomeModal.vue'
import CheckoutSubDestinationSwitch from '@/components/Checkout/CheckoutSubDestinationSwitch.vue'
import BenjaminekAddChildModal from '@/components/Clubs/Benjaminek/BenjaminekAddChildModal.vue'
import DeliveryOnLocationModal from '@/components/DeliveryOnLocationModal'
import type { CookiesSetValue } from '@/components/Privacy/CookieBar/CookieBarModal.vue'
import DetailedCookieModal from '@/components/Privacy/CookieBar/DetailedCookieModal.vue'
import ProductDetailModal from '@/components/Product/Detail/Modal/ProductDetailModal.vue'
import { getCookie } from '@/services/cookies'
import { cookieName } from '@/services/privacy'

const CookieBarModal = defineAsyncComponent(
  () => import('@/components/Privacy/CookieBar/CookieBarModal.vue'),
)
const isVisibleCookieBar = ref(getCookie(cookieName).length === 0)
const route = useRoute()
if (route.name === 'PrivacyPolicy') {
  isVisibleCookieBar.value = false
}
const cookieActionValue = ref<CookiesSetValue>('')
function handleCookieAction(value: CookiesSetValue) {
  cookieActionValue.value = value
}
</script>

<template>
  <CookieBarModal
    v-if="isVisibleCookieBar"
    @cookie-action="handleCookieAction" />
  <DetailedCookieModal :cookies-set-value="cookieActionValue" />
  <LiveChat />
  <PharmacyWelcomeModal />
  <ProductDetailModal />
  <CartAlternativesModal />
  <AddressSelector />
  <BenjaminekAddChildModal />
  <DeliveryOnLocationModal />
  <CheckoutSubDestinationSwitch />
</template>
