<script setup lang="ts">
import { computed } from 'vue'

import { BaseButton } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import type { TimeslotsLimitedProduct } from '@/store/pinia/useTimeslotsStore'
import { formatTime } from '@/utils/date'

import ProductRow from '../components/Row/ProductRow.vue'

type LimitedProduct = Product & TimeslotsLimitedProduct

type ProductLimitedProps = {
  product: LimitedProduct
  large?: boolean
  activeDayIndex: number
}

const props = defineProps<ProductLimitedProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { openModal } = useModalStore()
const { cartActions } = useCartStore()

const productRef = computed(() => props.product)

const getInteractionSource = useInteractionSource('ProductLimited')

const { productId, isSoldOut, hasBaseVendor, removeProductFromCart } = useProductItem(
  productRef,
  getInteractionSource,
)

const activeDayLimitations = computed(() => {
  const timeRanges = props.product.days[props.activeDayIndex].limitedTimeRanges

  if (!timeRanges?.length) {
    return null
  }

  const dayShortname = props.product.days[props.activeDayIndex].date.format('ddd')
  let limitationString = `${dayShortname.toUpperCase()}: ${t('delivery.availableInTimeRanges')} `
  limitationString += timeRanges
    .map((timeRange) => {
      // eslint-disable-next-line no-irregular-whitespace
      return `${formatTime(timeRange.start)} - ${formatTime(timeRange.end)}`
    })
    .join(', ')

  return limitationString
})

const slotDayLimitationsName = computed(() => {
  return deviceDetectorStore.breakpointUpSm ? 'summary' : 'nameAdditional'
})
const slotAlternativesControlName = computed(() => {
  return props.large ? 'controls' : 'bottom'
})

function openCartAlternativesModal() {
  openModal(modalIndex.cartAlternatives)
}

function setCurrentlyReplacedProduct() {
  cartActions.setCurrentlyReplacedProduct({
    id: productId.value,
    isSoldOut: isSoldOut.value,
    hasLimitedDelivery: true,
  })
}

function removeProduct(interactionElement: string) {
  setCurrentlyReplacedProduct()
  removeProductFromCart(interactionElement)
}

function handleLimitedProductAmount() {
  setCurrentlyReplacedProduct()
  openCartAlternativesModal()
}

const isProductAvailableInSelectedDay = computed(() => {
  const day = props.product.days[props.activeDayIndex]

  return day.isAvailable && !day.limitedTimeRanges
})
</script>

<template>
  <ProductRow
    :product="props.product"
    :large="props.large"
    :closable="!isProductAvailableInSelectedDay"
    detail-disabled
    @close="removeProduct('button_x_remove_limited')">
    <template #[slotAlternativesControlName]>
      <div
        v-if="!isProductAvailableInSelectedDay"
        :class="['d-flex align-items-center', !props.large && 'mt-1']">
        <BaseButton
          v-if="hasBaseVendor"
          variant="primary"
          :class="['product-limited__alternatives-control', props.large ? 'mr-3' : 'mr-1 w-50']"
          :icon-left="{
            name: 'rotate',
            class: 'mr-1',
          }"
          @click="handleLimitedProductAmount">
          {{ t('unavailableProducts.replace') }}
        </BaseButton>

        <BaseButton
          variant="secondary"
          :class="{
            'product-limited__alternatives-control': true,
            'w-50': !props.large,
          }"
          :icon-left="{
            name: 'cross',
            class: 'mr-1',
          }"
          @click="removeProduct('a_remove_limited')">
          {{ t('unavailableProducts.removeItem') }}
        </BaseButton>
      </div>
    </template>

    <template #[slotDayLimitationsName]>
      <ul
        :class="[
          'mt-1 mb-0 list-unstyled d-flex justify-content-sm-end',
          activeDayLimitations ? 'mt-sm-2 mt-md-3' : 'mt-sm-0',
        ]">
        <li
          v-for="(day, index) in product.days"
          :key="index"
          :class="[
            'day',
            {
              'day--active': index === activeDayIndex,
              'day--unlimited': day.isAvailable && !day.limitedTimeRanges,
              'day--unavailable': !day.isAvailable,
              'mr-1': index + 1 !== product.days.length,
            },
          ]"
          v-text="day.date.format('ddd')" />
      </ul>

      <div
        v-if="activeDayLimitations"
        class="notice"
        v-text="activeDayLimitations" />
    </template>
  </ProductRow>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-limited {
  &__alternatives-control {
    width: 127px;
    height: 26px;
  }
}

.day {
  @include make-font-scale(2);

  min-width: 28px;
  padding: 5px 6px 3px;
  line-height: 1;
  color: color('red');
  text-align: center;
  text-transform: uppercase;
  background: color('gray-lighter');
  border-radius: var(--borderRadiusXSmall);

  &--active {
    font-weight: var(--fontWeightBold);
    box-shadow: inset 0 0 0 1px currentColor;
  }

  &--unlimited {
    color: color('green');
  }

  &--unavailable {
    position: relative;

    &::after {
      $indent: 3px;

      position: absolute;
      top: 50%;
      right: $indent;
      left: $indent;
      height: 1px;
      content: '';
      background-color: currentColor;
    }
  }
}

.notice {
  @include make-font-scale(1);

  margin-top: 2px;
  line-height: 1.2;
  color: color('green');

  @include media-breakpoint-up('md') {
    margin-top: 4px;
  }
}
</style>
