<script setup lang="ts">
import { RouterLink } from 'vue-router'

import modalIndex from '@/constants/modalIndex'
import useModalStore from '@/store/pinia/useModalStore'

type ActionMenuItemProps = {
  icon: string
  text: string
  url: string
}

const props = defineProps<ActionMenuItemProps>()
const { closeModal } = useModalStore()

function handleMenuItemClick() {
  closeModal(modalIndex.mobileNavigation)
}
</script>

<template>
  <RouterLink
    :to="props.url"
    class="action-menu-item"
    @click.prevent="handleMenuItemClick">
    <img
      :src="`${props.icon}?v=3`"
      alt=""
      class="action-menu-icon" />
    {{ props.text }}
  </RouterLink>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.action-menu-item {
  position: relative;
  display: block;
  padding-left: 35px;
  line-height: var(--mobileMenuCategoryHeigth);
  white-space: nowrap;
  border-bottom: 1px solid color('gray-light');

  @include media-breakpoint-up(lg) {
    display: inline-block;
    padding: 5px 17px 5px 25px;
    line-height: inherit;
    border: 0;
  }

  &:hover {
    color: color('primary');
    text-decoration: none;
  }
}

.action-menu-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  width: auto;
  max-width: 17px;
  height: auto;
  max-height: 19px;
  transform: translateY(-50%);

  @include media-breakpoint-up(lg) {
    left: 0;
  }
}
</style>
