<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import PriceTransportInfo from '@/components/PriceTransportInfo'
import { BaseLoader, BaseTag } from '@/componentsPure'
import type { CartPrices } from '@/services/api/front/cartTypes'
import { t, tc } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

type PriceInclusionTypes = 'none' | 'Excluded' | 'Included'

export type PriceContentInfoSectionProps = {
  weightedProductDepositInclusion: Exclude<PriceInclusionTypes, 'none'>
  priceComponentsInclusion: PriceInclusionTypes
  priceComponentsDisplay?: boolean
  transportPriceBarHidden?: boolean
}

const props = defineProps<PriceContentInfoSectionProps>()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const getCartCheckoutPrices = computed(() => cartGetters.value.getCartCheckoutPrices)

type PriceComponents = Pick<CartPrices, 'transport' | 'tip' | 'credit' | 'voucher'>
const priceComponents = computed<PriceComponents>(() => ({
  transport: getCartCheckoutPrices.value.transport,
  tip: getCartCheckoutPrices.value.tip,
  credit: getCartCheckoutPrices.value.credit,
  voucher: getCartCheckoutPrices.value.voucher,
}))

function sumPriceComponents(pricesToSum: PriceComponents) {
  return pricesToSum.transport + pricesToSum.tip - pricesToSum.credit - pricesToSum.voucher
}

const getCartCurrentSum = computed(() => {
  if (!cartGetters.value.getCartTotalPrice) {
    return 0
  } else {
    const weightedProductsPriceAmount =
      props.weightedProductDepositInclusion === 'Included'
        ? getCartWeightedProductsDepositPrice.value
        : 0

    const priceComponentsAmount =
      props.priceComponentsInclusion === 'Included' ? sumPriceComponents(priceComponents.value) : 0

    return cartGetters.value.getCartTotalPrice + weightedProductsPriceAmount + priceComponentsAmount
  }
})

const getCartReturnablePackagingPrice = computed(
  () => cartGetters.value.getCartTotalPrice - cartGetters.value.getCartProductsPrice,
)
const getCartReplaceableProductsCount = computed(
  () => cartGetters.value.getCartReplaceableProductsCount,
)
const getCartWeightedProductsDepositPrice = computed(
  () =>
    cartGetters.value.getCartTotalPriceWithDeposits -
    getCartReturnablePackagingPrice.value -
    cartGetters.value.getCartProductsPrice,
)

const hasAdditionalInfo = computed(
  () =>
    !cartGetters.value.hasBaseVendorProduct ||
    getCartReplaceableProductsCount.value ||
    !props.transportPriceBarHidden,
)
</script>

<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center text-center position-relative"
    style="min-height: 108px">
    <div v-if="cartGetters.isCartLoaded">
      <p class="text-6 mb-2">
        <span
          class="cart-value"
          data-tid="cart-value">
          {{
            t(
              `cart.${
                priceComponentsInclusion === 'Included' ? 'priceSummarized' : 'contentSummarized'
              }`,
            )
          }}
          <strong>
            {{ localizePrice(getCartCurrentSum) }}
          </strong>
        </span>
        <BaseTag
          v-if="cartGetters.getCartTotalDiscount > 0"
          modifier="green"
          class="ml-1"
          size="big">
          <Icon
            class="saving-icon"
            icon="checkbox-ok" />
          <strong
            v-text="
              t('checkout.priceSaved', {
                price: localizePrice(cartGetters.getCartTotalDiscount, {
                  maximumFractionDigits: 2,
                }),
              })
            " />
        </BaseTag>
      </p>
      <div v-if="priceComponentsDisplay">
        <!-- Returnable packaging price is always displayed and included in the cart current sum (when not null) -->
        <p
          v-if="getCartReturnablePackagingPrice > 0"
          class="mb-0 text-4 text-gray">
          {{ t('general.fromThat') }}
          <strong>{{ localizePrice(getCartReturnablePackagingPrice) }}</strong>
          {{ t('checkout.returnablePackaging') }}
        </p>
        <!-- Weighted products deposit value can be included or not in the cart current sum, thus the different translations -->
        <p
          v-if="getCartWeightedProductsDepositPrice > 0"
          class="mb-0 text-4 text-gray">
          {{ t(`checkout.weightedProductDeposit${weightedProductDepositInclusion}.beginning`) }}
          <strong>{{ localizePrice(getCartWeightedProductsDepositPrice) }}</strong>
          {{ t(`checkout.weightedProductDeposit${weightedProductDepositInclusion}.end`) }}
        </p>
        <!-- Rest of price components can be not displayed at all -->
        <div v-if="priceComponentsInclusion !== 'none'">
          <template
            v-for="(priceComponent, priceComponentKey) in priceComponents"
            :key="priceComponentKey">
            <!-- And again, similar to weighted products deposit, rest of price components values can be included or not in the cart current sum when displayed, thus the different translations -->
            <p
              v-if="priceComponent"
              class="mb-0 text-4 text-gray"
              :data-tid="`cart-value__${priceComponentKey}`">
              {{ t(`checkout.${priceComponentKey + priceComponentsInclusion}.beginning`) }}
              <strong>{{ localizePrice(priceComponent) }}</strong>
              {{ t(`checkout.${priceComponentKey + priceComponentsInclusion}.end`) }}
            </p>
          </template>
        </div>
      </div>
      <div
        v-if="cartGetters.getCartProductsCount && hasAdditionalInfo"
        class="delivery-info text-4 mb-3 mb-md-0"
        data-tid="cart-checkout__delivery">
        <template v-if="!cartGetters.hasBaseVendorProduct">
          <p
            class="text-contrast-red text-6 mb-1"
            v-text="t('vendors.notJustPharmacy.warning')" />
          <p
            class="text-gray text-2 mb-2"
            v-text="t('vendors.notJustPharmacy.note')" />
        </template>
        <template v-else-if="getCartReplaceableProductsCount">
          <p class="text-contrast-red text-6 mb-1">
            {{ t('general.fromThat') }}
            <strong>{{ getCartReplaceableProductsCount }}</strong>
            {{
              t('cart.replaceableContentWarning.countInfoBeginning', {
                itemsDeclensed: tc('cart.itemsFourth', getCartReplaceableProductsCount),
              })
            }}
            <strong>{{ t('cart.replaceableContentWarning.countInfoEnd') }}</strong>
          </p>
          <p
            class="text-4 mb-2"
            v-text="t('cart.replaceableContentWarning.note')" />
        </template>
        <PriceTransportInfo v-else-if="!props.transportPriceBarHidden" />
      </div>
    </div>
    <BaseLoader v-else />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.cart-value {
  color: color('text-paragraph');
}

.saving-icon {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

.delivery-info {
  &::before {
    display: block;
    width: 100px;
    height: 1px;
    margin: 8px auto;
    content: '';
    background: color('gray-lighter');
  }
}
</style>
