<script setup>
import { computed } from 'vue'

const props = defineProps({
  routeTo: {
    type: [Object, String],
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  entity: {
    type: String,
    default: 'button',
  },
  rounded: {
    type: [Boolean, String],
    default: false,
  },
  variant: {
    type: String,
    default: 'primary',
  },
})

const getShapeClass = computed(() => {
  const customRounded = props.rounded.length ? `btn--${props.rounded}` : 'btn--rounded'
  return props.rounded ? customRounded : null
})
</script>

<template>
  <Component
    :is="props.entity"
    :to="props.routeTo"
    :loading="props.loading || null"
    :disabled="props.disabled || null"
    :class="['btn', `btn--${props.variant}`, getShapeClass]">
    <slot />
  </Component>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.btn {
  padding: 8px 15px;
  line-height: 1.4;
  color: color('black');
  text-align: center;
  cursor: pointer;
  border: none !important;
  transition:
    color var(--fastTransitionTime) ease-in,
    background-color var(--fastTransitionTime) ease-in,
    box-shadow var(--fastTransitionTime) ease-in;

  @include make-font-scale(2);

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.2;
  }

  &--rounded {
    border-radius: var(--borderRadiusSmall);
  }

  &--pill {
    border-radius: 18px;
  }
}

@mixin make-button-variant(
  $name,
  $color,
  $bg-color,
  $bg-color-active: $bg-color,
  $color-active: $color
) {
  .btn--#{$name} {
    color: color($color);
    background-color: color($bg-color);

    @if $bg-color == $color-active {
      box-shadow: inset 0 0 0 2px color($bg-color-active);
    }

    &:hover,
    &:focus,
    &:active {
      color: color($color-active);
      background-color: color($bg-color-active);
      box-shadow: 0 0 0 1px color($bg-color-active);
    }

    &:disabled {
      background-color: color($bg-color);
    }
  }
}

@include make-button-variant(primary, 'black', 'secondary-light', 'secondary-dark');
@include make-button-variant(primary-prominent, 'black', 'secondary', 'secondary-dark');
@include make-button-variant(
  secondary,
  'primary-light',
  'primary-lighter',
  'primary-light',
  'primary-lighter'
);
@include make-button-variant(tertiary, 'white', 'primary-light');
@include make-button-variant(neutral, 'black', 'gray-lighter', 'gray-light');
@include make-button-variant(neutral-light, 'black', 'white');
@include make-button-variant(neutral-dark, 'white', 'gray-dark', 'black');
@include make-button-variant(neutral-darker, 'white', 'gray-darker', 'black');
@include make-button-variant(danger, 'white', 'red');
@include make-button-variant(neutral-dark-inverted, 'black', 'white', 'gray-dark', 'white');
</style>
