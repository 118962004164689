import { useRouter } from 'vue-router'

import getScriptElementInHead from '@/utils/getScriptElementInHead'

type SitelinksSearchBox = { [key: string]: any }

/**
 * Generate sitelinks search box
 */
function generateSitelinksSearchBox() {
  const router = useRouter()
  const searchPath = router.resolve({ name: 'ProductSearchResults' }).fullPath

  const data: SitelinksSearchBox = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: window.location.origin,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${window.location.origin}${searchPath}?search={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  }

  const scriptElement = getScriptElementInHead('searchBox')
  scriptElement.textContent = JSON.stringify(data)
}

export { generateSitelinksSearchBox }
