<script setup lang="ts">
import { t } from '@/services/translations'

const emit = defineEmits<{
  onClick: []
}>()
</script>

<template>
  <div class="text-4 pr-1">
    {{ t('privacySettings.detailedDescription.description1') }}
    <strong>
      {{ t('privacySettings.detailedDescription.description2') }}
    </strong>
    {{ t('privacySettings.detailedDescription.description3') }}
    <RouterLink
      :to="{ name: 'PrivacyPolicy' }"
      class="a-link-underline"
      @click="emit('onClick')">
      {{ t('privacySettings.detailedDescription.description4') }}
    </RouterLink>
    <br /><br />
    {{ t('privacySettings.detailedDescription.description5') }}
    <a
      :href="t('privacySettings.googleLink')"
      target="_blank"
      rel="noopener"
      class="a-link-underline">
      {{ t('privacySettings.detailedDescription.description6') }}
    </a>
  </div>
</template>
