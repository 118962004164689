<script setup lang="ts">
/**
 * This component is used to show a message with a link to the product search results page
 * when there are products from other vendor than current one.
 */
import { computed } from 'vue'

import makeImgOptions from '@/components/ProductSearch/makeImgOptions'
import { Vendors } from '@/constants/vendors'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'

type OtherProductProps = {
  vendorId: number
  count: number
  searchTerm: string
}

const props = defineProps<OtherProductProps>()

const isPharmacy = computed(() => props.vendorId === Vendors.PHARMACY)
const domain = appConfig.domain

const img = computed(() => makeImgOptions(isPharmacy.value, domain))

const labelLink = computed(() =>
  t('productSearch.otherProductsLink.products', { count: props.count }),
)
</script>

<template>
  <RouterLink
    v-if="count"
    :class="[
      'd-inline-flex',
      'flex-nowrap',
      'align-items-center',
      'other-products-link',
      img.class,
    ]"
    data-tid="other-products__button-link"
    :to="{
      name: 'ProductSearchResults',
      query: { search: searchTerm, vendor: vendorId },
    }">
    <img
      :alt="img.alt"
      :src="img.src"
      class="logo" />
    <div>{{ labelLink }}</div>
  </RouterLink>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.other-products-link {
  @include make-font-size(2);

  gap: 16px;
  height: 58px;
  padding: 4px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 1px rgba(23, 27, 30, 24%);

  &.pharmacy {
    background: color('mint-cream');

    .logo {
      width: 84px;
      background: white;
      border: 1px solid color('gray', 0.7);
      border-radius: var(--topbarFieldAndButtonHeight);
    }
  }

  &.kosik {
    background: color('pink-pale');

    .logo {
      width: 110.64px;
      height: 26px;
    }
  }

  .link {
    padding: 8px 24px 8px 24px;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
}
</style>
