<script setup lang="ts">
import { computed } from 'vue'

type RadioButtonProps = {
  modelValue?: string
  name?: string
  formValue?: string
}

const props = withDefaults(defineProps<RadioButtonProps>(), {
  modelValue: '',
  name: undefined,
  formValue: undefined,
})

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const checked = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})
</script>

<template>
  <label
    :class="[
      'radio-label',
      {
        'bg-primary text-white': props.modelValue === props.formValue,
      },
    ]">
    <input
      v-model="checked"
      type="radio"
      :name="props.name"
      :value="props.formValue"
      class="radio-input" />
    <slot />
  </label>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.radio-label {
  $shadow-color: 'gray-dark';

  position: relative;
  display: inline-block;
  padding: 0 12px;
  font-weight: 700;
  line-height: 32px;
  color: color('primary');
  border: 1px solid color('primary');
  border-radius: var(--borderRadiusSmall);
  transition:
    color var(--baseTransitionTime),
    background var(--baseTransitionTime);
}

.radio-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zix-base;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
</style>
