<script setup lang="ts">
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'

const { getUserName, getUserEmail } = useUser()
</script>

<template>
  <div class="user-card">
    <strong
      class="user-card__name"
      data-tid="user-profile__user-name"
      v-text="getUserName ?? t('userMenu.user')" />
    <span
      v-if="getUserEmail"
      class="user-card__email"
      data-tid="user-profile__user-email"
      v-text="getUserEmail" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.user-card {
  @include make-font-scale(4);

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 20px;
  overflow: hidden;
  color: color('gray-darker');
  background: color('gray-lighter');
  border-radius: var(--borderRadiusSmall);

  &::before,
  &::after {
    position: absolute;
    content: '';
    background: color('gray-light');
    border-radius: 50%;
  }

  &::before {
    $size: 40px;

    right: -10px;
    bottom: 21px;
    width: $size;
    height: $size;
  }

  &::after {
    right: -21px;
    bottom: -33px;
    width: 65px;
    height: 55px;
  }

  &__name {
    @include make-font-scale(5);

    color: color('black');
  }

  &__name,
  &__email {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
