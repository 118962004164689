<script setup lang="ts">
import { computed } from 'vue'

import TransitionRender from '@/components/global/TransitionRender.vue'
import { BaseButton } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useModalStore from '@/store/pinia/useModalStore'

import ProductRow from '../components/Row/ProductRow.vue'

type ProductReplaceableProps = {
  product: Product
  large?: boolean
  readonly?: boolean
}

const props = defineProps<ProductReplaceableProps>()
const { cartActions } = useCartStore()

const productRef = computed(() => props.product)

const getInteractionSource = useInteractionSource('ProductReplaceable')
const { openModal } = useModalStore()

const { productId, isSoldOut, hasBaseVendor, removeProductFromCart } = useProductItem(
  productRef,
  getInteractionSource,
)

function openCartAlternativesModal() {
  openModal(modalIndex.cartAlternatives)
}

function setCurrentlyReplacedProduct() {
  cartActions.setCurrentlyReplacedProduct({
    id: productId.value,
    isSoldOut: isSoldOut.value,
    hasLimitedDelivery: false,
  })
}

function setCartQuantityToMaxAvailable() {
  cartActions.setCartQuantityToMaxAvailable(productId.value)
}

function showAlternatives() {
  setCurrentlyReplacedProduct()
  openCartAlternativesModal()
}

function handleReplaceableProductAmount() {
  hasBaseVendor.value ? showAlternatives() : setCartQuantityToMaxAvailable()
}
</script>

<template>
  <TransitionRender>
    <ProductRow
      :product="props.product"
      :large="props.large"
      :data-tids="{
        product: 'product-box__product',
        name: 'product-box__product-name',
        closeControl: 'cart-preview__delete-product',
      }"
      class="product-replaceable"
      has-error
      :closable="!props.readonly"
      @close="removeProductFromCart('button_x_remove_replaceable')">
      <template
        v-if="props.large"
        #controls>
        <div class="d-flex align-items-center">
          <BaseButton
            v-if="hasBaseVendor || !isSoldOut"
            variant="primary"
            class="product-replaceable__alternatives-control mr-3"
            :icon-left="
              hasBaseVendor
                ? {
                    name: isSoldOut ? 'rotate' : 'rotate-success',
                    class: 'mr-1',
                  }
                : undefined
            "
            @click="handleReplaceableProductAmount">
            <span
              v-if="hasBaseVendor"
              v-text="t(`unavailableProducts.${isSoldOut ? 'replace' : 'keepAndReplenish'}`)" />
            <span
              v-else
              v-html="
                t('unavailableProducts.keepAtLeast', { quantity: props.product.origin.maxInCart })
              " />
          </BaseButton>
          <BaseButton
            variant="secondary"
            :icon-left="{
              name: 'cross',
              class: 'mr-1',
            }"
            @click="removeProductFromCart('a_remove_replaceable')">
            {{ t('unavailableProducts.removeItem') }}
          </BaseButton>
        </div>
      </template>

      <template #summary>
        <div :class="['text-contrast-red text-nowrap', props.large ? 'text-4' : 'text-2']">
          <strong
            v-html="
              t(`unavailableProducts.${isSoldOut ? 'soldOut' : 'remainingQuantity'}`, {
                quantity: props.product.origin.maxInCart,
              })
            " />
          <div
            v-html="
              `(${t(`unavailableProducts.${isSoldOut ? 'originallyQuantity' : 'insteadQuantity'}`, {
                quantity: props.product.computed.quantity,
              })})`
            " />
        </div>
      </template>

      <template #bottom>
        <div
          v-if="!props.large"
          class="d-flex mt-1">
          <BaseButton
            v-if="hasBaseVendor || !isSoldOut"
            variant="primary"
            class="product-replaceable__alternatives-control w-50 mr-1"
            :icon-left="
              hasBaseVendor
                ? {
                    name: isSoldOut ? 'rotate' : 'rotate-success',
                    class: 'mr-1',
                  }
                : undefined
            "
            @click="handleReplaceableProductAmount">
            <span
              v-if="hasBaseVendor"
              v-text="t(`unavailableProducts.${isSoldOut ? 'replace' : 'keepAndReplenish'}`)" />
            <span
              v-else
              v-html="
                t('unavailableProducts.keepAtLeast', { quantity: props.product.origin.maxInCart })
              " />
          </BaseButton>
          <BaseButton
            variant="neutral-light"
            class="w-50"
            :icon-left="{
              name: 'cross',
              class: 'mr-1',
            }"
            @click="removeProductFromCart('a_remove_replaceable')">
            {{ t('unavailableProducts.removeItem') }}
          </BaseButton>
        </div>
      </template>
    </ProductRow>
  </TransitionRender>
</template>

<style lang="scss" scoped>
.product-replaceable {
  &__alternatives-control {
    width: 113px;
    height: 32px;
  }
}
</style>
