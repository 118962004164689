<script setup lang="ts">
import { ref } from 'vue'

import { storeToRefs } from 'pinia'

import NavL3 from '@/components/NavTop/NavL3.vue'
import ArrowToggle from '@/components/global/ArrowToggle.vue'
import type { Subcategory } from '@/data/api/frontApi/menu/menuTypes'
import { resizer } from '@/services/resizer'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

const props = defineProps<{
  categories: Subcategory[]
}>()

const openCategoryId = ref(0)

const emit = defineEmits<{
  categoryClick: []
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)
</script>

<template>
  <ul class="nav-l2">
    <li
      v-for="category in props.categories"
      :key="category.id"
      class="nav-l2__category">
      <RouterLink
        v-if="!breakpointDownMd && category.image"
        class="nav-l2__img-wrap"
        :to="category.url"
        @click.prevent="emit('categoryClick')">
        <img
          :srcset="`${resizer(category.image, 200, 200)} 1.5x, ${resizer(
            category.image,
            100,
            100,
          )} 1x`"
          :alt="category.name"
          loading="lazy"
          class="nav-l2__img" />
      </RouterLink>
      <RouterLink
        :to="category.url"
        :class="[
          'nav-l2__title',
          {
            'font-weight-bold': breakpointDownMd && category.id === openCategoryId,
          },
        ]"
        @click.prevent="emit('categoryClick')">
        {{ category.name }}
      </RouterLink>
      <ArrowToggle
        v-if="breakpointDownMd && category.subcategories"
        :id="category.id"
        v-model="openCategoryId"
        class="nav-l2__arrow-toggle" />

      <NavL3
        v-if="category.subcategories && (!breakpointDownMd || category.id === openCategoryId)"
        :categories="category.subcategories"
        :link-more="category.url"
        @category-click="emit('categoryClick')" />
    </li>
  </ul>
</template>

<style lang="scss">
@use '@/legacy' as *;

.nav-l2 {
  padding: 0;
  line-height: 1;
  list-style: none;
  background: color('white');

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100vh - var(--topbarSectionHeightDesktop));
    overflow: auto;
    line-height: 1.125;
    box-shadow: 0 4px 4px 0 color('black', 0.2);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__category {
    position: relative;
    border: solid color('gray-light');
    border-width: 0 0 1px;

    @include media-breakpoint-up(lg) {
      width: 25%;
      min-height: 130px;
      padding: 15px 10px 13px 120px;
      border: solid color('gray-lighter');
      border-width: 0 1px 1px 0;

      &:nth-child(4n) {
        border-right-width: 0;
      }
    }
  }

  &__img-wrap {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 100px;
    height: 100px;
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }

  &__title {
    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      height: 49px;
      padding: 0 60px 0 10px;
    }

    @include media-breakpoint-up(lg) {
      @include make-font-scale(4);

      display: block;
      margin-bottom: 3px;
      font-weight: var(--fontWeightBold);

      &:hover {
        color: color('off-primary-mid');
      }
    }
  }

  &__arrow-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
  }
}
</style>
