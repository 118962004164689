<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import TopBanner from '@/components/ProductSearch/TopBanner.vue'
import { useTopItems } from '@/components/ProductSearch/useTopItems'
import InteractionSource from '@/componentsPure/InteractionSource'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import TopCategories from './TopCategories.vue'
import TopProduct from './TopProduct.vue'
import TopProducts from './TopProducts.vue'
import TopSearchTerms from './TopSearchTerms.vue'

const emit = defineEmits<{
  setSearchTermImmediately: [term: string]
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpMd, breakpointUpLg, breakpointDownLg } = storeToRefs(deviceDetectorStore)

const { topProduct, topProducts, topCategories, topQueries, topBanner } = useTopItems()

const topProductsTransformed = computed(() => {
  return breakpointUpMd.value ? topProducts.value.slice(1) : topProducts.value
})

function setSearchTermImmediately(term: string) {
  emit('setSearchTermImmediately', term)
}
</script>

<template>
  <div class="d-md-flex">
    <div
      class="product-search__left-menu"
      data-tid="product-search__left-menu">
      <!-- top product -->
      <div
        v-if="breakpointUpMd"
        class="product-search__block-header d-flex align-items-center"
        data-tid="search__top-search-product">
        <div>
          {{ t('productSearch.topProduct') }}
        </div>
        <div
          class="bg-gray-light flex-grow-1 ml-3"
          style="height: 1px" />
      </div>
      <div
        v-if="breakpointUpMd"
        class="d-flex justify-content-center">
        <InteractionSource
          name="TopProduct"
          :data="{ code: 'top_product' }">
          <TopProduct
            class="bg-red"
            :product="topProduct" />
        </InteractionSource>
      </div>
      <!-- top search terms -->
      <div
        class="product-search__block-header d-flex align-items-center mb-1 mt-3"
        data-tid="search__top-search-terms">
        <div>{{ t('productSearch.topSearchTerms') }}</div>
        <div
          class="bg-gray-light flex-grow-1"
          style="height: 1px" />
      </div>
      <TopSearchTerms
        :search-terms="topQueries"
        @click="setSearchTermImmediately" />
      <!-- top categories -->
      <div
        v-if="breakpointUpMd"
        class="product-search__block-header d-flex align-items-center mt-4 mb-1"
        data-tid="search__top-search-categories">
        <div>
          {{ t('productSearch.topCategories') }}
        </div>
        <div
          class="bg-gray-light flex-grow-1 mx-4"
          style="height: 1px" />
      </div>

      <TopCategories
        v-if="breakpointUpMd && topCategories"
        :top-categories="topCategories" />
    </div>
    <!-- vertical menu / search separator -->
    <div
      class="bg-gray-light mt-3 mb-2 flex-shrink-0 mr-4 ml-3"
      style="width: 1px" />

    <div
      class="product-search__block-results"
      data-tid="search-results-block">
      <!--  top products -->
      <div>
        <div class="d-flex align-items-center">
          <div class="product-search__block-header-search">
            {{ t('productSearch.topProducts') }}
          </div>
          <div
            class="bg-gray-light flex-grow-1 mr-4 ml-3"
            style="height: 1px" />
        </div>

        <InteractionSource
          name="TopProducts"
          :data="{ code: 'favorite' }">
          <TopProducts
            :products="topProductsTransformed"
            class="top-products" />
        </InteractionSource>
        <div
          class="pt-4 pr-4"
          :class="{ 'mr-4': breakpointDownLg }">
          <TopBanner
            v-if="topBanner && breakpointUpLg"
            :banner="topBanner" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__link {
  &:hover {
    color: color('gray') !important;
  }
}

.product-search__block-results {
  flex: 1;

  @include media-breakpoint-up(lg) {
    max-width: 708px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 928px;
  }
}

.top-products {
  padding-right: 38px;
}
</style>
