<script setup>
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import ProductCart from '@/components/Product/Cart/ProductCart.vue'
import ProductGift from '@/components/Product/Gift/ProductGift.vue'
import ProductGroup from '@/components/Product/Group/ProductGroup.vue'
import ProductReplaceable from '@/components/Product/Replaceable/ProductReplaceable.vue'
import { t, tc } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

import CheckoutCartListActions from './components/CheckoutCartListActions.vue'

const props = defineProps({
  large: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  hasSummarizedGroups: {
    type: Boolean,
    default: false,
  },
  hasCartActions: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['hasReplaceableProducts'])

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const getGiftsInCategory = computed(() => ({
  category: {
    name: t('cart.gift'),
  },
  products: cartGetters.value.getCartGifts,
}))

const getCartReplaceableProducts = computed(() => cartGetters.value.getCartReplaceableProducts)
const getReplaceableProductsInCategories = computed(() => {
  const replaceableProducts = getCartReplaceableProducts.value
  const result = []

  Object.keys(replaceableProducts).forEach((key) => {
    const products = replaceableProducts[key]

    if (products.length) {
      result.push({
        category: {
          name: t(`cart.replaceable.${key}`),
        },
        products,
      })
    }
  })

  if (!result.length) {
    return null
  }

  return result
})

if (cartGetters.value.getCartReplaceableProductsCount.value) {
  emit('hasReplaceableProducts')
}
</script>

<template>
  <div>
    <CheckoutCartListActions
      v-if="props.hasCartActions"
      class="mb-4 mt-2 mb-md-2 mb-md-0" />

    <div
      v-if="getReplaceableProductsInCategories"
      :class="props.large ? 'mb-3' : 'mb-2'">
      <ProductGroup
        v-for="(group, index) in getReplaceableProductsInCategories"
        :key="`replaceable-${index}`"
        :title="group.category.name"
        :large="props.large"
        state="error"
        :rounded="rounded">
        <template #content>
          <ProductReplaceable
            v-for="product in group.products"
            :key="`product-${product.id}`"
            :product="product"
            :large="props.large" />
        </template>
      </ProductGroup>
    </div>

    <div>
      <ProductGroup
        v-for="group in cartGetters.getCartProductsInCategories"
        :key="`category-${group.category.id}`"
        :title="group.category.name"
        :large="props.large"
        :rounded="rounded">
        <template
          v-if="hasSummarizedGroups"
          #headerContent>
          <div
            :class="[
              'text-gray-dark my-2',
              {
                'text-center': !props.large,
              },
            ]">
            <Icon
              icon="basket-transparent"
              group="checkout"
              class="summary-icon mr-1" />
            <strong v-text="group.products.length" />
            {{ tc('cart.products', group.products.length) }}
            <Icon
              icon="money"
              group="checkout"
              class="summary-icon mr-1 ml-4" />
            <strong
              v-text="
                localizePrice(cartGetters.getCartProductsTotalPrice(group.products), {
                  style: 'decimal',
                })
              ">
            </strong>
            {{ t('general.currency') }}
          </div>
        </template>
        <template #content>
          <ProductCart
            v-for="product in group.products"
            :key="`product-${product.id}`"
            :product="product"
            :large="props.large" />
        </template>
      </ProductGroup>

      <ProductGroup
        v-if="cartGetters.getCartGifts.length"
        :title="getGiftsInCategory.category.name"
        :large="props.large"
        gifts
        :rounded="rounded">
        <template #content>
          <ProductGift
            v-for="product in getGiftsInCategory.products"
            :key="`gift-${product.id}`"
            :product="product"
            :large="props.large" />
        </template>
      </ProductGroup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.summary-icon {
  width: 16px;
  height: 16px;
  color: color('gray');
  vertical-align: text-top;
}
</style>
