<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'

type ArrowToggleProps = {
  modelValue: number
  id: number
}

const props = defineProps<ArrowToggleProps>()

const emit = defineEmits<{
  'update:modelValue': [newValue: number]
}>()

const active = computed(() => props.modelValue === props.id)

const toggle = () => {
  emit('update:modelValue', active.value ? 0 : props.id)
}
</script>

<template>
  <button
    class="arrow-toggle"
    :class="{
      'arrow-toggle--open': active,
    }"
    @click="toggle">
    <Icon icon="arrow-reversed" />
  </button>
</template>

<style lang="scss">
.arrow-toggle {
  position: absolute;
  width: 50px;
  height: 50px;
  background: none;
  border: 0;
  transition: transform var(--fastTransitionTime) ease-in-out;

  &--open {
    transform: rotate(180deg);
  }
}
</style>
