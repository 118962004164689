<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { useRouteMeta } from '@/composables/useRouteMeta'
import useUser from '@/composables/useUser'
import { mobileAppHash } from '@/constants/mobileApp'
import { LocalStorageKeys } from '@/constants/storageKeys'
import { bindSetToCartElements } from '@/services/amountSyncDom'
import { logCheckoutStep } from '@/services/analytics/analyticsLogger'
import { initYottly } from '@/services/analytics/yottly'
import { aadLogin } from '@/services/auth'
import { setCookie } from '@/services/cookies'
import { storagePersistLocal } from '@/services/storage'
import { generateSitelinksSearchBox } from '@/services/structuredData'
import { toastPushUnfinished } from '@/services/toast'
import { initVoucherMessage } from '@/services/voucher'
import useCartStore from '@/store/pinia/useCartStore'

import AppFooter from '../AppFooter'
import AppHeader from '../AppHeader'
import GlobalModals from './GlobalModals.vue'
import checkIdentityProvider from './checkIdentityProvider'
import useAffiliate from './useAffiliate'
import useOnUuid from './useOnUuid'

toastPushUnfinished() // displays unfinished messages from previous session

const onUuid = useOnUuid()
const cjEvent = useAffiliate()
const route = useRoute()
const { cartActions } = useCartStore()
const { isUserSignedIn } = useUser()
const { hasFooter, hasGlobalModals } = useRouteMeta()

toastPushUnfinished() // displays unfinished messages from previous session
bindSetToCartElements() // prepares weird global function

// If page is opened through mobile app
if (route.hash.endsWith(mobileAppHash)) {
  // Hide App suggester
  storagePersistLocal(LocalStorageKeys.isMobileAppSuggested, true)

  // Automatic login
  if (!isUserSignedIn) {
    aadLogin(route.fullPath.replace(mobileAppHash, ''))
  }
}

// Actions performed after UUID is initialized.
onUuid((uuid) => {
  cjEvent(uuid) // affiliate tracking
  cartActions.initCart()
})

// Post-render start-up stuff
onMounted(() => {
  initYottly()

  setCookie('kosik-first-visitor-consent', 'true')

  initVoucherMessage(route)
  generateSitelinksSearchBox()
  checkIdentityProvider(isUserSignedIn)

  // TODO This shouldn't be here, it's supposed to be in the checkout step.
  // Rewrite after Checkout 2.0 is released.
  if (route.meta.checkoutStep) {
    logCheckoutStep(route.meta.checkoutStep)
  }
})
</script>

<template>
  <AppHeader />
  <slot />
  <AppFooter v-if="hasFooter" />
  <GlobalModals v-if="hasGlobalModals" />
</template>
