<script setup lang="ts">
import { ref, watch } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import { useClientGetFlags, useClientUpdateFlags } from '@/composables/client'
import { t } from '@/services/translations'

const { data } = useClientGetFlags()

const printDeliveryNote = ref(true)

watch(data, () => {
  if (data.value?.printDeliveryNote !== undefined) {
    printDeliveryNote.value = data.value.printDeliveryNote
  }
})

const { mutate } = useClientUpdateFlags('print-delivery-note')

function handleClick(value: boolean) {
  printDeliveryNote.value = value
  mutate({
    flag: 'print-delivery-note',
    value: value,
  })
}
</script>

<template>
  <section class="section">
    <h3
      class="section__title"
      v-text="t('checkout.deliveryNote.title')" />

    <fieldset>
      <legend class="section__legend">
        <div class="section-labels__wrapper">
          <span class="eco">
            <Icon icon="eco-badge" />
            {{ t('pictographs.eko') }}
          </span>
        </div>

        <span v-text="t('checkout.deliveryNote.legend')" />
      </legend>

      <div
        class="radio-box radio-box--wide selection-box__item"
        @click="handleClick(false)">
        <input
          id="delivery-note--no"
          type="radio"
          name="delivery-note"
          :value="false"
          :checked="!printDeliveryNote" />
        <label
          class="paperless-label"
          for="option-paperless">
          <span class="paperless-left">
            <Icon
              icon="eco-badge"
              class="mr-1" />{{ t('checkout.deliveryNote.paperlessOption') }}
          </span>

          <span class="paperless-right">
            {{ t('checkout.deliveryNote.electornicEnough') }}
          </span>
        </label>
      </div>

      <div
        class="radio-box radio-box--wide selection-box__item"
        @click="handleClick(true)">
        <input
          id="delivery-note--yes"
          type="radio"
          name="delivery-note"
          :value="true"
          :checked="printDeliveryNote" />
        <label for="option-paper">
          <span>{{ t('checkout.deliveryNote.paperOption') }}</span>
        </label>
      </div>
    </fieldset>
  </section>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.section {
  max-width: 600px;
  margin: 32px auto;

  &-labels__wrapper {
    display: flex;
    gap: 4px;
    float: left;
    margin-right: 4px;
  }

  &__title {
    margin-bottom: 24px;
    font-weight: 600;
  }

  &__legend {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 11px;
    line-height: 22px;
    text-align: left;

    &::after {
      display: block;
      clear: both;
      content: '';
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 7px;
    }
  }
}

.eco {
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  color: color('contrast-green');
}

.paperless-label {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
}

.paperless-left {
  display: inline-block;
  padding: 0;
  color: color('contrast-green');
}

.paperless-right {
  display: inline-block;
  padding: 0;
}
</style>
