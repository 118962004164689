<script setup lang="ts">
import { computed, useSlots } from 'vue'

import { BaseTag } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import { resizer } from '@/services/resizer'
import { getCssVariable } from '@/utils/cssVariable'

type ProductRowProps = {
  product: Product
  entity?: string
  closable?: boolean
  detailDisabled?: boolean
  hasError?: boolean
  large?: boolean
  dataTids?: {
    product?: string
    name?: string
    closeControl?: string
  }
  imgOpacity?: boolean
}

const props = withDefaults(defineProps<ProductRowProps>(), {
  entity: 'div',
  dataTids: () => ({
    product: 'product',
    name: 'name',
    closeControl: 'close',
  }),
})

const emit = defineEmits<{
  close: []
}>()

const getInteractionSource = useInteractionSource()

const productRef = computed(() => props.product)
const { openDetail, formatQuantity } = useProductItem(productRef, getInteractionSource)
const componentSlots = useSlots()

const isDetailOpenable = computed(() => props.product.origin.url && !props.detailDisabled)
const getDetailOpeningEntity = computed(() => (isDetailOpenable.value ? 'a' : 'span'))
const getImageSize = computed(() => {
  const imgSize =
    getCssVariable(props.large ? '--productRowImageSizeLg' : '--productRowImageSize') ?? 0
  return {
    classic: imgSize,
    retina: imgSize * 2,
  }
})

const hasControlsSlot = computed(() => !!componentSlots.controls?.())

const openDetailModal = () => {
  if (isDetailOpenable.value) {
    openDetail()
  }
}
</script>

<template>
  <Component
    :is="props.entity"
    :data-tid="props.dataTids.product"
    :class="[
      'product-row',
      {
        'product-row--highlight-error': props.hasError,
        'pr-3 pr-lg-4': props.large,
      },
    ]">
    <slot name="top" />

    <div class="position-relative d-flex flex-grow-1 justify-content-between align-items-center">
      <!-- TODO href: If RouterLink is used, product detail doesn't open in popup -->
      <Component
        :is="getDetailOpeningEntity"
        :href="props.product.origin.url"
        :class="[
          'product-row__image-wrapper',
          {
            'product-row__image-wrapper--large': props.large,
          },
        ]"
        @click.prevent="openDetailModal">
        <img
          v-if="props.product.origin.image"
          class="product-row__image"
          :class="{ 'product-row__image--opacity': props.imgOpacity }"
          :srcset="`${resizer(props.product.origin.image, getImageSize.retina)} 1.5x, ${resizer(
            props.product.origin.image,
            getImageSize.classic,
          )} 1x`"
          alt="Product image" />
        <slot
          v-else
          name="imageFallback" />

        <BaseTag
          v-if="props.product.origin.productQuantity"
          class="product-row__quantity-tag"
          modifier="transparent"
          size="small">
          {{ formatQuantity(props.product.origin.productQuantity) }}
        </BaseTag>

        <slot name="imageWrapper" />
      </Component>

      <div
        class="flex-grow-1 mr-2"
        :class="{ 'd-flex flex-column': !props.large }">
        <!-- TODO href: If RouterLink is used, product detail doesn't open in popup -->
        <Component
          :is="getDetailOpeningEntity"
          :href="props.product.origin.url"
          :data-tid="props.dataTids.name"
          :class="[
            'product-row__name',
            {
              'product-row__name--large': props.large,
            },
          ]"
          @click.prevent="openDetailModal"
          v-html="props.product.origin.name" />

        <slot name="nameAdditional" />
      </div>

      <div
        :class="[
          'd-flex flex-shrink-0',
          props.large
            ? 'align-items-center'
            : 'align-items-end flex-column-reverse justify-content-around',
        ]">
        <div v-if="hasControlsSlot">
          <slot name="controls" />
        </div>
        <div
          :class="[
            'product-row__summary',
            {
              'product-row__summary--large': props.large,
            },
          ]">
          <slot name="summary" />
        </div>
      </div>

      <button
        v-if="props.closable"
        type="button"
        :data-tid="props.dataTids.closeControl"
        class="product-row__control-close a-control-close"
        @click="emit('close')" />
    </div>

    <slot name="bottom" />
  </Component>
</template>

<style src="./styles.scss" lang="scss" scoped />
