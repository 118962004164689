import {
  frontApiPostCheckoutFinish,
  frontApiPostCheckoutAdditionalOrderFinish,
} from '@/services/api/front/checkout'
import { frontApiGetPaymentGooglePayEcho } from '@/services/api/front/payment'

function _transformEchoData(fetchedData) {
  return {
    apiVersion: fetchedData.apiVersion,
    apiVersionMinor: fetchedData.apiVersionMinor,
    environment: fetchedData.environment,

    merchantInfo: {
      merchantName: fetchedData.merchantName,
      merchantId: fetchedData.googlepayMerchantId,
    },

    allowedPaymentMethods: [
      {
        type: fetchedData.paymentMethodType,

        parameters: {
          allowedAuthMethods: fetchedData.allowedCardAuthMethods,
          allowedCardNetworks: fetchedData.allowedCardNetworks,
        },

        tokenizationSpecification: {
          type: fetchedData.tokenizationSpecificationType,
          parameters: {
            gateway: fetchedData.gateway,
            gatewayMerchantId: fetchedData.gatewayMerchantId,
          },
        },
      },
    ],

    transactionInfo: {
      totalPriceStatus: fetchedData.totalPriceStatus,
    },
  }
}

/**
 * Calls {@link frontApiGetPaymentGooglePayEcho} and returns transformed data for checking if Google Pay is available.
 */
async function getEchoData() {
  const { data } = await frontApiGetPaymentGooglePayEcho()

  return _transformEchoData(data)
}

function _transformCheckoutFinishData(fetchedData) {
  return {
    orderId: fetchedData.data.order.id,
    transactionInfo: {
      totalPrice: fetchedData.data.paymentData.googlePay.totalPrice.toString(),
      currencyCode: fetchedData.data.paymentData.googlePay.currencyCode,
    },
  }
}

/**
 * Calls either {@link frontApiPostCheckoutFinish} or {@link frontApiPostCheckoutAdditionalOrderFinish}, to create order on BE + get data for Google Pay payment prompt.
 * @param {number | null} parentOrderId Id of parent order if additional order is made.
 * @throws Error with name "OrderError" or "AdditionalOrderError" according to the failed endpoint.
 */
async function getCheckoutFinishData(parentOrderId) {
  let data

  try {
    data = parentOrderId
      ? await frontApiPostCheckoutAdditionalOrderFinish(parentOrderId)
      : await frontApiPostCheckoutFinish()
  } catch (err) {
    err.customType = parentOrderId ? 'AdditionalOrderError' : 'OrderError'

    throw err
  }

  // If whole order is payed by credits, paymentData is null
  if (!data.data.paymentData) return { orderId: data.data.order.id, transactionInfo: null }

  return _transformCheckoutFinishData(data)
}

export {
  getEchoData as googlePayGetEchoData,
  getCheckoutFinishData as googlePayGetCheckoutFinishData,
}
