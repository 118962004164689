<script setup lang="ts">
import { computed } from 'vue'

import capitalize from 'lodash-es/capitalize'

import ProductBoxListing from '@/components/Product/ProductBoxListing.vue'
import type { ColumnSizes } from '@/components/Product/ProductBoxListing.vue'
import ProductCarousel from '@/components/Product/ProductCarousel.vue'
import type { Product } from '@/composables/useProductItem'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

// @todo when volar bug fixed, replace with keyof ColumnSizes
type BreakpointSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const DEFAULT_COLUMN_SIZES: ColumnSizes = { xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }

type TopProductsProps = {
  /** what are counts of rows in ProductBox */
  columnSizes?: ColumnSizes
  /** where to switch between ProductCarousel and ProductBoxListing */
  viewBreakPointName?: BreakpointSizes
  /** products to show */
  products: Product[]
}

const props = withDefaults(defineProps<TopProductsProps>(), {
  columnSizes: () => ({}),
  viewBreakPointName: 'lg',
})

const deviceDetectorStore = useDeviceDetectorStore()

const columnSizes = computed<ColumnSizes>(() => ({
  ...DEFAULT_COLUMN_SIZES,
  ...props.columnSizes,
}))

const showCarousel = computed(() => {
  const breakpointName = `breakpointUp${capitalize(props.viewBreakPointName)}`
  return deviceDetectorStore[breakpointName]
})
</script>

<template>
  <div v-if="props.products.length > 0">
    <ProductCarousel
      v-if="showCarousel"
      :products="props.products" />
    <ProductBoxListing
      v-else
      :products="props.products"
      :column-sizes="columnSizes"
      :show-horizontal-separators="false" />
  </div>
</template>
