import { notificationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

import useUser from '../useUser'
import useNotificationsData from './useNotificationsData'

function useNotificationsValid() {
  const { getUserId: customerId } = useUser()

  return useNotificationsData(notificationQueryKeys.valid(), () =>
    api.notifications.notifications.notificationsValid({
      queryParams: { consumer: 'WEB', customerId, maxSize: 6 },
    }),
  )
}

export default useNotificationsValid
