<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { BaseButton, Tooltip } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useModalStore from '@/store/pinia/useModalStore'

import CartDeliveryInfo from './CartDeliveryInfo.vue'
import useCartPreviewTooltip from './useCartPreviewTooltip'

const { isTooltipDisabled } = useCartPreviewTooltip()
const { openModal } = useModalStore()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

function openAddressSelectorModal() {
  openModal(modalIndex.addressSelector, modalIndex.addressSelector, 'preview-cart')
}
</script>

<template>
  <div>
    <CartDeliveryInfo v-if="cartGetters.getCartProductsCount" />
    <div class="mt-1 mx-2 py-1">
      <button
        class="btn a-link-underline text-primary font-weight-bold p-0 mb-3"
        data-tid="cart-preview__slot-preview"
        @click.prevent="openAddressSelectorModal"
        v-text="t('cart.timeslotsOverviewLink')" />

      <Tooltip
        class="d-block d-flex flex-column"
        direction="top"
        text-color="red"
        bg-color="white"
        shadow
        nowrap
        :disabled="isTooltipDisabled">
        <BaseButton
          entity="router-link"
          :to="{ name: 'Cart' }"
          class="d-block mx-2"
          :disabled="!cartGetters.isAllowedCheckout"
          data-tid="cart-preview__to-first-step-bot"
          variant="super-primary"
          size="lg">
          {{ t('cart.toCheckout') }}
        </BaseButton>

        <template #message>
          <span v-text="t('cart.empty')" />
        </template>
      </Tooltip>
    </div>
  </div>
</template>
