import { useMutation, useQueryClient } from '@tanstack/vue-query'

import type { Notification } from '@/components/Notification/notificationTypes'
import { notificationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type { NotificationsResponse } from '@/data/api/notificationsApi/notifications/notificationsTypes'

import useUser from '../useUser'

function setNewData(notificationId: Notification['id']) {
  return function setNewData2(oldData: NotificationsResponse | undefined) {
    if (!oldData) return
    return oldData.filter((notification) => notification.id !== notificationId)
  }
}

function useNotificationsDelete() {
  const queryClient = useQueryClient()
  const { getUserId: customerId } = useUser()

  const { mutate } = useMutation({
    mutationFn: (notificationId: Notification['id']) =>
      api.notifications.notifications.notificationsDelete({
        path: { notificationId },
        body: { reason: 'NOT_INTERESTED' },
        queryParams: { customerId },
      }),
    onMutate: (notificationId: Notification['id']) => {
      queryClient.setQueryData(notificationQueryKeys.valid(), setNewData(notificationId))
      queryClient.setQueryData(notificationQueryKeys.history(), setNewData(notificationId))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: notificationQueryKeys.data() })
    },
  })

  function removeNotification(notificationId: Notification['id']) {
    return mutate(notificationId)
  }

  return removeNotification
}

export default useNotificationsDelete
