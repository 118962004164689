<script setup lang="ts">
import { t } from '@/services/translations'

const props = defineProps<{
  time: number
}>()
</script>

<template>
  <div
    class="separator"
    v-text="
      t('delivery.fromHours', {
        time: props.time,
      })
    " />
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.separator {
  @include make-font-scale(2);

  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;

  &::before,
  &::after {
    flex-grow: 1;
    content: '';
    border-top: 1px solid color('gray-light');
  }
}
</style>
