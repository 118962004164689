<template>
  <div class="d-md-flex">
    <div class="ghost-sidebar px-4">
      <div class="d-flex align-items-center mb-2">
        <span class="a-placeholder-text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <div
          class="bg-gray-light flex-grow-1 ml-3"
          style="height: 1px" />
      </div>
      <div class="d-flex">
        <span class="ghost-grey-bg" />
      </div>
      <div class="d-flex align-items-center my-2">
        <span class="a-placeholder-text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <div
          class="bg-gray-light flex-grow-1 ml-3"
          style="height: 1px" />
      </div>
      <div class="d-flex">
        <span class="ghost-grey-bg" />
      </div>
    </div>
    <div
      class="flex-shrink-0 bg-gray-light mt-3 mb-2 mr-2"
      style="width: 1px" />
    <div class="w-100 d-flex flex-wrap">
      <div
        v-for="productGhost in 4"
        :key="productGhost"
        class="product">
        <div class="image-container image-container--placeholder" />
        <div class="content">
          <div class="name">
            <span class="a-placeholder-text">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
          <button class="add d-block mx-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$name-line-height: 16px;
$name-padding-bottom: 2px;
$content-line-height: 14px;
$content-spacing: 6px;
$content-separator-height: 1px;
$attributes-height: 22px;
$base-size: 14px;
$border-width: 1px;

.ghost-sidebar {
  min-width: 265px;
  max-width: 265px;
  height: 100%;
}

.ghost-grey-bg {
  width: 70px;
  height: 28px;
  background: color('gray-lighter');
}

.add {
  width: em(120, $base-size);
  min-height: em(32, $base-size);
  background: transparent;
  border: $border-width solid color('gray-light');
  border-radius: em(4, $base-size);
}

.product {
  position: relative;
  width: $productItemBoxWidth;
  height: $productItemBoxHeight;
  background: color('white');
  transition:
    box-shadow var(--baseTransitionTime),
    z-index var(--baseTransitionTime);

  @include media-breakpoint-up(lg) {
    height: $productItemBoxHeightLg;
  }

  @include media-breakpoint-only(xl) {
    &:hover {
      z-index: $zix-product--xl;
      box-shadow: 5px 5px 30px 0 color('black', 0.1);
    }
  }
}

.image-container {
  @include image-container($placeholder: true);
}

.content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: (($attributes-height * 0.5) + $content-spacing) 8px 8px;
  background: color('white', 0.9);
}

.name {
  margin-bottom: $content-line-height + (2 * $content-separator-height) + (3 * $content-spacing);
  text-align: center;
}
</style>
