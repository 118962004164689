<script setup lang="ts">
import { ref, watch } from 'vue'

import { FormCheckbox } from '@/components/Form'
import { analyticsLoggerComponentInteraction } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'

const earlyDelivery = ref(false)

watch(earlyDelivery, () =>
  analyticsLoggerComponentInteraction({
    name: 'early-delivery',
    type: 'checkbox',
    value: earlyDelivery.value ? 'check' : 'uncheck',
  }),
)
</script>

<template>
  <div class="early-delivery">
    <FormCheckbox v-model="earlyDelivery">
      {{ t('checkout.earlyDeliveryCheck') }}
    </FormCheckbox>
  </div>
</template>

<style scoped lang="scss">
.early-delivery {
  display: flex;
  justify-content: center;

  label {
    width: 250px;
    text-align: left;
  }
}
</style>
