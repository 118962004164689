import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { VENDOR_CONFIG } from '@/constants/vendors'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

export function useVendor() {
  const { currentVendorId, isPharmacy } = storeToRefs(useUserInterfaceStore())

  const vendorConfig = computed(() =>
    currentVendorId.value ? VENDOR_CONFIG[currentVendorId.value] : undefined,
  )

  return {
    currentVendorId,
    vendorConfig,
    isPharmacy,
  }
}
