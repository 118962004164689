<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import type { Ref } from 'vue'

import { debounce } from 'lodash-es'
import { storeToRefs } from 'pinia'

import ActionMenu from '@/components/ActionMenu/ActionMenu.vue'
import useActionMenuStaticBlock from '@/components/ActionMenu/useActionMenuStaticBlock'
import { AddressSelectorButton } from '@/components/AddressSelector'
import BenefitsStrip from '@/components/AppHeader/BenefitsStrip.vue'
import NewReleaseReload from '@/components/AppMain/NewReleaseReload.vue'
import MainLogo from '@/components/MainLogo/MainLogo.vue'
import MobileAppSuggester from '@/components/MobileAppSuggester/MobileAppSuggester.vue'
import NavL1 from '@/components/NavTop/NavL1.vue'
import NotificationControl from '@/components/Notification/NotificationControl.vue'
import ProductSearch from '@/components/ProductSearch/ProductSearch.vue'
import StatusMessages from '@/components/global/StatusMessages.vue'
import { useRouteMeta } from '@/composables/useRouteMeta'
import useUser from '@/composables/useUser'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'
import { viewportElementPxCount } from '@/utils/viewport'

import CartPreview from './CartPreview.vue'
import MobileHeader from './MobileHeader.vue'
import UserControl from './UserControl.vue'
import { useScroll } from './useScroll'
import { useUserInterface } from './useUserInterface'

const SCROLL_UP_MENU_REVEAL_THRESHOLD = 200

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpLg } = storeToRefs(deviceDetectorStore)

const { hasSimpleHeaderLayout, hasHidingMenu, hasHeader } = useRouteMeta()
const { isUserSignedIn } = useUser()
const {
  MODAL_NAMES,
  isBackdropScrollLocked,
  isModalFromCollisionGroupOpen,
  createModal,
  removeModal,
  closeModal,
} = useModalStore()

const { headerSettings } = storeToRefs(useUserInterfaceStore())

const { headerHeight, setHeaderHeight, setHeaderMenuVisible, isSuggesterVisible } =
  useUserInterface()
const { isArtificialScroll } = useScroll()

/// refs
const messagesBlockHeight = ref(0)
const isMessagesBlockInViewport = ref(false)

const topbarHeight = ref(0)
const topbarPosition = ref('relative')

const menuHeight = ref(0)
const menuOffsetTop = ref(0)
const menuPosition = ref('relative')

const scrollUpDistance = ref(0)
const scrollDirectionUp = ref(false)
const previousScrollTop = ref(0)

const uninterruptedScrollingDownFromY = ref(0)
const hideHeader = ref(false)

/// element refs
const messages: Ref<HTMLElement | null> = ref(null)
const topbar: Ref<HTMLElement | null> = ref(null)
const menu: Ref<HTMLElement | null> = ref(null)

const { data: actionMenuItems } = useActionMenuStaticBlock()

/// vendor specific
watch(headerSettings, calculateHeaderData)

/// startup
createModal({
  id: MODAL_NAMES.mobileNavigation,
  isOpen: false,
  collisionGroup: MODAL_NAMES.mobileHeader,
})
createModal({
  id: MODAL_NAMES.mobileCart,
  isOpen: false,
  collisionGroup: MODAL_NAMES.mobileHeader,
})
createModal({
  id: MODAL_NAMES.notifications,
  isOpen: false,
  hasBackdropScroll: true,
})

/// computed and functions

const isMessagesBlockVisible = computed(() => !isModalFromCollisionGroupOpen('MobileHeader'))

function calculateElementsHeightValues() {
  if (!messages.value || !topbar.value || !menu.value) {
    return
  }
  messagesBlockHeight.value = messages.value.offsetHeight
  topbarHeight.value = topbar.value.offsetHeight

  setTimeout(() => {
    menuHeight.value = menu.value?.offsetHeight ?? 0
  })
}

function calculateHeaderData() {
  setTimeout(() => {
    if (!topbar.value || !menu.value) {
      return
    }
    const topbarBottom = topbar.value.getBoundingClientRect().bottom
    const menuBottom = menu.value.getBoundingClientRect().bottom
    setHeaderHeight(Math.max(topbarBottom, menuBottom))
    setHeaderMenuVisible(topbarBottom < menuBottom)
  })
}

function calculateMessagesBlockInViewport() {
  if (!messages.value) {
    return
  }
  isMessagesBlockInViewport.value =
    messages.value.offsetHeight > 0 && viewportElementPxCount(messages.value) > 0
}

function determineHeaderVisibility(isScrollDirectionUp?: boolean) {
  // Do not hide on scroll down on non-mobile devices (bigger than lg breakpoint)
  if (breakpointUpLg.value) {
    hideHeader.value = false
    return
  }
  // Show the header on scroll up
  if (isScrollDirectionUp) {
    uninterruptedScrollingDownFromY.value = window.scrollY
    hideHeader.value = false
    return
  }

  // Hide after user scrolls down uninterrupted the specified distance
  // To prevent visual bugs, this distance is set to topbar + messages height
  const uninterruptedPxScrolledNeededToHide = topbarHeight.value + messagesBlockHeight.value
  const uninterruptedDistanceScrolledDown = window.scrollY - uninterruptedScrollingDownFromY.value

  if (uninterruptedDistanceScrolledDown > uninterruptedPxScrolledNeededToHide) {
    hideHeader.value = true
    closeModal(MODAL_NAMES.notifications)
  }
}

function adjustMenuOffsetTop() {
  if (!topbar.value) {
    return
  }
  if (['fixed', 'absolute'].includes(menuPosition.value)) {
    menuOffsetTop.value = topbar.value.getBoundingClientRect().bottom
  }
}

function fixTopbar(fix = true) {
  topbarPosition.value = fix ? 'fixed' : 'relative'
}

function fixMenu(fix = true) {
  menuPosition.value = fix ? 'fixed' : 'relative'
  menuOffsetTop.value = fix ? topbarHeight.value : 0
}

function fixHeaderPositionAfterMessagesLoad() {
  handleResize()
  fixTopbar(false)
  fixMenu(false)
}

function stickMenu(atCurrentPosition = false, threshold = 0) {
  if (!menu.value || !topbar.value) {
    return
  }
  const safeTop = messagesBlockHeight.value + topbarHeight.value
  const absoluteTop =
    topbar.value.getBoundingClientRect().bottom +
    window.scrollY -
    threshold -
    (atCurrentPosition ? 0 : menu.value.offsetHeight)

  menuPosition.value = 'absolute'
  menuOffsetTop.value = Math.max(absoluteTop, safeTop)
}

function handleScrollDown() {
  if (!isMessagesBlockInViewport.value) {
    if (topbarPosition.value === 'relative') {
      fixTopbar()
    }
    if (!hasHidingMenu.value && menuPosition.value === 'relative') {
      fixMenu()
    }
  }
  if (hasHidingMenu.value && menuPosition.value === 'fixed') {
    stickMenu(true, isArtificialScroll.value ? SCROLL_UP_MENU_REVEAL_THRESHOLD : 0)
  }
}

function handleScrollUp() {
  if (!menu.value || !topbar.value) {
    return
  }

  if (isArtificialScroll.value && menuPosition.value === 'absolute') {
    fixMenu(false)
    return
  }

  if (isMessagesBlockInViewport.value) {
    if (topbarPosition.value !== 'relative') {
      fixTopbar(false)
    }
    if (menuPosition.value !== 'relative') {
      fixMenu(false)
    }
    return
  }

  if (menuPosition.value !== 'fixed') {
    if (menu.value.getBoundingClientRect().top >= topbar.value.getBoundingClientRect().bottom) {
      fixMenu()
      return
    }

    if (scrollUpDistance.value >= SCROLL_UP_MENU_REVEAL_THRESHOLD) {
      stickMenu()
      scrollUpDistance.value = 0
    }
  }
}

function handleScroll() {
  const wasMenuFixed = menuPosition.value === 'fixed'
  const wasMessagesBlockInViewport = isMessagesBlockInViewport.value

  const scrollTop = window.scrollY
  scrollDirectionUp.value = scrollTop < previousScrollTop.value

  calculateMessagesBlockInViewport()

  determineHeaderVisibility(scrollDirectionUp.value)

  if (scrollDirectionUp.value) {
    scrollUpDistance.value += previousScrollTop.value - scrollTop
    handleScrollUp()
  } else {
    scrollUpDistance.value = 0
    handleScrollDown()
  }

  previousScrollTop.value = Math.max(scrollTop, 0)

  if (
    scrollDirectionUp.value ||
    wasMessagesBlockInViewport ||
    isMessagesBlockInViewport.value ||
    !wasMenuFixed ||
    menuPosition.value !== 'fixed'
  ) {
    calculateHeaderData()
  }
  calculateElementsHeightValues()
}

function handleResize() {
  determineHeaderVisibility()
  calculateElementsHeightValues()
  calculateHeaderData()
  adjustMenuOffsetTop()
}

// hooks, watch
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  window.addEventListener('resize', debounce(handleResize, 250))

  calculateHeaderData()
  calculateMessagesBlockInViewport()
  calculateElementsHeightValues()
  if (!isMessagesBlockInViewport.value) {
    fixTopbar()
    fixMenu()
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', handleResize)
  removeModal(MODAL_NAMES.mobileNavigation)
  removeModal(MODAL_NAMES.mobileCart)
})

watch(isMessagesBlockVisible, calculateHeaderData)
</script>

<template>
  <div v-if="hasHeader">
    <!--
    Messages & Mobile app suggester & Benefits strip
    -->
    <div
      v-show="isMessagesBlockVisible"
      ref="messages"
      class="app-messages">
      <MobileAppSuggester @close="calculateHeaderData" />
      <div
        :class="[
          'messages-wrapper',
          {
            'd-none': isBackdropScrollLocked,
          },
        ]">
        <NewReleaseReload @release-detected="fixHeaderPositionAfterMessagesLoad" />
        <StatusMessages @loaded="fixHeaderPositionAfterMessagesLoad" />
      </div>
      <BenefitsStrip />
    </div>

    <header class="topbar-wrapper clearfix">
      <div
        ref="topbar"
        :class="[
          { 'transform-transition': !breakpointUpLg },
          { 'header-hidden': !breakpointUpLg && hideHeader },
          `topbar position-${topbarPosition}`,
          {
            'topbar--short': hasSimpleHeaderLayout,
          },
        ]">
        <div
          :class="{
            'position-relative h-100': true,
            'd-flex container justify-content-lg-between justify-content-end': breakpointUpLg,
          }">
          <template v-if="!breakpointUpLg && actionMenuItems">
            <MobileHeader v-show="!isSuggesterVisible">
              {{ actionMenuItems.html }}
            </MobileHeader>
          </template>

          <template v-else>
            <div class="d-flex align-items-center flex-grow-1">
              <MainLogo
                allow-symbol-logo
                class="mr-lg-2 mr-xl-4" />
              <Transition
                v-if="breakpointUpLg && !hasSimpleHeaderLayout"
                name="fade">
                <AddressSelectorButton v-show="!isSuggesterVisible" />
              </Transition>
            </div>
          </template>

          <ProductSearch
            class="search ssr-hide-mobile"
            :class="{ 'search-suggester-visible': isSuggesterVisible }" />

          <Transition
            v-if="breakpointUpLg"
            name="fade">
            <div
              v-show="!isSuggesterVisible"
              class="right-controls">
              <NotificationControl
                v-if="isUserSignedIn && !hasSimpleHeaderLayout"
                class="mr-4" />
              <UserControl class="user-control" />
            </div>
          </Transition>
        </div>
      </div>
      <div
        :class="topbarPosition !== 'relative' ? 'd-block' : 'd-none'"
        :style="{ height: `${topbarHeight}px` }" />
      <div
        ref="menu"
        :class="`header-menu position-${menuPosition}`"
        :style="{ top: `${menuOffsetTop}px` }">
        <div
          v-if="breakpointUpLg"
          class="ssr-hide-mobile">
          <div class="container">
            <ActionMenu
              v-if="!hasSimpleHeaderLayout && headerSettings.actionMenu"
              class="action-menu" />
          </div>
          <div class="nav-l1-bg mx-auto">
            <div class="container">
              <NavL1 v-if="!hasSimpleHeaderLayout" />
            </div>
          </div>
        </div>
      </div>

      <div
        :class="menuPosition !== 'relative' ? 'd-block' : 'd-none'"
        :style="{ height: `${menuHeight}px` }" />
    </header>

    <!--
      Cart preview
    -->
    <div
      v-if="breakpointUpLg"
      class="cart-preview-wrapper"
      :style="{ top: `${headerHeight}px` }">
      <div class="container position-relative">
        <CartPreview
          v-if="!hasSimpleHeaderLayout"
          key="cart"
          class="ssr-hide-mobile" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

$zix-header__menu: $zix-above + $zix-header;
$zix-header__topbar: $zix-above + $zix-header__menu;

.messages-wrapper {
  max-width: calc($container-width + 16px);
  margin: 0 auto;
}

.nav-l1-bg {
  max-width: calc($container-width + 16px);
  background-color: color('primary-light');
  border-radius: var(--borderRadiusMedium);
}

.transform-transition {
  transition: all 200ms ease-in-out;
}

.header-hidden {
  transform: translateY(-100%);
}

.app-messages {
  position: relative;
  z-index: $zix-header__app-messages;
}

.topbar-wrapper {
  position: relative;
  z-index: $zix-header;
  width: 100%;
  background-color: color('white');
}

.topbar {
  position: relative;
  top: 0;
  left: 0;
  z-index: $zix-header__topbar;
  width: 100%;
  height: var(--topbarHeightDesktop);
  padding: 16px 0 11px;
  background: color('white');

  @include media-breakpoint-down(md) {
    z-index: $zix-header;
    height: var(--topbarHeightTablet);
    padding: 0;
  }

  &--short {
    border-bottom: 1px solid color('gray-light');
  }
}

.header-menu {
  left: 0;
  z-index: $zix-header__menu;
  width: 100%;
  background: color('white');
}

.search {
  padding-right: 12px;
  padding-left: 12px;
  margin-top: -10px;

  &.search-suggester-visible {
    padding: 0;
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: $zix-search;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.right-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include media-breakpoint-up(lg) {
    flex-basis: 30%;
  }
}

.cart-preview-wrapper {
  position: fixed;
  left: 0;
  z-index: $zix-header-cart-preview-wrapper;
  width: 100%;
}
</style>
