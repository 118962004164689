import appConfig from '../../appConfig'
import { fetchGet } from '../fetch'

const modulePath = 'order'

function getApplePayPaymentStatus(orderId) {
  return fetchGet(appConfig.webApiBaseUrl + modulePath + '/order/' + orderId + '/csob-payment')
}

export { getApplePayPaymentStatus as webApiGetApplePayPaymentStatus }
