import { useQuery } from '@tanstack/vue-query'

import { staticBlockQueryKeys } from '@/constants/queryKeys'
import staticBlockSlug from '@/constants/staticBlock'
import api from '@/data/api'

function useActionMenuStaticBlock() {
  return useQuery({
    queryKey: staticBlockQueryKeys.slug(staticBlockSlug.actionMenuContent),
    queryFn: () =>
      api.front.staticContent.staticBlock({
        path: { code: staticBlockSlug.actionMenuContent },
      }),
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export default useActionMenuStaticBlock
