<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import sortBy from 'lodash-es/sortBy'
import { storeToRefs } from 'pinia'

import AppFooterContactsMenu from '@/components/AppFooter/AppFooterContactsMenu.vue'
import AppFooterSocialLinks from '@/components/AppFooter/AppFooterSocialLinks.vue'
import PharmacyCertificates from '@/components/PharmacyCertificates'
import StaticBlock from '@/components/StaticBlock'
import { useRouteMeta } from '@/composables/useRouteMeta'
import { getCookie } from '@/services/cookies'
import { cookieName } from '@/services/privacy'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

const { pharmacyCertificates } = storeToRefs(useProductsStore())
const { isPharmacy } = storeToRefs(useUserInterfaceStore())

const sortedPharmacyCertificates = computed(() => sortBy(pharmacyCertificates.value, ['type']))

const isVisibleCookieBar = ref(getCookie(cookieName).length === 0)

const route = useRoute()
if (route.name === 'PrivacyPolicy') {
  isVisibleCookieBar.value = false
}

const { hasSimpleFooterLayout } = useRouteMeta()
</script>

<template>
  <footer class="pt-4 pb-5 py-lg-5 bg-gray-lighter footer">
    <div class="container position-relative mt-lg-n2 mb-3">
      <StaticBlock
        v-if="!hasSimpleFooterLayout"
        :slug="`page-footer-links${isPharmacy ? '-pharmacy' : ''}`" />

      <AppFooterContactsMenu
        v-if="isPharmacy"
        :title="t('document.footer.pharmacy.drMaxTitle')"
        vendor="pharmacy" />
      <AppFooterContactsMenu
        :title="isPharmacy && t('document.footer.pharmacy.kosikTitle')"
        vendor="base" />

      <AppFooterSocialLinks />

      <div
        v-if="sortedPharmacyCertificates.length"
        class="mb-5">
        <PharmacyCertificates
          v-if="isPharmacy"
          :certificates="sortedPharmacyCertificates" />
      </div>

      <p
        class="pb-1 text-1 text-gray font-weight-bold"
        v-text="`&copy; ${t('document.footer.brand')} – ${new Date().getFullYear()}`" />
      <p
        class="pb-1 text-1 text-gray"
        v-html="t('document.footer.legal')" />
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.footer {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 24px;
    right: 90px;
    width: 80%;
    height: 100%;
    max-height: 500px;
    content: '';
    background: url('/img/logo-symbol-white.svg?v=2') no-repeat center right/contain;
    transform: rotate(-17deg);
    transform-origin: top right;
  }

  @include media-breakpoint-up(lg) {
    &::before {
      top: -42px;
      right: 128px;
    }
  }
}
</style>
