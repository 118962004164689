import appConfig from '@/services/appConfig'

export type MenuItem = {
  key: string
  icon: string
  iconGroup?: string
  routeTo: string
  routeParams?: {
    presenter?: string
    action?: string
  }
  validator?: () => boolean
}

const menuItems: MenuItem[] = [
  {
    key: 'orders',
    icon: 'orders',
    routeTo: 'Profile',
    routeParams: {
      presenter: 'order',
    },
  },
  {
    key: 'credits',
    icon: 'credits',
    routeTo: 'Credits',
  },
  {
    key: 'myCart',
    icon: 'my-cart',
    routeTo: 'MyCart',
  },
  ...(appConfig.enableSavingsPage
    ? [
        {
          key: 'savings',
          icon: 'piggy-bank',
          routeTo: 'Savings',
        },
      ]
    : []),
  {
    key: 'favoriteList',
    icon: 'heart',
    iconGroup: 'general',
    routeTo: 'Favorites',
  },
  {
    key: 'shoppingLists',
    icon: 'list',
    routeTo: 'Profile',
    routeParams: {
      action: 'shopping-lists',
    },
  },
  ...(appConfig.enableClubs
    ? [
        {
          key: 'clubGifts',
          icon: 'gifts-club',
          routeTo: 'ProfileClubGifts',
        },
        {
          key: 'clubKids',
          icon: 'benjaminek',
          routeTo: 'ProfileClubKids',
        },
        {
          key: 'clubFullCare',
          icon: 'full-care',
          routeTo: 'ProfileClubFullCare',
        },
      ]
    : []),
  {
    key: 'notifications',
    icon: 'notification',
    routeTo: 'Profile',
    routeParams: {
      action: 'notifications',
    },
  },
  {
    key: 'newsletter',
    icon: 'newsletters',
    routeTo: 'Newsletter',
  },
  {
    key: 'addresses',
    icon: 'address',
    routeTo: 'Profile',
    routeParams: {
      action: 'address',
    },
  },
  {
    key: 'cards',
    icon: 'cards',
    routeTo: 'Cards',
  },
]

export default menuItems
