const getHeaderHeight = () =>
  document.querySelector('header.topbar-wrapper')?.getBoundingClientRect().height
const getToggleInfoTop = () =>
  document.querySelector('.js-recipe-hidden')?.getBoundingClientRect().top
const toggleDisplayNone = (el) => document.querySelector(el).classList.toggle('d-none')

function toIngredients() {
  if (!document.querySelector('.js-recipe-to-ingredients')) {
    return
  }

  document.querySelector('.js-recipe-to-ingredients').addEventListener('click', () => {
    const ingredients = document
      .querySelector('.js-recipe-ingredients')
      ?.getBoundingClientRect().top

    const breadcrumbs = document.querySelector('.breadcrumbs')
    const breadcrumbsHeight = breadcrumbs?.getBoundingClientRect().height

    window.scrollTo({
      top: ingredients - getHeaderHeight() - breadcrumbsHeight + window.scrollY,
      behavior: 'smooth',
    })
  })
}

function toggleRecipeInfo() {
  const recipeToggle = document.querySelector('.js-recipe-toggle')
  if (!recipeToggle) {
    return
  }

  const toggleInfo = document.querySelector('.js-recipe-hidden')
  const toggleArrow = document.querySelector('.js-recipe-arrow-toggle')
  recipeToggle.addEventListener('click', () => {
    if (toggleInfo.classList.contains('o-recipe__hidden--open')) {
      window.scroll({
        top: getToggleInfoTop() - getHeaderHeight() + window.scrollY,
        behavior: 'smooth',
      })
    }
    toggleDisplayNone('.js-recipe-show-btn')
    toggleDisplayNone('.js-recipe-hide-btn')
    toggleInfo.classList.toggle('o-recipe__hidden--open')
    toggleArrow.classList.toggle('o-recipe__toggle-show--open')
  })
}

function backToRecipe() {
  const recipeToTop = document.querySelector('.js-recipe-to-info')
  if (!recipeToTop) {
    return
  }

  recipeToTop.addEventListener('click', () => {
    window.scroll({
      top: getToggleInfoTop() - getHeaderHeight() + window.scrollY,
      behavior: 'smooth',
    })
  })
}

export default (() => {
  document.addEventListener('KosikRecipesLoaded', () => {
    toIngredients()
    toggleRecipeInfo()
    backToRecipe()
  })
})()
