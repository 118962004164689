<script setup lang="ts">
import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'

import checkoutConfirmLogic from './checkoutConfirmLogic'

type CheckoutDestinationSwitchProps = {
  changeAddressId: string | null
  addressDraft: string | null
  billingDraft: string | null
}

const props = defineProps<CheckoutDestinationSwitchProps>()

const { closeModal } = useModalStore()

function closeSwitch() {
  closeModal(modalIndex.checkoutDestinationSwitch)
}

function onConfirm() {
  window.location.href = '/'
}

async function confirmSwitch() {
  checkoutConfirmLogic(
    props.changeAddressId,
    props.addressDraft,
    props.billingDraft,
    closeSwitch,
    onConfirm,
  )
}
</script>

<template>
  <Popup
    content
    auto-open
    size="x-small"
    :name="modalIndex.checkoutDestinationSwitch">
    <div class="text-center mb-3">
      <p class="checkout-destination-switch__title mb-4">
        {{ t('checkout.destinationSwitch.title') }}
      </p>

      <img
        src="/img/map.svg?v=1"
        alt=""
        class="checkout-destination-switch__image" />

      <p class="text-gray-dark my-4">
        {{ t('checkout.destinationSwitch.claim') }}
      </p>

      <BaseButton
        variant="primary"
        size="sm"
        class="mx-3"
        @click="confirmSwitch">
        {{ t('checkout.destinationSwitch.confirm') }}
      </BaseButton>
      <BaseButton
        variant="tertiary"
        size="sm"
        class="mx-3"
        @click="closeSwitch">
        {{ t('checkout.destinationSwitch.back') }}
      </BaseButton>
    </div>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.checkout-destination-switch {
  &__title {
    @include make-font-scale(7);
  }

  &__image {
    height: 46px;
  }

  &__button {
    width: 113px;
    padding: 10px 12px;
  }
}
</style>
