<script setup lang="ts">
import { computed } from 'vue'

import type { Dayjs } from 'dayjs'
import { storeToRefs } from 'pinia'

import type { DeliverySlotsGroupName, DeliveryTimeslotsGridGroupSlots } from '@/components/Delivery'
import { DeliverySlot } from '@/componentsPure'
import useDeliveryData from '@/composables/transport/useDeliveryData'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import type { TimeslotTime } from '@/store/pinia/useTimeslotsStore'

import DeliveryTimeslotsGridTimeline from './DeliveryTimeslotsGridTimeline.vue'

const timelineWidth = 26
const gridCellHeightXs = 81
const gridCellHeightMd = 38

type DeliveryTimeslotsGridGroupProps = {
  groupName: DeliverySlotsGroupName
  group: DeliveryTimeslotsGridGroupSlots
  readonly: boolean
  highestLevel: number
  shortestSlotMiliseconds: number
}

const props = defineProps<DeliveryTimeslotsGridGroupProps>()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)

const { data: deliveryData } = useDeliveryData()

const tabularView = computed(
  () => breakpointDownMd.value && !deliveryData.value?.forAnonymousCustomer,
)

const getGridCellHeight = computed(() => (tabularView.value ? gridCellHeightXs : gridCellHeightMd))
/**
 * Generates styles to be applied to the grid of provided group.
 *
 * The first column with fixed width is reserved for timeline.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/repeat
 *
 * @param {object} group Group of slots to form a grid.
 * @param {number} group.rowsCount Number of rows with their height equal to the day's shortest slot's duration.
 * @returns {object} Styles to be applied.
 */
function calculateGridContainerStyle(group: any) {
  return {
    gridTemplateRows: `repeat(${group.rowsCount}, ${getGridCellHeight.value}px)`,
    gridTemplateColumns: `${timelineWidth}px repeat(${props.highestLevel}, 1fr)`,
  }
}

function getGridRow(dateTime: Dayjs) {
  return dateTime.diff(props.group.start) / props.shortestSlotMiliseconds + 1
}

/**
 * Prepares properties to be bound to a single delivery slot component.
 *
 * The most important is composing of the style attribute in which the actual placement of the slot in the grid
 * is being made.
 *
 * Considering our grid is dynamic and areas cannot be easily named, we resort to placing slots between
 * automatically numbered imaginary borderlines (starting from 1). Following example visually describes
 * the placement of an element inside a grid, starting on row borderline 1 and column borderline 2, ending on row
 * borderline 5 and column borderline 4.
 *
 * ```
 *    1     2     3     4     5     6
 *    |     |     |     |     |     |
 * 1--+-----+-----+-----+-----+-----+
 *    |xxxxx|           |xxxxx|xxxxx|
 * 2--+-----+           +-----+-----+
 *    |xxxxx|           |xxxxx|xxxxx|
 * 3--+-----+     E     +-----+-----+
 *    |xxxxx|           |xxxxx|xxxxx|
 * 4--+-----+           +-----+-----+
 *    |xxxxx|           |xxxxx|xxxxx|
 * 5--+-----+-----+-----+-----+-----+
 *    |xxxxx|xxxxx|xxxxx|xxxxx|xxxxx|
 * 6--+-----+-----+-----+-----+-----+
 * ```
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area
 *
 * @param {object} timeslot Delivery timeslot.
 * @param {object} group Group of slots to form a grid.
 */
function getGridArea(timeslot: TimeslotTime) {
  const { start, end, level } = timeslot

  // Difference between slot start and group start in milisecond is divided with the height of one row (reminder:
  // row height = shortest slot's duration). Adding 1 takes place because indexing of borderlines starts from 1.
  // E.g.: Our slot starts 2 hours after group starts, the shortest timeslot's duration (STD) is 1 hour.
  // The difference divided by STD is 2, plus 1 is 3. Our slot should start on borderline 3, thus skipping 2 rows.
  // Note: Math magic works perfectly even with 30-minutes or 15-minutes slots.
  const rowStart = getGridRow(start)
  // This works the same, except we are placing the end, thus the bottom border of the slot onto a borderline.
  const rowEnd = getGridRow(end)
  // Shortest timeslots have level 1, placing them on borderline 2 keeps place for the special timeline column.
  const colStart = level + 1
  // All slots are exactly one-column wide.
  const colEnd = level + 2

  return { gridArea: `${rowStart} / ${colStart} / ${rowEnd} / ${colEnd}` }
}
</script>

<template>
  <div
    class="timeslot-group"
    data-tid="timeslot-group"
    :style="calculateGridContainerStyle(group)">
    <template
      v-for="(timelineItem, timelineIndex) in group.timeline"
      :key="`timeline-${timelineIndex}`">
      <!-- Fill the first column with timeline. Only whole-hour times shall appear. -->
      <DeliveryTimeslotsGridTimeline
        v-if="!timelineItem.minute()"
        :timeline-item="timelineItem"
        :timeline-index="timelineIndex" />
    </template>
    <DeliverySlot
      v-for="timeslot in props.group.timeslots"
      :key="timeslot.id"
      :group-name="props.groupName"
      :data-tid="'delivery-slot-level-' + timeslot.level"
      :tabular="tabularView"
      :compact="breakpointDownMd && props.highestLevel > 2"
      :readonly="props.readonly"
      :timeslot="timeslot"
      :multiline="props.highestLevel > 2"
      is-in-grid
      :style="getGridArea(timeslot)" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.timeslot-group {
  display: grid;
  gap: 4px;

  @include media-breakpoint-up(md) {
    gap: 2px 8px;
  }
}
</style>
