import type { Plugin } from 'vue'
import type { Router } from 'vue-router'

import { apmBase } from '@elastic/apm-rum'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'

import { ensureConfig } from '@/composables/useConfig'

import { currentEnv, isBot, isPuppeteer } from '../environment'
import { errorConfig, optimizedConfig } from './config'
import getEnvironmentName from './getEnvironmentName'

type TelemetryOptionsBase = {
  enabled: boolean
  /**
   * Service name as shown in Elastic APM.
   */
  serviceName: string
  /**
   * Elastic endpoint.
   */
  serverUrl: string
  /**
   * full - all telemetry is recorded (inlcuding fetch, pageload, etc.)
   * optimized - not all instrumentations are recorded and some transactions are ignored
   * error - only errors are recorded
   */
  logLevel: 'full' | 'error' | 'optimized'
  /**
   * If you want to enable telemetry on localhost, you have to specify
   * the name of the localhost. Otherwise (null), it will be disabled.
   * E.g. 'davidovo'.
   */
  localhostName: string | null
}

type TelemetryOptions = TelemetryOptionsBase & {
  router: Router
}

/**
 * Telemetry plugin for Vue. You can find configuration in appConfig.ts
 * under `telemetry` key.
 *
 * @see https://www.elastic.co/guide/en/apm/agent/rum-js/current/vue-integration.html
 */
const telemetry: Plugin<TelemetryOptions> = {
  install(app, options) {
    if (
      !options.enabled ||
      currentEnv === null ||
      (currentEnv === 'localhost' && options.localhostName === null) ||
      isPuppeteer ||
      isBot
    ) {
      return
    }

    const baseConfig = {
      serviceName: options.serviceName,
      serverUrl: options.serverUrl,
      serviceVersion: import.meta.env.PACKAGE_VERSION,
      environment: getEnvironmentName(options.localhostName),
    }

    switch (options.logLevel) {
      case 'full':
        app.use(ApmVuePlugin, {
          config: baseConfig,
          router: options.router,
        })
        break
      case 'optimized':
        app.use(ApmVuePlugin, {
          config: {
            ...baseConfig,
            ...optimizedConfig,
          },
          router: options.router,
        })
        break
      case 'error':
        app.use(ApmVuePlugin, {
          config: {
            ...baseConfig,
            ...errorConfig,
          },
        })
        break
      default:
        throw new Error(`Unknown telemetry log level: ${options.logLevel}`)
    }

    if (apmBase.isActive()) {
      ensureConfig().then((config) => {
        apmBase.setUserContext({
          id: config.user.luigisboxUserId ?? 0,
          email: config.user.email ?? 'anonymous@kosik.cz',
          username: config.user.name ?? 'anonymous',
        })
      })
    }
  },
}

export default telemetry
