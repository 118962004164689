import { useQuery } from '@tanstack/vue-query'

import { configurationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'

import useConfig from './useConfig'
import useIsLogged from './useIsLogged'

/**
 * Fetches the UUID to identify anonymous users.
 * UUID is for our purposes only. Actual user identification is performed
 * by the X-Ksp-Token cookie. Calling this query will set this cookie.
 * When user already has a token, initial data for this query will be provided
 * by the config object.
 * @returns Query with UUID. String value means user is anonymous and
 * authenticated with token. Null means user has no token or is logged in.
 */
function useUuid() {
  const configData = useConfig()
  const isLogged = useIsLogged()

  return useQuery({
    queryKey: configurationQueryKeys.uuid(),
    enabled: !isLogged,
    queryFn: () => api.front.client.anonym(),
    staleTime: Infinity,
    gcTime: Infinity,
    // If no UUID is provided in config, it will perform the request.
    // Otherwise, it will use the UUID from the config
    // and request will be skipped.
    initialData: () => {
      if (configData.value.user.uuid === null) return
      return configData.value.user.uuid
    },
  })
}

export default useUuid
