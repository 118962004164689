import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartStore from '@/store/pinia/useCartStore'

function useCartPreviewTooltip() {
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)

  const isTooltipDisabled = computed(
    () => !cartGetters.value.isCartLoaded || cartGetters.value.isAllowedCheckout,
  )

  return {
    isTooltipDisabled,
  }
}

export default useCartPreviewTooltip
