<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { RouterLink } from 'vue-router'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import ArrowToggle from '@/components/global/ArrowToggle.vue'
import { useBanners } from '@/composables/banner'
import useMenuMain from '@/composables/useMenuMain'
import modalIndex from '@/constants/modalIndex'
import { Vendors } from '@/constants/vendors'
import isCategory from '@/data/api/frontApi/menu/isCategory'
import type { Category, Subcategory } from '@/data/api/frontApi/menu/menuTypes'
import appConfig from '@/services/appConfig'
import { clearVendorSpecificToasts } from '@/services/toast'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

import BannerGrid from '../BannerGrid'
import NavL2 from './NavL2.vue'

const openDesktopSubcategoryDelay = 300

const emit = defineEmits<{
  categoryClick: []
}>()

const deviceDetectorStore = useDeviceDetectorStore()
const { openModal, closeModal, createModal, isModalOpen } = useModalStore()
const {
  headerSettings,
  isHeaderMenuVisible,
  currentL1CategoryId,
  currentL2CategoryId,
  isPharmacy,
} = storeToRefs(useUserInterfaceStore())

const { breakpointDownMd, breakpointUpLg } = storeToRefs(deviceDetectorStore)

const checkForBanners = computed(() => appConfig.enableBigTip && !isPharmacy.value)

const { banners } = useBanners('webBigTipMain')

const openCategoryId = ref(0)
const timeoutId = ref(0)
const { data: categories } = useMenuMain()

const isOpenCategory = computed(() => isModalOpen(modalIndex.navL1))

// Vendor specific
const pharmacyCategory = computed(() =>
  categories.value?.find((category) => isPharmacyCategory(category)),
)
const pharmacySubcategories = computed(() => pharmacyCategory.value?.subCategories)

function isPharmacyCategory(category: Category) {
  return category.vendorId === Vendors.PHARMACY
}

const l1Categories = computed(() =>
  isPharmacy.value ? pharmacySubcategories.value : categories.value,
)

/**
 * A function to determine if the category sheet (`L2`) has
 * subcategories that should be shown, or if there is some other
 * reason why the sheet should not be shown.
 */
function canShowThisCategorySheet(category: Category | Subcategory) {
  return (
    headerSettings.value.showL2Categories &&
    isCategory(category) &&
    !isPharmacyCategory(category) &&
    !!category.subCategories.length
  )
}

function openNavModal() {
  openModal(modalIndex.navL1)
}
function closeNavModal() {
  closeModal(modalIndex.navL1)
}
function createNavModal() {
  createModal({
    id: modalIndex.navL1,
    isOpen: false,
    hasBackdropScroll: true,
  })
}

function openCategory(id: number) {
  openCategoryId.value = id
}
function closeCategory() {
  openCategoryId.value = 0
}

function navEnter() {
  if (breakpointUpLg.value) {
    timeoutId.value = window.setTimeout(() => {
      openNavModal()
    }, openDesktopSubcategoryDelay)
  } else {
    openNavModal()
  }
}
function navLeave() {
  if (breakpointUpLg.value) {
    closeNavModal()
    window.clearTimeout(timeoutId.value)
  }
}

function hoverCategory(category: Category | Subcategory) {
  if (
    !headerSettings.value.showL2Categories ||
    !isCategory(category) ||
    !canShowThisCategorySheet(category)
  ) {
    return
  }
  if (breakpointUpLg.value) {
    openCategory(category.id)
  }
}
function unhoverCategory() {
  if (breakpointUpLg.value) {
    closeCategory()
  }
}

function handleScroll() {
  if (!isHeaderMenuVisible.value) {
    navLeave()
  }
}

function handleCategoryClick(category?: Category | Subcategory) {
  closeNavModal()
  const vendorId = category && isCategory(category) ? category.vendorId : Vendors.BASE
  clearVendorSpecificToasts(vendorId)
  emit('categoryClick')
}

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

createNavModal()

window.addEventListener('scroll', handleScroll)

const isBigTipOpen = ref(false)

function bigTipToggle() {
  isBigTipOpen.value = !isBigTipOpen.value
}

// Big Tip item is not part of the category list, so we need to close it like this, when a normal category is opened
watch(openCategoryId, (newVal) => {
  if (newVal) {
    isBigTipOpen.value = false
  }
})
</script>

<template>
  <ul
    class="nav-top"
    @mouseenter="navEnter"
    @mouseleave="navLeave">
    <template v-if="l1Categories">
      <li
        v-if="headerSettings.showBackToHomepage"
        class="nav-item">
        <RouterLink
          to="/"
          :class="{
            'nav-link': true,
            'nav-link--first': true,
            'nav-link--no-l2': !headerSettings.showL2Categories,
          }"
          @click.prevent="handleCategoryClick">
          <Icon
            icon="arrow-reversed"
            class="back-icon" />
          {{ t('general.backToHome') }}
        </RouterLink>
      </li>

      <li
        v-if="banners.length && checkForBanners"
        class="nav-item"
        @mouseenter="breakpointUpLg && bigTipToggle()"
        @mouseleave="breakpointUpLg && bigTipToggle()">
        <RouterLink
          :to="{
            name: 'PageBigTip',
          }"
          :class="{
            'nav-link': true,
            'nav-link--open': isBigTipOpen,
          }"
          @click.prevent="handleCategoryClick">
          {{ t('general.bigTip') }}
        </RouterLink>

        <button
          v-if="breakpointDownMd"
          class="arrow-toggle nav-arrow-toggle"
          :class="{
            'arrow-toggle--open': isBigTipOpen,
          }"
          @click="bigTipToggle">
          <Icon icon="arrow-reversed" />
        </button>

        <template v-if="isBigTipOpen && isModalOpen(modalIndex.navL1)">
          <div class="nav-l2">
            <BannerGrid
              :banners="banners"
              paddings />
          </div>
        </template>
      </li>

      <li
        v-for="(category, index) in l1Categories"
        :key="index"
        class="nav-item"
        @mouseenter="hoverCategory(category)"
        @mouseleave="unhoverCategory">
        <RouterLink
          :to="category.url"
          :class="{
            'nav-link': true,
            'nav-link--current':
              category.id === currentL1CategoryId || category.id === currentL2CategoryId,
            'nav-link--open': category.id === openCategoryId && canShowThisCategorySheet(category),
            'nav-link--no-l2': !canShowThisCategorySheet(category),
            'nav-link--first':
              index === 0 &&
              appConfig.isFirstL1CategoryHighlighted &&
              !headerSettings.showBackToHomepage,
          }"
          @click.prevent="handleCategoryClick(category)">
          {{ category.name }}
        </RouterLink>

        <ArrowToggle
          v-if="
            breakpointDownMd &&
            headerSettings.showL2Categories &&
            canShowThisCategorySheet(category)
          "
          :id="category.id"
          v-model="openCategoryId"
          class="nav-arrow-toggle" />

        <template v-if="isOpenCategory && category.id === openCategoryId && isCategory(category)">
          <NavL2
            v-if="category.subCategories.length"
            :categories="category.subCategories"
            @category-click="handleCategoryClick" />
        </template>
      </li>
    </template>
    <template v-else>
      <li
        v-for="n in 12"
        :key="n"
        class="skeleton">
        <span class="a-placeholder-text skeleton-item">&nbsp;</span>
      </li>
    </template>
  </ul>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@use 'sass:color';
@use '@/legacy' as *;

$height: 50px;
$spacing: 4px;

.skeleton {
  display: flex;
  align-items: center;
  height: 50px;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .skeleton-item {
    display: inline-block;
    width: 130px;
    margin-left: 10px;
    background: linear-gradient(
      to right,
      color.adjust(#f6edfc, $lightness: -5%) 8%,
      color.adjust(#f6edfc, $lightness: -2%) 20%,
      color.adjust(#f6edfc, $lightness: -2%) 37%
    );

    @include media-breakpoint-up(lg) {
      width: 90%;
    }
  }

  &:nth-child(odd) .skeleton-item {
    width: 165px;

    @include media-breakpoint-up(lg) {
      width: 90%;
    }
  }
}

.nav-top {
  padding: 0;
  margin: 0;
  list-style: none;
  background: color('primary-light');

  @include media-breakpoint-up(lg) {
    position: relative; // for the absolutely-positioned NavL2
    display: flex;
    flex-direction: row;
    height: $height;
  }
}

.nav-item {
  flex: 1;
  padding: 0 calc($spacing / 2);

  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  height: $height; // in order to prevent layout shift after categories are loaded
  padding: 0 $height 0 10px;
  line-height: 1.3;
  color: color('text-paragraph');
  text-decoration: none;
  outline: none;
  transition: all var(--fastTransitionTime) ease-in-out;

  .back-icon {
    margin-right: 4px;
    transform: rotate(90deg);
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;
    height: $height - 2 * $spacing;
    padding: 0 math.div($spacing, 2);
    margin: $spacing 0;
    text-align: center;
    border-radius: var(--borderRadiusSmall);

    &--no-l2 {
      &:hover {
        color: color('primary');
        background-color: color('white');
      }
    }

    &--current,
    &--open {
      background-color: color('white');
    }

    &--open {
      height: $height - $spacing;
      padding-bottom: $spacing;
      margin: $spacing 0 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.nav-arrow-toggle {
  position: absolute;
  top: 0;
  right: 0;
  color: color('black');
}
</style>
