<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import useUser from '@/composables/useUser'
import modalIndex from '@/constants/modalIndex'
import { SessionStorageKeys } from '@/constants/storageKeys'
import { frontApiDaktelaQueue } from '@/services/api/front/daktela'
import loadExternalJs from '@/services/loadExternalJs'
import { storagePersistSession, storageRetrieveSession } from '@/services/storage'
import useModalStore from '@/store/pinia/useModalStore'

const { isModalOpen, getModalCollisionGroup } = useModalStore()
const { getUserName, getUserEmail } = useUser()

const isChatOverllaped = computed(() => {
  const modalCollisionGroup = getModalCollisionGroup(modalIndex.mobileHeader)
  const addressLoginModalGroup = modalCollisionGroup.filter(
    (modal) => modal.isOpen && modal.id !== 'AddressSelector' && modal.id !== 'login',
  )

  return (
    !!addressLoginModalGroup.length ||
    !!isModalOpen(modalIndex.notifications) ||
    !!isModalOpen(modalIndex.deliveryOnLocation)
  )
})

onMounted(() => {
  setTimeout(async () => {
    await fetchChatStatus()

    if (storageRetrieveSession<boolean>(SessionStorageKeys.liveChatOpenInSession)) {
      initChat(true)
    }
  }, 2000)
})

const isPlaceholderVisible = ref(false)
const daktelaAccessToken = ref<string>('')
const daktelaAccessServer = ref<string>('')

const fetchChatStatus = async () => {
  const {
    data: {
      result: { accessToken, server, isOnline, isOverload },
    },
  } = await frontApiDaktelaQueue()
  isPlaceholderVisible.value = isOnline && !isOverload
  daktelaAccessToken.value = accessToken
  daktelaAccessServer.value = server
}

let chatInstance: any = null

const initExternalChatScript = async () => {
  await loadExternalJs(daktelaAccessServer.value + 'external/web/web.js')
  await new Promise((resolve) => {
    // eslint-disable-next-line new-cap
    chatInstance = new window.daktelaGui()
    chatInstance.init({
      server: daktelaAccessServer.value,
      accessToken: daktelaAccessToken.value,
      settings: {
        inputs: {
          title: {
            defaultValue: getUserName,
          },
          email: {
            defaultValue: getUserEmail,
          },
        },
      },
      fnAfterInit: resolve,
    })
  })
}

const isSpinnerVisible = ref(false)
const isLoadingFailed = ref(false)

const showSpinner = () => {
  setTimeout(() => {
    if (!isLoadingFailed.value) {
      isSpinnerVisible.value = true
    }
  }, 500)
}

const startConversation = async () => {
  if (getUserName && getUserEmail) {
    await new Promise((resolve) => {
      chatInstance.cli.chat.actions.create(
        {
          title: getUserName,
          email: getUserEmail,
        },
        null,
        null,
        resolve,
      )
    })
  }
}

const openChatWindow = () => {
  document.getElementById('daktela-web-header-state')?.click()
}

const setSessionChatOpened = () => {
  storagePersistSession(SessionStorageKeys.liveChatOpenInSession, true)
}

const realDaktela = ref<HTMLElement | null>(null)
const moveChatElement = () => {
  const chatWrapperElement = document.getElementById('daktela-web')
  if (chatWrapperElement) {
    realDaktela.value?.appendChild(chatWrapperElement)
  }
}

const isChatLoaded = ref(false)

const initChat = async (openFromSession) => {
  if (!isChatLoaded.value) {
    isChatLoaded.value = true
    showSpinner()
    await initExternalChatScript()
    setSessionChatOpened()
    moveChatElement()

    if (!openFromSession) {
      await startConversation()
      openChatWindow()
    }
  }
}
</script>

<template>
  <div
    ref="realDaktela"
    :class="[
      'livechat',
      {
        'livechat--overlapped': isChatOverllaped,
      },
    ]">
    <Transition name="fade">
      <div
        v-if="isPlaceholderVisible && !isChatLoaded"
        class="livechat__placeholder d-flex align-items-center justify-content-center"
        data-tid="daktela-chat"
        @click="initChat(false)">
        <div
          v-if="isSpinnerVisible"
          class="livechat__loading">
          <div class="livechat__spinner" />
        </div>
        <Icon
          v-else
          class="livechat__icon"
          icon="daktela" />
        <div
          class="livechat__status position-absolute"
          :class="{ 'livechat__status--off': isLoadingFailed }" />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.livechat {
  position: relative;
  z-index: $zix-livechat;

  &--overlapped {
    z-index: $zix-livechat--overlapped;
  }

  &__placeholder {
    $size: 50px;

    position: fixed;
    bottom: 10px;
    left: 10px;
    width: $size;
    height: $size;
    cursor: pointer;
    background-color: color('primary');
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 40%));
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
      bottom: 20px;
      left: 20px;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 16px;
    height: 16px;
    transform: translateY(-50%) translateX(50%);
  }

  &__spinner {
    width: 100%;
    height: 100%;
    border: 2px solid color('white', 0.2);
    border-left: 2px solid color('white');
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }

  &__icon {
    width: 22px;
    height: 22px;
    color: color('white');
  }

  &__status {
    $size: 10px;

    top: 4px;
    left: 0;
    width: $size;
    height: $size;
    background-color: #75fb4c;
    border-radius: 50%;

    &--off {
      background-color: #f44;
    }
  }
}
</style>
