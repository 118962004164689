import appConfig from '@/services/appConfig'

import { fetchGet, fetchPost } from '../fetch'

const modulePath = 'applepay'

/**
 * Validates merchant's session
 * @param {string} validationUrl The URL your server must use to validate itself and obtain a merchant session object.
 * @returns {Promise<Object>} Merchant session object
 */
function getApplePayMerchant(validationUrl) {
  return fetchGet(appConfig.webApiBaseUrl + modulePath + '/merchant', {
    validationUrl,
  })
}

/**
 * Returns data needed for Apple Pay Wallet initialization
 * @returns {Promise<Object>}
 */
function getApplePayPaymentRequest() {
  return fetchGet(appConfig.webApiBaseUrl + modulePath + '/payment-request')
}

function postApplePayPaymentAuthorization(applePayPaymentData) {
  return fetchPost(
    appConfig.webApiBaseUrl + modulePath + '/payment/',
    undefined,
    applePayPaymentData,
  )
}

export {
  getApplePayMerchant as webApiGetApplePayMerchantValidation,
  getApplePayPaymentRequest as webApiGetApplePayPaymentRequest,
  postApplePayPaymentAuthorization as webApiPostApplePayPaymentAuthorization,
}
