<script setup lang="ts">
import { BaseButton } from '@/componentsPure'
import useAuth from '@/composables/useAuth'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'

const { handleLoginClick, handleRegistrationClick } = useAuth()

type AuthWidgetProps = {
  title?: string
}

const props = withDefaults(defineProps<AuthWidgetProps>(), {
  title: t('mustLoggedIn.title'),
})
</script>

<template>
  <section
    class="my-4 my-md-5 d-flex flex-column align-items-center text-center"
    style="text-wrap: pretty">
    <p
      class="mb-2 mb-md-3 text-6"
      v-text="props.title" />
    <h3
      class="text-7 text-brand mb-3 font-weight-bold"
      v-text="t('checkout.loginToContinue')" />
    <div
      class="my-2 my-lg-3 d-flex justify-content-center"
      style="gap: 10px">
      <button
        v-for="(provider, index) in appConfig.authSocialProviders"
        :key="index"
        class="button-social"
        :title="t('general.loginUsing') + provider"
        :aria-label="provider"
        @click="handleLoginClick('both', provider)">
        <img
          :src="`/vue/img/social/${provider}.svg`"
          :alt="provider" />
      </button>
    </div>

    <div class="divider my-2 d-flex align-items-center text-4 text-text-paragraph text-uppercase">
      <span class="divider__text">{{ t('general.or') }}</span>
    </div>

    <div class="mt-3 mb-4 d-flex flex-column flex-sm-row align-items-center">
      <BaseButton
        class="button-base mb-2 mb-sm-0 mr-sm-3"
        data-tid="login-button"
        variant="primary"
        inverted
        size="md"
        @click="handleLoginClick('login')"
        v-text="t('general.login')" />

      <BaseButton
        class="button-base"
        data-tid="register-button"
        variant="primary"
        inverted
        size="md"
        @click="handleRegistrationClick"
        v-text="t('general.register')" />
    </div>
    <p class="text-1 text-gray">
      {{ t('checkout.loginToContinueDescription.sentenceStart') }}
      <a
        class="a-link-underline"
        target="_blank"
        :href="appConfig.termsAndConditionsLink"
        >{{ t('checkout.loginToContinueDescription.termsAndConditionsText') }}</a
      >
      {{ t('checkout.loginToContinueDescription.sentenceMiddle') }}
      <a
        class="a-link-underline"
        target="_blank"
        :href="appConfig.cookiePolicyPath">
        {{ t('checkout.loginToContinueDescription.gdprText') }} </a
      >.
    </p>
  </section>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.divider {
  &::before,
  &::after {
    width: 94px;
    height: 1px;
    content: '';
    background-color: color('gray-light');
  }

  &__text {
    padding-inline: 20px;
  }
}

.button-social {
  width: 60px;
  height: 40px;
  background-color: color('white');
  border: 1px solid color('gray-light');
  border-radius: var(--borderRadiusSmall);

  @include media-breakpoint-up('sm') {
    width: 70px;
  }

  &:hover,
  &:focus {
    border-color: color('primary');
  }

  &:focus {
    background-color: color('primary-light');
  }
}

.button-base {
  min-width: 220px;
  border-radius: var(--borderRadiusBig);
}
</style>
