<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

type PriceTransportBarLabelProps = {
  /**
   * Calculates the price for the progress bar display.
   * - If the minimum order price has not been reached, it excludes eLicence products from the total price.
   * - If the minimum order price is met or exceeded, it includes eLicence products in the total price.
   * This helps in dynamically adjusting the progress bar based on the inclusion or exclusion of eLicence products.
   */
  effectivePrice: number
}

const props = defineProps<PriceTransportBarLabelProps>()

const {
  showProgressInfo,
  isMinimalOrderPrice,
  getMinimalOrderPrice,
  getFreeDeliveryOrderPrice,
  forAnonymousCustomer,
} = useCartDeliveryData()

const priceRemaining = computed(() => {
  if (getFreeDeliveryOrderPrice.value === undefined || getMinimalOrderPrice.value === undefined) {
    return
  }

  if (!isMinimalOrderPrice.value) {
    return localizePrice(Math.max(getMinimalOrderPrice.value - props.effectivePrice, 0))
  }

  return localizePrice(Math.max(getFreeDeliveryOrderPrice.value - props.effectivePrice, 0))
})

const freeDeliveryLabel = computed(() => {
  return forAnonymousCustomer.value
    ? t('addressSelector.freeDeliveryNewBuyer')
    : t('general.freeDelivery')
})
</script>

<template>
  <Icon
    icon="car"
    class="delivery-icon mr-2" />
  <strong
    v-if="!showProgressInfo"
    class="text-contrast-green"
    data-tid="progress-free-delivery">
    {{ freeDeliveryLabel }}
  </strong>
  <span v-else-if="isMinimalOrderPrice">
    <strong>{{ t('cart.progress.remaining') }} {{ priceRemaining }}</strong>
    {{ t('general.till') }}
    <span>{{ t('cart.progress.freeDeliveryRemainingCheckout') }}</span>
  </span>
  <span v-else>
    <strong>{{ t('cart.progress.remaining') }} {{ priceRemaining }}</strong>
    {{ t('general.till') }}
    <span>{{ t('cart.progress.minimalOrderRemainingCheckout') }}</span>
  </span>
</template>

<style lang="scss" scoped>
.delivery-icon {
  width: 24px;
  height: 24px;
  margin-top: -4px;
}
</style>
