<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import UserLogin from '@/components/UserLogin'
import { BaseButton } from '@/componentsPure'
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'

const props = defineProps<{
  showCloseButton: boolean
}>()

const router = useRouter()
const { isUserSignedIn } = useUser()

const userLoginRedirect = computed(() => {
  if (router.currentRoute.value.name === 'PageWelcome') return 'Home'

  return undefined
})

function closeAndGoShoping() {
  router.push({ name: 'Home' }).catch(() => {
    // @TODO To be implemented
  })
}
</script>
<template>
  <footer
    v-if="!isUserSignedIn"
    class="address-selector-footer text-text-paragraph text-5 px-5 px-md-3 pt-3 pb-5 py-sm-3">
    <span v-text="t('addressSelector.alreadyRegistered')" />
    <UserLogin
      class="a-link-underline font-weight-bold text-primary"
      data-tid="login-button"
      :redirect-name="userLoginRedirect">
      {{ t('addressSelector.login') }}
    </UserLogin>
    <div
      v-if="props.showCloseButton"
      class="text-center mt-3 mb-2">
      <BaseButton
        variant="neutral"
        size="sm"
        @click="closeAndGoShoping"
        v-text="t('addressSelector.closeAndGoShop')" />
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-selector-footer {
  text-align: center;
  background: color('gray-lighter');

  @include media-breakpoint-up(md) {
    padding: 20px 0;
  }
}
</style>
