<script setup lang="ts">
import { computed, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import ProductDetailGhost from '@/components/Product/Detail/Ghost/ProductDetailGhost.vue'
import ProductDetail from '@/components/Product/Detail/ProductDetail.vue'
import Popup from '@/components/global/Popup/Popup.vue'
import useInteractionSource from '@/composables/useInteractionSource'
import modalIndex from '@/constants/modalIndex'
import { Vendors } from '@/constants/vendors'
import useModalStore from '@/store/pinia/useModalStore'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

const route = useRoute()
const router = useRouter()
const { isModalOpen } = useModalStore()
const { currentVendorId } = storeToRefs(useUserInterfaceStore())
const productDetailStore = useProductDetailStore()
const { getProductDetail } = storeToRefs(productDetailStore)
const { openProductDetailModal, closeProductDetailModal } = productDetailStore

const isProductDetailLoaded = computed(() => getProductDetail.value?.id)
const isThisModalOpen = computed(() => isModalOpen(modalIndex.productDetail))

function handlenavigationHistoryChange(event: PopStateEvent) {
  if (event.state?.productDetail) {
    openProductDetailModal({
      ...router.resolve(event.state.productDetail),
      isPopstateEvent: true,
      vendorContextId: currentVendorId.value ?? null,
    })
  } else if (isThisModalOpen.value) {
    closeProductDetailModal(true)
  }
}
onMounted(() => {
  window.addEventListener('popstate', handlenavigationHistoryChange)
})

onUnmounted(() => {
  window.removeEventListener('popstate', handlenavigationHistoryChange)
})

function initOpenDetailFromNette() {
  nextTick(() => {
    if (window.openProductDetail) {
      const url = new URL(window.openProductDetail, window.location.origin)
      openProductDetailModal({
        ...router.resolve(url.pathname),
        vendorContextId: Vendors.BASE,
      })
    }

    // eslint-disable-next-line accessor-pairs
    Object.defineProperty(window, 'openProductDetail', {
      set(productDetailUrl) {
        const url = new URL(productDetailUrl, window.location.origin)
        openProductDetailModal({
          ...router.resolve(url.pathname),
          vendorContextId: Vendors.BASE,
        })
      },
    })
  })
}
initOpenDetailFromNette()

watch(
  () => route.path,
  () => {
    if (
      router.resolve(window.location.pathname).name !== 'ProductDetail' &&
      isThisModalOpen.value
    ) {
      closeProductDetailModal(true)
    }
  },
)

useInteractionSource('ProductDetailModal')
</script>

<template>
  <Popup
    :name="modalIndex.productDetail"
    size="large"
    content
    class-css="product-detail-modal"
    data-tid-close-cross="product-detail__close"
    @close="closeProductDetailModal(false)">
    <ProductDetail
      v-if="isProductDetailLoaded && getProductDetail"
      :product="getProductDetail" />
    <ProductDetailGhost
      v-else
      class="py-3 px-lg-5" />
  </Popup>
</template>

<style lang="scss">
@use '@/legacy' as *;

.product-detail-modal {
  .popup__content {
    vertical-align: top;
  }

  .popup__box {
    padding: 0;
  }

  .breadcrumb {
    padding-top: 12px;
    padding-right: 35px;
    padding-left: 15px;
    margin-top: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 26px;
      padding-left: 38px;
    }
  }
}
</style>
