<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import ProductGift from '@/components/Product/Gift/ProductGift.vue'
import ProductGroup from '@/components/Product/Group/ProductGroup.vue'
import ProductOrdering from '@/components/Product/Ordering/ProductOrdering.vue'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const getGiftsInCategory = computed(() => ({
  category: {
    name: t('cart.gift'),
  },
  products: cartGetters.value.getCartGifts,
}))
</script>

<template>
  <div>
    <ProductGroup
      v-for="group in cartGetters.getCartProductsInCategories"
      :key="`category-${group.category.id}`"
      :title="group.category.name">
      <template #content>
        <ProductOrdering
          v-for="product in group.products"
          :key="`product-${product.id}`"
          :product="product"
          class="product-list__item" />
      </template>
    </ProductGroup>
    <ProductGroup
      v-if="cartGetters.getCartGifts.length"
      :title="getGiftsInCategory.category.name"
      gifts>
      <template #content>
        <ProductGift
          v-for="product in getGiftsInCategory.products"
          :key="`gift-${product.id}`"
          :product="product"
          readonly />
      </template>
    </ProductGroup>
  </div>
</template>
