<script setup lang="ts">
import { ref, computed } from 'vue'

import DialogConfirmOverlay from '@/components/Dialog/DialogConfirmOverlay.vue'
import Icon from '@/components/Icon/Icon.vue'
import TransitionRender from '@/components/global/TransitionRender.vue'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

import ProductRow from '../components/Row/ProductRow.vue'

type ProductGiftProps = {
  product: Product
  large?: boolean
  readonly?: boolean
}
const props = defineProps<ProductGiftProps>()
const { cartActions } = useCartStore()

const isRemoveOverlayVisible = ref(false)

const getGift = computed(() => ({
  ...props.product,
  origin: {
    ...props.product.origin,
    image: props.product.image ?? '',
    name: `<strong class="text-red text-uppercase">${t('general.gift')}</strong> - ${
      props.product.name
    }`,
  },
}))

function removeCartGift() {
  cartActions.removeCartGift(props.product.id)
}
</script>

<template>
  <TransitionRender>
    <ProductRow
      class="product-gift"
      :product="getGift"
      :large="props.large"
      :closable="!props.readonly"
      :data-tids="{
        name: 'cart__gift-name',
      }"
      @close="isRemoveOverlayVisible = true">
      <template #imageFallback>
        <Icon
          icon="gift"
          class="product-gift__icon text-gray-light" />
      </template>

      <template #summary>
        <div class="product-gift__quantity">
          {{ props.product.quantity + '&nbsp;' + t('general.piecesShortened') }}
        </div>
      </template>

      <template #bottom>
        <DialogConfirmOverlay
          v-if="isRemoveOverlayVisible"
          @accepted="removeCartGift"
          @declined="isRemoveOverlayVisible = false" />
      </template>
    </ProductRow>
  </TransitionRender>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-gift {
  &__quantity {
    @include media-breakpoint-up(lg) {
      @include make-font-scale(4);
    }
  }

  &__icon {
    min-width: 32px;
    min-height: 32px;
  }
}
</style>
