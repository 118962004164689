<script setup lang="ts">
import { computed } from 'vue'

import type { Dayjs } from 'dayjs'
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { BaseButton } from '@/componentsPure'
import { analyticsLoggerBeginCheckout } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import { formatDate } from '@/utils/date'

type CartAdditionalOrderItem = {
  number: string
  deliverySlot: {
    date: Dayjs
    from: string
    to: string
  }
  deliveryAddress: {
    street: string
    city: string
  }
}

const { getProductOrThrow } = useProductsStore()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const hasMoreAdditionalOrders = computed(() => cartGetters.value.hasMoreAdditionalOrders)
const hasNonBaseVendorProduct = computed(() => cartGetters.value.hasNonBaseVendorProduct)

const hasMarketPlaceProducts = computed(() => {
  return cartGetters.value.getCartProducts.some((product) => {
    const productDetail = getProductOrThrow(product.id)
    const marketplaceVendor = productDetail.origin.marketplaceVendor
    return marketplaceVendor !== null
  })
})

const warningTranslation = computed(() => {
  const places: string[] = []
  if (hasNonBaseVendorProduct.value) {
    places.push(t('checkout.additionalOrders.pharmacyWarningName'))
  }

  if (hasMarketPlaceProducts.value) {
    places.push(t('checkout.additionalOrders.marketPlaceWarningName'))
  }

  return t('checkout.additionalOrders.pharmacyWarning', {
    places: places.join(t('checkout.additionalOrders.warningConjunction')),
  })
})

function getAdditionalOrdersInfoText(item: CartAdditionalOrderItem) {
  return t(`checkout.additionalOrders.${hasMoreAdditionalOrders.value ? 'multi.item' : 'single'}`, {
    order: item.number,
    day: formatDate(item.deliverySlot.date),
    timeFrom: item.deliverySlot.from,
    timeTo: item.deliverySlot.to,
    street: item.deliveryAddress.street,
    city: item.deliveryAddress.city,
  })
}
</script>

<template>
  <div class="wrapper">
    <div
      v-if="hasNonBaseVendorProduct || hasMarketPlaceProducts"
      class="px-4 py-3 mb-n1 text-5 text-center bg-background-error text-contrast-red pharmacy-warning"
      data-tid="pharmacy-warning">
      <span v-html="warningTranslation" />
    </div>
    <div
      class="orders-merge"
      data-tid="orders-merge-infobox">
      <Icon
        icon="future"
        class="orders-merge__icon" />
      <span
        v-if="hasMoreAdditionalOrders"
        v-html="t('checkout.additionalOrders.multi.text')" />
      <div
        v-for="(item, index) in cartGetters.getCartAdditionalOrders"
        :key="index"
        :class="[
          'orders-merge__item',
          {
            'orders-merge__item--multi': hasMoreAdditionalOrders,
          },
        ]">
        <span v-html="getAdditionalOrdersInfoText(item)" />
        <BaseButton
          entity="router-link"
          :to="{
            name: 'SummaryAdditional',
            params: {
              id: item.id,
            },
          }"
          variant="super-primary"
          :borderless="!(hasNonBaseVendorProduct || hasMarketPlaceProducts)"
          :disabled="hasNonBaseVendorProduct || hasMarketPlaceProducts"
          opaque-when-disabled
          pill
          size="sm"
          class="orders-merge__button"
          data-tid="orders-merge-infobox__button-merge"
          @click="analyticsLoggerBeginCheckout('additional_order')">
          {{ t('checkout.additionalOrders.add') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.wrapper {
  overflow: hidden;
  border-radius: var(--borderRadiusMedium);
}

.pharmacy-warning {
  position: relative;
  line-height: 1.25;

  &::after {
    @include make-font-scale(4);

    $size: 40px;

    position: absolute;
    top: 0;
    right: 0;
    width: $size;
    height: $size;
    padding: 4px 10px;
    font-weight: var(--fontWeightBold);
    color: color('white');
    text-align: right;
    content: '!';

    // non-round value in the following rule prevents zigzag rendering in FF
    background: linear-gradient(45deg, transparent 50%, color('accent-red') 50.1%);
  }
}

.orders-merge {
  @include make-font-scale(4);

  position: relative;
  padding: 12px 11px 12px 36px;
  color: color('text-paragraph');
  background: color('primary-light');

  @include media-breakpoint-up(lg) {
    padding: 16px 14px 16px 64px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 24px;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    &::after {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background: color('gray-light');
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    &--multi {
      flex-wrap: wrap;
      padding: 14px 0;
    }
  }

  &__button {
    min-width: 185px;
    text-transform: uppercase;
  }

  &__icon {
    position: absolute;
    top: 16px;
    left: 8px;
    width: 20px;
    height: 20px;
    color: color('primary');

    @include media-breakpoint-up(lg) {
      top: 21px;
      left: 16px;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
