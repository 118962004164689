<script setup>
import Icon from '@/components/Icon/Icon.vue'

const props = defineProps({
  icon: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'green',
  },
})
</script>

<template>
  <div class="delivery-message">
    <div class="delivery-message__label">
      <Icon
        :group="props.icon.group"
        :icon="props.icon.name"
        :class="['mr-1 flex-shrink-0 delivery-message__icon', `text-${color}`]" />
      <strong
        :class="['flex-shrink-0 text-uppercase', `text-${color}`]"
        v-text="props.title" />
    </div>
    <div
      class="ml-3 text-text-paragraph text-left text-2"
      v-html="props.description" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.delivery-message {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    $size: 20px;

    width: $size;
    height: $size;
  }

  &__label {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-shrink: 0;
      justify-content: flex-end;
      width: 125px;
    }
  }
}
</style>
