import { useMutation } from '@tanstack/vue-query'

import frontApi from '@/data/api/frontApi'

function useValidateSubdestination() {
  return useMutation({
    mutationFn: (address: string) =>
      frontApi.address.validateSubdestination({
        queryParams: {
          address,
        },
      }),
  })
}

export default useValidateSubdestination
