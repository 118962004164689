import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type { ProductListItem } from './productTypes'

export type TopItemsCategory = {
  id: number
  name: string
  url: string
  image: string
  highlight: boolean
}

export type TopItemsResponse = {
  banner: Banner
  products: ProductListItem[]
  categories: TopItemsCategory[]
  queries: string[]
}

function getTopItems() {
  return fetchGet<TopItemsResponse>(appConfig.frontApiBaseUrl + 'top-items')
}

export { getTopItems as frontApiGetTopItems }
