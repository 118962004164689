<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import debounce from 'lodash-es/debounce'

import DialogConfirmOverlay from '@/components/Dialog/DialogConfirmOverlay.vue'
import ProductReturnableItems from '@/components/Product/ProductReturnableItems.vue'
import DiscountInfo from '@/components/global/DiscountInfo.vue'
import ProductAmount from '@/components/global/ProductAmount.vue'
import TransitionRender from '@/components/global/TransitionRender.vue'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import { formatDate } from '@/utils/date'
import localizePrice from '@/utils/localizePrice'

import ProductRow from '../components/Row/ProductRow.vue'
import ProductCartLimitDay from './ProductCartLimitDay.vue'

type ProductCartProps = {
  product: Product
  large?: boolean
}

const props = defineProps<ProductCartProps>()

const productRef = computed(() => props.product)

const getInteractionSource = useInteractionSource('ProductCart')

const {
  productId,
  cartProductQuantity,
  hasMatchingVendorId,
  hasProductCumulativePrices,
  isCheaperPrice,
  isSale,
  openDetail,
  removeProductFromCart,
} = useProductItem(productRef, getInteractionSource)

const isQuantityChanged = ref(false)
const isRemoveOverlayVisible = ref(false)

const isActionLabelVisible = computed(() => isSale.value && !!props.product.origin.actionLabel)

const removeQuantityChangeClass = debounce(() => {
  isQuantityChanged.value = false
}, appConfig.productQuantityDebouncing)

const isFutureDeliveryVisible = computed(() => props.product.origin.firstOrderDay)

function handleQuantityChangeClass(quantityNew, quantityOld) {
  if (quantityNew > quantityOld) {
    isQuantityChanged.value = true
    removeQuantityChangeClass()
  }
}

watch(cartProductQuantity, handleQuantityChangeClass)
</script>

<template>
  <TransitionRender>
    <ProductRow
      :product="props.product"
      :large="props.large"
      :data-tids="{
        product: 'product-box__product',
        name: 'product-box__product-name',
        closeControl: 'cart-preview__delete-product',
      }"
      :data-cart-product-id="productId"
      :class="[
        'product-cart',
        {
          'product-cart--changed': isQuantityChanged,
        },
      ]"
      closable
      @close="removeProductFromCart('button_x')">
      <template #nameAdditional>
        <p
          v-if="isActionLabelVisible"
          class="product-cart__action-label"
          v-text="props.product.origin.actionLabel" />

        <template v-if="props.large">
          <ProductCartLimitDay
            v-if="isFutureDeliveryVisible"
            :modal-name="`limitedDeliveryFrom-${productId}`">
            <template #claim>
              {{
                t('possibleOrderFrom.todayUnavailable', {
                  weekday: formatDate(props.product.firstOrderDay, { showYear: false }),
                })
              }}
            </template>
            <template #modalTitle>
              {{ t('possibleOrderFrom.popup.title') }}
            </template>
            <template #modalContent>
              <p v-text="t('possibleOrderFrom.popup.part1')" />
              <p v-text="t('possibleOrderFrom.popup.part2')" />
            </template>
          </ProductCartLimitDay>
        </template>
        <template v-if="!props.large">
          <p
            v-if="isFutureDeliveryVisible"
            class="font-weight-bold mt-2 mb-1 tw-text-ds-accent-blue"
            v-text="
              t('possibleOrderFrom.todayUnavailable', {
                weekday: formatDate(props.product.firstOrderDay, { showYear: false }),
              })
            " />
        </template>
      </template>

      <template
        v-if="props.large"
        #controls>
        <div
          v-if="!hasMatchingVendorId"
          class="text-center"
          v-text="t('cart.inCartPcs', { amount: cartProductQuantity })" />
        <ProductAmount
          :product="product"
          :has-matching-vendor-id="hasMatchingVendorId"
          :removable="false"
          class="d-inline-flex text-center"
          @remove="isRemoveOverlayVisible = true"
          @open-detail="openDetail" />
      </template>

      <template #summary>
        <div
          v-if="!props.large && !hasMatchingVendorId"
          class="mb-n1 text-2"
          v-text="t('cart.inCartPcs', { amount: cartProductQuantity })" />
        <s
          v-if="isCheaperPrice"
          :class="[
            'product-cart__recommended-price',
            {
              'product-cart__recommended-price--large': props.large,
            },
          ]"
          v-text="localizePrice(props.product.computed.recommendedPrice * cartProductQuantity)" />
        <strong
          data-tid="product-box__product-price"
          :class="[
            props.large ? 'text-5' : 'text-4',
            {
              'text-red': isCheaperPrice,
            },
          ]"
          v-text="
            (props.product.origin.productQuantity
              ? `${props.product.origin.productQuantity.prefix} `
              : '') + localizePrice(props.product.computed.price * cartProductQuantity)
          " />

        <div class="text-right">
          <ProductAmount
            v-if="!props.large"
            :product="product"
            :has-matching-vendor-id="hasMatchingVendorId"
            :removable="false"
            size="sm"
            class="d-inline-flex"
            @remove="isRemoveOverlayVisible = true"
            @open-detail="openDetail" />
        </div>
      </template>

      <template #bottom>
        <DiscountInfo
          v-if="hasProductCumulativePrices || isCheaperPrice"
          :product="product"
          :class="[
            'product-cart__discount-info',
            {
              'product-cart__discount-info--large': props.large,
            },
          ]" />

        <ProductReturnableItems
          v-if="
            props.product.origin.returnablePackagePrice > 0 ||
            props.product.origin.returnableCarrier
          "
          :product="props.product"
          :large="props.large"
          class="mt-1" />

        <DialogConfirmOverlay
          v-if="isRemoveOverlayVisible"
          @accepted="removeProductFromCart('button_remove_confirm')"
          @declined="isRemoveOverlayVisible = false" />
      </template>
    </ProductRow>
  </TransitionRender>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-cart {
  &--changed {
    background: color('green-light');
  }

  &__action-label {
    @include make-font-scale(1);

    margin: 0;
    font-weight: 600;
    color: color('red');
  }

  &__recommended-price {
    @include make-font-scale(1);

    position: relative;
    top: 5px;
    display: block;
    color: color('gray');

    &--large {
      top: 0;
      display: inline;
      margin-right: 3px;
    }
  }

  &__discount-info {
    margin-top: 5px;
    text-align: right;

    &--large {
      margin-top: -15px;
    }
  }
}
</style>
