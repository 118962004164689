import appConfig from '../../appConfig'
import { fetchPost } from '../fetch'

const modulePath = 'cart'

function postCartShoppingList() {
  return fetchPost(appConfig.webApiBaseUrl + modulePath + '/shopping-list/')
}

export { postCartShoppingList as webApiPostCartShoppingList }
