<script setup lang="ts">
import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import { VENDOR_CONFIG, Vendors } from '@/constants/vendors'
import { t } from '@/services/translations'

const vendorLogo = VENDOR_CONFIG[Vendors.PHARMACY].logo
</script>

<template>
  <Popup
    v-slot="{ close }"
    :name="modalIndex.pharmacyWelcome"
    content
    size="small"
    data-tid-close-cross="pharmacy-welcome__close"
    box-class-css="text-center">
    <div class="title">
      <span>
        {{ t('vendors.welcomeToPharmacy.title') }}
      </span>
      <img
        class="vendor-logo"
        :src="`/vue/img/logo-${vendorLogo}.svg?v=1`"
        :alt="`${vendorLogo} logo`" />
    </div>
    <p class="mb-4 text-4">
      {{ t('vendors.welcomeToPharmacy.paraOne') }}
    </p>
    <p class="mb-4 text-4">
      {{ t('vendors.welcomeToPharmacy.paraTwo') }}
    </p>
    <BaseButton
      variant="green"
      size="md"
      class="mb-sm-2 px-4 py-2 text-uppercase"
      data-tid="pharmacy-welcome__understood-close-button"
      @click="close">
      {{ t('general.understood') }}
    </BaseButton>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.vendor-logo {
  height: 20px;
  margin: 0 8px;
  border: 1px solid color('gray', 0.7);
  border-radius: 20px;

  @include media-breakpoint-up(sm) {
    height: 24px;
    margin: 0 12px;
  }
}
</style>
