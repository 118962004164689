import type { Category } from './menuTypes'

// Type guard for Category
function isCategory(category: Record<string, unknown>): category is Category {
  return (
    typeof category.id == 'number' &&
    typeof category.name == 'string' &&
    typeof category.url == 'string' &&
    typeof category.vendorId == 'number' &&
    category.subCategories !== undefined
  )
}

export default isCategory
