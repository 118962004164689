<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Icon from '@/components/Icon/Icon.vue'
import useUser from '@/composables/useUser'
import { LocalStorageKeys } from '@/constants/storageKeys'
import { aadLogout, aadResetPassword } from '@/services/auth'
import type { EmailProvider, SocialProvider } from '@/services/auth/aadTypes'
import { storageRetrieveLocal } from '@/services/storage'
import { t } from '@/services/translations'

import menuItems, { type MenuItem } from './menuItems'

type UserNavProps = {
  excludedActiveRoute?: boolean
  itemClass?: string
}

const props = defineProps<UserNavProps>()

const emit = defineEmits<{
  close: []
}>()

const route = useRoute()
const router = useRouter()
const { getUserCredits, getUserCreditsEnable } = useUser()

function getRouteTo(item: MenuItem) {
  return {
    name: item.routeTo,
    ...(item.routeParams && {
      params: {
        presenter: item.routeParams.presenter ?? 'profile',
        action: item.routeParams.action ?? 'default',
      },
    }),
  }
}

const visibleMenuItems = computed(() => {
  const menuItemsWithoutCurrent = props.excludedActiveRoute
    ? menuItems.filter((item) => router.resolve(getRouteTo(item)).path !== route.path)
    : menuItems
  return menuItemsWithoutCurrent.filter((item) => item.key !== 'credits' || getUserCreditsEnable)
})

/**
 * Check if the user has logged with email/password. False if social provider
 * was used for login (like Google, Seznam, Facebook, etc.
 */
const isEmailUser =
  storageRetrieveLocal<EmailProvider | SocialProvider>(LocalStorageKeys.identityProvider) ===
  'email'
</script>

<template>
  <ul class="user-nav">
    <li
      v-for="(menuItem, index) in visibleMenuItems"
      :key="`user-menu-item-${index}`"
      :class="itemClass">
      <RouterLink
        :to="getRouteTo(menuItem)"
        :active-class="'user-nav__link--active'"
        :data-tid="menuItem.key"
        class="user-nav__link"
        @click.prevent="emit('close')">
        <Icon
          :icon="menuItem.icon"
          :group="menuItem.iconGroup || 'profile'"
          class="user-nav__icon" />
        {{ t(`userMenu.labels.${menuItem.key}`) }}
        <template v-if="menuItem.key === 'credits'"> ({{ getUserCredits }}) </template>
      </RouterLink>
    </li>
    <li
      v-if="isEmailUser"
      :class="itemClass">
      <button
        class="btn w-100 user-nav__link font-weight-normal"
        data-tid="changePassword"
        @click.prevent="aadResetPassword(/* Must have empty attrs. */)">
        <Icon
          icon="password"
          group="profile"
          class="user-nav__icon" />
        {{ t('userMenu.labels.changePassword') }}
      </button>
    </li>
    <li :class="[itemClass, 'mx-3']">
      <button
        class="btn w-100 user-nav__link user-nav__link--separated font-weight-normal"
        data-tid="signOut"
        @click.prevent="aadLogout(/* Must have empty attrs. */)">
        {{ t(`userMenu.labels.signOut`) }}
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.user-nav {
  &__icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: color('primary');
  }

  &__link--separated {
    justify-content: center;
    padding-top: 13px;
    padding-bottom: 8px;
    margin-top: 10px;
    text-decoration: underline;
    border-top: 1px solid color('gray-light');
  }
}
</style>
