enum PageType {
  Category = 'category',
  SpecificCategory = 'specific_category',
  Product = 'product',
  Content = 'content',
  Recipes = 'recipes',
}

function getReloadPageInfoTypeValues(): string[] {
  return Object.values(PageType)
}

export { PageType, getReloadPageInfoTypeValues }
