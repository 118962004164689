<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import type { Ref } from 'vue'

import { storeToRefs } from 'pinia'

import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import useConfig from '@/composables/useConfig'
import modalIndex from '@/constants/modalIndex'
import {
  analyticsLoggerPrivacySettingsUpdate,
  analyticsLoggerConsentImpression,
} from '@/services/analytics/analyticsLogger'
import type { ConsentType } from '@/services/analytics/gtm/ckConsentUpdate'
import { initYottly } from '@/services/analytics/yottly'
import { getCookie } from '@/services/cookies'
import { setGrowthbookAttributes } from '@/services/growthbook'
import {
  cookiesConsentMap,
  setCookieConsent,
  cookieName,
  type CookiesConsentMap,
  analyticsCookiesValues,
} from '@/services/privacy'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'

import Accordion from './Accordion.vue'
import type { CookiesSetValue } from './CookieBarModal.vue'
import DetailedText from './DetailedText.vue'

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownSm } = storeToRefs(deviceDetectorStore)

const config = useConfig()
const { closeModal, isModalOpen } = useModalStore()

type DetailedCookieModalProps = {
  cookiesSetValue: CookiesSetValue
}

const props = defineProps<DetailedCookieModalProps>()

const accordionSettings = ref(
  Object.entries(cookiesConsentMap).map(([key, value]) => ({
    name: key,
    active: value === analyticsCookiesValues.granted,
  })),
)

// set active accordions based on cookie consents
function setAccordionActive() {
  if (!getCookie(cookieName)) return

  const browserCookies = JSON.parse(getCookie(cookieName))
  for (const setting of accordionSettings.value) {
    setting.active = browserCookies[setting.name] === analyticsCookiesValues.granted
  }
}

const cookieConsents: Ref<CookiesConsentMap> = ref({})

// transform the array with real settings to object so that it can be pushed to datalayer
function setCookieConsentsObject() {
  for (const setting of accordionSettings.value) {
    cookieConsents.value[setting.name] = setting.active
      ? analyticsCookiesValues.granted
      : analyticsCookiesValues.denied
  }
}

function saveConsents(type?: string, section?: string) {
  setCookieConsentsObject()
  setCookieConsent(cookieConsents.value)
  setGrowthbookAttributes({
    userId: config.value.user.luigisboxUserId,
    warehouseId: config.value.cart.warehouseId,
    subDestinationId: config.value.cart.subDestinationId,
  })
  analyticsLoggerPrivacySettingsUpdate(
    cookieConsents.value,
    (type as ConsentType) ?? 'save_preferences',
    section,
  )
  initYottly()
}

function setCookies(callback: () => void) {
  callback()
  closeModal(modalIndex.detailedCookieModal)
}

function setAllCookieConsents(value: boolean, type?: string, section?: string) {
  accordionSettings.value = accordionSettings.value.map((setting) => ({
    ...setting,
    active: setting === accordionSettings.value[0] ? setting.active : value,
  }))

  saveConsents(type, section)
}

onMounted(() => {
  setAccordionActive()
})

const isThisModalOpen = computed(() => isModalOpen(modalIndex.detailedCookieModal))

// watch if the modal is open and log the impression according to, if cookies are set or not
watch(isThisModalOpen, (value) => {
  if (value) {
    if (getCookie(cookieName)) {
      analyticsLoggerConsentImpression('detailed_section', 'change_preferences')
    }
  }
})

// if the user clicks on the acceptAll or rejectAll button, set all cookie consents accordingly
watch(
  () => props.cookiesSetValue,
  (type) => {
    const value = type === 'acceptAll'
    setAllCookieConsents(value, type, 'main')
  },
)
</script>

<template>
  <Popup
    class-css="detailed-cookie-modal"
    :name="modalIndex.detailedCookieModal"
    size="detailed-cookie-modal"
    disable-close
    content>
    <div class="mx-3 mx-sm-4">
      <h2 class="text-8 mb-3 mb-sm-4">
        {{ t('privacySettings.detailedTitle') }}
      </h2>
      <div class="heading-line" />
    </div>
    <div class="detailed-cookie-modal__details">
      <div class="mx-3 mx-sm-4">
        <DetailedText
          class="mt-3 mt-sm-4 mb-2"
          @on-click="closeModal(modalIndex.detailedCookieModal)" />
        <BaseButton
          :class="[
            'text-5',
            {
              'w-100': breakpointDownSm,
            },
          ]"
          variant="dark-brand"
          size="sm"
          @click="setCookies(() => setAllCookieConsents(true, 'acceptAll'))">
          {{ t('privacySettings.acceptAll') }}
        </BaseButton>
      </div>

      <div class="detailed-cookie-modal__toggle-container p-3 p-sm-4 my-4 mx-sm-4">
        <h3 class="font-weight-bold mb-4 text-6">
          {{ t('privacySettings.accordionHeading') }}
        </h3>
        <div class="d-flex flex-column accordion-container">
          <Accordion
            v-for="(accordion, index) in accordionSettings"
            :key="index"
            v-model="accordion.active"
            :index="index"
            :accordion-heading="t(`privacySettings.cookieConsents.${accordion.name}.title`)"
            :accordion-text="t(`privacySettings.cookieConsents.${accordion.name}.description`)" />
        </div>
      </div>
    </div>
    <div class="mx-3 mx-sm-4">
      <div class="heading-line" />
      <BaseButton
        :class="[
          'text-5 mt-3 mt-sm-4',
          {
            'w-100': breakpointDownSm,
          },
        ]"
        variant="dark-brand"
        size="sm"
        @click="setCookies(saveConsents)">
        {{ t('privacySettings.saveSettings') }}
      </BaseButton>
    </div>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.detailed-cookie-modal {
  &__details {
    overflow-y: scroll;
  }

  &__toggle-container {
    background-color: color('gray-lighter');
    border-radius: var(--borderRadiusMedium);
  }
}

.heading-line {
  width: calc(100% + 32px);
  height: 1px;
  margin: 0 -16px;
  background-color: color('gray-lighter');

  @include media-breakpoint-up(sm) {
    width: calc(100% + 32px);
    margin: 0 -16px;
  }

  @include media-breakpoint-up(md) {
    width: calc(100% + 64px);
    margin: 0 -32px;
  }
}

.accordion-container {
  gap: 16px;
}
</style>
