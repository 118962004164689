import { useQuery } from '@tanstack/vue-query'

import { deliveryQueryKeys } from '@/constants/queryKeys'
import frontApi from '@/data/api/frontApi'

function useDeliveryOptions() {
  return useQuery({
    queryKey: deliveryQueryKeys.all,
    queryFn: () => frontApi.address.getDeliveryOptions(),
    select: (data) => data.priceSteps,
  })
}

export default useDeliveryOptions
