<script setup lang="ts">
import { computed, watch, ref } from 'vue'

import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import type { GPS } from '@/data/api/frontApi/address/addressTypes'
import { analyticsLoggerAddressPin } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'

import LocationMapEditWrapper from '../DeliveryOnLocationMap/LocationMapEditWrapper.vue'
import useDeliveryOnLocationMap from '../DeliveryOnLocationMap/useDeliveryOnLocationMap'
import DeliveryOnLocationModalHeader from './DeliveryOnLocationModalHeader.vue'

type DeliveryOnLocationTriggerOptions = {
  currentGps: GPS | null
  addressGps: GPS | null
  address: string
  street: string
  zip: string
  onSave: (newLocation: GPS) => void
}

const { getModal, closeModal } = useModalStore()

const modalTriggerOptions = computed(() => {
  const modal = getModal<DeliveryOnLocationTriggerOptions>(modalIndex.deliveryOnLocation)

  return modal?.triggerOptions ?? null
})

const address = computed(() => modalTriggerOptions.value?.address ?? '')
const street = computed(() => modalTriggerOptions.value?.street ?? '')
const zip = computed(() => modalTriggerOptions.value?.zip ?? '')

const { changedLocations, gpsIsValid, saveIsDisabled, onMapMove, onMapChanged } =
  useDeliveryOnLocationMap(address)

function onResetLocation() {
  if (modalTriggerOptions.value?.addressGps) {
    changedLocations.value = { ...modalTriggerOptions.value.addressGps }
  }
}

function saveLocation() {
  changedLocations.value && modalTriggerOptions.value?.onSave(changedLocations.value)
  closeModal(modalIndex.deliveryOnLocation)
}

// we need to store info from modalTriggerOptions to ref because after onClose is called modalTriggerOptions.value is null
function onClose() {
  analyticsLoggerAddressPin({ activity: 'pin_closed' })
  changedLocations.value = currentGps.value
}

const currentGps = ref<GPS | null>(null)

watch(
  () => modalTriggerOptions.value,
  (newVal) => {
    if (newVal) {
      currentGps.value = newVal.currentGps
    }
  },
  { immediate: true },
)
</script>

<template>
  <Popup
    content
    :name="modalIndex.deliveryOnLocation"
    size="large"
    class-css="delivery-on-location-modal"
    @close="onClose">
    <section class="delivery-on-location-modal__content">
      <DeliveryOnLocationModalHeader
        :street="street"
        :zip="zip"
        class="mb-5" />
      <LocationMapEditWrapper
        :gps="changedLocations"
        :location-error="!gpsIsValid"
        edit
        class="location-map-edit-mobile"
        :on-map-move="onMapMove"
        @map-changed="onMapChanged"
        @reset-location="onResetLocation">
        <template #info-append>
          <BaseButton
            variant="primary"
            :disabled="saveIsDisabled"
            class="delivery-modal-save-btn"
            :class="{
              'delivery-modal-save-btn--disabled': saveIsDisabled,
            }"
            @click.prevent="saveLocation">
            {{ t('delivery.deliveryLocation.saveButton') }}
          </BaseButton>
        </template>
      </LocationMapEditWrapper>
    </section>
  </Popup>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.location-map-edit-mobile {
  flex: 1;

  .delivery-modal-save-btn {
    height: 50px;
    margin: 0 4px 4px;
    font-size: get-font-size(4);
    border-radius: 12px;

    &--disabled {
      color: color(gray-dark);
      background-color: color(gray-lighter);
      opacity: 1 !important;
    }
  }
}
</style>

<style lang="scss">
.delivery-on-location-modal {
  .popup__content {
    height: calc(100% - 24px);

    .popup__box {
      height: 100%;
      padding: 24px 12px 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
