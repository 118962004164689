const specialChars = '\'‘<>"`'

/**
 * A valid string tested against this regex must not contain any of the special characters anywhere.
 * Empty string is treated as valid though.
 *
 * Step-by-step explanation:
 * Must not contain ~ the second caret ^
 * Any of characters listed ~ the square brackets []
 * Other characters can be present between 0 and infinite times ~ the star symbol *
 * Anywhere, i.e. from the beginning till the end ~ the first caret ^ and the dollar sign $
 */
const validStringRegex = new RegExp(`^[^${specialChars}]*$`)

export { specialChars, validStringRegex }
