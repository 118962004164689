<script setup lang="ts">
import useInteractionSource from '@/composables/useInteractionSource'
import useUser from '@/composables/useUser'
import { ResponseNotOKError } from '@/services/api/makeRequest'
import { webApiPostCartShoppingList } from '@/services/api/web/cart'
import { toastError, toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

const { cartActions } = useCartStore()

const { isUserSignedIn } = useUser()

const clearCartProducts = async (interactionElement) => {
  try {
    await cartActions.clearCart(getInteractionSource({ element: interactionElement }))
    toastSuccess(t('cart.flashMessages.CLEAR_CART_SUCCESS'))
  } catch (error) {
    const message =
      error instanceof ResponseNotOKError && error.response.data.context?.translatedDetail
        ? error.response.data.context.translatedDetail
        : t('cart.flashMessages.UNKNOWN_ERROR')
    toastError(message)
  }
}

const createShoppingList = async () => {
  try {
    await webApiPostCartShoppingList()

    toastSuccess(t('cart.flashMessages.CREATE_SHOPPING_LIST_SUCCESS'))
  } catch (error) {
    const message =
      error instanceof ResponseNotOKError && error.response.data.errorCode
        ? t(`cart.flashMessages.${error.response.data.errorCode}`)
        : t('cart.flashMessages.UNKNOWN_ERROR')
    toastError(message)
  }
}

const getInteractionSource = useInteractionSource('CheckoutCartListActions')
</script>

<template>
  <div class="checkout-cart-list-actions">
    <a
      href="#"
      class="cart-delete"
      data-tid="cart-checkout-removeAll"
      @click.prevent="clearCartProducts('a_clear_cart')"
      v-text="t('cart.removeAll')" />

    <a
      v-if="isUserSignedIn"
      href="#"
      class="a-link-underline cart-new-list"
      @click.prevent="createShoppingList"
      v-text="t('cart.createShoppingList')" />
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.checkout-cart-list-actions {
  display: flex;
  flex-flow: row wrap;
  column-gap: 1rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
}

.cart-delete {
  color: color('contrast-red');
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cart-new-list {
  color: color('primary');
}
</style>
