<script setup lang="ts">
import { computed } from 'vue'

type RoundedPillProps = {
  bigSize?: boolean
}

const props = defineProps<RoundedPillProps>()

const sizeClass = computed(() => {
  return props.bigSize ? 'padding-big text-2' : 'padding-small text-1'
})
</script>

<template>
  <span
    class="rounded-pill bg-green text-white"
    :class="[sizeClass]">
    <slot />
  </span>
</template>

<style scoped lang="scss">
.padding-small {
  padding: 2px 6px;
}

.padding-big {
  padding: 4px 24px;
}
</style>
