<script setup lang="ts">
import { BaseLoader } from '@/componentsPure'
import { frontApiPostCheckoutFingerprint } from '@/services/api/front/checkout'
import { t } from '@/services/translations'

const props = defineProps({
  fingerprintUrl: {
    type: String,
    required: true,
  },
  redirectUrl: {
    type: String,
    required: true,
  },
  orderId: {
    type: Number,
    required: true,
  },
})

// When the <iframe /> is created, it sends POST request that needs to be executed before redirecting.
// The code for this POST request is provided by payment provider (ČSOB) and therefore we don't have any control over it.
// We set default waiting time 3s. After that we assume the POST request is finished and user can be redirected.
// If the POST request fails/won't finish in 3s, the user might be asked to authorize the payment (sms/app ...).
const makeRedirect = () => {
  setTimeout(() => {
    window.location.href = props.redirectUrl
  }, 3000)
}

const makeFingerprint = async () => {
  try {
    await frontApiPostCheckoutFingerprint(props.orderId, {
      browser: {
        language: window.navigator.language,
        javascriptEnabled: true,
        colorDepth: window.screen.colorDepth,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        timezone: new Date().getTimezoneOffset(),
        javaEnabled: false,
      },
    })
    makeRedirect()
  } catch (err) {
    console.error('Error while making fingerprint:', err)
    makeRedirect()
  }
}
makeFingerprint()
</script>

<template>
  <div>
    <div class="overlay" />

    <section class="info">
      <h1
        class="text-10 font-weight-bold"
        v-text="t('checkout.fingerprint.title')" />

      <p
        class="text-7 font-weight-normal"
        v-html="t('checkout.fingerprint.text')" />

      <div class="position-relative py-5">
        <BaseLoader />
      </div>

      <iframe
        :src="props.fingerprintUrl"
        class="iframe"
        title="payment-fingerprint" />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.overlay {
  @include overlay;
}

.info {
  position: relative;
  z-index: $zix-overlay;
  padding: 90px 0;
  text-align: center;
}

.iframe {
  display: none;
  width: 1px;
  height: 1px;
}
</style>
