<script setup lang="ts">
type TopSearchTermsProps = {
  searchTerms: string[]
}
const props = defineProps<TopSearchTermsProps>()

const emit = defineEmits<{
  click: [term: string]
}>()
</script>

<template>
  <nav
    class="breadcrumb"
    :data-tid="$attrs['data-tid'] || 'breadcrumbs'">
    <span
      v-for="term in props.searchTerms"
      :key="term"
      class="breadcrumb__item">
      <span
        class="breadcrumb__link"
        @click="emit('click', term)">
        {{ term }}
      </span>
    </span>
  </nav>
</template>
