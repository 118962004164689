<script setup lang="ts">
import { computed, defineComponent, ref } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import ActionMenuItem from './ActionMenuItem.vue'
import ActionMenuCarousel from './Carousel.vue'
import useActionMenuStaticBlock from './useActionMenuStaticBlock'

const { data: actionMenuItems, isError: actionMenuItemsError } = useActionMenuStaticBlock()

// Parse string with plain HTML to array of component templates.
const items = computed(() => {
  if (!actionMenuItems.value) {
    return null
  }

  return actionMenuItems.value.html
    .split('</action-menu-item>')
    .filter((item) => item.trim() !== '')
    .map((item) => item.replace(/\n/g, ''))
    .map((item) => item + '</action-menu-item>')
})

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd, breakpointUpLg } = storeToRefs(deviceDetectorStore)

const isVisibleCategories = ref(false)
const toggleSubcategories = () => {
  if (actionMenuItems.value) isVisibleCategories.value = !isVisibleCategories.value
}

// Prepare component renderer for each template.
const menuItemRenderer = computed(() => {
  if (!items.value) {
    return null
  }
  return items.value.map((item) =>
    defineComponent({
      components: {
        ActionMenuItem,
      },
      template: item,
    }),
  )
})
</script>

<template>
  <div
    v-if="breakpointDownMd"
    class="action-menu">
    <div
      class="action-menu__title-wrapper"
      @click="toggleSubcategories">
      <p class="action-menu__title">
        <Icon
          icon="bargain"
          group="profile"
          class="action-menu__title__image" />
        <template v-if="actionMenuItems">
          {{ t('navBar.specialOffers') }}
        </template>
        <span v-else-if="actionMenuItemsError">&nbsp;</span>
        <span
          v-else
          class="a-placeholder-text skeleton-item-mobile"
          >&nbsp;</span
        >
      </p>
      <div class="action-menu__arrow-wrap">
        <Icon
          v-if="actionMenuItems"
          icon="arrow-reversed"
          :class="{ 'action-menu__arrow-down': isVisibleCategories }" />
      </div>
    </div>
    <div
      v-if="isVisibleCategories"
      class="action-menu__items"
      data-tid="category-bar__action-menu-items">
      <Component
        :is="item"
        v-for="(item, index) in menuItemRenderer"
        :key="index" />
    </div>
  </div>
  <div
    v-else
    class="action-menu">
    <div
      v-if="menuItemRenderer !== null"
      class="action-menu__items"
      data-tid="category-bar__action-menu-items">
      <ActionMenuCarousel v-if="breakpointUpLg">
        <Component
          :is="item"
          v-for="(item, index) in menuItemRenderer"
          :key="index" />
      </ActionMenuCarousel>
    </div>
    <div
      v-else-if="actionMenuItemsError"
      class="action-menu__items"></div>
    <div
      v-else
      class="action-menu__items skeleton">
      <span
        v-for="n in 12"
        :key="n"
        class="a-placeholder-text skeleton-item"
        >&nbsp;</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.skeleton {
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  width: 100%;
  height: 31px;
  overflow: hidden;
}

.skeleton-item {
  display: inline-block;
  width: 65px;
  margin: 0 0.5rem;

  &:nth-child(odd) {
    width: 125px;
  }
}

.skeleton-item-mobile {
  display: inline-block;
  width: 125px;
  line-height: 1rem;
}

.action-menu {
  border-top: 1px solid color('gray-lighter');

  @include media-breakpoint-up(lg) {
    padding: 7px 0;
  }

  &__title-wrapper {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid color('gray-light');
  }

  &__title {
    padding-left: 12px;
    margin: 0;
    line-height: var(--mobileMenuCategoryHeigth);

    &__image {
      width: 18px;
      height: 18px;
      margin-right: 11px;
      vertical-align: middle;
    }
  }

  &__arrow-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--mobileMenuCategoryHeigth);
    margin-left: auto;
    line-height: var(--mobileMenuCategoryHeigth);
    background: none;
    border: 0;
  }

  &__arrow-down {
    transform: rotate(180deg);
  }

  &__items {
    @include media-breakpoint-up(lg) {
      display: flex;
      height: 31px;
      overflow: hidden;
    }
  }
}
</style>
