import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

export function useUserInterface() {
  const userInterfaceStore = useUserInterfaceStore()
  const { headerHeight } = storeToRefs(userInterfaceStore)
  const { isModalOpen, MODAL_NAMES } = useModalStore()
  const isSuggesterVisible = computed(() => isModalOpen(MODAL_NAMES.productSearch))

  return {
    headerHeight,
    setHeaderMenuVisible: userInterfaceStore.setHeaderMenuVisible,
    setHeaderHeight: userInterfaceStore.setHeaderHeight,
    isSuggesterVisible,
  }
}
