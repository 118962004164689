const errorConfig = {
  disableInstrumentations: [
    'page-load',
    'history',
    'click',
    'xmlhttprequest',
    'fetch',
    'eventtarget',
  ],
  logLevel: 'error',
}

const optimizedConfig = {
  disableInstrumentations: ['history', 'click', 'eventtarget', 'xmlhttprequest'],
  ignoreTransactions: [
    'POST https://api.luigisbox.com',
    'POST https://linter.luigisbox.com',
    'POST https://yottlyscript.com/save-action',
    'POST https://backend.yottly.com/trackpoint/77/browsing/basket-complements',
    'GET https://measurement-api.criteo.com/register-trigger',
    'POST https://x.clarity.ms/collect',
    /GET https:\/\/kosik\.daktela\.com\/external\/web\/api\/webPullData/,
    'POST https://region1.analytics.google.com/g/collect',
    'POST https://region1.google-analytics.com/g/collect',
    'POST https://www.google-analytics.com/g/collect',
  ],
}

export { errorConfig, optimizedConfig }
