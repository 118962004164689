<script setup lang="ts">
import { computed } from 'vue'

import ProductReturnableItems from '@/components/Product/ProductReturnableItems.vue'
import DiscountInfo from '@/components/global/DiscountInfo.vue'
import { useProductItem, type Product } from '@/composables/useProductItem'
import localizePrice from '@/utils/localizePrice'

import ProductRow from '../components/Row/ProductRow.vue'

type ProductOrderingProps = {
  product: Product
}

const props = defineProps<ProductOrderingProps>()

const productRef = computed(() => props.product)

const { cartProductQuantity, hasProductCumulativePrices, hasReturnables, isCheaperPrice } =
  useProductItem(productRef)
</script>

<template>
  <ProductRow
    class="product-ordering"
    :product="props.product"
    detail-disabled>
    <template #summary>
      <s
        v-if="isCheaperPrice"
        class="product-ordering__recommended-price"
        v-text="localizePrice(props.product.computed.recommendedPrice * cartProductQuantity)" />
      <strong
        :class="[
          'text-4',
          {
            'text-red': isCheaperPrice,
          },
        ]"
        v-text="
          (props.product.origin.productQuantity
            ? `${props.product.origin.productQuantity.prefix} `
            : '') + localizePrice(props.product.computed.price * cartProductQuantity)
        " />
      <div
        class="text-4"
        v-text="`${cartProductQuantity} ${props.product.origin.unit}`" />
    </template>

    <template #bottom>
      <DiscountInfo
        v-if="hasProductCumulativePrices || isCheaperPrice"
        :product="props.product"
        class="text-right" />
      <ProductReturnableItems
        v-if="hasReturnables"
        :product="props.product"
        readonly
        class="mt-1" />
    </template>
  </ProductRow>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-ordering {
  &__recommended-price {
    @include make-font-scale(1);

    position: relative;
    top: 5px;
    display: block;
    color: color('gray');
  }
}
</style>
