import appConfig from '@/services/appConfig'

import { fetchGet } from '../fetch'
import type { RequestQueryParams } from '../makeRequest'

type NoticeSeverity = 'success' | 'warning' | 'error'

type Notice = {
  message: string
  type: NoticeSeverity
}

type InfoNoticesResponse = {
  notices: Notice[]
}

const modulePath = 'info'

/**
 * Sends GET request for getting information about notices.
 * @see https://sasandboxstdhotapidocs.z1.web.core.windows.net/#/Info/readNotices
 * @param url current path in object where key can be url or categoryUrl.
 * @returns Promise of the API response with notices object.
 */
function getInfoNotices(url: RequestQueryParams) {
  return fetchGet<InfoNoticesResponse>(appConfig.frontApiBaseUrl + modulePath + '/notices', url)
}

export { getInfoNotices as frontApiGetInfoNotices }
export type { Notice, NoticeSeverity }
