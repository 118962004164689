<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import Icon from '@/components/Icon/Icon.vue'
import { useOrder } from '@/components/OrderTracking'
import { BaseButton } from '@/componentsPure'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import type { TransformedReadOrderList } from '@/store/utils/prepareOrder'
import { formatDate, isPast } from '@/utils/date'
import localizePrice from '@/utils/localizePrice'

type OrdersItemProps = {
  /**
   * One of the customer's orders
   */
  order: TransformedReadOrderList
  /**
   * Forces visibility of "table head", usually for the first item in a list.
   * On small viewports there is no difference when this option is on or off, as every item has the "table head" displayed.
   */
  headVisible?: boolean
}

const props = defineProps<OrdersItemProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const router = useRouter()

const { linkToOrder } = useOrder(props.order.id)

const isAdditionalOrderPossible = computed(() => {
  return (
    props.order.additionalOrderCloseDateTime && !isPast(props.order.additionalOrderCloseDateTime)
  )
})

const downloadInvoiceUrl = computed(() => {
  return router.resolve({
    name: 'Profile',
    params: {
      presenter: 'profile',
      action: 'download-invoice',
    },
    query: {
      orderId: props.order.id,
    },
  }).href
})
</script>

<template>
  <div>
    <div
      :class="[
        'orders-item',
        'orders-item--default',
        {
          'orders-item--multiple': props.order.complaintUntil,
        },
      ]">
      <template v-if="deviceDetectorStore.breakpointDownMd || props.headVisible">
        <div
          class="orders-item__data orders-item__data--top orders-item__data--first"
          v-text="t('profile.orders.orderId')" />
        <div
          class="orders-item__data orders-item__data--top"
          v-text="t('profile.orders.orderDate')" />
        <div
          class="orders-item__data orders-item__data--top"
          v-text="t('profile.orders.orderState')" />
        <div
          class="orders-item__data orders-item__data--top justify-content-lg-end"
          v-text="t('profile.orders.orderPrice')" />
      </template>
      <div
        class="orders-item__data orders-item__data--bottom orders-item__data--first"
        v-text="props.order.number" />
      <div
        class="orders-item__data orders-item__data--bottom"
        v-text="formatDate(props.order.created, { showYear: true })" />
      <div
        class="orders-item__data orders-item__data--bottom"
        v-text="props.order.state.text" />
      <div
        class="orders-item__data orders-item__data--bottom justify-content-end"
        v-text="localizePrice(props.order.prices.priceWhenFinishingOrder)" />
      <div class="orders-item__data orders-item__data--bottom orders-item__data--control">
        <BaseButton
          v-if="props.order.invoice"
          :href="downloadInvoiceUrl"
          entity="a"
          variant="secondary"
          pill
          class="orders-item__button orders-item__button--light">
          <Icon
            icon="download"
            group="profile"
            class="orders-item__icon" />
          {{ t('profile.orders.invoice') }}
        </BaseButton>
        <BaseButton
          v-if="props.order.tracking"
          entity="router-link"
          :to="linkToOrder"
          variant="secondary"
          class="orders-item__button orders-item__button--light"
          pill>
          {{ t('profile.orders.tracking') }}
        </BaseButton>
        <BaseButton
          entity="router-link"
          :to="linkToOrder"
          variant="primary"
          class="orders-item__button orders-item__button--dark"
          data-tid="orders-item__button-order-detail"
          pill>
          {{ t('profile.orders.detail') }}
        </BaseButton>
      </div>
    </div>
    <div
      v-if="isAdditionalOrderPossible"
      class="orders-item orders-item--more-options orders-item--additional-order">
      <Icon
        icon="future"
        class="orders-item__icon" />
      <p
        class="mb-0"
        data-tid="orders-item__additional-order-info">
        {{ t('profile.orders.additionalOrdersText') }}
        <RouterLink
          class="a-link-underline"
          :to="{ name: 'StaticPage', params: { id: t('additionalOrder.link') } }"
          :title="t('additionalOrder.linkTitle')"
          v-text="t('general.here')" />
      </p>
    </div>
    <div
      v-if="props.order.complaintUntil"
      class="orders-item orders-item--more-options orders-item--complaints">
      <span
        v-html="
          t('profile.orders.orderComplaintText', {
            time: formatDate(props.order.complaintUntil, { showYear: true }),
          })
        " />
      <BaseButton
        entity="router-link"
        :to="{
          name: 'NewComplaint',
          params: {
            orderNumber: props.order.number,
          },
        }"
        variant="peach"
        class="orders-item__button"
        pill>
        {{ t('profile.orders.orderComplaintButton') }}
        <Icon
          icon="arrow-button"
          class="orders-item__icon" />
      </BaseButton>
    </div>
  </div>
</template>

<style src="./styles.scss" lang="scss" scoped />
