<script setup lang="ts">
import { computed } from 'vue'

import { FormText } from '@/components/Form'
import type { BenjaminekChild } from '@/data/api/frontApi/benjaminek/benjaminekTypes'
import { t } from '@/services/translations'
import {
  defineMax,
  defineRequired,
  defineValidateDate,
  defineValidatePersonName,
} from '@/utils/validations/rules'

type BenjaminekAddChildInputsProps = {
  modelValue: BenjaminekChild
}
const props = defineProps<BenjaminekAddChildInputsProps>()

const emit = defineEmits<{
  'update:modelValue': [newValue: BenjaminekChild]
}>()

const child = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

defineRequired()
defineMax()
defineValidateDate()
defineValidatePersonName()

const validationRules = {
  name: {
    required: true,
    max: 200,
    validatePersonName: true,
  },
  birthDate: {
    required: true,
    validateDate: {
      allowFutureDate: false,
      maxYears: 10,
    },
  },
} as const
</script>

<template>
  <FormText
    v-model="child.name"
    :label="t('kidsClub.name.label')"
    :placeholder="t('kidsClub.name.placeholder')"
    :rules="validationRules.name" />
  <FormText
    v-model="child.birthDate"
    :label="t('kidsClub.dateOfBirth.label')"
    :placeholder="t('kidsClub.dateOfBirth.placeholder')"
    :rules="validationRules.birthDate" />
</template>
