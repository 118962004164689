<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import { RoundedPill } from '@/componentsPure/RoundedPill'
import { t } from '@/services/translations'

import useAddressSelectorButton from './useAddressSelectorButton'

const { buttonText, hasFreeDeliveryTag, earliestTimeslotsValid, open } = useAddressSelectorButton()
</script>

<template>
  <button
    type="button"
    data-tid="destination-address__open-modal"
    class="address-btn"
    @click="open('home-button')">
    <div class="d-flex flex-column">
      <div
        v-if="earliestTimeslotsValid"
        class="d-flex align-items-end">
        <span class="text-2 text-brand font-weight-bold">
          {{ t('addressSelector.buttonDelivery') }}
        </span>
        <RoundedPill
          v-if="hasFreeDeliveryTag"
          class="ml-1">
          {{ t('general.gratis') }}
        </RoundedPill>
      </div>
      <div class="d-flex">
        <div class="address-btn__title">
          {{ buttonText }}
        </div>
        <Icon
          icon="arrow-button"
          class="address-btn__arrow" />
      </div>
    </div>
  </button>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.address-btn {
  display: flex;
  align-items: center;
  padding: 0;
  color: color('brand');
  background: color('white');
  border: 0;

  &__icon {
    width: 37px;
    height: 37px;
    margin-right: 6px;
    margin-bottom: 4px;
  }

  &__arrow {
    margin-left: 4px;
  }

  &__title {
    @include make-font-size(3);

    font-weight: var(--fontWeightBold);
    color: color('primary');
    text-decoration: underline;
    text-underline-offset: 0.2em;
    letter-spacing: -0.045em;
  }

  &:hover & {
    &__title {
      text-decoration: none;
    }
  }
}
</style>
