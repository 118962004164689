<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { NavTab } from '@/components/Nav'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { analyticsLoggerWidgetContent } from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useProductsStore from '@/store/pinia/useProductsStore'

import Widget from './components/Widget.vue'

const YOTTLY_CODES = ['cartComplement']

const loadingWidgetsMap = {}

type WidgetTabbedProps = {
  codes: string[]
}

const props = defineProps<WidgetTabbedProps>()

const { fetchProductWidget, fetchPersonalizedProducts } = useProductsStore()

const getNavItems = computed(() => {
  return props.codes.map((code) => ({
    name: code,
    formValue: code,
    label: t(`widget.WidgetTabbed.${code}`),
  }))
})

const products = ref({})
const yottlyProductIds = ref<number[]>([])
const activeCode = ref(props.codes[0])

const interactionSourceData = computed(() => ({
  code: activeCode.value,
}))

const yottly = computed(() => ({
  products: yottlyProductIds.value,
}))

useInteractionSource('WidgetTabbed', interactionSourceData, yottly)

async function handleWidgetCodeChange(code = activeCode.value) {
  if (products.value[code]?.length || loadingWidgetsMap[code]) {
    return
  }

  loadingWidgetsMap[code] = true
  let newProducts: Product[]

  if (YOTTLY_CODES.includes(code)) {
    const fetchedPersonalizedProducts = await fetchPersonalizedProducts(code)

    yottlyProductIds.value = fetchedPersonalizedProducts.products.map(
      (product: Product) => product.id,
    )
    newProducts = fetchedPersonalizedProducts.products
  } else {
    const fetchedProducts = await fetchProductWidget({ code })
    analyticsLoggerWidgetContent(fetchedProducts)

    newProducts = fetchedProducts.products
  }

  loadingWidgetsMap[code] = false
  products.value[code] = newProducts
}

watch(() => activeCode.value, handleWidgetCodeChange)
</script>

<template>
  <div :data-tid="$options.name">
    <NavTab
      v-if="props.codes.length > 1"
      v-model="activeCode"
      :items="getNavItems"
      class="mb-2" />
    <h3
      v-else
      class="text-center text-7 my-3"
      v-text="getNavItems[0].label" />

    <Widget
      class="widget-tabbed bg-gray-lighter p-3"
      :products="products[activeCode]"
      :data-tid="activeCode"
      @intersecting="handleWidgetCodeChange" />
  </div>
</template>

<style lang="scss" scoped>
.widget-tabbed {
  border-radius: var(--borderRadiusMedium);
}
</style>
