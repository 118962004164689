<script setup lang="ts">
import ProductItemBox from '@/components/ProductItemBox'
import type { Product } from '@/composables/useProductItem'

type TopProductProps = {
  product: Product | null
}

const props = defineProps<TopProductProps>()
</script>

<template>
  <div v-if="props.product">
    <ProductItemBox :product="props.product" />
  </div>
</template>
