<script setup lang="ts">
import TransitionRender from '@/components/global/TransitionRender.vue'

const backgroundClassNamesMap = {
  success: 'bg-green-light',
  error: 'bg-background-error',
  warning: 'bg-yellow-light',
  default: '',
} as const

type ProductGroupProps = {
  title: string
  state?: 'success' | 'error' | 'warning'
  large?: boolean
  smallHeader?: boolean
  rounded?: boolean
  gifts?: boolean
}

const props = defineProps<ProductGroupProps>()
</script>

<template>
  <TransitionRender>
    <div
      :class="[
        'product-group',
        backgroundClassNamesMap[props.state ?? 'default'],
        {
          'product-group--large': props.large,
          'product-group--rounded': props.rounded,
        },
      ]"
      :data-tid="`product-group${props.gifts ? '-gifts' : ''}`">
      <div :class="['product-group__header', props.smallHeader && 'product-group__header--sm']">
        <slot name="header">
          <p
            v-if="props.title"
            :class="{
              'product-group__title mb-1': true,
              'text-red': !props.large && props.state === 'error',
            }"
            v-text="props.title" />
          <slot name="headerContent" />
        </slot>
      </div>
      <div class="product-group__content">
        <slot name="content" />
      </div>
    </div>
  </TransitionRender>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-group {
  $this: &;
  $rounded-radius: 8px;

  padding: 4px;
  background: color('gray-lighter');

  &--large {
    position: relative;
    display: flex;
    padding: 16px 16px 16px 24px;

    & + &::before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 97%;
      height: 1px;
      content: '';
      background: color('white');
      transform: translateX(-50%);
    }

    #{$this}__header {
      min-width: 240px;

      @include make-font-scale(4);

      @include media-breakpoint-up(lg) {
        width: 25%;

        &--sm {
          width: 20%;
          min-width: auto;
        }
      }
    }

    #{$this}__title {
      font-weight: normal;

      @include make-font-scale(5);

      &::before,
      &::after {
        display: none;
      }
    }
  }

  &--rounded {
    &:first-child {
      border-top-left-radius: $rounded-radius;
      border-top-right-radius: $rounded-radius;
    }

    &:last-child {
      border-bottom-right-radius: $rounded-radius;
      border-bottom-left-radius: $rounded-radius;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    font-weight: var(--fontWeightBold);
    color: color('gray-darker');

    @include make-font-scale(3);

    &::before,
    &::after {
      flex-grow: 1;
      height: 1px;
      content: '';
      background: color('gray-light');
    }

    &::before {
      margin-right: 16px;
    }

    &::after {
      margin-left: 16px;
    }
  }
}
</style>

<style lang="scss">
.product-group__content {
  flex: 1;

  & > * + * {
    margin-top: 4px;
  }
}
</style>
