import { ref, watch } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { manifestQueryKeys } from '@/constants/queryKeys'
import getBuildManifest from '@/data/getBuildManifest'

/**
 * Checks for new release of front-end every 30 seconds.
 * @param callbackFn Callback function called when new release is detected.
 */
function useReleaseChecker(callbackFn: () => void) {
  const lastCheckedVersion = ref<string | null>(null)

  const { data: releaseVersion } = useQuery({
    queryKey: manifestQueryKeys.all,
    queryFn: getBuildManifest,
    refetchInterval: 1000 * 30, // 30 seconds
    refetchOnWindowFocus: true,
  })

  // watch releaseVersion to change
  watch(releaseVersion, () => {
    // No release version detected, skip.
    if (typeof releaseVersion.value !== 'string') return

    // Same release version dected, skip.
    if (lastCheckedVersion.value === releaseVersion.value) return

    // Release version detected first time, save it's value and skip.
    if (lastCheckedVersion.value === null) {
      lastCheckedVersion.value = releaseVersion.value
      return
    }

    // Version has changed, save new value and run callback.
    lastCheckedVersion.value = releaseVersion.value
    callbackFn()
  })
}

export default useReleaseChecker
