import { ref, onMounted } from 'vue'

import { analyticsLoggerPayment } from '@/services/analytics/analyticsLogger'
import {
  webApiGetApplePayMerchantValidation,
  webApiGetApplePayPaymentRequest,
  webApiPostApplePayPaymentAuthorization,
} from '@/services/api/web/applepay'
import { webApiGetApplePayPaymentStatus } from '@/services/api/web/order'

import { defaultPaymentErrorRedirect } from './paymentConstants'

/**
 * Device supports Apple pay && it supports desired version of Apple Pay JS API
 */
function canMakePaymentsWithApplePay() {
  return window.ApplePaySession?.canMakePayments() && window.ApplePaySession?.supportsVersion(4)
}

async function getPaymentRequest() {
  const { data } = await webApiGetApplePayPaymentRequest()
  return data
}

/**
 * @see https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api
 */
async function authorizeApplePayPayment(applePayPaymentRequest) {
  analyticsLoggerPayment('apple_pay')

  // Apple Pay is not availiable OR we don't have needed data for Apple Pay Wallet initialization
  if (!canMakePaymentsWithApplePay() || !applePayPaymentRequest) {
    window.location.replace(defaultPaymentErrorRedirect)
  }

  const session = new window.ApplePaySession(4, applePayPaymentRequest)

  // An event handler the system calls when it displays the payment sheet.
  session.onvalidatemerchant = async function (event) {
    try {
      const { data } = await webApiGetApplePayMerchantValidation(event.validationURL)

      session.completeMerchantValidation(data)
    } catch (err) {
      session.abort()
      window.location.replace(defaultPaymentErrorRedirect)
    }
  }

  // An event handler the system calls when the user has authorized the Apple Pay payment with Touch ID, Face ID, or a passcode.
  session.onpaymentauthorized = async function (event) {
    try {
      const { data } = await webApiPostApplePayPaymentAuthorization(event.payment.token)

      // Fingerprint is needed, so we redirect to fingerprint component
      if (data.authLinks) {
        session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS })
        top.location.href = data.authLinks.redirectUrl
      }

      // Didn't need fingerprint, but some error occured on server
      if (data.status !== 'ok') {
        session.abort()
        window.location.replace(data.redirectUrl)
      }

      const interval = setInterval(() => {
        const intervalFn = async () => {
          const paymentStatus = await webApiGetApplePayPaymentStatus(data.orderId)
          if (paymentStatus.data.approved) {
            session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS })
            clearInterval(interval)
            window.location.replace(data.redirectUrl)
          }
        }
        intervalFn()
      }, 2000)
    } catch (err) {
      session.abort()
      window.location.replace(defaultPaymentErrorRedirect)
    }
  }

  // An event handler that is automatically called when the payment UI is dismissed.
  session.oncancel = function () {
    window.location.replace(defaultPaymentErrorRedirect)
  }

  // Begins the merchant validation process.
  // When this method is called, the payment sheet is presented and the merchant validation process is initiated.
  session.begin()
}

/**
 * @deprecated Used in Checkout 1.0. Will be deleted after Checkout 2.0 is released.
 */
function usePaymentApplePayOld() {
  const applePayPaymentRequest = ref(undefined)

  async function applePay() {
    if (canMakePaymentsWithApplePay()) {
      const applePayPayments = document.getElementsByClassName('apple-pay-button-payment-box')
      const applePayButtons = document.getElementsByClassName('apple-pay-button')

      if (applePayPayments.length) {
        for (const applePayPayment of applePayPayments) {
          applePayPayment.classList.add('radio-box')
        }
      }

      if (applePayButtons.length) {
        applePayPaymentRequest.value = await getPaymentRequest()

        for (const applePayButton of applePayButtons) {
          applePayButton.addEventListener('click', () => {
            authorizeApplePayPayment(applePayPaymentRequest.value)
          })
        }
      }
    } else {
      const applePayName = 'Apple Pay'
      const applePayPayment = document.querySelector('.cart-summary-box__payment-method')

      if (applePayPayment !== null && applePayName === applePayPayment.innerHTML.trim()) {
        window.location.replace(defaultPaymentErrorRedirect)
      }
    }
  }

  onMounted(() => applePay())
}

export { usePaymentApplePayOld }
