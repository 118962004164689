import { useRoute } from 'vue-router'

import { useMutation } from '@tanstack/vue-query'

import api from '@/data/api'
import type { ClientAffiliateRequest } from '@/data/api/frontApi/client/clientTypes'

function useAffiliate() {
  const route = useRoute()
  const { cjevent } = route.query

  const affiliateMutation = useMutation({
    mutationFn: (data: ClientAffiliateRequest) =>
      api.front.client.affiliate({
        body: data,
      }),
  })

  return (uuid: string | null | undefined) => {
    if (typeof cjevent !== 'string') return

    // If cjevent is set in URL's query string,
    // this will connect anonymous user with the affiliate code.
    affiliateMutation.mutate({
      value: cjevent,
      uuid,
    })
  }
}

export default useAffiliate
