<script setup>
import { ref, onMounted } from 'vue'

import InputTel from '../InputTel.vue'

const props = defineProps({
  propPhonePrefixes: {
    type: String,
    required: true,
  },
  propInputId: {
    type: String,
    default: '',
  },
  propInputDataTid: {
    type: String,
    default: '',
  },
  // bugfix for latte https://mallfresh.atlassian.net/browse/KNW-15316
  shouldCalculateHeight: {
    type: Boolean,
    default: false,
  },
})

const phonePrefixes = ref([])
phonePrefixes.value = JSON.parse(props.propPhonePrefixes)

const inputInLatte = ref(null)
const slotWrapper = ref(null)
const phone = ref('')
onMounted(() => {
  inputInLatte.value = slotWrapper.value.firstElementChild
  const startPhone = inputInLatte?.value.value

  if (startPhone) {
    phone.value = startPhone
  }

  if (inputInLatte.value) {
    inputInLatte.value.type = 'hidden'
  }
})

const setValueToLatte = () => {
  if (inputInLatte.value) {
    inputInLatte.value.value = phone.value.replace(/ /g, '')
  }
}
</script>

<template>
  <div>
    <InputTel
      v-if="inputInLatte"
      v-model="phone"
      :prop-phone-prefixes="phonePrefixes"
      :prop-input-id="propInputId"
      :prop-positive-validation="false"
      :prop-input-data-tid="propInputDataTid"
      :should-calculate-height="props.shouldCalculateHeight"
      @change="setValueToLatte" />
    <div ref="slotWrapper">
      <slot />
    </div>
  </div>
</template>
