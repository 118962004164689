<script setup lang="ts">
import { computed } from 'vue'

import makeImgOptions from '@/components/ProductSearch/makeImgOptions'
import { BaseButton } from '@/componentsPure'
import { type VendorID, Vendors } from '@/constants/vendors'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'

type MoreProductsItemBoxProps = {
  vendorId: VendorID
  productCount: number
  searchTerm: string
}

const emit = defineEmits<{ navigate: [] }>()

const props = defineProps<MoreProductsItemBoxProps>()

const isPharmacy = computed(() => props.vendorId === Vendors.PHARMACY)
const domain = appConfig.domain as 'cz' | 'bg' | 'sk'

const img = computed(() => makeImgOptions(isPharmacy.value, domain))

const productMessage = computed(() => {
  return props.productCount === 100
    ? t('productSearch.moreProductsItemBox.maxProducts')
    : t('productSearch.moreProductsItemBox.products', { count: props.productCount })
})

const whereMessage = computed(() => {
  return isPharmacy.value
    ? t('productSearch.moreProductsItemBox.wherePharmacy')
    : t('productSearch.moreProductsItemBox.whereMarket')
})
</script>

<template>
  <div :class="['box', img.class]">
    <img
      :alt="img.alt"
      :src="img.src"
      class="logo" />
    <div class="messages w-100 mb-2">
      <div class="count font-weight-bolder">{{ productMessage }}</div>
      <div class="message mb-5 font-weight-bold mt-1">{{ whereMessage }}</div>
      <BaseButton
        entity="router-link"
        :to="{
          name: 'ProductSearchResults',
          query: { search: searchTerm, vendor: vendorId },
        }"
        class="nowrap px-4 py-2"
        :variant="isPharmacy ? 'green' : 'brand'"
        size="sm"
        data-tid="other-products__button-link"
        @click="emit('navigate')">
        {{ t('general.showAll') }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.box {
  position: relative;
  width: $productItemBoxWidth;
  height: $productItemBoxHeight;
  transition:
    box-shadow var(--baseTransitionTime),
    z-index var(--baseTransitionTime);

  .messages {
    position: absolute;
    bottom: 0;
    color: color('brand');

    .count {
      @include make-font-scale(6);
    }

    .message {
      @include make-font-scale(2);
    }
  }

  &.pharmacy {
    background: color('mint-cream');

    .logo {
      width: 140px;
      margin-top: 84px;
      background: white;
      border: 2px solid color('gray', 0.7);
      border-radius: var(--topbarFieldAndButtonHeight);
    }
  }

  &.kosik {
    background: color('pink-pale');

    .logo {
      width: 142px;
      margin-top: 40%;
    }
  }

  @include media-breakpoint-up(lg) {
    height: $productItemBoxHeightLg;
  }

  @include media-breakpoint-only(xl) {
    &:hover {
      z-index: $zix-product--xl;
      box-shadow: 5px 5px 30px 0 color('black', 0.1);
    }
  }
}
</style>
