<script setup lang="ts">
import Popup from '@/components/global/Popup/Popup.vue'
import { t } from '@/services/translations'

type LimitDayProps = {
  modalName: string
}

const props = defineProps<LimitDayProps>()
</script>

<template>
  <div>
    <p class="tw-mb-0 tw-font-bold tw-text-ds-accent-blue">
      <slot name="claim" />

      <Popup
        control
        :name="props.modalName">
        <a
          href="#"
          class="text-decoration-none text-nowrap">
          <span
            class="d-inline-block"
            v-text="t('general.moreInfo')" />
          <span
            class="a-icon-round d-inline-block"
            v-text="'i'" />
        </a>
      </Popup>
    </p>

    <Popup
      v-slot="{ close }"
      content
      size="small"
      :name="props.modalName">
      <div class="a-popup-message">
        <span class="a-popup-message__title">
          <slot name="modalTitle" />
        </span>

        <slot name="modalContent" />

        <button
          class="btn btn--white btn__rounded a-popup-message__btn"
          type="button"
          @click="close">
          {{ t('general.close') }}
        </button>
      </div>
    </Popup>
  </div>
</template>
