<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'

import AddressInputBox from '@/components/AddressInputBox/AddressInputBox.vue'
import { t } from '@/services/translations'

import useLatteInputAddress from './useLatteInputAddress'

const inputAddressRef = ref<HTMLDivElement | null>(null)

const { addressForm, initAddressForms } = useLatteInputAddress()

// we really need to update the form with spread op. when InputAddress mutable changes it :(
function updateAddressForm() {
  addressForm.value = { ...addressForm.value }
}

onMounted(() => {
  initAddressForms(inputAddressRef)
})

// check only the gps position for updating hidden inputs in latte
watch(() => addressForm.value.positionGps, updateAddressForm)
</script>

<template>
  <div ref="inputAddressRef">
    <p>
      {{ t('addressSelector.address') }}
    </p>
    <AddressInputBox
      v-model="addressForm"
      show-map
      editable-map
      prefiled
      @address-validated="updateAddressForm" />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
:deep(.delivery-on-location-map) {
  margin-top: 28px;
}
</style>
