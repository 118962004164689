<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { StatusMessage } from '@/componentsPure'
import { frontApiGetInfoNotices, type Notice } from '@/services/api/front/info'

type ParsedNotice = Notice & { expand?: boolean }

const emit = defineEmits<{
  loaded: []
}>()

const router = useRouter()
const noticesToShow = ref<ParsedNotice[]>([])

async function fetchNotices() {
  const url = router.currentRoute.value.path
  const { data } = await frontApiGetInfoNotices({ url })

  noticesToShow.value = data.notices
  emit('loaded')
}
fetchNotices()
</script>

<template>
  <StatusMessage
    v-for="(notice, index) in noticesToShow"
    :key="index"
    :message="notice.message"
    :severity="notice.type" />
</template>
