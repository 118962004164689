<script setup lang="ts">
import { computed } from 'vue'

import { BaseTag } from '@/componentsPure'
import { useProductItem, type Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import localizePrice from '@/utils/localizePrice'

import ProductRow from '../components/Row/ProductRow.vue'

type ProductReplacingProps = {
  product: Product
  large?: boolean
  productOriginalQuantity: number
  limitedDelivery?: boolean
}

const props = defineProps<ProductReplacingProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const productRef = computed(() => props.product)

const { isSoldOut } = useProductItem(productRef)

const getTextationFirstPart = computed(() => {
  if (props.limitedDelivery) {
    return t('unavailableProducts.limitedDelivery')
  }
  if (isSoldOut.value) {
    return t('unavailableProducts.soldOut')
  }
  return t('unavailableProducts.remainingQuantity', {
    quantity: props.product.computed.quantity,
  })
})

const getTextationSecondPart = computed(() => {
  const text = isSoldOut.value
    ? t('unavailableProducts.originallyQuantity', {
        quantity: props.productOriginalQuantity,
      })
    : t('unavailableProducts.insteadQuantity', {
        quantity: props.productOriginalQuantity,
      })
  return `(${text})`
})
</script>

<template>
  <ProductRow
    class="product-replacing"
    :product="props.product"
    detail-disabled
    :large="props.large"
    has-error>
    <template #top>
      <div>
        <BaseTag
          v-if="props.product.origin.percentageDiscount"
          class="product-replacing__discount-tag"
          modifier="strong red">
          {{ `&minus;${props.product.origin.percentageDiscount}&nbsp;&percnt;` }}
        </BaseTag>
      </div>
    </template>

    <template #nameAdditional>
      <p class="text-red text-2 mb-0">
        <strong v-text="getTextationFirstPart" />
        <span
          v-if="!props.limitedDelivery"
          v-text="getTextationSecondPart" />
      </p>
    </template>

    <template #summary>
      <p
        :class="['text-gray mb-0', deviceDetectorStore.breakpointUpMd ? 'text-2' : 'text-1']"
        v-text="
          `${localizePrice(props.product.computed.pricePerUnit.price)}/${
            props.product.computed.pricePerUnit.unit
          }`
        " />
      <p
        class="font-weight-bold text-4 mb-0"
        v-text="localizePrice(props.product.computed.price)" />
    </template>
  </ProductRow>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-replacing {
  &__discount-tag {
    position: relative;
    top: -$product-row-spacing;
    left: -$product-row-spacing;
    z-index: $zix-base;
  }
}
</style>
