import { computed } from 'vue'

/// we import router like this as useRoute works only in components
import router from '@/router'

const route = router.currentRoute
const hasHidingMenu = computed(() => !!route.value.meta.hasHidingMenu)
// @TODO remove this and use e.g. "route.name === 'Checkout'" instead when Checkout 2.0 released, thus only that one route will have custom heading
const customLogoHeadingKey = computed(() => route.value.meta.customLogoHeadingKey)
const hasSimpleHeaderLayout = computed(() => !!route.value.meta.hasSimpleHeaderLayout)
const hasSimpleFooterLayout = computed(() => !!route.value.meta.hasSimpleFooterLayout)
// @TODO remove this when Checkout 2.0 is released, since all routes will have footer. Remove that meta field too. Possibly merge hasSimpleHeaderLayout and hasSimpleFooterLayout into single hasSimpleLayout as it originally was.
const hasFooter = computed(() => !route.value.meta.hasFooterHidden)
const hasHeader = computed(() => !route.value.meta.hasHeaderHidden)
const hasGlobalModals = computed(() => !route.value.meta.hasGlobalModalsHidden)

export function useRouteMeta() {
  return {
    hasHidingMenu,
    customLogoHeadingKey,
    hasSimpleHeaderLayout,
    hasSimpleFooterLayout,
    hasFooter,
    hasHeader,
    hasGlobalModals,
  }
}
