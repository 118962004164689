<script setup lang="ts">
import { computed } from 'vue'

import { useRouteMeta } from '@/composables/useRouteMeta'
import { useVendor } from '@/composables/useVendor'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type MainLogoProps = {
  allowSymbolLogo?: boolean
}

const props = defineProps<MainLogoProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const { vendorConfig } = useVendor()
const { customLogoHeadingKey } = useRouteMeta()

const brandLogo = computed(() =>
  // Full country-specific logo can be displayed only when the page the page does not have custom logo heading AND (on XL breakpoint up OR symbol logo disallowed OR not having custom vendor)
  !customLogoHeadingKey.value &&
  (deviceDetectorStore.breakpointUpXl || !props.allowSymbolLogo || !customVendorLogo.value)
    ? (appConfig.domain as 'cz' | 'bg' | 'sk')
    : 'symbol',
)
const customVendorLogo = computed(() => vendorConfig.value?.logo ?? false)
</script>

<template>
  <div class="d-flex align-items-center">
    <RouterLink
      :to="{ name: 'Home' }"
      :class="['mr-2', 'app-logo', `app-logo--${brandLogo}`]">
      <img
        :src="`/vue/img/logo-${brandLogo}.svg?v=9`"
        :alt="t('general.brand')"
        class="h-100" />
    </RouterLink>
    <h1
      v-if="customLogoHeadingKey"
      class="font-weight-bold text-5 mb-0 text-brand"
      v-text="t(customLogoHeadingKey)" />
    <img
      v-if="customVendorLogo"
      :alt="`${customVendorLogo} logo`"
      :src="`/vue/img/logo-${customVendorLogo}.svg?v=1`"
      class="vendor-logo" />
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

$logoSizingMobile: 28px;

.app-logo,
.vendor-logo {
  height: $logoSizingMobile;

  @include media-breakpoint-up(lg) {
    height: var(--topbarFieldAndButtonHeight);
  }
}

.app-logo {
  &--symbol {
    width: $logoSizingMobile;
  }

  &--cz {
    width: 111px;
  }

  &--bg {
    width: 138px;
  }

  @include media-breakpoint-up(xl) {
    &--symbol {
      width: var(--topbarFieldAndButtonHeight);
    }

    &--cz {
      width: 140px;
    }

    &--bg {
      width: 183px;
    }
  }
}

.vendor-logo {
  border: 1px solid color('gray', 0.7);
  border-radius: var(--topbarFieldAndButtonHeight);
}
</style>
