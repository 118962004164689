<script setup lang="ts">
import { inject } from 'vue'

import States from '@/components/Notification/States'
import NotificationMessage from '@/components/Notification/components/NotificationMessage.vue'
import { isButtonKey } from '@/components/Notification/notificationProviderKey'
import type {
  NotificationActionName,
  Notification,
} from '@/components/Notification/notificationTypes'
import useUser from '@/composables/useUser'
import api from '@/data/api'
import { analyticsLoggerNotificationClick } from '@/services/analytics/analyticsLogger'

type NotificationListProps = {
  notifications: Notification[]
}

const props = defineProps<NotificationListProps>()

const isButton = inject(isButtonKey)

const emit = defineEmits<{
  remove: [notificationId: Notification['id']]
}>()

function removeById(notificationId: Notification['id']) {
  emit('remove', notificationId)
}

const { getUserId: customerId } = useUser()

const changedNotificationStateIds: Notification['id'][] = []

async function setStateToRead(notificationId: Notification['id']) {
  if (!changedNotificationStateIds.includes(notificationId)) {
    await api.notifications.notifications.notificationsState({
      body: { state: States.CLICKED, notificationIds: [notificationId] },
      queryParams: { customerId },
    })
    changedNotificationStateIds.push(notificationId)
  }
}

function handleClickMessage(notification: Notification) {
  let actionName: NotificationActionName = 'generic'

  if (notification.parameters.redirectUrl) {
    actionName = 'redirectURL'
  } else if (notification.parameters.copyBox) {
    actionName = 'copyBox'
  }

  analyticsLoggerNotificationClick(actionName, notification)
}
</script>

<template>
  <template v-if="props.notifications.length">
    <NotificationMessage
      v-for="(notification, index) in props.notifications"
      :key="notification.id"
      :is-new="notification.state === States.TARGETED || notification.state === States.SEEN"
      :is-read="notification.state === States.CLICKED"
      :notification-id="notification.id"
      :title="notification.title"
      :description="notification.description"
      :image-url="notification.parameters.imageUrl"
      :validity-to="notification.validityTo"
      :hide-validity="notification.hideValidity"
      :redirect-url="notification.parameters.redirectUrl"
      :copy-value="notification.parameters.copyBox"
      :class="[
        'message',
        {
          'mt-2': index > 0,
          'message-top-border': !isButton && index === 0,
        },
      ]"
      @remove="removeById"
      @message-read="setStateToRead"
      @click="handleClickMessage(notification)" />
  </template>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.content-divider {
  line-height: 0.5em;
  text-align: center;
  border-bottom: 1px solid color('gray-light');
}

.message-top-border {
  padding-top: 10px;
  border-top: 1px solid color('gray-lighter');
}

.message {
  margin: 0 5px;
}
</style>
