<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import useReturnableCarrier from '@/composables/product/useReturnableCarrier'
import useInteractionSource from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import { t } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

type ProductReturnableItemsProps = {
  product: Product
  large?: boolean
  readonly?: boolean
  carrierQuantity?: number
}

const props = defineProps<ProductReturnableItemsProps>()

const getInteractionSource = useInteractionSource('returnableItems', {
  element: 'checkbox_returnable_item',
})

const { inputCarrierValue, getCarrierQuantity } = useReturnableCarrier(
  props.product,
  getInteractionSource(),
)

const getPackagesPrice = computed(
  () => props.product.origin.returnablePackagePrice * props.product.computed.quantity,
)

const isPackage = computed(() => props.product.origin.returnablePackagePrice > 0)

const isCarrier = computed(() => props.product.origin.returnableCarrier)

const getCarrierUnitPrice = computed(() => props.product.origin.returnableCarrier?.price ?? 0)
</script>

<template>
  <div
    :class="[
      'returnable',
      {
        'returnable--smaller': !props.large,
      },
    ]">
    <Icon
      v-if="isCarrier"
      :icon="'bottles' + (getCarrierQuantity ? '-in-crate' : '')"
      group="checkout"
      class="returnable__icon" />
    <div
      v-if="isPackage"
      key="packages"
      class="d-flex align-items-center">
      <span class="flex-grow-1">
        <label
          :class="[
            'a-check',
            {
              'a-check--small-gap': !props.large,
            },
          ]">
          <input
            class="a-check__input"
            type="checkbox"
            disabled
            checked />
          <span class="a-check__box a-check__box--disabled">
            <Icon
              icon="checkbox-ok"
              class="a-check__icon" />
          </span>

          {{ t('product.packagesTitle') }}
        </label>
      </span>

      <strong
        class="returnable__price ml-1 text-nowrap"
        v-text="localizePrice(getPackagesPrice)" />
    </div>

    <div
      v-if="props.product.origin.returnableCarrier"
      key="carrier"
      class="d-flex align-items-center mt-1">
      <span class="flex-grow-1">
        <label
          :class="[
            'a-check',
            {
              'a-check--small-gap': !props.large,
            },
          ]">
          <input
            v-model="inputCarrierValue"
            class="a-check__input"
            type="checkbox"
            :disabled="props.readonly" />
          <span
            :class="[
              'a-check__box',
              {
                'a-check__box--disabled': props.readonly,
              },
            ]">
            <Icon
              icon="checkbox-ok"
              class="a-check__icon" />
          </span>
          {{ t('product.carriersTitle') }}

          <span class="text-nowrap">
            {{
              `(${getCarrierUnitPrice} ${t('general.currency')}/${t('general.piecesShortened')})`
            }}
          </span>
        </label>
      </span>

      <strong
        class="returnable__price ml-1 text-nowrap"
        v-text="localizePrice(getCarrierUnitPrice * getCarrierQuantity)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.returnable {
  position: relative;
  padding-left: 82px;

  @include make-font-scale(4);

  &__icon {
    position: absolute;
    top: 6px;
    left: 24px;
    width: 31px;
    height: 34px;
    color: color('gray-light');
  }

  &__price {
    white-space: nowrap;

    @include make-font-scale(5);
  }

  &--smaller {
    padding-left: 60px;

    @include make-font-scale(2);
  }

  &--smaller & {
    &__icon {
      left: 14px;
      width: 28px;
      height: 31px;
    }

    &__price {
      @include make-font-scale(3);
    }
  }
}
</style>
