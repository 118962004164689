<script setup lang="ts">
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { analyticsLoggerBannerImpression } from '@/services/analytics/analyticsLogger'
import { resizer } from '@/services/resizer'

import ImgResponsive from '../stories/atoms/imgResponsive/ImgResponsive.vue'

type BannerGridProps = {
  banners: Banner[]
  paddings?: boolean
}

const props = defineProps<BannerGridProps>()
</script>
<template>
  <div
    class="wrapper"
    :class="{ 'wrapper--paddings': props.paddings }">
    <div
      v-for="(banner, index) in props.banners"
      :key="banner.id"
      :class="{
        'grid-item': true,
        'grid-item--main': index === 0,
        'grid-item--secondary': index !== 0,
      }">
      <a
        :href="banner.link"
        @click="
          analyticsLoggerBannerImpression('select_promotion', banner, 'webBigTipMain', index)
        ">
        <ImgResponsive
          v-if="index === 0"
          class="d-block h-100 banner-img mx-auto"
          class-img="banner-img-border w-100 h-100"
          :alt="banner.name"
          :srcset="[
            {
              url: resizer(banner.images.main, 1542, 868, 'SHRINK_ONLY'),
            },
          ]" />
        <ImgResponsive
          v-else
          class="d-block h-100 banner-img mx-auto"
          class-img="banner-img-border w-100 h-100"
          :alt="banner.name"
          :srcset="[
            {
              url: resizer(banner.images.main, 771, 434, 'SHRINK_ONLY'),
            },
          ]" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(lg) {
    margin-top: 16px;
  }

  &--paddings {
    padding: 8px;

    @include media-breakpoint-up(lg) {
      padding: 0 32px;
    }
  }
}

.grid-item {
  &--main {
    @include media-breakpoint-up(lg) {
      grid-row: span 2;
      grid-column: span 2;
    }
  }

  &--secondary {
    grid-column: span 1;
  }
}

:deep(.banner-img-border) {
  border-radius: 8px;
}
</style>
