<script setup lang="ts">
import { computed, provide, ref } from 'vue'

import { storeToRefs } from 'pinia'

import Popup from '@/components/global/Popup/Popup.vue'
import useCheckHaveReloadPage from '@/composables/address/useCheckHaveReloadPage'
import useInteractionSource from '@/composables/useInteractionSource'
import modalIndex from '@/constants/modalIndex'
import { analyticsLoggerModalToggled } from '@/services/analytics/analyticsLogger'
import type { ModalInitiator } from '@/services/analytics/gtm/modal'
import type { UserAddress } from '@/store/pinia/useAddressStore'
import useAddressStore from '@/store/pinia/useAddressStore'
import useModalStore from '@/store/pinia/useModalStore'

import type {
  CheckoutSubDestinationModalSaveOptions,
  CheckoutSubDestinationModalTriggerOptions,
} from '../Checkout/types'
import AddressSelectorEmptyAddress from './AddressSelectorEmptyAddress.vue'
import AddressSelectorFooter from './AddressSelectorFooter.vue'
import AddressSelectorSelectedAddress from './AddressSelectorSelectedAddress.vue'
import {
  hideTimeslotsKey,
  onChangeInputAddressKey,
  saveAddressKey,
} from './addressSelectorProviderKey'
import useReloadPageInfo from './useReloadPageInfo'
import useValidateSubdestination from './useValidateSubdestination'

type AddressSelectorModalTriggerOptions = {
  autoClose?: boolean
  forceRedirect?: boolean
  autoConfirm?: boolean
  continueWithoutAddressButton?: boolean
  hideTimeslots?: boolean
}

const { getModal, closeModal, openModal } = useModalStore()
const addressStore = useAddressStore()
const { changeUserAddress } = addressStore
const { isUserStreetOrCityFilled } = storeToRefs(addressStore)
const checkHaveReloadPage = useCheckHaveReloadPage()
const getReloadPageInfo = useReloadPageInfo()
const { mutateAsync: isSubDestinationOrderThresholdsChange } = useValidateSubdestination()

const showTimeslots = ref(false)
const editAddress = ref(true)

function onOpen() {
  showTimeslots.value = modalTriggerOptions.value?.hideTimeslots
    ? false
    : isUserStreetOrCityFilled.value
}

const modalTriggerOptions = computed(
  () => getModal<AddressSelectorModalTriggerOptions>(modalIndex.addressSelector)?.triggerOptions,
)

const continueWithoutAddressButton = computed(
  () => !!modalTriggerOptions.value?.continueWithoutAddressButton,
)

// whenever content of modal changes, we need to update analytics so it behaves like we close selector and open timeslots
function updateModalAnalytics(timeslotsVisible: boolean, initiator: ModalInitiator) {
  analyticsLoggerModalToggled(
    timeslotsVisible,
    timeslotsVisible ? 'TimeSlots' : 'AddressSelector',
    initiator,
    'switch',
  )
}

function onSaveAddress(saveAddressOptions: CheckoutSubDestinationModalSaveOptions) {
  changeUserAddress({
    address: saveAddressOptions.address,
    autoClose: saveAddressOptions.autoClose,
    forceRedirect: saveAddressOptions.forceRedirect,
    reloadPageInfo: saveAddressOptions.reloadPageInfo,
  })

  showTimeslots.value = true
  updateModalAnalytics(true, 'save-address-button')
}

async function saveAddress(address: UserAddress) {
  const saveAddressOptions: CheckoutSubDestinationModalSaveOptions = {
    address,
    autoClose: modalTriggerOptions.value?.autoClose ?? false,
    forceRedirect: modalTriggerOptions.value?.forceRedirect ?? false,
    reloadPageInfo: getReloadPageInfo(),
  }

  const changeAddressOptions: CheckoutSubDestinationModalTriggerOptions = {
    onSaveAddress: () => onSaveAddress(saveAddressOptions),
  }

  let openSubdestinationModal = false
  try {
    openSubdestinationModal = await isSubDestinationOrderThresholdsChange(address.address)
  } catch (e) {
    // do nothing
  }

  if (openSubdestinationModal) {
    openModal({
      id: modalIndex.addressSelectorSubDestinationSwitch,
      triggerOptions: changeAddressOptions,
    })
  } else {
    onSaveAddress(saveAddressOptions)
  }
}

function hideTimeslots() {
  editAddress.value = true
  showTimeslots.value = false
  updateModalAnalytics(false, 'modal-address-link')
}

function onChangeInputAddress(address: UserAddress) {
  if (modalTriggerOptions.value?.autoConfirm) {
    saveAddress(address)
  } else {
    editAddress.value = false
  }
}

const analyticsId = computed(() => (showTimeslots.value ? 'TimeSlots' : 'AddressSelector'))

provide(hideTimeslotsKey, hideTimeslots)
provide(saveAddressKey, saveAddress)
provide(onChangeInputAddressKey, onChangeInputAddress)

useInteractionSource(modalIndex.addressSelector)

// @fixup this is a HACK! refactor this css https://mallfresh.atlassian.net/browse/KNW-17991
// we dont need fixed height of popup content in modal when long list of timeslots is displayed
const modalCss = computed(() =>
  showTimeslots.value
    ? 'address-selector-modal'
    : 'address-selector-modal address-selector-modal--fixed-height',
)
</script>

<template>
  <Popup
    content
    :name="modalIndex.addressSelector"
    size="large"
    :analytics-id="analyticsId"
    :collision-group="modalIndex.mobileHeader"
    :class-css="modalCss"
    @open="onOpen"
    @close="checkHaveReloadPage">
    <section class="address-selector-modal__content">
      <div>
        <AddressSelectorSelectedAddress
          v-if="showTimeslots"
          @on-close-click="() => closeModal(modalIndex.addressSelector, analyticsId)" />
        <AddressSelectorEmptyAddress
          v-else
          :edit="editAddress"
          class="address-selector-modal__content__empty-address" />
      </div>
      <AddressSelectorFooter :show-close-button="!showTimeslots && continueWithoutAddressButton" />
    </section>

    <!-- <AddressSelectorSubDestinationSwitch /> -->
  </Popup>
</template>

<style lang="scss">
@use '@/legacy' as *;

.address-selector-modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__empty-address {
      padding-bottom: 8px;
      background-color: white;
    }
  }

  &--fixed-height {
    .popup__content {
      height: calc(100% - 24px);
    }
  }

  .popup__content {
    @include media-breakpoint-up(sm) {
      max-width: 85%;
    }

    @include media-breakpoint-up(md) {
      height: unset;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      max-width: calc(100% - 30px);
    }

    .popup__box {
      width: 100%;
      height: 100%;
      padding: 0;
      margin-bottom: var(--liveChatHeight);

      @include media-breakpoint-up(lg) {
        width: 850px;
        margin: 25px;
      }

      @include media-breakpoint-up(xl) {
        width: 1032px;
      }
    }
  }
}
</style>
