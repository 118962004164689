<script setup lang="ts">
import { ref } from 'vue'

import { storeToRefs } from 'pinia'

import { CheckoutSummaryList } from '@/components/Checkout'
import TransitionRender from '@/components/global/TransitionRender.vue'
import { t, tc } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

const productsVisible = ref(false)

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
</script>

<template>
  <div>
    <div class="ml-4 mb-2">
      {{ cartGetters.getCartProductsCount }}
      {{ tc('cart.items', cartGetters.getCartProductsCount) }}
      {{ t('cart.inCart') }}
      <button
        type="button"
        class="btn d-inline-flex p-0"
        data-tid="cart-summary__show-hide-products"
        @click="productsVisible = !productsVisible">
        (
        <u v-text="productsVisible ? t('cart.hide') : t('cart.show')" />
        )
      </button>
    </div>
    <TransitionRender>
      <CheckoutSummaryList
        v-if="productsVisible"
        data-tid="cart-summary-list" />
    </TransitionRender>
  </div>
</template>
