<script setup lang="ts">
import { inject, ref, watch } from 'vue'

import { storeToRefs } from 'pinia'

import AddressInputBox from '@/components/AddressInputBox/AddressInputBox.vue'
import {
  onChangeInputAddressKey,
  saveAddressKey,
} from '@/components/AddressSelector/addressSelectorProviderKey'
import { BaseButton } from '@/componentsPure'
import type { ValidatePhase } from '@/data/api/frontApi/address/addressTypes'
import { t } from '@/services/translations'
import type { UserAddress } from '@/store/pinia/useAddressStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

import AddressSelectorHeaderEmpty from './AddressSelectorHeaderEmpty.vue'

const address = ref<UserAddress>({
  street: '',
  city: '',
  zip: '',
  gps: null,
  address: '',
  note: '',
  positionGps: null,
})

const editMode = ref(false)
const isAddressForSave = ref(false)
const { breakpointDownMd } = storeToRefs(useDeviceDetectorStore())

const saveAddress = inject(saveAddressKey)

function acceptAddress() {
  saveAddress?.(address.value)
}

const onChangeInputAddress = inject(onChangeInputAddressKey)

function handleChangeInputAddress(phase: ValidatePhase) {
  if (phase !== 'available') {
    isAddressForSave.value = false
    return
  }
  isAddressForSave.value = true

  onChangeInputAddress?.(address.value)
}
// save address location when positionGps is set
watch(
  address,
  (newValue) => {
    newValue.positionGps && saveAddress?.(newValue)
  },
  { deep: true },
)
</script>

<template>
  <div>
    <AddressSelectorHeaderEmpty />
    <div class="address-selector-empty-address__body">
      <p class="address-selector-empty-address__title">
        {{ t('addressSelector.startWithBox') }}
      </p>
      <AddressInputBox
        v-model="address"
        :show-map="isAddressForSave"
        show-status-messages
        editable-map
        accept-partial-address
        autofocus
        show-user-addresses
        @address-validated="handleChangeInputAddress">
        <template #submit>
          <BaseButton
            class="address-selector-empty-address__submit"
            variant="super-primary"
            :size="breakpointDownMd ? 'xs' : 'md'"
            data-tid="destination-delivery-confirmation-accept"
            :disabled="!isAddressForSave || editMode"
            @click="acceptAddress">
            {{ t('addressSelector.saveAddress') }}
          </BaseButton>
        </template>
      </AddressInputBox>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-selector-empty-address {
  &__body {
    margin: 20px 10px;

    @include media-breakpoint-up(md) {
      margin: 10% 4%;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 3rem;
      margin: 0 4%;
    }
  }

  &__title {
    padding-top: 0.25rem;
    font-size: get-font-size(5);
    color: color('text-paragraph');

    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }
  }

  &__submit {
    margin-inline: auto;
  }
}

:deep(.address-input-box__input-wrapper) {
  @include media-breakpoint-up(lg) {
    max-width: 638px;
  }
}

:deep(.address-input-box__form) {
  justify-content: center;

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
}

:deep(.delivery-on-location-map) {
  margin-inline: -10px;
  margin-top: 34px;

  @include media-breakpoint-up(md) {
    margin-inline: -4%;
  }
}
</style>
