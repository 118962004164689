<script setup lang="ts">
import { computed } from 'vue'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'

type PriceTransportBarProps = {
  /**
   * Calculates the price for the progress bar display.
   * - If the minimum order price has not been reached, it excludes eLicence products from the total price.
   * - If the minimum order price is met or exceeded, it includes eLicence products in the total price.
   * This helps in dynamically adjusting the progress bar based on the inclusion or exclusion of eLicence products.
   */
  effectivePrice: number
}

const props = defineProps<PriceTransportBarProps>()

const {
  showProgressInfo,
  getMinimalOrderPrice,
  isFreeDelivery,
  isMinimalOrderPrice,
  getFreeDeliveryOrderPrice,
  forAnonymousCustomer,
} = useCartDeliveryData()

const freeDeliveryBar = computed(() => {
  return (isFreeDelivery.value || forAnonymousCustomer.value) && isMinimalOrderPrice.value
})

/**
 * Calculates either the ration of effective price / minimal order price
 * or effective price / free delivery order price. (see effectivePrice prop)
 * If free delivery amount is reached, it returns 100%.
 * @returns The width in percents.
 */
const getProgressBarWidth = computed(() => {
  if (!showProgressInfo.value) return '100%'
  if (getMinimalOrderPrice.value === undefined || getFreeDeliveryOrderPrice.value === undefined) {
    return '0%'
  }

  const barStartPrice = isMinimalOrderPrice.value ? getMinimalOrderPrice.value : 0
  const barEndPrice = isMinimalOrderPrice.value
    ? getFreeDeliveryOrderPrice.value
    : getMinimalOrderPrice.value

  const percentage = Math.ceil(
    Math.min(((props.effectivePrice - barStartPrice) / (barEndPrice - barStartPrice)) * 100, 100),
  )

  return percentage + '%'
})
</script>

<template>
  <div :class="['price-transport-bar overflow-hidden']">
    <div
      :class="[
        'price-transport-bar__progress h-100',
        {
          'bg-primary-mid': !isMinimalOrderPrice,
          'bg-primary': isMinimalOrderPrice,
          'bg-contrast-green': freeDeliveryBar,
        },
      ]"
      :style="{
        width: getProgressBarWidth,
      }" />
  </div>
</template>

<style lang="scss">
@use '@/legacy' as *;

.price-transport-bar {
  height: 6px;
  background-color: color('background-disabled');
  border-radius: 3px;

  &__progress {
    border-radius: 3px;
    transition: width var(--baseTransitionTime);
  }
}
</style>
