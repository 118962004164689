<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'
import { Form as VeeForm } from 'vee-validate'

import { useUserInterface } from '@/components/AppHeader/useUserInterface'
import ProductBoxListing from '@/components/Product/ProductBoxListing.vue'
import { InteractionSource } from '@/componentsPure'
import useInteractionSource from '@/composables/useInteractionSource'
import { useVendor } from '@/composables/useVendor'
import { Vendors } from '@/constants/vendors'
import OtherProductsLink from '@/pages/PageProducts/OtherProductsLink.vue'
import { analyticsLoggerSearch } from '@/services/analytics/analyticsLogger'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore, { type SuggestResponse } from '@/store/pinia/useUserInterfaceStore'

import EmptySearch from './EmptySearch.vue'
import MoreProductsItemBox from './MoreProductsItemBox.vue'
import NoResults from './NoResults.vue'
import ProductSearchGhost from './ProductSearchGhost.vue'
import SearchBar from './SearchBar.vue'
import SearchResultsFound from './SearchResultsFound.vue'
import SeparatorLine from './SeparatorLine.vue'
import { useSuggestionsAnalytics } from './suggestionsAnalytics'
import { useTopItems } from './useTopItems'

const route = useRoute()
const router = useRouter()
const { currentVendorId, isPharmacy } = useVendor()

// @TODO: Convert to vee-validate composables
const formRef: Ref<InstanceType<typeof VeeForm> | null> = ref(null)

const isDirty = ref(false)
const products = ref<any[]>([])
const categories = ref<any[]>([])
const brands = ref<any[]>([])
const otherProducts = ref<SuggestResponse['otherProducts']>({
  productCount: 0,
  vendorId: 1,
})
const hasResultsForThisSearch = ref(false)
const searchTerm = ref('')
const searchTermError = ref('')

const { createModal, removeModal, closeModal } = useModalStore()
const { fetchSuggest } = useUserInterfaceStore()

const { isSuggesterVisible } = useUserInterface()

const isSearchTermValid = computed(
  () => searchTerm.value.trim().length >= 2 && !searchTermError.value,
)

const showResults = computed(() => isSearchTermValid.value && products.value.length > 0)
const showNoResults = computed(
  () => products.value.length === 0 && isSearchTermValid.value && hasResultsForThisSearch.value,
)
const showEmptySuggester = computed(() => searchTerm.value.trim().length < 2)

const hasAnyResults = computed(
  () =>
    !!(
      categories.value.length ||
      products.value.length ||
      brands.value.length ||
      searchTermError.value
    ),
)

const { fetchTopItemsLazy } = useTopItems()

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointUpLg } = storeToRefs(deviceDetectorStore)

function sendForm() {
  if (searchTerm.value.trim() === '') {
    router.push({ name: 'AllProductPage', query: {} }).catch(() => {
      // @TODO To be implemented
    })
  } else if (searchTerm.value !== route.query.search) {
    sendSearchAnalytics()
    router.push({ name: 'ProductSearchResults', query: { search: searchTerm.value } })
  }
  closeSuggester()
}

function sendSearchAnalytics() {
  analyticsLoggerSearch(searchTerm.value, '', lastSearchType.value)
}

function closeSuggester() {
  closeModal('ProductSearch')
  nextTick(() => window.dispatchEvent(new Event('resize')))
}

function clearSearchData() {
  products.value = []
  categories.value = []
  brands.value = []
  isDirty.value = false
}

function performSearch() {
  if (!searchTerm.value.length) {
    clearSearchData()
  } else {
    getDataForSuggestion()
  }
}

async function getFormState() {
  return formRef.value?.validate()
}

const suggestionsAnalytics = useSuggestionsAnalytics()
const { onProductsRender } = suggestionsAnalytics

const suggestionsProductCount = computed(() =>
  breakpointUpLg.value ? appConfig.defaultSuggestionsProductCount : 8,
)

async function getDataForSuggestion() {
  const formState = await getFormState()

  if (!formState?.valid) return

  try {
    const suggestData = await fetchSuggest({
      searchTerm: searchTerm.value,
      vendorId: currentVendorId.value,
      limit: suggestionsProductCount.value,
    })

    products.value = suggestData.products
    categories.value = suggestData.categories
    brands.value = suggestData.brands
    isDirty.value = true
    otherProducts.value = suggestData.otherProducts

    suggestionsAnalytics.registerRenderCallback('current', {
      products: products.value,
      vendorId: currentVendorId.value ?? Vendors.BASE,
      dataSourceEndpoint: suggestData.dataSourceEndpoint,
      searchTerm: searchTerm.value,
    })
  } catch (e) {
    // @TODO
  }
  hasResultsForThisSearch.value = true
}

watch(products, () => {
  if (
    products.value.length >= suggestionsProductCount.value &&
    otherProducts.value.productCount > 0 &&
    !isOtherVendorProductsCountSame.value
  ) {
    products.value.pop()
  }
})

function setSearchTermImmediately(term: string) {
  searchTerm.value = term
  hasResultsForThisSearch.value = false
  performSearch()
}

watch(() => route.path, closeSuggester)

onMounted(() => {
  createModal({
    id: 'ProductSearch',
    isOpen: false,
    collisionGroup: 'MobileHeader',
    hasBackdropScroll: false,
  })
})

onUnmounted(() => {
  removeModal('ProductSearch')
})

const searchTermValue = computed(() => ({
  searchTerm: searchTerm.value,
}))

useInteractionSource('searchSuggestions', searchTermValue)

// analytics
const lastSearchType = ref<'default' | 'voice' | 'multiple'>('default')

watch(currentVendorId, () => {
  performSearch()
})

onMounted(fetchTopItemsLazy)

/**
 * @description The ghost component will display only, when you are writing in the search field, after you stop its hidden
 * showResults - when you have results
 * showNoResults - when you have no results
 * showEmptySuggester - when you have no results and you have not written anything in the search field
 * @returns {boolean}
 */
const isSearchGhostVisible = computed(
  () => !showResults.value && !showNoResults.value && !showEmptySuggester.value,
)

const isNoResultsAnywhere = computed(
  () =>
    !messageTooShort.value &&
    products.value.length === 0 &&
    otherProducts.value.productCount === 0 &&
    !showEmptySuggester.value,
)

const messageTooShort = computed(() => showEmptySuggester.value && isPharmacy.value)

type OutsideParts = 'error' | 'ghost' | 'search-results' | 'no-results-anywhere' | 'too-short'

const outsideParts = computed<OutsideParts[]>(() => {
  const parts: OutsideParts[] = []

  if (searchTermError.value) {
    return ['error']
  }
  if (isSearchGhostVisible.value) {
    return ['ghost']
  }
  if (messageTooShort.value) {
    parts.push('too-short')
  }

  if (isNoResultsAnywhere.value) {
    parts.push('no-results-anywhere')
  } else if (!messageTooShort.value) {
    parts.push('search-results')
  }

  return parts
})

const countOfOtherVendorProducts = computed(
  () =>
    products.value.filter((product) => product.origin.vendorId !== currentVendorId.value).length,
)

const isOtherVendorProductsCountSame = computed(
  () => otherProducts.value.productCount === countOfOtherVendorProducts.value,
)
</script>

<template>
  <div>
    <div
      :class="[
        'product-search',
        {
          'product-search--open': isSuggesterVisible,
        },
      ]">
      <SearchBar
        v-model:search-term="searchTerm"
        v-model:has-results-for-this-search="hasResultsForThisSearch"
        v-model:form-ref="formRef"
        v-model:search-term-error="searchTermError"
        :is-pharmacy="isPharmacy"
        :has-any-results="hasAnyResults"
        :get-form-state="getFormState"
        @close-suggester="closeSuggester"
        @send-form="sendForm"
        @perform-search="performSearch"
        @clear-search-data="clearSearchData" />

      <!--
      **************************
      ******** suggester *******
      **************************
      -->

      <div
        v-if="isSuggesterVisible"
        class="product-search__results"
        data-tid="product-search__results"
        @click.self="closeSuggester">
        <!-- container -->
        <div class="product-search__container pt-md-3 pt-2">
          <!--          <div>{{ outsideParts }}</div>-->
          <!-- render parts in v-for cycle -->
          <div
            v-for="partName of outsideParts"
            :key="partName">
            <!--
            error
            -->
            <div
              v-if="partName === 'error'"
              class="validation-errors text-center"
              data-tid="validation-errors__searchTerm"
              v-text="searchTermError" />

            <!--
            ghost
            -->
            <ProductSearchGhost v-if="partName === 'ghost'" />

            <!--
              too-short-message
              only for pharmacy, replaces waiting for input default layout
              message 'To search for a product, enter at least 2 letters', for pharmacy only.
            -->
            <div
              v-if="partName === 'too-short'"
              class="text-center pt-1 mx-5 text-gray product-search__empty-suggester-pharmacy">
              {{ t('productSearch.emptySuggesterPharmacy') }}
            </div>

            <!--
            no-results-anywhere
            -->
            <div
              v-if="partName === 'no-results-anywhere'"
              class="mx-md-3 mx-2">
              <SeparatorLine
                :show-display-all="false"
                :vendor-id="currentVendorId"
                :search-term="searchTerm" />
              <NoResults
                class="pt-3 pb-3"
                :vendor-id="currentVendorId" />
            </div>

            <!--
            search-results (inside is another loop over innerParts)
            -->

            <div v-if="partName === 'search-results'">
              <!--              <div class="product-search__inner-container">-->
              <div class="mx-md-3 mx-2">
                <EmptySearch
                  v-if="showEmptySuggester && !isPharmacy"
                  @set-search-term-immediately="setSearchTermImmediately" />
                <SearchResultsFound
                  v-else
                  :products="products"
                  :categories="categories"
                  :brands="brands"
                  :search-term="searchTerm"
                  @close-suggester="closeSuggester"
                  @send-search-analytics="sendSearchAnalytics">
                  <!--
                  ****** content (default slot)
                  -->
                  <div class="pl-md-1">
                    <div>
                      <!--
                      current vendor title
                      -->
                      <SeparatorLine
                        :show-display-all="false"
                        :vendor-id="currentVendorId"
                        :search-term="searchTerm"
                        @click="
                          () => {
                            closeSuggester()
                            sendSearchAnalytics()
                          }
                        " />

                      <!--
                      current vendor 'Nothing found message'
                      -->
                      <NoResults
                        v-if="products.length === 0"
                        class="pt-3 mb-2 pb-3"
                        :vendor-id="currentVendorId" />

                      <div v-if="otherProducts.productCount && !products.length">
                        <OtherProductsLink
                          :count="otherProducts.productCount"
                          :vendor-id="otherProducts.vendorId"
                          :search-term="searchTerm" />
                      </div>

                      <!--
                      current vendor search result
                      -->
                      <InteractionSource
                        v-if="products.length"
                        name="searchResults"
                        :data="{ code: 'search_results' }">
                        <ProductBoxListing
                          :column-sizes="{ xs: 2, sm: 2, md: 2, lg: 3, xl: 5 }"
                          :products="products"
                          :show-horizontal-separators="false"
                          @products-render="onProductsRender">
                          <template
                            v-if="otherProducts.productCount > 0 && !isOtherVendorProductsCountSame"
                            #after="{ className }">
                            <MoreProductsItemBox
                              :class="className"
                              :product-count="otherProducts.productCount"
                              :vendor-id="otherProducts.vendorId"
                              :search-term="searchTerm"
                              @navigate="closeSuggester" />
                          </template>
                        </ProductBoxListing>
                      </InteractionSource>

                      <!-- show all clickable link -->
                      <div
                        v-if="products.length"
                        class="pt-4 pb-3 text-center">
                        <RouterLink
                          :to="{ name: 'ProductSearchResults', query: { search: searchTerm } }"
                          class="text-primary show-all-btn font-weight-normal text-4"
                          @click="
                            () => {
                              closeSuggester()
                              sendSearchAnalytics()
                            }
                          ">
                          {{ t('general.showAll') }}
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </SearchResultsFound>
              </div>
            </div>
          </div>

          <div
            :class="{
              'mobile-bottom-space': !breakpointUpLg && !isPharmacy,
              'mobile-bottom-space--edge':
                !breakpointUpLg && deviceDetectorStore.isEdgeMobile && !isPharmacy,
            }" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-search {
  $form-height: var(--topbarFieldAndButtonHeight);

  @include media-breakpoint-up(lg) {
    position: relative;
    top: 0;
    width: 42%;
    height: auto;
    margin-left: 32%;
    border: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 36%;
  }

  &__input {
    flex: 1;
    min-width: 0;
    height: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.2;
    background: color('gray-lighter');
    border: 0;
    border-radius: var(--borderRadiusSmall) 0 0 var(--borderRadiusSmall);

    @include make-font-scale(3);

    &::placeholder {
      opacity: 1;
    }

    &--cross-visible {
      padding-left: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 10px;
      padding-left: 10px;
      background: color('neutrals-5');
    }

    @include media-breakpoint-up(xl) {
      padding-right: 30px;
      padding-left: 10px;
    }
  }

  &__voice-control {
    height: 100%;
    background: color('gray-lighter');

    :deep(.icon) {
      width: 22px;
      height: 25px;
    }

    @include media-breakpoint-up(lg) {
      background: color('white');
    }
  }

  $form-height-mobile: 40px;

  &__form {
    display: flex;
    height: $form-height-mobile;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: $zix-base;
      width: 100%;
      height: $form-height;
      border-top: 1px solid color('gray-light');
      border-bottom: 1px solid color('gray-light');
      border-left: 1px solid color('gray-light');
      border-radius: var(--borderRadiusSmall);
      transition: width 0.3s ease-in-out;
      transform: translateX(-50%);
      will-change: auto;
    }

    &--is-focus {
      border-top: 1px solid color('primary-light');
      border-bottom: 1px solid color('primary-light');
      border-left: 1px solid color('primary-light');
      border-radius: var(--borderRadiusSmall);

      @include media-breakpoint-up(lg) {
        left: 45%;
        width: 150%;
        height: $form-height;
      }

      .product-search__input {
        background: color('gray-lighter');
      }

      .product-search__voice-control {
        background: color('gray-lighter');
      }
    }
  }

  &__submit {
    box-sizing: content-box;
    width: 42px;
    height: 100%;
    padding: 0;
    color: color('white');
    background-color: color('primary');
    border: 0;
    border-radius: 0 var(--borderRadiusSmall) var(--borderRadiusSmall) 0;

    &:hover {
      background-color: hover-color('primary');
    }

    @include media-breakpoint-up(lg) {
      width: 55px;
      background-color: color('primary-light');

      &:hover {
        background-color: hover-color('primary-light');
      }
    }

    &__icon {
      width: 23px;
      height: 23px;
      margin-bottom: 1px;

      @include media-breakpoint-up(lg) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__bulk-search {
    height: 100%;
    padding-top: 5px;
    background: color('gray-lighter');

    a {
      color: color('primary');
      text-decoration: underline;
      cursor: pointer;

      @include make-font-scale(3);
    }
  }

  &__toggle {
    width: 34px;
    height: $form-height-mobile;
    padding: 0;
    margin: 0;
    color: color('gray-darker');
    border: 0;

    :deep(.icon) {
      width: 24px;
      height: 18px;
      padding-right: 0;
    }
  }

  &__empty-suggester-pharmacy {
    @include media-breakpoint-down(md) {
      height: calc(100vh - 96px);
    }
  }

  &__results {
    inset: calc(var(--topbarHeightTablet) * 2) 0 0 0;
    z-index: $zix-below;
    height: calc(100vh - 36px);
    padding-bottom: 12px;
    overflow: hidden;
    background-color: color('black', 0.2);

    @include media-breakpoint-up(lg) {
      position: fixed;
      top: var(--topbarHeightDesktop);
      z-index: $zix-base;
      height: calc(100vh - 48px);
      padding-bottom: 12px;
    }
  }

  &__container {
    max-height: 100%;
    padding-bottom: 20px;
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: color('white');

    @include media-breakpoint-up(md) {
      min-width: 668px;
      max-width: 968px;

      :deep(.product-search__left-menu) {
        min-width: 232px;
        max-width: 232px;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 968px;
      border-top: 2px solid color('gray-light');
      border-radius: 0 0 10px 10px;
      box-shadow:
        0 4px 4px rgba(0, 0, 0, 25%),
        inset 0 5px 10px rgba(0, 0, 0, 10%);
    }

    @include media-breakpoint-up(xl) {
      max-width: 1206px;
      border: 0;
    }
  }

  :deep(.breadcrumb__item) {
    max-width: 217px;
    padding: 5px 8px;
    margin: 3px 3px;
    font-weight: bold;
    color: color('gray-darker');
    cursor: pointer;
    background: color('gray-lighter');
    border-radius: 3px;

    @include make-font-scale(2);
  }

  :deep(.breadcrumb) {
    display: flex;
    flex-wrap: wrap;
  }

  .show-all-btn {
    padding: 8px 0 8px 24px;
    text-decoration: underline;
  }

  :deep(.product-search__block-header) {
    padding-bottom: 6px;
    font-size: 18px;
    font-weight: var(--fontWeightBold);

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  :deep(.product-search__block-header-search) {
    font-size: 18px;
    font-weight: var(--fontWeightBold);

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  :deep(.flex-grow-1) {
    flex-grow: 1;
  }

  .mobile-bottom-space {
    height: 68px;

    // Edge mobile has two bottom bars which cover the bottom of the search
    // where some buttons may be located, so we need bigger space
    &--edge {
      margin-bottom: 112px;
    }
  }
}
</style>
