<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'

const isRendered = ref(false)

onMounted(() => {
  isRendered.value = true
})

onBeforeUnmount(() => {
  isRendered.value = false
})
</script>

<template>
  <Transition
    v-if="isRendered"
    name="fade">
    <slot />
  </Transition>
</template>
