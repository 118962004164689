<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { Ref } from 'vue'

import { t } from '@/services/translations'

type PaginationInfiniteProps = {
  ghosts: boolean
  spinner?: boolean
}

const props = defineProps<PaginationInfiniteProps>()

const emit = defineEmits<{
  loadNext: []
}>()

const loadMoreInfinite = ref<number | null>(null)
const loadMoreCount = ref(3)

function resetLoadMoreInfinite() {
  loadMoreInfinite.value = loadMoreCount.value
}

function loadNextClick() {
  resetLoadMoreInfinite()
  emit('loadNext')
}
function loadNextInfinite() {
  if (loadMoreInfinite.value !== null && loadMoreInfinite.value > 0) {
    emit('loadNext')
    loadMoreInfinite.value--
  }
}

const button: Ref<HTMLButtonElement | null> = ref(null)
function observerScrollPosition() {
  const optionsObserver: IntersectionObserverInit = {
    root: null,
    rootMargin: '100px 0px',
    threshold: 0,
  }

  function callbackObserver(entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      loadNextInfinite()
    }
  }

  button.value && new IntersectionObserver(callbackObserver, optionsObserver).observe(button.value)
}

resetLoadMoreInfinite()

onMounted(observerScrollPosition)

defineExpose({
  resetLoadMoreInfinite,
})
</script>

<template>
  <div class="m-pagination">
    <button
      ref="button"
      class="btn__rounded btn--secondary-old btn--center m-pagination__btn"
      :class="{
        invisible: props.ghosts && loadMoreInfinite !== 0,
      }"
      @click="loadNextClick">
      <span
        v-if="props.spinner"
        class="a-spinner m-pagination__spinner" />
      {{ t('general.loadNext') }}
    </button>
  </div>
</template>
