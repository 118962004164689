<script setup lang="ts">
import dayjs, { Dayjs } from 'dayjs'
import { uniqWith } from 'lodash-es'

import type { DeliverySlotsGroupName, DeliveryTimeslotsGridGroupSlots } from '@/components/Delivery'
import type { TimeslotTime } from '@/store/pinia/useTimeslotsStore'
import { dateSameOrAfter, dateSameOrBefore } from '@/utils/date'

import DeliveryTimeslotsGridGroup from './DeliveryTimeslotsGrid/DeliveryTimeslotsGridGroup.vue'
import DeliveryTimeslotsGridSeparator from './DeliveryTimeslotsGrid/DeliveryTimeslotsGridSeparator.vue'

type DeliveryTimeslotsGridProps = {
  timeslots: TimeslotTime[]
  groupName: DeliverySlotsGroupName
  readonly: boolean
}

const props = defineProps<DeliveryTimeslotsGridProps>()

function getHighestLevel(times: TimeslotTime[]) {
  return times.reduce((prev, curr) => Math.max(prev, curr.level), 0)
}

function getShortestSlotMiliseconds(times: TimeslotTime[]) {
  const shortestSlot = times.find((timeslot) => timeslot.level === 1)
  return shortestSlot ? shortestSlot.end.diff(shortestSlot.start) : 0
}

/**
 * Calculates the separators according to highest-level delivery slots.
 *
 * Separators are horizontal lines cutting all the slots into separated groups. The start and end of each
 * of these group are the times the highest-level slot starts and ends accordingly. If the group has no
 * highest-level slot, it is treated as the group in whole no matter how long (or short) it is.
 *
 * ```
 * +=================+ <- separator
 * |  1  |     |xxxxx|
 * +-----+  2  |xxxxx|
 * |  1  |     |xxxxx|
 * +=================+ <- separator
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +-----+-----+  3  |
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +=================+ <- separator
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +-----+-----+  3  |
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +=================+ <- separator
 * |  1  |xxxxxxxxxxx|
 * +=================+ <- separator
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +-----+-----+  3  |
 * |  1  |     |     |
 * +-----+  2  |     |
 * |  1  |     |     |
 * +=================+ <- separator
 * |  1  |     |xxxxx|
 * +-----+  2  |xxxxx|
 * |  1  |     |xxxxx|
 * +-----+-----+xxxxx|
 * |  1  |     |xxxxx|
 * +-----+  2  |xxxxx|
 * |  1  |     |xxxxx|
 * +-----+-----+xxxxx|
 * |  1  |     |xxxxx|
 * +-----+  2  |xxxxx|
 * |  1  |     |xxxxx|
 * +=================+ <- separator
 * ```
 */
function calculateGroupsSeparators(times: TimeslotTime[]) {
  // We need to find the earliest and latest times all the delivery starts and ends to be used as separators.
  const earliest = dayjs.min(times.map((timeslot) => timeslot.start))
  const latest = dayjs.max(times.map((timeslot) => timeslot.end))

  // Bounding times of highest-level slots are always used as separators.
  const highestLevelTimeslots = times.filter(
    (timeslot) => timeslot.level === getHighestLevel(times),
  )
  const highestLevelStarts = highestLevelTimeslots.map((timeslot) => timeslot.start)
  const highestLevelEnds = highestLevelTimeslots.map((timeslot) => timeslot.end)
  // The ending time of a slot can be eqal to the starting time of the following slot.
  const groupSeparators = [...highestLevelStarts, ...highestLevelEnds]
  if (earliest) groupSeparators.push(earliest)
  if (latest) groupSeparators.push(latest)

  const sortedGroupSeparators = [...groupSeparators].sort((first, second) =>
    first.isAfter(second) ? 1 : -1,
  )
  // removing duplicities
  return uniqWith(sortedGroupSeparators, (a, b) => a.valueOf() === b.valueOf())
}
/**
 * Calculates the individual frames for each of the groups formed by provided delivery slots.
 *
 * Each frame has a timeline in which every row of the tabular structure has its own time the row starts.
 * Row represents the duration of the shortest slot in a day, thus all row's "heights" across all groups/frames
 * are meant to be equal.
 * That means the slot on level 2 as long as two slots on level 1 is going to be spread on two rows and associated
 * with two timeline items.
 *
 * ```
 *         +=================+ <--+
 * row --> |  1  |     |xxxxx|    |
 *         +-----+  2  |xxxxx|    | group
 * row --> |  1  |     |xxxxx|    |
 *         +=================+ <--+
 * row --> |  1  |     |     |    |
 *         +-----+  2  |     |    |
 * row --> |xxxxx|     |     |    |
 *         |xxxxx+-----+  3  |    | group
 * row --> |xxxxx|     |     |    |
 *         +-----+  2  |     |    |
 * row --> |  1  |     |     |    |
 *         +=================+ <--+
 * ```
 */
function calculateGroupStructure(times: TimeslotTime[]) {
  const groupSeparators = calculateGroupsSeparators(times)
  const groups: DeliveryTimeslotsGridGroupSlots[] = []

  for (let i = 0; i < groupSeparators.length - 1; i++) {
    // For each pair of the following separators we create a group frame bounded by the times os these separators.
    // @TODO Dayjs should be created in a store (DOP-182), but we will be getting rid of it anyway.
    const start = groupSeparators[i]
    // @TODO Dayjs should be created in a store (DOP-182), but we will be getting rid of it anyway.
    const end = groupSeparators[i + 1]

    const timeline: Dayjs[] = []
    // We "fill" the times between start and end of the group, each one is the previous increased by the duration
    // of the shortest slot.
    for (
      let time = dayjs(start);
      time.isBefore(end);
      time = time.add(getShortestSlotMiliseconds(times), 'millisecond')
    ) {
      timeline.push(time)
    }

    const rowsCount = timeline.length

    groups.push({
      start,
      end,
      rowsCount,
      timeline,
      timeslots: [],
    })
  }

  return groups
}
/**
 * Retrieves groups in which are sorted-out provided delivery slots.
 */
function getTimeslotGroups(times: TimeslotTime[]) {
  const groups = calculateGroupStructure(times)

  times.forEach((timeslot) => {
    // Each slot is placed into a group in which the slot can be contained according to start and end times.
    // Slots can not be spread across multiple groups, such slot will INTENTIONALLY cause critical error!
    // @todo: After the FE log system is created, log the inexistence of suitable group instead of letting fail.
    const filteredGroup = groups.find((group) => {
      const dateIsSameOrAfter = dateSameOrAfter(timeslot.start, group.start)
      const dateIsSameOrBefore = dateSameOrBefore(timeslot.end, group.end)

      return dateIsSameOrAfter && dateIsSameOrBefore
    })
    filteredGroup?.timeslots.push(timeslot)
  })

  return groups
}
</script>

<template>
  <div>
    <!-- Each group of delivery timeslots has its own grid. -->
    <template
      v-for="(group, index) in getTimeslotGroups(props.timeslots)"
      :key="`group-${index}`">
      <!-- Render horizontal separators but the first to appear only between grids. -->
      <DeliveryTimeslotsGridSeparator
        v-if="index"
        :time="group.start.tz().hour()" />
      <!-- Render grid. -->
      <DeliveryTimeslotsGridGroup
        :group-name="groupName"
        :readonly="readonly"
        :group="group"
        :highest-level="getHighestLevel(props.timeslots)"
        :shortest-slot-miliseconds="getShortestSlotMiliseconds(props.timeslots)" />
    </template>
    <slot />
  </div>
</template>
