<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { RouterLink } from 'vue-router'

import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'

import type { DeliverySlotsGroupName } from '@/components/Delivery'
import { BaseLoader } from '@/componentsPure'
import useConfig from '@/composables/useConfig'
import type { Product } from '@/composables/useProductItem'
import {
  analyticsLoggerDeliveryWindow,
  analyticsLoggerItemConflict,
} from '@/services/analytics/analyticsLogger'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import useTimeslotsStore, {
  type RefinedTimeslot,
  type TimeslotTime,
  type TimeslotsLimitedProduct,
} from '@/store/pinia/useTimeslotsStore'
import { isPast, isSameDay } from '@/utils/date'

import DeliveryDays from './components/DeliveryDays.vue'
import DeliveryTimeslotsGrid from './components/DeliveryTimeslotsGrid.vue'
import LimitedProducts from './components/DeliveryTimeslotsLimitedProducts.vue'
import DeliveryTimeslotsList from './components/DeliveryTimeslotsList.vue'
import getSlotForAnalytics from './getSlotForAnalytics'

type DeliveryTimeslotsProps = {
  timeslots: readonly RefinedTimeslot[]
  groupName: DeliverySlotsGroupName
  readonly?: boolean
}

const props = withDefaults(defineProps<DeliveryTimeslotsProps>(), { readonly: false })

const configData = useConfig()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const { limitedProducts: storeLimitedProducts, cartTimeslotsStale } =
  storeToRefs(useTimeslotsStore())
const { getProduct } = useProductsStore()

const openDayIndex = ref(0)

const isLoading = computed(() => cartTimeslotsStale.value)

const getSelectedTimeslot = computed(() => cartGetters.value.getSelectedTimeslot)
/**
 * Delivery timeslots need to be filtered because we don't want our customer to see the slots (or groups of slots
 * if they are parallel) whose duration ended in the past.
 */
const futureTimeslots = computed(() => {
  return props.timeslots.map<RefinedTimeslot>((day) => {
    const isDayToday = day.date.tz().isToday()
    // For today, slots/groups (single slots in linear structure, groups in parallel structure) are filtered out
    // if they already ended.
    // In the evening, no future slots may be available for the rest of the day.
    return {
      ...day,
      times: isDayToday ? day.times.filter((time) => !isPast(time.end)) : day.times,
      hasFreeTimeslots: isDayToday && !day.times.length ? false : day.hasFreeTimeslots,
    }
  })
})

const getOpenDayTimes = computed(() => futureTimeslots.value[openDayIndex.value].times)

const limitedProducts = computed(() =>
  storeLimitedProducts.value.map((limitedProduct) => {
    const limitedDays = limitedProduct.days
    const unlimitedDays = futureTimeslots.value
      .filter((timeslot) => !limitedDays.some((day) => isSameDay(day.date, timeslot.date)))
      .map((unlimitedDay) => ({
        date: unlimitedDay.date,
        isAvailable: true,
      }))

    const days = limitedDays.concat(unlimitedDays).sort((a, b) => a.date.diff(b.date))
    // @todo type fix
    return {
      ...getProduct(limitedProduct.id),
      days,
    } as Product & TimeslotsLimitedProduct
  }),
)

const hasOpenDayLimitedProducts = computed(() => {
  return !!limitedProducts.value.filter((item) => {
    const itemLimitationDay = item.days[openDayIndex.value < 0 ? 0 : openDayIndex.value]
    return !itemLimitationDay.isAvailable || itemLimitationDay.limitedTimeRanges
  }).length
})

// TODO create composable
function timesHasLevelGreaterThan(timeslotsTimes: TimeslotTime[], level: number) {
  return timeslotsTimes?.some((times) => times?.level > level)
}

function openFirstFreeDay() {
  openDayIndex.value = Math.max(
    futureTimeslots.value.findIndex((day) => day.hasFreeTimeslots),
    0,
  )
}

function openSelectedDay() {
  if (getSelectedTimeslot.value) {
    openDayIndex.value = futureTimeslots.value.findIndex((day) => {
      return day.times.some((time) => time.id === getSelectedTimeslot.value?.id)
    })
    if (openDayIndex.value < 0) {
      openFirstFreeDay()
    }
  } else {
    openFirstFreeDay()
  }
}

function onDayClick(index: number | null) {
  if (index === null) return

  openDayIndex.value = index
  logDeliveryTimeslots()
}

const displayLimitedProducts = computed(() => hasOpenDayLimitedProducts.value && !props.readonly)

const TimeslotsVariant = computed(() => {
  if (timesHasLevelGreaterThan(getOpenDayTimes.value, 1)) {
    return DeliveryTimeslotsGrid
  }
  return DeliveryTimeslotsList
})

watch(
  () => displayLimitedProducts.value,
  (newVal) => newVal && analyticsLoggerItemConflict(),
  { immediate: true },
)
watch(
  () => getSelectedTimeslot.value,
  () => openSelectedDay(),
  { immediate: true },
)

function logDeliveryTimeslots() {
  analyticsLoggerDeliveryWindow({
    day: `d+${openDayIndex.value}`,
    date: futureTimeslots.value[openDayIndex.value].date.format('YYYY-MM-DD'),
    slots: getOpenDayTimes.value.map((time) => getSlotForAnalytics(time)),
  })
}

logDeliveryTimeslots()

const openDay = computed(() => futureTimeslots.value[openDayIndex.value])

const isOpenDayFutureDay = computed(() => {
  return openDay.value.date.isAfter(dayjs(), 'day')
})

const isAdditionalOrdersEnabled = computed(() => {
  return configData.value.cart.isAdditionalOrdersEnabled
})

/*
  Displays additional order promo message
    - only in checkout
    - only for days in the future (starting from “tommorrow”) and not for today
    - only when there is no conflict of slots with products or other product based limitation on slots (e.g. sold out product)
    - only when there is at least one slot available in the day
    - additional orders are enabled in the country / region
*/
const displayAdditionalOrderMessage = computed(() => {
  return (
    props.groupName === 'checkout' &&
    isOpenDayFutureDay.value &&
    !displayLimitedProducts.value &&
    !openDay.value.unavailable &&
    isAdditionalOrdersEnabled.value
  )
})
</script>

<template>
  <div
    class="position-relative"
    data-tid="delivery-days">
    <DeliveryDays
      :open-day-index="openDayIndex"
      :readonly="props.readonly"
      :future-timeslots="futureTimeslots"
      @on-day-click="onDayClick" />

    <p
      v-if="displayAdditionalOrderMessage"
      class="additional-order-message">
      {{ t('additionalOrder.promo') }}
      <RouterLink
        class="additional-order-message__link"
        :to="`/${t('additionalOrder.promoLink')}`"
        target="_blank">
        {{ t('additionalOrder.promoLinkText') }}
      </RouterLink>
    </p>

    <LimitedProducts
      v-if="displayLimitedProducts"
      :limited-products="limitedProducts"
      :open-day-index="openDayIndex" />

    <slot name="aboveTimeslotsContent" />

    <div
      class="timeslots"
      data-tid="timeslots-section"
      :class="{ timeslots__wide: timesHasLevelGreaterThan(getOpenDayTimes, 2) }">
      <Component
        :is="TimeslotsVariant"
        :timeslots="getOpenDayTimes"
        :group-name="groupName"
        :readonly="readonly">
        <slot name="belowTimeslotsContent" />
      </Component>
    </div>

    <BaseLoader
      v-if="isLoading"
      overlay="white" />
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/legacy' as *;

.additional-order-message {
  max-width: 520px;
  margin: 0 auto;
  font-size: 12px;

  &__link {
    text-decoration: underline;
  }
}

.timeslots {
  margin: 16px 8px;

  @include media-breakpoint-up(md) {
    max-width: 541px;
    margin: 16px auto;

    &__wide {
      max-width: 700px !important;
    }
  }
}
</style>
