<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'

import debounce from 'lodash-es/debounce'

import Icon from '@/components/Icon/Icon.vue'
import { frontApiPostPhoneValidate } from '@/services/api/front/phone'
import { t } from '@/services/translations'

import SelectBox from './SelectBox.vue'

type PhonePrefix = {
  value: number
  country: string
  selected: boolean
}

type InputTelProps = {
  propPhonePrefixes: number[]
  modelValue: string
  propInputId?: string
  propPositiveValidation?: boolean
  propInputDataTid?: string
  shouldCalculateHeight?: boolean
}

const props = withDefaults(defineProps<InputTelProps>(), {
  propInputId: '',
  propPositiveValidation: true,
  propInputDataTid: '',
})

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
  change: []
}>()

const focusSelect = ref(false)

const phone = ref('')
watch(
  () => phone.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) return

    setPatternPhone()
    emit('update:modelValue', getFullPhoneNumber.value)
    emit('change')
  },
)

const prefixes = ref<PhonePrefix[]>([])
// Map and set prefixes
prefixes.value = props.propPhonePrefixes.map((prefix, index) => {
  return {
    value: prefix,
    country: t(`phone.prefixes.${prefix}`),
    selected: index === 0,
  }
})

const getSelectedPrefix = computed(() => {
  const selectedPrefix = prefixes.value.find((prefix) => prefix.selected)
  return selectedPrefix?.value
})

const getFullPhoneNumber = computed(() => {
  return '+' + getSelectedPrefix.value + phone.value
})

const isCzechOrSlovakia = computed(() => {
  return getSelectedPrefix.value === 420 || getSelectedPrefix.value === 421
})

const phoneInput = ref<HTMLInputElement>()
const focusInput = ref(false)

async function setPatternPhone() {
  if (isCzechOrSlovakia.value) {
    let formatPhone = phone.value.replace(/ /g, '')
    if (!phoneInput.value) return

    const cursorPosition: number = phoneInput.value.selectionStart ?? 0
    const newPosition = cursorPosition + Number(cursorPosition === 4 || cursorPosition === 8)

    const part1 = formatPhone.substring(0, 3)
    const part2 = formatPhone.substring(3, 6)
    const part3 = formatPhone.substring(6)

    formatPhone = part1 + (part2 ? ' ' + part2 : '') + (part3 ? ' ' + part3 : '')

    if (formatPhone !== phone.value) {
      phone.value = formatPhone

      if (focusInput.value) {
        await nextTick()
        phoneInput.value?.setSelectionRange(newPosition, newPosition)
      }
    }
  }
}

const isValid = ref<boolean>()
async function getDataForValidate() {
  try {
    await frontApiPostPhoneValidate(getFullPhoneNumber.value)

    isValid.value = true
  } catch {
    isValid.value = false
  }
}

const preValidate = computed(() => {
  const sanitizedPhone = phone.value.replace(/ /g, '')

  if (isCzechOrSlovakia.value) {
    return !isNaN(+sanitizedPhone) && sanitizedPhone.length === 9
  } else {
    return sanitizedPhone.length > 4
  }
})

const debounceForValidate = debounce((preValidateValue: boolean) => {
  if (preValidateValue) {
    getDataForValidate()
  } else {
    isValid.value = false
  }
}, 300)

function phoneValidate() {
  debounceForValidate(preValidate.value)
}

function changePrefix() {
  phoneValidate()
  emit('update:modelValue', getFullPhoneNumber.value)
  emit('change')
}

function getPrefixFromPhone(phoneNumber: string) {
  const phoneWithoutPlus = phoneNumber.substring(1)
  return props.propPhonePrefixes.find((prefix) => phoneWithoutPlus.startsWith(prefix.toString()))
}

function getPhoneWithoutPrefix(phoneNumber: string, prefix: number) {
  return phoneNumber.replace('+' + prefix, '')
}

function setPrefix(newPrefix: number) {
  const selectedPrefix = prefixes.value.find((prefix) => prefix.selected)
  const newSelectedPrefix = prefixes.value.find((prefix) => prefix.value === newPrefix)

  if (!selectedPrefix) return
  selectedPrefix.selected = false

  if (!newSelectedPrefix) return
  newSelectedPrefix.selected = true
}

function parseValueToPhone() {
  if (props.modelValue) {
    const prefix = getPrefixFromPhone(props.modelValue)

    if (!prefix) return
    const phoneWithoutPrefix = getPhoneWithoutPrefix(props.modelValue, prefix)

    setPrefix(prefix)
    phone.value = phoneWithoutPrefix
  }
}
parseValueToPhone()

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (newValue === oldValue) return

    parseValueToPhone()
  },
)

const prefixesLength = computed(() => props.propPhonePrefixes.length)
</script>

<template>
  <div :class="[{ 'calculated-height': focusSelect && props.shouldCalculateHeight }]">
    <div
      :class="{
        'is-focus-input': focusInput,
        'is-focus-select': focusSelect,
        'is-valid': propPositiveValidation && isValid,
        'is-not-valid': isValid === false,
      }"
      class="input-tel">
      <div class="input-tel__select-wrap">
        <SelectBox
          :options="prefixes"
          @change="changePrefix()"
          @change-from-detail="phoneInput?.focus()"
          @blur="focusSelect = false"
          @focus="focusSelect = true">
          <template #selected="{ option }">
            <span :class="'input-tel__flag a-flag a-flag--' + option?.value" />
            <span class="input-tel__text input-tel__text--prefix"> + {{ option?.value }} </span>
          </template>

          <template #option="{ option }">
            <span :class="'input-tel__flag a-flag a-flag--' + option.value" />
            <span class="input-tel__text input-tel__text--prefix"> + {{ option.value }} </span>
            <span class="input-tel__text input-tel__text--country">
              {{ option.country }}
            </span>
          </template>
        </SelectBox>
      </div>

      <input
        :id="propInputId"
        ref="phoneInput"
        v-model="phone"
        :data-tid="propInputDataTid"
        type="tel"
        class="input-tel__number a-input"
        @blur="focusInput = false"
        @focus="focusInput = true"
        @input="phoneValidate" />

      <span
        v-if="propPositiveValidation"
        class="input-tel__valid-symbol input-tel__valid-symbol--ok">
        <Icon
          icon="ok"
          class="input-tel__valid-symbol__tick" />
      </span>

      <span class="input-tel__valid-symbol input-tel__valid-symbol--not-ok">!</span>
    </div>

    <span
      v-if="isValid === false"
      class="a-input-error-message">
      {{ t('phone.notExist') }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

/* Fixes https://mallfresh.atlassian.net/browse/KNW-15316 */
.calculated-height {
  /* stylelint-disable-next-line value-keyword-case */
  height: calc((v-bind(prefixesLength) + 1) * 44px);
}

.input-tel {
  position: relative;
  z-index: $zix-input-tel;
  display: flex;

  &__text {
    display: inline-block;
    margin-top: -4px;
    vertical-align: middle;

    &--prefix {
      min-width: 43px;
      text-align: right;
    }

    &--country {
      margin-left: 12px;
    }
  }

  &__flag {
    margin: -6px 5px 0 5px;
    vertical-align: middle;
  }

  &__select-wrap {
    width: 134px;
  }

  &__number {
    width: calc(100% - 134px);
    padding-right: 40px;
    padding-left: 22px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @include media-breakpoint-down(sm) {
      padding-left: 10px;
    }
  }

  &__valid-symbol {
    position: absolute;
    top: 7px;
    right: 10px;
    height: 30px;
    overflow: hidden;
    color: color('green');
    opacity: 0;
    transition: opacity var(--fastTransitionTime);

    &--ok {
      padding-top: 2px;

      .is-valid & {
        opacity: 1;
      }
    }

    &--not-ok {
      top: 12px;
      right: 12px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: color('white');
      text-align: center;
      background: color('red');
      border-radius: 50%;

      .is-not-valid & {
        opacity: 1;
      }
    }

    &__tick {
      @include make-font-size(9);
    }
  }

  & &__select-wrap {
    :deep(.selectbox) {
      position: static;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .selectbox__arrow {
        right: auto;
        left: 108px;
      }

      .selectbox__options {
        right: 0;
        left: 0;
        max-height: 177px;
        margin-top: -1px;
      }

      .selectbox__option {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 132px;
    width: 4px;
    height: 100%;
    pointer-events: none;
    content: '';
    border-color: transparent;
    border-style: solid;
    border-width: 1px 0;
    transition: border-color var(--fastTransitionTime);
  }

  &.is-focus-input {
    &::before {
      border-color: color('gray-darker');
    }
  }

  &.is-focus-input &__select-wrap {
    .selectbox {
      border-color: color('gray-darker');
      border-right-color: color('gray-light');
    }
  }

  &.is-not-valid {
    &::before {
      border-color: color('red');
    }

    .input-tel__number {
      border-color: color('red');
    }
  }

  &.is-not-valid &__select-wrap {
    .selectbox {
      border-top-color: color('red');
      border-bottom-color: color('red');
      border-left-color: color('red');
    }
  }
}
</style>
