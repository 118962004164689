import { watch } from 'vue'

import { useQueryClient } from '@tanstack/vue-query'

import useUuid from '@/composables/useUuid'
import { configurationQueryKeys } from '@/constants/queryKeys'
import type { ConfigurationWebResponse } from '@/data/api/frontApi/configuration/configurationTypes'

/**
 * Initializes UUID if needen (user is not logged in and has no token).
 * Obtained UUID is then stored in the configuration object.
 */
function useInitUuid() {
  const queryClient = useQueryClient()
  const { data: uuid } = useUuid()

  // When UUID from /anonymous endpoint changes,
  // also change it's value in the configuration object.
  // @todo Maybe just invalidate configuration query? That would mean one extra requst, though.
  function uuidConfigUpdate() {
    if (!uuid.value) return
    queryClient.setQueryData<ConfigurationWebResponse>(configurationQueryKeys.all, (oldConfig) => {
      if (!oldConfig) throw new Error('Configuration not found') // this should never happen

      return {
        ...oldConfig,
        user: {
          ...oldConfig.user,
          uuid: uuid.value,
        },
      }
    })
  }
  watch(uuid, uuidConfigUpdate)
}

export default useInitUuid
