<script setup lang="ts">
import { useConfigInit } from '@/composables/useConfig'
import useGrowthbookReady from '@/composables/useGrowthbookReady'

import AppMainWithConfig from './AppMainWithConfig.vue'

/**
 * Unless config is loaded, don't render anything.
 * Config is imperative for the app to work.
 */
const { data: config } = useConfigInit()
const isGrowthbookReady = useGrowthbookReady()
</script>

<template>
  <AppMainWithConfig v-if="config && isGrowthbookReady">
    <template #latte-flash>
      <slot name="latte-flash" />
    </template>
    <template #latte-content-head>
      <slot name="latte-content-head" />
    </template>
    <template #latte-content>
      <slot name="latte-content" />
    </template>
  </AppMainWithConfig>
</template>
