<script setup>
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { resizer } from '@/services/resizer'
import { t } from '@/services/translations'
import useCartAlertsStore from '@/store/pinia/useCartAlertsStore'
import useCartStore from '@/store/pinia/useCartStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import localizePrice from '@/utils/localizePrice'

const cartAlertsStore = useCartAlertsStore()
const { getProduct } = useProductsStore()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const { currentlyChangedProductId, currentlyAddedGiftsIds, cartAlert } =
  storeToRefs(cartAlertsStore)

const getCartGifts = computed(() =>
  currentlyAddedGiftsIds.value.map((id) => cartGetters.value.getCartGift(id)),
)
const getProductData = computed(() => getProduct(currentlyChangedProductId.value))
</script>

<template>
  <Transition name="fade">
    <div
      v-if="cartAlert"
      class="cart-flash-message cart-flash-message--alert text-center"
      v-text="t(cartAlert.messageKey, cartAlert.params)" />
  </Transition>

  <Transition name="fade">
    <div
      v-if="currentlyChangedProductId && getProductData"
      class="cart-flash-message"
      data-tid="cart-preview__new-product-notice">
      <div class="cart-flash-message__img-wrap">
        <img
          class="cart-flash-message__img"
          :srcset="`${resizer(getProductData.origin.image, 121, 100)} 1.5x, ${resizer(
            getProductData.origin.image,
            61,
            50,
          )} 1x`"
          :alt="getProductData.origin.name" />
      </div>
      <span class="cart-flash-message__name">
        {{ getProductData.origin.name }}
      </span>
      <div class="cart-flash-message__info-wrap">
        <p
          class="cart-flash-message__info"
          v-text="localizePrice(getProductData.computed.price)" />
        <p class="cart-flash-message__info">
          {{ `${getProductData.computed.quantity} ${getProductData.origin.unit}` }}
        </p>
      </div>
    </div>
  </Transition>

  <TransitionGroup
    name="fade"
    tag="span">
    <div
      v-for="(cartGift, index) in getCartGifts"
      :key="`gift-${index}`"
      class="cart-flash-message cart-flash-message--gift">
      <div class="cart-flash-message__img-wrap">
        <img
          v-if="cartGift.image"
          class="cart-flash-message__img"
          :srcset="`${resizer(cartGift.image, 112, 112)} 1.5x, ${resizer(
            cartGift.image,
            56,
            56,
          )} 1x`"
          :alt="cartGift.name" />
        <Icon
          v-else
          icon="gift"
          class="cart-flash-message__gift text-gray-light" />
      </div>
      <span class="cart-flash-message__name">
        <strong
          class="text-red text-uppercase"
          v-text="t('general.gift')" />
        -
        {{ cartGift.name }}
      </span>
      <div class="cart-flash-message__info-wrap">
        <p class="cart-flash-message__info">
          {{ cartGift.quantity + '&nbsp;' + t('general.piecesShortened') }}
        </p>
      </div>
    </div>
  </TransitionGroup>
</template>

<style lang="scss">
@use '@/legacy' as *;

.cart-flash-message {
  display: flex;
  align-items: center;
  max-height: 100px;
  padding: 5px 10px;
  margin-bottom: 5px;
  overflow: hidden;
  color: color('white');
  background: color('green', 0.8);
  box-shadow: 0 6px 17px -2px color('black', 0.5);

  &--alert {
    justify-content: center;
    height: 60px;
    background: color('red', 0.8);
  }

  &--gift {
    line-height: 1.1;
    color: color('gray-darker');
    background: color('white', 0.9);
  }

  &__img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 50px;
  }

  &__img {
    max-height: 100%;
  }

  &__gift {
    width: 32px;
    height: 32px;
  }

  &__name {
    width: 40%;
    max-height: 50px;
    padding-left: 3px;

    @include make-font-scale(2);
  }

  &__info-wrap {
    position: relative;
    display: inline-block;
    width: 30%;
    font-weight: bold;
    vertical-align: middle;
  }

  &__info {
    padding: 2px 0;
    margin-bottom: 0;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;

    @include make-font-scale(2);
  }
}
</style>
