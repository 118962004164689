<script setup lang="ts">
import { computed } from 'vue'

import { t } from '@/services/translations'
import localizePrice from '@/utils/localizePrice'

import AddressSelectorDeliveryOption from './AddressSelectorDeliveryOption.vue'
import useDeliveryOptions from './useDeliveryOptions'

const { data } = useDeliveryOptions()

const deliveryOptions = computed(() => data.value ?? [])

function isLastDeliveryOption(deliveryOptionIndex: number) {
  return deliveryOptionIndex === deliveryOptions.value.length - 1
}

function getOrderLabel(deliveryOptionIndex: number, orderPrice: number) {
  const priceKeyOption = {
    price: localizePrice(orderPrice, { maximumFractionDigits: 0 }),
  }

  return isLastDeliveryOption(deliveryOptionIndex)
    ? t('addressSelector.banner.bigOrderFrom', priceKeyOption)
    : t('addressSelector.banner.orderFrom', priceKeyOption)
}

function getDeliveryLabel(deliveryOptionIndex: number) {
  return isLastDeliveryOption(deliveryOptionIndex)
    ? t('addressSelector.banner.deliveryAlwaysLabel')
    : t('addressSelector.banner.deliveryLabel')
}
</script>

<template>
  <div class="delivery-price-banner">
    <AddressSelectorDeliveryOption
      v-for="(deliveryOption, index) of deliveryOptions"
      :key="deliveryOption.orderPrice"
      :data-tid="`delivery-price-banner-box-${index}`"
      :order-price="deliveryOption.orderPrice"
      :delivery-price="deliveryOption.deliveryPrice"
      :order-label="getOrderLabel(index, deliveryOption.orderPrice)"
      :delivery-label="getDeliveryLabel(index)"
      :style="{ width: deliveryOptions.length === 2 ? '236px' : '180px' }" />
  </div>
</template>

<style lang="scss">
.delivery-price-banner {
  display: flex;
  justify-content: center;
}
</style>
