import { storeToRefs } from 'pinia'

import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

//@todo remove redundant composable
export function useScroll() {
  const { isArtificialScroll } = storeToRefs(useUserInterfaceStore())
  return {
    isArtificialScroll,
  }
}
