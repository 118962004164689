import { watch } from 'vue'

import { useMutation, useQuery, useQueryClient, type QueryKey } from '@tanstack/vue-query'
import dayjs from 'dayjs'

import States from '@/components/Notification/States'
import type { Notification } from '@/components/Notification/notificationTypes'
import { notificationQueryKeys } from '@/constants/queryKeys'
import api from '@/data/api'
import type { NotificationsResponse } from '@/data/api/notificationsApi/notifications/notificationsTypes'
import { analyticsLoggerNotificationsImpression } from '@/services/analytics/analyticsLogger'

import useUser from '../useUser'
import useNotificationsSetCount from './useNotificationsSetCount'

function useNotificationsData(queryKey: QueryKey, queryFn: () => Promise<NotificationsResponse>) {
  const queryClient = useQueryClient()
  const { getUserId: customerId } = useUser()

  const setNotificationsCount = useNotificationsSetCount()
  const setNotificationsAsSeen = useMutation({
    mutationFn: (notificationIds: Notification['id'][]) =>
      api.notifications.notifications.notificationsState({
        body: { state: States.SEEN, notificationIds },
        queryParams: { customerId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: notificationQueryKeys.count() })
    },
  })

  const query = useQuery({
    queryKey,
    queryFn,
    select: (responseObject) =>
      responseObject.map((notification) => ({
        ...notification,
        validityTo: dayjs(notification.validityTo),
      })),
  })

  watch(query.isSuccess, () => {
    setNotificationsCount(0)
    analyticsLoggerNotificationsImpression('modal', query.data.value as Notification[])
    if (query.data.value) {
      // Filter out notifications that are seen first time
      const notificationsTargetedIds = query.data.value
        .filter((notification) => notification.state === States.TARGETED)
        .map((notification) => notification.id)

      // Only set notifications as seen if there are any
      if (notificationsTargetedIds.length) {
        setNotificationsAsSeen.mutate(notificationsTargetedIds)
      }
    }
  })

  return query
}

export default useNotificationsData
