<script setup lang="ts">
import { computed } from 'vue'

import { vOnClickOutside } from '@vueuse/components'
import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import NotificationModal from '@/components/Notification/NotificationModal.vue'
import NotificationCount from '@/components/Notification/components/NotificationCount.vue'
import { Tooltip } from '@/componentsPure'
import { useNotificationsCount } from '@/composables/notifications'
import useUser from '@/composables/useUser'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'

const deviceDetectorStore = useDeviceDetectorStore()
const { isModalOpen, toggleModal, closeModal } = useModalStore()

const { breakpointUpSm, breakpointUpLg } = storeToRefs(deviceDetectorStore)

const { getUserId: customerId } = useUser()

const { data: notificationCount } = useNotificationsCount({
  consumer: 'WEB',
  customerId,
})

const isNotificationControlVisible = computed(() => isModalOpen(modalIndex.notifications))
function toggleNotificationsModal() {
  toggleModal(modalIndex.notifications)
}

function closeNotificationsModal() {
  closeModal(modalIndex.notifications)
}

function handleNotificationControl() {
  toggleNotificationsModal()
}
</script>

<template>
  <Tooltip
    v-on-click-outside="closeNotificationsModal"
    :visible="isNotificationControlVisible"
    :direction="breakpointUpSm ? 'bottom-left' : 'bottom'"
    bg-color="white"
    text-color="black"
    :shadow="breakpointUpSm"
    :wrapper-class="!breakpointUpSm ? 'position-static' : ''"
    :message-class="!breakpointUpSm ? 'rounded-0' : ''"
    :tooltip-class="!breakpointUpSm ? 'vw-100 pt-0' : ''">
    <div class="position-relative">
      <button
        :title="t('notifications.title')"
        class="notification-btn"
        data-tid="bell-button"
        @click="handleNotificationControl">
        <slot name="icon">
          <Icon
            icon="notification"
            class="notification-icon" />
        </slot>
      </button>
      <NotificationCount
        v-if="notificationCount"
        :count="notificationCount"
        data-tid="notification-count"
        :class="[
          'notification-count',
          {
            'notification-count--mobile': !breakpointUpLg,
          },
        ]" />
    </div>
    <template #message>
      <NotificationModal data-tid="notification-modal" />
    </template>
  </Tooltip>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.notification {
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--topbarHeightTablet) - 1px);
    padding: 0 5px;
    color: color('primary');
    background-color: color('gray-lighter');
    border: 0;
    border-right: 1px solid color('gray-light');
    border-left: 1px solid color('gray-light');
    outline: 0;

    @include media-breakpoint-up(lg) {
      $size: 30px;

      width: $size;
      height: $size;
      padding: 0;
      border: 0;
      border-radius: 50%;

      &:hover {
        color: color('gray-darker');
      }
    }
  }

  &-icon {
    $icon-size: 18px;

    width: $icon-size;
    height: $icon-size;
  }

  &-count {
    position: absolute;
    top: -4px;
    left: 21px;

    &--mobile {
      top: 7px;
      left: 21px;
    }
  }
}
</style>
