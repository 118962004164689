import { computed } from 'vue'

import appConfig from '@/services/appConfig'

import useConfig from './useConfig'

function usePharmacy() {
  const configData = useConfig()

  const isPharmacyAvailable = computed(() =>
    appConfig.pharmacyAvailableInWarehouses.includes(configData.value.cart.warehouseId),
  )

  return {
    isPharmacyAvailable,
  }
}

export default usePharmacy
