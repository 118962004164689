<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { FormCheckbox } from '@/components/Form'
import usePharmacy from '@/composables/usePharmacy'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const { isPharmacyAvailable } = usePharmacy()

function setRefuseReplacement(isRefused: boolean) {
  cartStore.cartActions.setRefuseReplacement(isRefused)
}

const refuseCheckboxValue = computed({
  get() {
    return cartGetters.value.isRefusedReplacement
  },
  set(val) {
    setRefuseReplacement(val)
  },
})
</script>

<template>
  <div class="cart-replacement-refusal px-md-4">
    <h3
      class="text-7 font-weight-bold cart-replacement-refusal__title"
      v-text="t('checkout.compensation.title')" />
    <p
      class="text-5 cart-replacement-refusal__text text-center"
      v-text="
        isPharmacyAvailable
          ? t('checkout.compensation.perexPharmacy')
          : t('checkout.compensation.perex')
      " />
    <FormCheckbox
      v-model="refuseCheckboxValue"
      class="text-5 text-left d-inline-block cart-replacement-refusal__checkbox">
      {{ t('checkout.compensation.label') }}
    </FormCheckbox>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.cart-replacement-refusal {
  padding: 16px;
  margin: 24px 0 24px 0;
  text-align: center;
  border: 1px solid color('gray-light');
  border-radius: 8px;

  &__title,
  &__checkbox {
    color: color('text-paragraph');
  }

  &__text {
    color: color('text-paragraph-alternative');
  }
}
</style>
