import { computed } from 'vue'
import { useRouter } from 'vue-router'

import {
  frontApiPostCheckoutPayApple,
  frontApiPutCheckoutRollback,
  frontApiPostCheckoutFinish,
} from '@/services/api/front/checkout'
import type { CheckoutPayAppleRequest } from '@/services/api/front/checkoutTypes'
import { webApiGetApplePayMerchantValidation } from '@/services/api/web/applepay'

const canMakePaymentsWithApplePay = computed(
  () => window.ApplePaySession?.canMakePayments() && window.ApplePaySession?.supportsVersion(14),
)

// TODO additional order
// Creates order on back-end
async function createOrder() {
  try {
    const {
      data: {
        order: { id },
      },
    } = await frontApiPostCheckoutFinish()

    return id
  } catch (err) {
    console.log(err)
  }
}

function usePaymentApplePay() {
  const router = useRouter()

  function authorizeApplePayPayment(paymentRequestData: ApplePayJS.ApplePayPaymentRequest) {
    if (!canMakePaymentsWithApplePay.value || !window.ApplePaySession || !paymentRequestData) return

    // Creates new ApplePaySession
    const session = new window.ApplePaySession(14, paymentRequestData)

    // Opens payment sheet
    session.begin()

    // Initiates merchant validation process. Is automatically called by ApplePaySession
    // TODO use frontApi when EP is ready
    session.onvalidatemerchant = async (event) => {
      const { data } = await webApiGetApplePayMerchantValidation(event.validationURL)

      session.completeMerchantValidation(data)
    }

    let orderId: number | undefined

    // User authorized payment by Touch ID or Face ID
    session.onpaymentauthorized = async (event) => {
      // Creates order on back-end
      orderId = await createOrder()
      if (!orderId) return

      try {
        // Sends payment token to back-end
        const { data } = await frontApiPostCheckoutPayApple(
          // typecasting needed because of wrong type definition of ApplePayPaymentToken
          event.payment.token as unknown as CheckoutPayAppleRequest,
          orderId,
        )

        // mark payment as successful
        session.completePayment({ status: window.ApplePaySession!.STATUS_SUCCESS })

        // Fingerprint or additional auth is needed
        if (data.authLinks) {
          window.location.replace(data.authLinks.redirectUrl)
        }

        // Payment success -> redirect to thank you page
        if (data.success) {
          router.push({
            name: 'PageThankYou',
            params: {
              id: orderId,
            },
          })
        }

        // Payment has been created, but not cleared yet -> redirect to payment check page
        if (data.authLinks === null && !data.success) {
          router.push({
            name: 'PaymentFingerprint',
          })
        }
      } catch (err) {
        console.error(err)
        session.abort()
        frontApiPutCheckoutRollback(orderId)
      }
    }

    // User cancelled payment
    session.oncancel = function () {
      if (!orderId) return

      frontApiPutCheckoutRollback(orderId)
    }
  }

  return {
    canMakePaymentsWithApplePay,
    authorizeApplePayPayment,
  }
}

export default usePaymentApplePay
