import appConfig from '../appConfig'
import { currentEnv, getEocName, getTitNumber } from '../environment'

/**
 * Gets the environment name for telemetry purposes.
 * @param localhostName Your localhost name, e.g. 'leosovo', or null if you don't want to use it.
 */
function getEnvironmentName(localhostName: string | null) {
  switch (currentEnv) {
    case 'production':
      return `prod-${appConfig.domain}`
    case 'stage':
      return `stage-${appConfig.domain}`
    case 'tit': {
      const titNumber = getTitNumber() ?? 0
      return `tit-${titNumber}`
    }
    case 'eoc': {
      const eocName = getEocName() ?? 'unknown'
      return eocName
    }
    case 'localhost':
      if (localhostName) {
        return `localhost-${localhostName}`
      }
      break
  }

  return 'unknown'
}

export default getEnvironmentName
