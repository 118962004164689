<script setup lang="ts">
import { inject } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'

import { hideTimeslotsKey } from './addressSelectorProviderKey'

const props = defineProps<{
  hasDivider: boolean
}>()

const { userAddress, hasChangedDestination } = storeToRefs(useAddressStore())

const hideTimeslots = inject(hideTimeslotsKey)
</script>

<template>
  <div
    class="address-selector-select"
    :class="{ 'address-selector-select--border': props.hasDivider }">
    <div class="address-selector-select__control">
      <Icon
        icon="car"
        class="address-selector-select__control__icon" />
      <button
        data-tid="destination-address-select"
        class="address-selector-select__control__button"
        @click="hideTimeslots"
        v-text="userAddress.address" />
    </div>
    <div
      v-if="hasChangedDestination"
      class="address-selector-select__alert address-selector-select__alert--warning"
      :class="{ 'pb-2': !props.hasDivider }"
      v-text="t('addressSelector.destinationChanged')" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.address-selector-select {
  margin: 0 7px 16px;
  text-align: center;

  &--border {
    padding-bottom: 18px;
    border-bottom: 1px solid color('gray-light');
  }

  @include media-breakpoint-up(md) {
    margin: 0 20px;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0 8px;

    &__icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      color: color('primary');
    }

    &__button {
      padding: 0;
      overflow: hidden;
      color: color('primary');
      text-decoration: underline;
      text-decoration-skip-ink: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: transparent;
      border: 0;
      outline: 0;

      &:hover {
        color: color('black');
      }
    }
  }

  &___alert {
    display: inline-block;
    padding: 8px 16px;
    margin-bottom: 10px;
    color: color('green');
    text-align: center;
    background: color('gray-lighter', 0.5);
    border-radius: 4px;

    &--warning {
      color: color('red');
      background: color('red-light', 0.5);
    }
  }
}
</style>
