<script setup lang="ts">
import { useRouter } from 'vue-router'

import CartCheckoutControls from '@/components/CartCheckoutControls'
import AuthWidget from '@/components/global/AuthWidget.vue'
import { t } from '@/services/translations'

const router = useRouter()

const linkBack = router.resolve({ name: 'Cart' }).href
</script>

<template>
  <CartCheckoutControls
    position="top"
    :link-back="linkBack"
    link-next=""
    checkout-step="PersonalData" />

  <AuthWidget :title="t('checkout.loginToContinueIntro')" />

  <CartCheckoutControls
    position="bottom"
    :link-back="linkBack"
    link-next=""
    checkout-step="PersonalData" />
</template>
