import { watch } from 'vue'

import useIsLogged from '@/composables/useIsLogged'
import useUuid from '@/composables/useUuid'

type CallbackFn = (uuid: string | null | undefined) => void

/**
 * Provides a callback function that will be called after UUID is ready.
 * @returns Callback function with UUID as argument.
 */
function useOnUuid() {
  let callbackFn: CallbackFn = () => {}
  const isLogged = useIsLogged()
  const { isSuccess: uuidReady, data: uuid } = useUuid()

  watch(uuidReady, () => {
    if (uuidReady.value || isLogged) callbackFn(uuid.value)
  })

  return (cb: CallbackFn) => {
    callbackFn = cb

    // UUID could be set already.
    if (uuidReady.value || isLogged) {
      callbackFn(uuid.value)
    }
  }
}

export default useOnUuid
