<script setup lang="ts">
import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import ProductReplacing from '@/components/Product/Replacing/ProductReplacing.vue'
import WidgetAlternatives from '@/components/Widget/WidgetAlternatives.vue'
import Popup from '@/components/global/Popup/Popup.vue'
import BaseButton from '@/componentsPure/BaseButton/BaseButton.vue'
import type { Product } from '@/composables/useProductItem'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import useProductsStore from '@/store/pinia/useProductsStore'

const deviceDetectorStore = useDeviceDetectorStore()
const { getProduct } = useProductsStore()
const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

const getCurrentlyReplacedProduct = computed(() => cartGetters.value.getCurrentlyReplacedProduct)
const replacingProduct = ref<Product | null>(null)
const replacingProductOriginalQuantity = ref(0)
const isReplacingProductSoldOut = ref(false)
const hasReplacingProductLimitedDelivery = ref(false)

const isProductToRemove = computed(() => {
  return isReplacingProductSoldOut.value || hasReplacingProductLimitedDelivery.value
})

const { closeModal } = useModalStore()

function clearCurrentlyReplacedProduct() {
  cartStore.cartActions.clearCurrentlyReplacedProduct()
}
function setCartQuantityToMaxAvailable(id: number) {
  cartStore.cartActions.setCartQuantityToMaxAvailable(id)
}

function loadReplacingProductData() {
  if (!getCurrentlyReplacedProduct.value) {
    return
  }

  const { id, isSoldOut, hasLimitedDelivery } = getCurrentlyReplacedProduct.value

  replacingProduct.value = getProduct(id)
  isReplacingProductSoldOut.value = isSoldOut
  hasReplacingProductLimitedDelivery.value = hasLimitedDelivery

  if (replacingProduct.value) {
    replacingProductOriginalQuantity.value = replacingProduct.value.computed.quantity

    if (!isProductToRemove.value) {
      setCartQuantityToMaxAvailable(replacingProduct.value.id)
    }
  }
}

const getTextations = computed(() => {
  if (isReplacingProductSoldOut.value) {
    return {
      title: t('unavailableProducts.alternatives.soldOut.title'),
      claim: t('unavailableProducts.alternatives.soldOut.claim'),
    }
  }
  if (!hasReplacingProductLimitedDelivery.value) {
    return {
      title: t('unavailableProducts.alternatives.partiallySoldOut.title'),
      claim: t('unavailableProducts.alternatives.partiallySoldOut.claim'),
    }
  }
  return {
    title: t('unavailableProducts.alternatives.limitedDelivery.title'),
    claim: t('unavailableProducts.alternatives.limitedDelivery.claim'),
  }
})
const getCloseControlLabel = computed(() => {
  return isProductToRemove.value && getCurrentlyReplacedProduct.value
    ? t('unavailableProducts.alternatives.closeControlLabel.keepAndClose')
    : t('unavailableProducts.alternatives.closeControlLabel.doneAndClose')
})
</script>

<template>
  <Popup
    content
    :name="modalIndex.cartAlternatives"
    size="large"
    box-class-css="px-2 p-lg-4"
    @open="loadReplacingProductData"
    @close="clearCurrentlyReplacedProduct">
    <h3
      class="text-center text-8 mb-3"
      v-text="getTextations.title" />
    <ProductReplacing
      v-if="replacingProduct && replacingProductOriginalQuantity"
      class="product-replacing"
      :product="replacingProduct"
      :product-original-quantity="replacingProductOriginalQuantity"
      :limited-delivery="hasReplacingProductLimitedDelivery"
      :large="deviceDetectorStore.breakpointUpSm" />
    <p
      class="text-center my-3"
      v-html="getTextations.claim" />
    <WidgetAlternatives
      v-if="replacingProduct"
      :replacing-product="replacingProduct"
      :include-replacing-product="!isProductToRemove" />
    <div class="text-center mt-3">
      <BaseButton
        variant="secondary"
        @click="closeModal(modalIndex.cartAlternatives)">
        {{ getCloseControlLabel }}
      </BaseButton>
    </div>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-replacing {
  max-width: 500px;
  margin: 0 auto;
}
</style>
