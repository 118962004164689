<script setup lang="ts">
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import type { ImgSrc } from '@/components/stories/atoms/imgResponsive/types'
import { t } from '@/services/translations'

const imageSrcSet: ImgSrc[] = [
  {
    url: '/vue/img/delivery-address/header.webp?v=1',
  },
  {
    pixelRatio: 1.5,
    url: '/vue/img/delivery-address/header@2x.webp?v=1',
  },
]
</script>

<template>
  <div class="empty-address">
    <div class="empty-address__image a-img-wrap">
      <ImgResponsive
        class-img="a-img-wrap__img a-img-wrap__img--positioned-left"
        :srcset="imageSrcSet" />
    </div>
    <div
      class="empty-address__banner h-100 d-flex flex-column justify-content-center position-absolute text-text-paragraph">
      <p
        class="text-9 px-5 px-sm-0 mb-1 mb-lg-2 font-weight-bold"
        v-text="t('addressSelector.popUpTitle')" />
      <p
        class="text-4 text-lg-5 p-2 mb-0 pl-lg-0"
        data-tid="address-modal-info-address"
        v-text="t('addressSelector.offerForLocationMessage')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.empty-address {
  position: relative;
  height: 190px;

  @include media-breakpoint-up(md) {
    height: unset;
  }

  &__banner {
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;

    @include media-breakpoint-up(lg) {
      width: 64%;
      margin-inline: 4%;
      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      width: 61%;
    }
  }

  &__image {
    height: 190px;

    @include media-breakpoint-up(lg) {
      height: 220px;
    }
  }
}
</style>
