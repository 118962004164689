<script setup lang="ts">
import { storeToRefs } from 'pinia'

import useCartDeliveryData from '@/composables/cart/useCartDeliveryData'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import localizePrice from '@/utils/localizePrice'

import AddressSelectorDeliveryOptions from './AddressSelectorDeliveryOptions.vue'
import AddressSelectorFreeDeliveryBanner from './AddressSelectorFreeDeliveryBanner.vue'
import AddressSelectorTitle from './AddressSelectorTitle.vue'
import usePreviewTimeslots from './usePreviewTimeslots'

const { hasPreviewFreeTimeslots } = usePreviewTimeslots()

const { isAlwaysFreeDelivery, getMinimalOrderPrice, forAnonymousCustomer } = useCartDeliveryData()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
</script>

<template>
  <div>
    <AddressSelectorTitle
      data-tid="delivery-days-title"
      :title="t('addressSelector.banner.title')"
      class="mt-3" />
    <div class="text-gray text-center mx-3 mb-2">
      <div
        v-if="isAlwaysFreeDelivery && getMinimalOrderPrice !== undefined"
        class="address-selector__alert">
        {{
          t('addressSelector.timeslots.freeDeliveryFrom', {
            price: localizePrice(getMinimalOrderPrice, { maximumFractionDigits: 0 }),
          })
        }}
      </div>
      <div
        v-if="!hasPreviewFreeTimeslots"
        class="address-selector__alert address-selector__alert--warning"
        v-text="t('addressSelector.timeslots.noFreeTimeslots')" />
    </div>
    <AddressSelectorFreeDeliveryBanner v-if="cartGetters.hasFreeDelivery || forAnonymousCustomer" />
    <AddressSelectorDeliveryOptions
      v-else-if="!isAlwaysFreeDelivery && hasPreviewFreeTimeslots"
      class="py-3" />
  </div>
</template>

<style lang="scss">
@use '@/legacy' as *;

.address-selector {
  &__alert {
    display: inline-block;
    padding: 8px 16px;
    margin-bottom: 10px;
    color: color('green');
    text-align: center;
    background: color('gray-lighter', 0.5);
    border-radius: 4px;

    &--warning {
      color: color('red');
      background: color('red-light', 0.5);
    }
  }
}
</style>
