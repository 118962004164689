<script setup lang="ts">
import { ref } from 'vue'

import Icon from '@/components/Icon/Icon.vue'

const emit = defineEmits<{
  closed: []
}>()

const isVisible = ref(true)

const hide = () => {
  isVisible.value = false
  emit('closed')
}
</script>

<template>
  <Transition name="fade">
    <div
      v-if="isVisible"
      class="position-relative">
      <slot />
      <button
        class="control btn position-absolute p-0"
        @click="hide">
        <Icon icon="cross" />
      </button>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.control {
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.icon {
  width: 16px;
  height: 16px;
  color: color('black');
}
</style>
