<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import ActionMenu from '@/components/ActionMenu/ActionMenu.vue'
import { AddressSelectorButtonMenu } from '@/components/AddressSelector'
import CartFlashMessage from '@/components/CartFlashMessage'
import Icon from '@/components/Icon/Icon.vue'
import MainLogo from '@/components/MainLogo/MainLogo.vue'
import NavL1 from '@/components/NavTop/NavL1.vue'
import NotificationControl from '@/components/Notification/NotificationControl.vue'
import NotificationCount from '@/components/Notification/components/NotificationCount.vue'
import UserLogin from '@/components/UserLogin/UserLogin.vue'
import AButton from '@/components/stories/atoms/button/Button.vue'
import { PriceDisplay } from '@/componentsPure'
import { useRouteMeta } from '@/composables/useRouteMeta'
import useUser from '@/composables/useUser'
import { t } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useModalStore from '@/store/pinia/useModalStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'

import CartPreview from './CartPreview.vue'
import { useCart } from './useCart'

// constants
const { hasAdditionalOrders, cartTotalItems } = useCart()
const { isUserSignedIn } = useUser()
const { MODAL_NAMES, isModalOpen, toggleModal } = useModalStore()
const { hasSimpleHeaderLayout } = useRouteMeta()
const { headerSettings } = storeToRefs(useUserInterfaceStore())

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)

/// computed and functions

const isMenuVisible = computed(() => isModalOpen('MobileNavigation'))
const isCartPreviewOpenMobile = computed(() => isModalOpen('MobileCart'))

function toggleNavigation() {
  toggleModal(MODAL_NAMES.mobileNavigation)
}
function toggleCartPreviewOpenMobile() {
  toggleModal(MODAL_NAMES.mobileCart)
}
</script>

<template>
  <div class="py-3">
    <div class="first-line d-flex justify-content-between align-items-start">
      <div class="d-flex align-items-center">
        <!-- menu button -->
        <button
          v-if="!hasSimpleHeaderLayout"
          class="menu-button"
          data-tid="responsive-header__menu"
          @click="toggleNavigation">
          <span class="d-flex flex-column">
            <Icon
              :group="isMenuVisible ? 'general' : 'mobile'"
              :icon="isMenuVisible ? 'cross' : 'menu'"
              class="menu-button__icon" />
            <span class="menu-button__text">{{ t('general.menu') }}</span>
          </span>
        </button>

        <MainLogo />
      </div>

      <div class="d-flex align-items-top icons">
        <NotificationControl
          v-if="isUserSignedIn && !hasSimpleHeaderLayout"
          class="bell-button">
          <template #icon>
            <Icon
              group="mobile"
              icon="bell-outline" />
          </template>
        </NotificationControl>

        <AButton
          v-if="isUserSignedIn"
          class="user-button"
          variant=""
          :route-to="{
            name: 'Profile',
            params: { presenter: 'order', action: 'default' },
            query: { showmenu: 1 },
          }"
          entity="router-link">
          <Icon
            group="mobile"
            icon="user" />
          <span class="check">
            <Icon icon="checkbox-ok" />
          </span>
        </AButton>
        <UserLogin
          v-else
          class="user-button">
          <Icon
            group="mobile"
            icon="user" />
        </UserLogin>

        <div class="d-flex flex-column justify-center">
          <button
            v-if="!hasSimpleHeaderLayout"
            data-tid="responsive-header__preview-cart-button"
            class="cart-button"
            @click="toggleCartPreviewOpenMobile">
            <Icon
              icon="cart"
              group="mobile" />
            <PriceDisplay
              v-if="cartGetters.getCartTotalPrice > 0"
              :price="cartGetters.getCartTotalPrice"
              :decimal-position="'bottom'"
              :separator="'comma'"
              class="cart-button__amount text-1" />
            <span
              v-if="
                !isCartPreviewOpenMobile &&
                hasAdditionalOrders &&
                cartTotalItems === 0 &&
                !hasSimpleHeaderLayout
              "
              class="info-badge">
              <Icon icon="info-full" />
            </span>
            <NotificationCount
              v-show="cartTotalItems > 0"
              :count="cartTotalItems" />
          </button>
        </div>

        <span
          v-if="!isCartPreviewOpenMobile"
          class="mobile-header__cart-flash-message">
          <CartFlashMessage />
        </span>
      </div>

      <Transition name="fade">
        <CartPreview
          v-if="!hasSimpleHeaderLayout && isCartPreviewOpenMobile"
          class="ssr-hide-mobile" />
      </Transition>

      <Transition name="fade">
        <div
          v-if="isCartPreviewOpenMobile"
          class="mobile-header__shadow-cart"
          @click="toggleCartPreviewOpenMobile" />
      </Transition>

      <Transition name="slideFromRight">
        <div
          v-if="isMenuVisible"
          class="mobile-header__overlay ssr-hide-mobile">
          <!--
        Backdrop za menu, po kliknutí na nej se zavře navigace
        -->
          <div
            v-if="isMenuVisible"
            class="mobile-header__shadow-sidebar"
            @click="toggleNavigation" />
          <!--
        Menu
        -->
          <div
            v-if="isMenuVisible"
            class="mobile-header__sidebar">
            <AddressSelectorButtonMenu class="mobile-header__address-selector" />
            <ActionMenu v-if="!hasSimpleHeaderLayout && headerSettings.actionMenu">
              <slot />
            </ActionMenu>
            <NavL1
              v-if="!hasSimpleHeaderLayout"
              @category-click="toggleNavigation" />
          </div>
        </div>
      </Transition>
    </div>
    <div class="separator-line"></div>
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

$icon-margin: 3px; // space between buttons on right
$icon-gap: 8px; // space between buttons on right

.separator-line {
  position: absolute;
  top: 114px;
  width: 100%;
  border-top: 1px solid color('gray-lighter');
}

.first-line {
  height: 47px;
  padding-right: 16px;
  margin-left: 12px;
}

.icons {
  gap: $icon-gap;
  padding-top: 7px;
}

.mobile-header {
  &__cart-flash-message {
    position: absolute;
    top: calc(var(--topbarHeightTablet) + 10px);
    right: 0;
    left: 0;
    z-index: $zix-header__cart-flash-message;
  }

  &__cart {
    position: relative;
    width: var(--mobileCartButtonWidth);
    height: calc(var(--topbarHeightTablet) - 1px);
    padding: 10px 0 0;
    text-align: center;
    cursor: pointer;
    background: color('gray-lighter');
    border: 0;
    border-right: 1px solid color('gray-light');
    border-left: 1px solid color('gray-light');

    @include make-font-scale(1);
  }

  &__cart-icon,
  &__info-icon {
    width: 16px;
    height: 16px;
  }

  &__cart-icon {
    display: block;
    margin: 0 auto 3px;
  }

  &__info-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    color: color('green');
  }

  &__shadow-cart {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    background: color('black', 0.2);
  }

  &__overlay {
    position: fixed;
    inset: var(--topbarHeightTablet) 0 0 0;
    overflow: hidden;
  }

  &__shadow-sidebar {
    position: absolute;
    inset: 0 0 0 0;
    background-color: color('black', 0.2);
  }

  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: color('white');
  }

  &__address-selector,
  &__user-menu {
    padding: 10px 0;
  }

  &__address-selector {
    border-top: 1px solid color('gray-lighter');
  }
}

.cart-button {
  position: relative;
  max-width: 36px;
  padding: 0;
  margin: 0;
  color: color('primary');
  background: color('white');
  border: 0;

  .icon {
    width: 24px;
    height: 24px;
  }

  &__amount {
    position: absolute;
    width: 60px;
    margin-left: -19px;
    color: color('primary');
    text-align: right;
  }

  :deep(.count) {
    position: relative;
    top: -31px;
    right: -18px;
    width: 16px;
    height: 16px;
    font-weight: unset;
    color: color('brand');
    background: color('yellow');

    @include make-font-size(1);
  }
}

.menu-button {
  padding: 0;
  margin: 0 20px 0 0;
  color: color('brand');
  background: color('white');
  border: 0;

  @include make-font-scale(1);

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.info-badge {
  position: absolute;
  top: -7px;
  left: 18px;
  max-width: 16px;
  padding: 0;
  margin: 0;
  color: color('green');

  .icon {
    width: 16px;
    height: 16px;
  }
}

.user-button {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 0;
  color: color('primary');

  .icon {
    width: 24px;
    height: 24px;
  }

  .check {
    position: absolute;
    top: -7px;
    left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    color: color('white');
    background-color: color('green');
    border-radius: 100%;

    .icon {
      width: 10px;
      height: 10px;
    }
  }
}

.bell-button {
  :deep(.notification-btn) {
    height: 24px;
    padding: 0;
    background: color('white');
    border: 0;

    .icon {
      width: 24px;
      height: 24px;
      color: color('primary');
    }
  }

  :deep(.notification-count) {
    position: absolute;
    top: -7px;
    left: 11px;
    width: 16px;
    height: 16px;
    font-weight: unset;
    color: color('primary');
    background: color('yellow');

    @include make-font-size(1);
  }
}

.cart-preview-wrapper {
  position: fixed;
  left: 0;
  z-index: $zix-header-cart-preview-wrapper;
  width: 100%;
}
</style>
