<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import throttle from 'lodash-es/throttle'
import { storeToRefs } from 'pinia'
import { Field as VeeField, Form as VeeForm } from 'vee-validate'

import { useUserInterface } from '@/components/AppHeader/useUserInterface'
import Icon from '@/components/Icon/Icon.vue'
import VoiceControl from '@/components/global/voiceControl.vue'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useModalStore from '@/store/pinia/useModalStore'
import { defineNoSpecialChars } from '@/utils/validations/rules'

defineNoSpecialChars()

type SearchBarProps = {
  isPharmacy: boolean
  hasAnyResults: boolean
  getFormState: () => Promise<any>
}

const props = defineProps<SearchBarProps>()

const emit = defineEmits<{
  closeSuggester: []
  sendForm: []
  performSearch: []
  clearSearchData: []
}>()

const searchTerm = defineModel<string>('searchTerm')
const hasResultsForThisSearch = defineModel<boolean>('hasResultsForThisSearch')
const formRef = defineModel<InstanceType<typeof VeeForm> | null>('formRef')
const searchTermError = defineModel<string>('searchTermError')

const deviceDetectorStore = useDeviceDetectorStore()
const { breakpointDownMd } = storeToRefs(deviceDetectorStore)

const searchTermInput = ref<HTMLInputElement>()

const { createModal, removeModal, openModal } = useModalStore()

const { isSuggesterVisible } = useUserInterface()

const showVoiceControl = deviceDetectorStore.hasSpeechRecognition

const isCrossVisible = computed(() => breakpointDownMd.value && isSuggesterVisible.value)

function openSuggester() {
  openModal('ProductSearch')
  nextTick(() => window.dispatchEvent(new Event('resize')))
}

const crossClicked = () => {
  searchTerm.value = ''
  emit('closeSuggester')
}

async function inputValidation(event: Event) {
  searchTerm.value = (event.target as HTMLInputElement).value

  const formState = await props.getFormState()
  searchTermError.value = formState?.errors.searchTerm ?? ''

  if (formState?.valid) {
    hasResultsForThisSearch.value = false
    searchingThrottle()
  }
}

function voiceRecognised(input: string) {
  searchTerm.value = input
  if (!isSuggesterVisible.value) {
    emit('clearSearchData')
    openSuggester()
  }
  emit('performSearch')
}

const searchingThrottle = throttle(() => {
  emit('performSearch')
}, appConfig.productSearch.resultsSuggestionThrottle)

onMounted(() => {
  createModal({
    id: 'ProductSearch',
    isOpen: false,
    collisionGroup: 'MobileHeader',
    hasBackdropScroll: false,
  })
})

onMounted(() => {
  const term = useRoute().query.search?.toString()
  if (term) {
    searchTerm.value = term
  }
})

onUnmounted(() => {
  removeModal('ProductSearch')
})

function onChange(event) {
  lastSearchType.value = 'default'
  inputValidation(event)
}

// analytics
const lastSearchType = ref<'default' | 'voice' | 'multiple'>('default')
</script>

<template>
  <div class="d-flex product-search__input-control">
    <button
      v-if="isCrossVisible"
      class="product-search__toggle"
      @click="crossClicked">
      <Icon icon="cross" />
    </button>

    <VeeForm
      ref="formRef"
      :class="[
        'd-flex',
        'flex-grow-1',
        'align-items-center',
        'product-search__form',
        {
          'product-search__form--is-focus': isSuggesterVisible && !breakpointDownMd,
        },
      ]"
      method="get"
      @submit="emit('sendForm')">
      <VeeField
        id="input-product-search"
        v-model="searchTerm"
        name="searchTerm">
        <input
          ref="searchTermInput"
          :value="searchTerm"
          :placeholder="t('productSearch.placeholder')"
          type="text"
          name="search"
          data-tid="search__input-field"
          :class="[
            'product-search__input',
            {
              'pr-3': !deviceDetectorStore.hasSpeechRecognition,
              'validation-errors__input': searchTermError,
            },
          ]"
          autocomplete="off"
          @focus="openSuggester"
          @input="onChange"
          @focusin="onChange"
          @keydown.exact="openSuggester"
          @keydown.tab.exact="props.hasAnyResults ? null : emit('closeSuggester')"
          @keydown.shift.tab.exact="emit('closeSuggester')"
          @keydown.esc="emit('closeSuggester')" />
      </VeeField>

      <VoiceControl
        v-if="showVoiceControl"
        class="product-search__voice-control pr-2 pl-1"
        @recognised="
          (event) => {
            lastSearchType = 'voice'
            voiceRecognised(event)
          }
        " />

      <button
        class="product-search__submit"
        type="submit">
        <Icon
          class="product-search__submit__icon"
          icon="search" />
      </button>
    </VeeForm>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-search {
  $form-height: var(--topbarFieldAndButtonHeight);

  &__input {
    flex: 1;
    min-width: 0;
    height: 100%;
    padding: 0 12px 0 12px;
    line-height: 1.2;
    background: color('neutrals-5');
    border: 0;
    border-radius: var(--borderRadiusSmall) 0 0 var(--borderRadiusSmall);

    @include make-font-scale(3);

    &::placeholder {
      opacity: 1;
    }

    &--cross-visible {
      padding-left: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 10px;
      padding-left: 10px;
      background: color('neutrals-5');
    }

    @include media-breakpoint-up(xl) {
      padding-right: 30px;
      padding-left: 10px;
    }
  }

  &__voice-control {
    height: 100%;
    background: color('gray-lighter');

    :deep(.icon) {
      width: 22px;
      height: 25px;
    }
  }

  $form-height-mobile: 40px;

  &__form {
    display: flex;
    height: $form-height-mobile;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: $zix-base;
      width: 100%;
      height: $form-height;
      border-top: 1px solid color('gray-light');
      border-bottom: 1px solid color('gray-light');
      border-left: 1px solid color('gray-light');
      border-radius: var(--borderRadiusSmall);
      transition: width 0.3s ease-in-out;
      transform: translateX(-50%);
      will-change: auto;
    }

    &--is-focus {
      border-top: 1px solid color('primary-light');
      border-bottom: 1px solid color('primary-light');
      border-left: 1px solid color('primary-light');
      border-radius: var(--borderRadiusSmall);

      @include media-breakpoint-up(lg) {
        left: 45%;
        width: 150%;
        height: $form-height;
      }

      .product-search__input {
        background: color('gray-lighter');
      }

      .product-search__voice-control {
        background: color('gray-lighter');
      }
    }
  }

  &__submit {
    box-sizing: content-box;
    width: 42px;
    height: 100%;
    padding: 0;
    color: color('white');
    background-color: color('primary');
    border: 0;
    border-radius: 0 var(--borderRadiusSmall) var(--borderRadiusSmall) 0;

    &:hover {
      background-color: hover-color('primary');
    }

    @include media-breakpoint-up(lg) {
      width: 55px;

      &:hover {
        background-color: hover-color('off-primary-mid');
      }
    }

    &__icon {
      width: 23px;
      height: 23px;
      margin-bottom: 1px;

      @include media-breakpoint-up(lg) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__bulk-search {
    height: 100%;
    padding-top: 5px;
    background: color('gray-lighter');

    a {
      color: color('primary');
      text-decoration: underline;
      cursor: pointer;

      @include make-font-scale(3);
    }
  }

  &__toggle {
    width: 34px;
    height: $form-height-mobile;
    padding: 0;
    margin: 0;
    color: color('gray-darker');
    border: 0;

    :deep(.icon) {
      width: 24px;
      height: 18px;
      padding-right: 0;
    }
  }

  .flex-grow-1 {
    flex-grow: 1;
  }
}
</style>
// Validation errors general styles
<style lang="scss" src="../Form/styles.scss" />
