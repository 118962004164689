<script lang="ts" setup>
import { NavBreadcrumb } from '@/componentsPure'
import type { TopItemsCategory } from '@/services/api/front/topItems'

type TopCategoriesProps = {
  topCategories: TopItemsCategory[]
}

const props = defineProps<TopCategoriesProps>()
</script>

<template>
  <NavBreadcrumb
    :key="props.topCategories.length"
    :items="props.topCategories"
    separator="" />
</template>
