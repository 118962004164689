<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import Icon from '@/components/Icon/Icon.vue'
import { t } from '@/services/translations'
import useAddressStore from '@/store/pinia/useAddressStore'

import AddressSelectorFreeDeliveryTag from './AddressSelectorFreeDeliveryTag.vue'
import useAddressSelectorButton from './useAddressSelectorButton'

const { open, buttonText, hasFreeDeliveryTag, earliestTimeslotsValid } = useAddressSelectorButton()
const { isUserStreetOrCityFilled, isUserStreetFilled, userAddress } = storeToRefs(useAddressStore())

const formatedAddress = computed(() => {
  const partialAddress = `${userAddress.value.city}, ${userAddress.value.zip}`

  return isUserStreetFilled.value
    ? `${userAddress.value.street}, ${partialAddress}`
    : partialAddress
})
</script>

<template>
  <div>
    <button
      type="button"
      data-tid="destination-address__open-modal"
      class="address-btn"
      @click="open('home-button')">
      <div class="address-btn__icon">
        <Icon icon="car" />
      </div>
      <div class="address-btn__container">
        <div>
          <AddressSelectorFreeDeliveryTag
            v-if="hasFreeDeliveryTag"
            class="mb-1" />
          <span
            v-else-if="earliestTimeslotsValid"
            class="address-btn__title">
            {{ t('addressSelector.buttonDelivery') }}
          </span>
          <span class="address-btn__title">
            {{ buttonText }}
          </span>
        </div>
        <span
          v-if="isUserStreetOrCityFilled"
          class="address-btn__address">
          {{ formatedAddress }}
        </span>
      </div>
      <div class="address-btn__arrow">
        <Icon icon="arrow-reversed" />
      </div>
    </button>
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.address-btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--topbarFieldAndButtonHeight);
  padding: 0;
  background: color('white');
  border: 0;

  &__icon {
    width: 46px;

    .icon {
      width: 26px;
      height: 26px;
      margin-left: -8px;
    }
  }

  &__arrow {
    width: 50px;

    .icon {
      transform: rotate(270deg);
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
  }

  &__title {
    @include make-font-size(3);

    font-weight: var(--fontWeightBold);
    color: color('black');
    letter-spacing: -0.045em;
  }

  &__address {
    @include make-font-size(1);

    overflow: hidden;
    color: color('gray');
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
