import type { InjectionKey } from 'vue'

import type { UserAddress } from '@/store/pinia/useAddressStore'

const hideTimeslotsKey = Symbol('hideTimeslots') as InjectionKey<() => void>
const saveAddressKey = Symbol('saveAddress') as InjectionKey<(address: UserAddress) => void>
const onChangeInputAddressKey = Symbol('onChangeInputAddress') as InjectionKey<
  (address: UserAddress) => void
>

export { hideTimeslotsKey, saveAddressKey, onChangeInputAddressKey }
