<script setup>
import { onMounted } from 'vue'

const emits = defineEmits(['voucher-send-full-form', 'voucher-send-form-after-ajax-is-complete'])

onMounted(() => {
  const voucher = document.querySelector('#voucher-setting')

  if (voucher) {
    voucher
      .querySelector('.voucher__text_wrapper .voucher__button')
      .addEventListener('click', dropVoucher)

    voucher.querySelector('input').addEventListener('keyup', () => {
      voucher.className = 'unused'
    })

    voucher.querySelector('input').addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        emits('voucher-send-full-form')
      }
    })

    emits('voucher-send-form-after-ajax-is-complete')
  }
})

function dropVoucher() {
  document.querySelector('#voucher-setting input').value = ''
  emits('voucher-send-full-form')
}
</script>

<!-- eslint-disable -->
<template></template>
