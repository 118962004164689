<script setup lang="ts">
import { VENDOR_CONFIG } from '@/constants/vendors'
import { t } from '@/services/translations'

type VendorConfigType = typeof VENDOR_CONFIG
type VendorNames = VendorConfigType[keyof VendorConfigType]['generalName']

type AppFooterContactsMenuProps = {
  title: string | false
  vendor: VendorNames
}

const props = defineProps<AppFooterContactsMenuProps>()
</script>

<template>
  <h6
    v-if="props.title"
    class="mb-3 font-weight-bold">
    {{ props.title }}
  </h6>
  <div :class="{ 'mb-3 d-flex flex-column flex-sm-row contacts': true, 'mt-n1': props.title }">
    <div class="mb-3 mb-lg-4 mr-sm-3 mr-md-4 mr-xl-5 d-flex">
      <div class="flex-shrink-0 mr-2 mr-md-3 a-avatar contacts-icon">
        <img
          loading="lazy"
          class="a-avatar__content"
          src="https://static-new.kosik.cz/files/nl/kosik/footer/question-mark-icon.svg?v=3"
          :alt="t(`document.footer.contacts.${props.vendor}.faq.title`)" />
      </div>
      <div class="d-flex flex-column text-2">
        <RouterLink
          class="font-weight-bold text-5 info-link"
          :to="t(`document.footer.contacts.${props.vendor}.faq.link`)"
          target="_top">
          {{ t(`document.footer.contacts.${props.vendor}.faq.title`) }}
        </RouterLink>
        {{ t(`document.footer.contacts.${props.vendor}.faq.subtitle`) }}
      </div>
    </div>

    <div class="mb-3 mb-lg-4 mr-sm-3 mr-md-4 mr-xl-5 d-flex">
      <div class="flex-shrink-0 mr-2 mr-md-3 a-avatar contacts-icon">
        <img
          loading="lazy"
          class="a-avatar__content"
          src="https://static-new.kosik.cz/files/nl/kosik/footer/call-icon.svg?v=3"
          :alt="t(`document.footer.contacts.${props.vendor}.phone.title`)" />
      </div>
      <div class="d-flex flex-column text-2">
        <a
          class="font-weight-bold text-5 info-link"
          :href="t(`document.footer.contacts.${props.vendor}.phone.link`)"
          target="_top">
          {{ t(`document.footer.contacts.${props.vendor}.phone.title`) }}
        </a>
        {{ t(`document.footer.contacts.${props.vendor}.phone.subtitle`) }}
      </div>
    </div>

    <div class="mb-3 mb-lg-4 d-flex">
      <div class="flex-shrink-0 mr-2 mr-md-3 a-avatar contacts-icon">
        <img
          loading="lazy"
          class="a-avatar__content"
          src="https://static-new.kosik.cz/files/nl/kosik/footer/mail-icon.svg?v=3"
          :alt="t(`document.footer.contacts.${props.vendor}.email.title`)" />
      </div>
      <div class="d-flex flex-column text-2">
        <a
          class="font-weight-bold text-5 info-link"
          :href="t(`document.footer.contacts.${props.vendor}.email.link`)"
          target="_top">
          {{ t(`document.footer.contacts.${props.vendor}.email.title`) }}
        </a>
        {{ t(`document.footer.contacts.${props.vendor}.email.subtitle`) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.contacts {
  @include media-breakpoint-up(lg) {
    padding-right: var(--previewCartWidth);
  }
}

.info-link {
  line-height: 1;
  color: color('primary');

  &:hover {
    text-decoration: underline;
  }
}

.contacts-icon {
  $size: 34px;

  width: $size;
  height: $size;
  background-color: color('primary');
}
</style>
