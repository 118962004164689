<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { fetchGet } from '@/services/api/fetch'

import PaginationInfinite from '../PaginationInfinite.vue'

type LattePaginationProps = {
  startMaxPage: number
  startActualPage: number
  snippet: string
  isProductList: boolean
  urlPattern: string
}

const props = defineProps<LattePaginationProps>()

const actualPage = ref(props.startActualPage)

const isLoading = ref(false)

const pageNext = computed(() => actualPage.value + 1)
const pageNextUrl = computed(() => {
  const urlPatternFormatted = props.urlPattern
    .replace(/&amp;/g, '&')
    .replace('#PAGE#', pageNext.value.toString())
  const urlPatternObject = new URL(window.location.origin + urlPatternFormatted)
  const paramsPattern = new URLSearchParams(urlPatternObject.search)
  const paramsUrl = new URLSearchParams(window.location.search)
  const newParams = paramsPattern

  for (const param of paramsUrl) {
    newParams.set(param[0], param[1])
  }

  const newParamsString = newParams.toString()
  const newUrl = urlPatternObject.pathname + (newParamsString ? '?' + newParamsString : '')

  return newUrl
})

const paginationInfinite = ref<InstanceType<typeof PaginationInfinite> | null>(null)

watch(
  () => props.startMaxPage,
  () => {
    if (paginationInfinite.value) {
      paginationInfinite.value?.resetLoadMoreInfinite()
    }
  },
)

type SnippetKey = `snippet--${string}`

type Snippets = Record<SnippetKey, string>

type State = {
  locale: string
  'paginator-page': number
}

type Paginator = {
  state: State
  snippets: Snippets
}

async function load() {
  if (isLoading.value) return
  else {
    isLoading.value = true
  }

  const {
    data: { snippets },
  } = await fetchGet<Paginator>(pageNextUrl.value)

  if (snippets) {
    setHtmlToSnippet(snippets['snippet--' + props.snippet])
    actualPage.value = pageNext.value
  }

  isLoading.value = false
}
function setHtmlToSnippet(htmlSnippet: string) {
  const elSnippet = document.getElementById('snippet--' + props.snippet)
  elSnippet?.insertAdjacentHTML('beforeend', htmlSnippet)
}
</script>

<template>
  <div>
    <template v-if="actualPage < props.startMaxPage">
      <PaginationInfinite
        v-show="!isProductList || (isProductList && !isLoading)"
        ref="paginationInfinite"
        :ghosts="isProductList"
        :spinner="!isProductList && isLoading"
        @load-next="load" />
    </template>
    <div
      v-else
      class="m-pagination" />
  </div>
</template>
