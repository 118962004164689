<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import Popup from '@/components/global/Popup/Popup.vue'
import { BaseButton } from '@/componentsPure'
import modalIndex from '@/constants/modalIndex'
import { t } from '@/services/translations'
import useModalStore from '@/store/pinia/useModalStore'

import checkoutConfirmLogic from './checkoutConfirmLogic'
import type { CheckoutSubDestinationModalTriggerOptions } from './types'

type CheckoutSubDestinationSwitchProps = {
  autoOpen?: 'true' | 'false' // true only in old checkout!
  changeAddressId?: string | null
  addressDraft?: string | null
  billingDraft?: string | null
}

const props = withDefaults(defineProps<CheckoutSubDestinationSwitchProps>(), {
  autoOpen: 'false',
  changeAddressId: null,
  addressDraft: null,
  billingDraft: null,
})

const { getModal, closeModal } = useModalStore()
const { push } = useRouter()

function closeSwitch() {
  closeModal(modalName.value)
}

const modalTriggerOptions = computed(() => {
  const modal = getModal<CheckoutSubDestinationModalTriggerOptions>(modalName.value)

  return modal?.triggerOptions
})

function onConfirm() {
  push({ name: 'TransportPayment' })
}

const isAutoOpen = props.autoOpen === 'true'

async function confirmSwitch() {
  if (isAutoOpen) {
    // latte checkout logic
    checkoutConfirmLogic(
      props.changeAddressId,
      props.addressDraft,
      props.billingDraft,
      closeSwitch,
      onConfirm,
    )
  } else {
    // AddressSelector logic
    if (!modalTriggerOptions.value) {
      return
    }

    modalTriggerOptions.value.onSaveAddress()
    closeSwitch()
  }
}

// because of different placements of modals (one in checkout latte, the othe on AppFooter) its necessary to have different names
const modalName = computed(() =>
  isAutoOpen
    ? modalIndex.checkoutSubDestinationSwitch
    : modalIndex.addressSelectorSubDestinationSwitch,
)
</script>

<template>
  <Popup
    content
    :auto-open="isAutoOpen"
    size="x-small"
    :name="modalName">
    <div class="text-center mb-3">
      <p class="checkout-sub-destination-switch__title mb-4">
        {{ t('checkout.subDestinationSwitch.title') }}
      </p>

      <img
        src="/img/map.svg?v=1"
        alt=""
        class="checkout-sub-destination-switch__image" />

      <p class="text-gray-dark my-4">
        {{ t('checkout.subDestinationSwitch.claim') }}
      </p>

      <BaseButton
        variant="primary"
        size="sm"
        class="mx-3"
        @click="confirmSwitch">
        {{ t('checkout.subDestinationSwitch.confirm') }}
      </BaseButton>
      <BaseButton
        variant="tertiary"
        size="sm"
        class="mx-3"
        @click="closeSwitch">
        {{ t('general.back') }}
      </BaseButton>
    </div>
  </Popup>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.checkout-sub-destination-switch {
  &__title {
    @include make-font-scale(7);
  }

  &__image {
    height: 46px;
  }

  &__button {
    width: 113px;
    padding: 10px 12px;
  }
}
</style>
