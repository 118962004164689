import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import useCartStore from '@/store/pinia/useCartStore'
import roundToDecimals from '@/utils/roundToDecimals'

import useDeliveryData from '../transport/useDeliveryData'

function useCartDeliveryData() {
  const { data: deliveryData } = useDeliveryData()
  const cartStore = useCartStore()
  const { cartGetters } = storeToRefs(cartStore)

  const forAnonymousCustomer = computed(() => deliveryData.value?.forAnonymousCustomer ?? false)
  const hasFreeTimeslots = computed(() => !!deliveryData.value?.earliestDeliveryTime)
  /**
   * Rounded in order to avoid floating point errors
   * @see Bug https://mallfresh.atlassian.net/browse/KNW-9632
   *  */
  const isMinimalOrderPrice = computed(() => {
    return (
      deliveryData.value &&
      roundToDecimals(cartGetters.value.getCartProductsRealPrice) >=
        deliveryData.value.minOrderThreshold
    )
  })

  const isFreeDelivery = computed(() => {
    return (
      deliveryData.value &&
      cartGetters.value.getCartProductsRealPrice >= deliveryData.value.freeDeliveryThreshold
    )
  })

  const isAlwaysFreeDelivery = computed(() => {
    if (!deliveryData.value) return false

    const { minOrderThreshold, freeDeliveryThreshold } = deliveryData.value
    return minOrderThreshold >= freeDeliveryThreshold
  })

  const getMinimalOrderPrice = computed(() => deliveryData.value?.minOrderThreshold)
  const getFreeDeliveryOrderPrice = computed(() => deliveryData.value?.freeDeliveryThreshold)
  const showFreeDeliveryInfo = computed(() => {
    return (isFreeDelivery.value && isMinimalOrderPrice.value) || cartGetters.value.hasFreeDelivery
  })

  const showProgressInfo = computed(() => {
    const hasFreeDelivery = cartGetters.value.hasFreeDelivery

    const showForStandardUser =
      !forAnonymousCustomer.value &&
      (!isMinimalOrderPrice.value || !isFreeDelivery.value) &&
      !hasFreeDelivery

    // user with free delivery tag (in segments/newBuyers/etc)
    const showForFreeDeliveryUser =
      !forAnonymousCustomer.value &&
      !isMinimalOrderPrice.value &&
      !isFreeDelivery.value &&
      hasFreeDelivery

    const showForAnonymousUser = forAnonymousCustomer.value && !isMinimalOrderPrice.value

    return showForStandardUser || showForFreeDeliveryUser || showForAnonymousUser
  })

  return {
    isMinimalOrderPrice,
    isFreeDelivery,
    isAlwaysFreeDelivery,
    getMinimalOrderPrice,
    getFreeDeliveryOrderPrice,
    forAnonymousCustomer,
    showFreeDeliveryInfo,
    showProgressInfo,
    hasFreeTimeslots,
  }
}

export default useCartDeliveryData
