import { createApp } from 'vue'
import Toast from 'vue-toastification'

import '@kosik/ui/styles/styles.css'
import { VueQueryPlugin } from '@tanstack/vue-query'
import 'core-js/stable/object/has-own'
import { createPinia } from 'pinia'
import 'url-polyfill'
import 'vite/modulepreload-polyfill'

/*
 *
 *  Registering Vue components for global scope of the app
 *  Need to also be added to components inside the Vue object below
 *
 */
import ActionMenuItem from '@/components/ActionMenu/ActionMenuItem.vue'
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'
import AppMain from '@/components/AppMain'
import CartCheckout from '@/components/CartCheckout'
import CartCheckoutControls from '@/components/CartCheckoutControls'
import CartPriceContentInfoSection from '@/components/CartPriceContentInfoSection'
import CartSummary from '@/components/CartSummary'
import { CheckoutTimeslots, CheckoutPayment, CheckoutDeliveryNote } from '@/components/Checkout'
import CheckoutDestinationSwitch from '@/components/Checkout/CheckoutDestinationSwitch.vue'
import CheckoutSubDestinationSwitch from '@/components/Checkout/CheckoutSubDestinationSwitch.vue'
import { CheckoutPrice } from '@/components/CheckoutPrice'
import Calculator from '@/components/Clubs/Benjaminek/Calculator.vue'
import ConsentNewsletter from '@/components/Consent/ConsentNewsletter.vue'
import Icon from '@/components/Icon/Icon.vue'
import MobileAppSuggester from '@/components/MobileAppSuggester/MobileAppSuggester.vue'
import { OrdersList } from '@/components/Orders'
import PaymentFingerprint from '@/components/Payment/Fingerprint.vue'
import UserCard from '@/components/User/Card/UserCard.vue'
import UserNavToggle from '@/components/UserNavToggle'
import UtilScript from '@/components/Util/Script/UtilScript.vue'
import WidgetAdmin from '@/components/Widget/WidgetAdmin.vue'
import WidgetRecipe from '@/components/Widget/WidgetRecipe.vue'
import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'
import Closable from '@/components/global/Closable.vue'
import Popup from '@/components/global/Popup/Popup.vue'
import LatteCheckoutRegistration from '@/components/global/latte/LatteCheckoutRegistration.vue'
import LatteFlashMessage from '@/components/global/latte/LatteFlashMessage.vue'
import LatteInputAddress from '@/components/global/latte/LatteInputAddress.vue'
import LatteInputTel from '@/components/global/latte/LatteInputTel.vue'
import LattePagination from '@/components/global/latte/LattePagination.vue'
import { BaseButton } from '@/componentsPure'
import router from '@/router'
import '@/services/dayjs'
import '@/services/external_libraries/external_libs_main'
import kosikUi from '@/services/kosikUi'
import '@/services/polyfills/scrollIntoViewIfNeeded'
import queryClient from '@/services/queryClient'
import telemetryPlugin from '@/services/telemetry'
import { toastOptions } from '@/services/toast'
import { i18n } from '@/services/translations'
import store from '@/store'
import '@/styles/index.scss'
import veeValidatePlugin from '@/utils/validations'

import { gtmEventCkConsentStatus } from './services/analytics/gtm'
import appConfig from './services/appConfig'
import { getCookie } from './services/cookies'
import { growthbookPlugin } from './services/growthbook'
import { cookieName } from './services/privacy'

// this must always the first thing to be called, before any other dataLayer push
if (getCookie(cookieName)) {
  gtmEventCkConsentStatus()
}

const app = createApp({
  name: 'MainApp',
  components: {
    ActionMenuItem,
    CheckoutPrice,
    OrdersList,
    Popup,
    LatteInputTel,
    Icon,
    LattePagination,
    MobileAppSuggester,
    BaseButton,
    Closable,
    CarouselWrapper,
    CarouselItem,
    LatteInputAddress,
    CheckoutDestinationSwitch,
    CheckoutSubDestinationSwitch,
    AppHeader,
    AppFooter,
    AppMain,
    CartCheckout,
    CartCheckoutControls,
    CartPriceContentInfoSection,
    CartSummary,
    LatteFlashMessage,
    WidgetAdmin,
    WidgetRecipe,
    CheckoutTimeslots,
    UserCard,
    UserNavToggle,
    LatteCheckoutRegistration,
    UtilScript,
    ConsentNewsletter,
    Calculator,
    PaymentFingerprint,
    CheckoutPayment,
    CheckoutDeliveryNote,
  },
})

app
  .use(createPinia())
  .use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true })
  .use(router)
  .use(store.original)
  .use(i18n)
  .use(kosikUi)
  .use(Toast, toastOptions)
  .use(veeValidatePlugin)
  .use(telemetryPlugin, { router, ...appConfig.telemetry })
  .use(growthbookPlugin, { enabled: true })

router
  .isReady()
  .then(() => {
    app.mount('#app')
  })
  .catch((error) => {
    console.error(error)
  })
