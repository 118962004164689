/**
 * Calculates number of pixels of provided element which are in the viewport.
 * @param el Element to be calculated on. If string is provided, it is treated as ID of the element.
 * @returns Number of element's pixels in viewport.
 */
function viewportElementPxCount(el: HTMLElement | string) {
  const selectedElement = typeof el === 'string' ? document.getElementById(el) : el

  if (selectedElement === null) {
    return 0
  }

  const elRect = selectedElement.getBoundingClientRect()

  if (elRect.bottom <= 0 || window.innerHeight <= elRect.top) {
    return 0
  }

  const top = Math.max(elRect.top, 0)
  const bottom = Math.min(elRect.bottom, window.innerHeight)

  return bottom - top
}

export { viewportElementPxCount }
