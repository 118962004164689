<script setup lang="ts">
import { computed } from 'vue'

type NotificationCountProps = {
  count: number
}

const props = defineProps<NotificationCountProps>()

const maxToDisplay = 9
const countToDisplay = computed(() =>
  props.count > maxToDisplay ? `${maxToDisplay}+` : props.count,
)
</script>

<template>
  <strong
    class="count"
    v-text="countToDisplay" />
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.count {
  @include make-font-scale(2);

  $size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  color: color('gray-darker');
  background-color: color('accent-yellow');
  border-radius: 50%;
}
</style>
