<script setup>
import { onMounted, ref } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import appConfig from '@/services/appConfig'

const emit = defineEmits(['recognised'])

const speechRecognition = ref(null)
const isAvailable = ref(false)
const isListening = ref(false)

const initSpeechRecognition = () => {
  speechRecognition.value.lang = appConfig.defaultLang
  speechRecognition.value.onspeechend = stopListening
  speechRecognition.value.onerror = stopListening

  speechRecognition.value.onresult = (event) => {
    if (typeof event.results === 'undefined') {
      this.stopListening()
      return
    }

    const input = event.results[0][0].transcript
    if (input.length > 1) {
      emit('recognised', input)
    }
  }
}

const startListening = () => {
  isListening.value = true
  speechRecognition.value.start()
}

const stopListening = () => {
  isListening.value = false
  speechRecognition.value.stop()
}

const toggleListening = () => {
  isListening.value ? stopListening() : startListening()
}

onMounted(() => {
  window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

  speechRecognition.value = new window.SpeechRecognition()
  isAvailable.value = true
  initSpeechRecognition()
})
</script>

<template>
  <button
    v-if="isAvailable"
    type="button"
    :class="[
      'voice-control',
      {
        'voice-control--listening': isListening,
      },
    ]"
    @click="toggleListening">
    <Icon
      icon="mic"
      class="voice-control__icon" />
  </button>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.voice-control {
  padding: 0;
  color: color('gray');
  background: transparent;
  border: 0;

  &--listening {
    animation: red-pulse 1s ease infinite alternate;
  }

  &__icon {
    width: 100%;
    height: 100%;
  }
}

@keyframes red-pulse {
  0% {
    color: color('gray');
  }

  100% {
    color: color('red');
  }
}
</style>
