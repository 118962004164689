<template>
  <input
    type="hidden"
    name="transport_window_hour_id"
    :value="getSelectedTimeslot?.id || null" />
  <CheckoutPriceVoucher
    @voucher-send-full-form="send_full_form"
    @voucher-send-form-after-ajax-is-complete="sendFormAfterAjaxIsComplete" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { charityServiceSetPersistValue } from '@/components/Charity/services'
import { CheckoutPriceVoucher } from '@/components/Checkout'
import { fetchPost } from '@/services/api/fetch'
import { toastError } from '@/services/toast'
import useTimeslotsStore from '@/store/pinia/useTimeslotsStore'

const standardisePriceData = (prices) => {
  return {
    total: prices.finalOrderPrice,
    weightedProductDeposite: prices.overchargePrice,
    returnablePackaging: prices.returnablePrice,
    tip: prices.tipPrice,
    transport: prices.transportPrice,
    credit: prices.credits,
    voucher: prices.voucherDiscount,
  }
}

export default {
  name: 'CheckoutPrice',

  components: {
    CheckoutPriceVoucher,
  },

  props: {
    priceObject: {
      type: Object,
      required: false,
      default: () => ({
        credit: 0,
        voucher: 0,
        returnablePackaging: 0,
        transport: 0,
        weightedProductDeposite: 0,
        total: 0,
        tip: 0,
      }),
    },
    allowedTipPaymentIdsArray: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      allowedTipPaymentIds: this.allowedTipPaymentIdsArray,
      formIsSending: false,
      formWantsToBeSent: false,
      formWantsToBeSentNextPage: false,
    }
  },

  computed: {
    ...mapGetters('cart', ['getSelectedTimeslot', 'isCartLoaded']),
  },

  watch: {
    isCartLoaded() {
      if (this.isCartLoaded) {
        charityServiceSetPersistValue()
      }
    },
  },

  created() {
    this.updateCartPrices(standardisePriceData(this.priceObject))
  },

  mounted() {
    const inputSendForm = document.querySelectorAll(
      '.js-transport-window-form input:not(.js-timeslots-day-input)',
    )
    const selectedPayment = document.querySelector(
      'input[name="payment[payment_project_domain_id]"]:checked',
    )
    const tipSection = document.querySelector('#tip-setting')

    if (tipSection && selectedPayment) {
      const paymentProjectDomainId = parseInt(selectedPayment.value)

      if (!this.allowedTipPaymentIds.includes(paymentProjectDomainId)) {
        tipSection.style.display = 'none'
      }
    }

    inputSendForm.forEach((el) => {
      const eventType = el.type === 'date' ? 'blur' : 'change'
      el.addEventListener(eventType, this.send_full_form)
    })
  },

  methods: {
    ...mapActions('cart', ['updateCartPrices']),

    sendFormAfterAjaxIsComplete() {
      const form = document.getElementById('frm-transportPaymentForm')

      form.addEventListener('submit', (e) => {
        if (this.formIsSending) {
          e.preventDefault()
          if (!this.formWantsToBeSentNextPage) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this
            this.formWantsToBeSentNextPage = setTimeout(() => {
              self.formIsSending = false
              form.submit()
            }, 5000)
          }
        }
      })
    },
    async send_full_form() {
      const form = document.getElementById('frm-transportPaymentForm')
      const formData = new FormData(form)
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this

      if (this.formIsSending) {
        clearTimeout(this.formWantsToBeSentNextPage)
        this.formWantsToBeSentNextPage = false

        if (!this.formWantsToBeSent) {
          this.formWantsToBeSent = setTimeout(() => {
            self.formIsSending = false
            self.send_full_form()
          }, 5000)
        }

        return
      }

      this.formIsSending = true

      const tipSection = document.querySelector('#tip-setting')

      if (tipSection && formData.has('payment[payment_project_domain_id]')) {
        const paymentProjectDomainId = parseInt(formData.get('payment[payment_project_domain_id]'))

        if (this.allowedTipPaymentIds.includes(paymentProjectDomainId)) {
          tipSection.style.display = 'block'
        } else {
          tipSection.style.display = 'none'
        }
      }

      const { data } = await fetchPost(form.action, undefined, undefined, formData)

      if (data.redirect) {
        window.location.replace(data.redirect)
      } else {
        const voucherSetting = document.getElementById('voucher-setting')
        this.updateCartPrices(standardisePriceData(data))
        const { fetchCartTimeslots } = useTimeslotsStore()
        fetchCartTimeslots()

        if (data.voucher.status === 'error') {
          voucherSetting.className = 'invalid unused'
          voucherSetting.querySelector('.voucher__message').innerHTML = data.voucher.message
        } else if (data.voucher.code) {
          voucherSetting.className = 'used'
          voucherSetting.querySelector('.voucher__notice').innerHTML = data.voucher.notice || ''
          voucherSetting.querySelector('.js-voucher-code').innerHTML = data.voucher.code
          voucherSetting.querySelector('.js-voucher-sale').innerHTML = ` (${data.voucher.sale})`
        } else {
          voucherSetting.className = 'unused'
        }
      }

      if (data.errorFlashMessage) {
        toastError(data.errorFlashMessage)
      }

      this.formIsSending = false

      if (this.formWantsToBeSent) {
        clearTimeout(this.formWantsToBeSent)
        this.formWantsToBeSent = false
        this.send_full_form()
      } else if (this.formWantsToBeSentNextPage) {
        clearTimeout(this.formWantsToBeSentNextPage)
        this.formWantsToBeSentNextPage = false
        form.submit()
      }
    },
  },
}
</script>
