import { useRoute } from 'vue-router'

import type { CartReloadPageInfo } from '@/services/api/front/cartTypes'

import { PageType, getReloadPageInfoTypeValues } from './pageType'

function useReloadPageInfo() {
  const route = useRoute()

  function getReloadPageInfo(): CartReloadPageInfo | null {
    let categoryUrl
    let productUrl
    let staticPageUrl

    const allowPageType = getReloadPageInfoTypeValues()
    const allowCategoryPageType = [
      PageType.Category.toString(),
      PageType.SpecificCategory.toString(),
    ]
    const pageType = route.meta.pageType ?? ''
    const component = route.meta.component

    if (!allowPageType.includes(pageType)) {
      return null
    }

    if (allowCategoryPageType.includes(pageType)) {
      categoryUrl = route.path ?? undefined
    }

    if (pageType === PageType.Product && route.meta.component === 'PageProductDetail') {
      productUrl = route.path ?? undefined
    }

    if (pageType === PageType.Content && component === 'PageStatic') {
      staticPageUrl = route.params.id ?? undefined
    }

    return {
      type: pageType,
      categoryUrl,
      productUrl,
      staticPageUrl,
    }
  }

  return getReloadPageInfo
}

export default useReloadPageInfo
