import { toRaw } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { paymentQueryKeys } from '@/constants/queryKeys'
import { googlePayGetEchoData } from '@/services/googlePay'

/**
 * Fetches data needed for creation of a Google Pay client
 */
function usePaymentGooglePayEcho() {
  return useQuery({
    queryKey: paymentQueryKeys.googlePayEcho(),
    queryFn: googlePayGetEchoData,
    select: (data) => structuredClone(toRaw(data)),
  })
}

export default usePaymentGooglePayEcho
