<script setup lang="ts">
import { onMounted, computed, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Error401 from '@pages/Error/401.vue'

import useUser from '@/composables/useUser'
import { useDynamicRoutes } from '@/router'
import usePageViewAnalytics from '@/services/analytics/usePageViewAnalytics'

import useInitUuid from './useInitUuid'
import useWrapperComponent from './useWrapperComponent'

useInitUuid() // Init anonymous user if needed
const { wrapperComponentName, wrapperComponents } = useWrapperComponent()

const { isUserSignedIn } = useUser()
const route = useRoute()
const sendPageView = usePageViewAnalytics()

watch(
  () => route.fullPath,
  () => {
    sendPageView({ pageData: {}, isClientSideRouted: true, sendToLugisBox: true })
  },
)

// Actions performed after dynamic routes are ready.
const isRoutesLoaded = useDynamicRoutes(() => {
  sendPageView({ pageData: {}, isClientSideRouted: false, sendToLugisBox: true })
})

/**
 * Returns true if the page is accessible to current user.
 * (Some pages are accessible only to signed-in users.)
 */
const hasAuthorizedContentAccess = computed(() => !route.meta.hasAuthorizedAccess || isUserSignedIn)

// Post-render start-up stuff
onMounted(() => {
  // Brand icon shown on background before app is initialized
  document.documentElement.classList.remove('beforeVueInit')
})
</script>

<template>
  <Component
    :is="wrapperComponents[wrapperComponentName]"
    v-if="isRoutesLoaded">
    <div>
      <slot name="latte-header" />
      <slot name="latte-flash" />
      <slot name="latte-content-head" />
      <RouterView
        v-if="hasAuthorizedContentAccess"
        v-slot="{ Component: RouteComponent }">
        <Component :is="RouteComponent">
          <slot name="latte-content" />
        </Component>
      </RouterView>
      <Error401
        v-else
        class="py-5 my-5" />
    </div>
  </Component>
</template>
