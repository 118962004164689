<script setup lang="ts">
import ImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import type { Banner } from '@/data/api/frontApi/banners/bannerTypes'
import { resizer } from '@/services/resizer'

type TopBannerProps = {
  banner: Banner
}

const props = defineProps<TopBannerProps>()
</script>

<template>
  <!-- TODO href: Change to RouterLink after BE starts sending a relative link -->
  <a
    :href="props.banner.link"
    class="d-block">
    <ImgResponsive
      :alt="props.banner.name"
      class="image"
      :srcset="[
        {
          url: resizer(props.banner.images.main, 880),
        },
        {
          url: resizer(props.banner.images.main, 1300),
          pixelRatio: 1.5,
        },
      ]"
      lazyload />
  </a>
</template>

<style scoped lang="scss">
.image {
  border-radius: var(--borderRadiusMedium);
}
</style>
